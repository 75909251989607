import { IControl } from "./IControls";
import { DocumentType } from './../../viewModels/IDocumentSignatureDataViewModel';

export interface IDocument {

	id: number;
	name: string;
	url: string;
	disabled: boolean;
	documentType: DocumentType;
	pages: IPage[];
	allControlSigned: boolean;
}

export class Document implements IDocument {

	id: number;
	name: string;
	url: string;
	disabled: boolean;
	documentType: DocumentType;
	pages: IPage[];
	allControlSigned: boolean;

	constructor(id: number, name: string, url: string, disabled: boolean, documentType: DocumentType, pages: IPage[], allControlSigned: boolean) {

		this.id = id;
		this.name = name;
		this.url = url;
		this.disabled = disabled;
		this.documentType = documentType;
		this.pages = pages;
		this.allControlSigned = allControlSigned;
	}


	public static create(id: number, name: string, url: string, disabled: boolean, documentType: DocumentType, pages: IPage[], allControlSigned: boolean): IDocument {
		return new Document(id, name, url, disabled, documentType, pages, allControlSigned);
	}

	public static createNullObject(): IDocument {
		return new Document(0, "", "", false, DocumentType.createNullObject(), [], false);
	}
}


export interface IPage {
	page: number;
	formName: string;
	bookmark: string;
	controls: IControl[];
	allControlSigned: boolean;
}

export class Page implements IPage {
	page: number;
	formName: string;
	bookmark: string;
	controls: IControl[];
	allControlSigned: boolean;


	constructor(page: number, formName: string, bookmark: string, controls: IControl[], allControlSigned: boolean) {
		this.bookmark = bookmark,
			this.formName = formName,
			this.page = page;
		this.controls = controls;
		this.allControlSigned = allControlSigned;

	}

	public static create(page: number, formName: string, bookmark: string, controls: IControl[], allControlSigned: boolean): IPage {
		return new Page(page, formName, bookmark, controls, allControlSigned);
	}

}


