import { Action, Reducer } from 'redux';
import { AxiosResponse } from 'axios';
import { AppThunkAction } from './../';
import { actionTypes } from './../ActionTypes';
import {
    StatusType,
    NotificationAction
} from './../Common/NotificationStore';
import { ILoader } from '../../core/utilities/ui/Loader';
import { TYPES } from '../../startup/types';
import { container } from '../../startup/inversify.config';
import {
    ErrorMessages,
    DelegateeSignerConstants
} from '../../components/Common/Constants';
import { IDocumentReviewModel, initialDocumentReviewModel } from 'src/core/domain/viewModels/IDocumentReviewModel';
import { IDelegateeConsentInfo, IDelegateeInfo, IDelegateeSignerDetails, initialDelegateeInfoState } from 'src/core/domain/models/DelegateeModels';
import { ICCRecipientDownloadableDocuments, initialModalState } from 'src/core/domain/models/ICCRecipientModel';
import { initializeAxios } from 'src/core/services/dataAccess/DataService.Axios';

interface RequestDelegateeDocumentReviewModelAction {
    type: actionTypes.DELEGATEE_DOCUMENT_REVIEW_MODEL_REQUEST;
}

interface ResponseDelegateeDocumentReviewModelAction {
    type: actionTypes.DELEGATEE_DOCUMENT_REVIEW_MODEL_RESPONSE;
    data: IDocumentReviewModel[];
}
interface FailureDelegateeDocumentReviewModelAction {
    type: actionTypes.DELEGATEE_DOCUMENT_REVIEW_MODEL_FAILURE;
}

interface RequestDelegateeInfoAction {
    type: actionTypes.DELEGATEE_DETAILS_REQUEST;
    clientId: string;
}

interface ResponseDelegateeInfoAction {
    type: actionTypes.DELEGATEE_DETAILS_RESPONSE;
    delegateeSigner: IDelegateeInfo;
}

interface FailureDelegateeInfoAction {
    type: actionTypes.DELEGATEE_DETAILS_FAILURE;
}

interface RequestDelegateeDownloadableDocuments {
    type: actionTypes.DELEGATEE_DOWNLOADABLE_DOCUMENTS_REQUEST;
    clientId: string;
}

interface ResponseDelegateeDownloadableDocuments {
    type: actionTypes.DELEGATEE_DOWNLOADABLE_DOCUMENTS_RESPONSE;
    data: ICCRecipientDownloadableDocuments[];
}

interface FailuerDelegateeDownloadableDocuments {
    type: actionTypes.DELEGATEE_DOWNLOADABLE_DOCUMENTS_FAILURE;
    clientId: string;
}

interface UpdateDelegateePhoneNumberRequest {
    type: actionTypes.DELEGATEE_UPDATE_PHONE_NUMBER_REQUEST;
    clientId: string;
}

interface UpdateDelegateePhoneNumberResponse {
    type: actionTypes.DELEGATEE_UPDATE_PHONE_NUMBER_RESPONSE;
    countryCode: string;
    phoneNumber: string;
}

interface RequestTaxClientDelegateeDetails {
    type: actionTypes.TAXCLIENT_DELEGATEE_DETAILS_REQUEST;
    clientId: string;
}

interface ResponseTaxClientDelegateeDetails {
    type: actionTypes.TAXCLIENT_DELEGATEE_DETAILS_RESPONSE;
    delegateeSigner: IDelegateeInfo;
}

interface FailureTaxClientDelegateeDetails {
    type: actionTypes.TAXCLIENT_DELEGATEE_DETAILS_FAILURE;
}

interface RequestUpdateDelegateeSigner {
    type: actionTypes.UPDATE_DELEGATEE_DETAILS_REQUEST;
    clientId: string;
}

interface ResponseUpdateDelegateeSigner {
    type: actionTypes.UPDATE_DELEGATEE_DETAILS_RESPONSE;
    updatedDelegatee: IDelegateeSignerDetails;
}

interface FailureUpdateDelegateeSigner {
    type: actionTypes.UPDATE_DELEGATEE_DETAILS_FAILURE;
}

interface UpdateDelegateePhoneNumberFailure {
    type: actionTypes.DELEGATEE_UPDATE_PHONE_NUMBER_FAILURE;
}

interface ResponseSaveDelegateeConsentInfo {
    type: actionTypes.SAVE_DELEGATEE_CONSENT_DETAILS_RESPONSE;
    isSuccess: boolean;
}

interface FailureSaveDelegateeConsentInfo {
    type: actionTypes.SAVE_DELEGATEE_CONSENT_DETAILS_FAILURE;
}

type KnownAction =
    DispatchAction |
    NotificationAction;

type DispatchAction = RequestDelegateeDocumentReviewModelAction
    | ResponseDelegateeDocumentReviewModelAction
    | FailureDelegateeDocumentReviewModelAction
    | RequestDelegateeInfoAction
    | ResponseDelegateeInfoAction
    | FailureDelegateeInfoAction
    | RequestDelegateeDownloadableDocuments
    | ResponseDelegateeDownloadableDocuments
    | FailuerDelegateeDownloadableDocuments
    | UpdateDelegateePhoneNumberRequest
    | UpdateDelegateePhoneNumberResponse
    | UpdateDelegateePhoneNumberFailure
    | RequestTaxClientDelegateeDetails
    | ResponseTaxClientDelegateeDetails
    | FailureTaxClientDelegateeDetails
    | RequestUpdateDelegateeSigner
    | ResponseUpdateDelegateeSigner
    | FailureUpdateDelegateeSigner
    | ResponseSaveDelegateeConsentInfo
    | FailureSaveDelegateeConsentInfo;


const loader = container.get<ILoader>(TYPES.ILoader);

export const actionCreators = {
    assignToDelegatee: (clientId: string, delegatee: IDelegateeSignerDetails,
        callback: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
            loader.show();
            return initializeAxios().postJson<boolean>(delegatee, '/api/DelegateeSigner/AssignToDelegatee/' + clientId)
                .then(function (response: any) {
                    loader.hide();
                    callback();
                })
                .catch(function (error: any) {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: error.response ? error.response.statusText :
                            DelegateeSignerConstants.ErrorMessage.ErrorSavingDelegateeDetails,
                        statusType: StatusType.Error
                    });
                });
        },

    updateDelegatee: (clientId: string, delegatee: IDelegateeSignerDetails, taxyear: number
        ,callback: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
            loader.show();
            return initializeAxios().postJson<boolean>(delegatee,`/api/DelegateeSigner/UpdateDelegatee/${clientId}?taxYear=${taxyear}`)
                .then(function (response: any) {
                    loader.hide();
                    if (response.data) {
                        dispatch({ type: actionTypes.UPDATE_DELEGATEE_DETAILS_RESPONSE, updatedDelegatee: delegatee });
                        callback();
                    }
                    else {
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage:
                                DelegateeSignerConstants.WarningMessage.WarningUpdatingDelegatee,
                            statusType: StatusType.Warning
                        });
                    }
                })
                .catch(function (error: any) {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: error.response ? error.response.statusText :
                            DelegateeSignerConstants.ErrorMessage.ErrorUpdatingDelegatee,
                        statusType: StatusType.Error
                    });
                });
        },

    cancelDelegation: (clientId: string, remarks: string, taxYear: number,
        callback: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
            loader.show();
            return initializeAxios().post<boolean>('/api/DelegateeSigner/CancelDelegation/' + clientId +
                '?remarks=' + remarks + "&taxYear=" + taxYear)
                .then(function (response: any) {
                    loader.hide();
                    if (response.data) {
                        callback();
                    }
                    else {
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage:
                                DelegateeSignerConstants.WarningMessage.WarningCancelDelegation,
                            statusType: StatusType.Warning
                        });
                    }
                })
                .catch(function (error: any) {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: error.response ? error.response.statusText :
                            DelegateeSignerConstants.ErrorMessage.ErrorCancelingDelegation,
                        statusType: StatusType.Error
                    });
                });
        },

    requestDelegateeDetails: (clientId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.DELEGATEE_DETAILS_REQUEST, clientId: clientId });
        return initializeAxios().get<IDelegateeInfo>('/api/DelegateeSigner/GetDelegateeSignerInfo/' + clientId)
            .then(function (response: any) {
                dispatch({
                    type: actionTypes.DELEGATEE_DETAILS_RESPONSE, delegateeSigner: response.data
                });
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: error.response ? error.response.statusText :
                        DelegateeSignerConstants.ErrorMessage.ErrorFetchingDelegatee,
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.DELEGATEE_DETAILS_FAILURE });
            });
    },

    requestDelegateeDocumentReviewModel: (clientId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.DELEGATEE_DOCUMENT_REVIEW_MODEL_REQUEST });
        return initializeAxios().get<IDocumentReviewModel[]>('/api/DelegateeSigner/GetDelegateeDocumentReviewModel/' + clientId)
            .then(function (response: AxiosResponse<IDocumentReviewModel[]>) {

                dispatch({
                    type: actionTypes.DELEGATEE_DOCUMENT_REVIEW_MODEL_RESPONSE, data: response.data
                });

            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.Response ? error.response.statusText :
                        ErrorMessages.DocumentReviewModel,
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.DELEGATEE_DOCUMENT_REVIEW_MODEL_FAILURE });
            });
    },

    requestDelegateeDownloadableDocuments: (clientId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();
        dispatch({ type: actionTypes.DELEGATEE_DOWNLOADABLE_DOCUMENTS_REQUEST, clientId: clientId });
        return initializeAxios().get<ICCRecipientDownloadableDocuments>(
            '/api/DelegateeDownload/GetDelegateeDownloadableDocuments/' + clientId)
            .then(function (response: AxiosResponse<ICCRecipientDownloadableDocuments[]>) {

                dispatch({
                    type: actionTypes.DELEGATEE_DOWNLOADABLE_DOCUMENTS_RESPONSE, data: response.data
                });
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText
                        : "Failed to fetch document list",
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.DELEGATEE_DOWNLOADABLE_DOCUMENTS_FAILURE, clientId: clientId });
            });
    },

    saveDeclinedRemarks: (clientId: string, remarks: string,
        callback: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
            loader.show();
            return initializeAxios().post<boolean>('/api/DelegateeSigner/DeclineSign/' + clientId + '?remarks=' + remarks)
                .then(function (response: any) {
                    loader.hide();
                    callback();
                })
                .catch(function (error: any) {
                    dispatch({
                        type: actionTypes.NOTIFICATION, statusMessage: error.response,
                        statusType: StatusType.Error
                    });
                });
        },

    updateDelegateeMobileNumber: (clientId: string, countryCode: string, mobileNumber: string, callback?: () => void):
        AppThunkAction<KnownAction> => (dispatch, getState) => {
            loader.show();
            dispatch({ type: actionTypes.DELEGATEE_UPDATE_PHONE_NUMBER_REQUEST, clientId: clientId });
            return initializeAxios().post<boolean>('/api/DelegateeSigner/UpdateDelegateeMobileNumber/'
                + clientId + '?countryCode=' + encodeURIComponent(countryCode)
                + "&mobileNumber=" + mobileNumber)
                .then(function (response: any) {
                    loader.hide();
                    dispatch({
                        type: actionTypes.DELEGATEE_UPDATE_PHONE_NUMBER_RESPONSE,
                        countryCode: countryCode, phoneNumber: mobileNumber
                    });
                    if (callback) {
                        callback();
                    }
                })
                .catch(function (error: any) {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: error.response ? error.response.statusText : ErrorMessages.ChangeMobileNumberError,
                        statusType: StatusType.Error
                    });
                });
        },
    requestTaxClientDelegateeDetails: (clientId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.TAXCLIENT_DELEGATEE_DETAILS_REQUEST, clientId: clientId });
        loader.show();
        return initializeAxios().get<IDelegateeInfo>('/api/DelegateeSigner/GetDelegateeSignerDetailsForTaxClient/' + clientId)
            .then(function (response: any) {
                dispatch({
                    type: actionTypes.TAXCLIENT_DELEGATEE_DETAILS_RESPONSE, delegateeSigner: response.data
                });
                loader.hide();
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: error.response ? error.response.statusText :
                        DelegateeSignerConstants.ErrorMessage.ErrorFetchingDelegatee,
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.TAXCLIENT_DELEGATEE_DETAILS_FAILURE });
            });
    },

    saveDelegateeConsentDetails: (clientId: string, consentDetails: IDelegateeConsentInfo, callback: () => void):
        AppThunkAction<KnownAction> => (dispatch, getState) => {
            loader.show();
            return initializeAxios().postJson<IDelegateeInfo>(consentDetails, '/api/DelegateeSigner/SaveDelegateeKBAConsent/' + clientId)
                .then(function (response: any) {
                    callback();
                    dispatch({
                        type: actionTypes.SAVE_DELEGATEE_CONSENT_DETAILS_RESPONSE, isSuccess: response.data
                    });
                    loader.hide();
                })
                .catch(function (error: any) {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: error.response ? error.response.statusText :
                            DelegateeSignerConstants.ErrorMessage.ErrorFetchingDelegatee,
                        statusType: StatusType.Error
                    });
                    dispatch({ type: actionTypes.SAVE_DELEGATEE_CONSENT_DETAILS_FAILURE });
                });
        }

}

export const delegateeReviewReducer: Reducer<IDocumentReviewModel[]> = (state: IDocumentReviewModel[] = initialDocumentReviewModel, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    switch (action.type) {
        case actionTypes.DELEGATEE_DOCUMENT_REVIEW_MODEL_REQUEST:
            return initialDocumentReviewModel;
        case actionTypes.DELEGATEE_DOCUMENT_REVIEW_MODEL_RESPONSE:
            let receivedDocumentReviewModel: IDocumentReviewModel[] = { ...initialDocumentReviewModel }
            receivedDocumentReviewModel = action.data;
            return receivedDocumentReviewModel;
        default:
            return state || initialDocumentReviewModel;
    }
};

export const delegateeInfoReducer: Reducer<IDelegateeInfo> = (state: IDelegateeInfo = initialDelegateeInfoState, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    switch (action.type) {

        case actionTypes.DELEGATEE_DETAILS_REQUEST:
        case actionTypes.TAXCLIENT_DELEGATEE_DETAILS_REQUEST:
            return initialDelegateeInfoState;

        case actionTypes.DELEGATEE_DETAILS_RESPONSE:
            let receivedDelegateeModel: IDelegateeInfo = { ...initialDelegateeInfoState }
            receivedDelegateeModel = action.delegateeSigner;
            return receivedDelegateeModel;

        case actionTypes.DELEGATEE_UPDATE_PHONE_NUMBER_RESPONSE:
            let updatedDelegateeMobile: IDelegateeInfo = { ...initialDelegateeInfoState }
            updatedDelegateeMobile.countryCode = action.countryCode;
            updatedDelegateeMobile.mobileNumber = action.phoneNumber;
            return updatedDelegateeMobile;

        case actionTypes.TAXCLIENT_DELEGATEE_DETAILS_RESPONSE:
            let taxClientDelegateeInfo: IDelegateeInfo = { ...initialDelegateeInfoState }
            taxClientDelegateeInfo = action.delegateeSigner;
            taxClientDelegateeInfo.confirmEmail = action.delegateeSigner.email;
            return taxClientDelegateeInfo;

        case actionTypes.UPDATE_DELEGATEE_DETAILS_RESPONSE:
            let updatedDelegatee: IDelegateeInfo = { ...initialDelegateeInfoState }
            updatedDelegatee.firstName = action.updatedDelegatee.firstName;
            updatedDelegatee.lastName = action.updatedDelegatee.lastName;
            updatedDelegatee.email = action.updatedDelegatee.email;
            updatedDelegatee.confirmEmail = action.updatedDelegatee.confirmEmail;
            updatedDelegatee.countryCode = action.updatedDelegatee.countryCode;
            updatedDelegatee.mobileNumber = action.updatedDelegatee.mobileNumber;
            updatedDelegatee.description = action.updatedDelegatee.description;
            return updatedDelegatee;


        default:
            return state || initialDelegateeInfoState;
    }
};

export const delegateeDownloadableDocReducer: Reducer<ICCRecipientDownloadableDocuments[]> =
    (state: ICCRecipientDownloadableDocuments[] = initialModalState,
        incomingAction: Action) => {
        const action = incomingAction as DispatchAction;
        switch (action.type) {
            case actionTypes.DELEGATEE_DOWNLOADABLE_DOCUMENTS_REQUEST:
                return initialModalState;
            case actionTypes.DELEGATEE_DOWNLOADABLE_DOCUMENTS_RESPONSE:
                return action.data;
            default:
                return state || initialModalState;
        }
    };