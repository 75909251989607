import { IUserModel, initialUserModel } from "../models/IUserModel";
import { IBrandingSettings, initialBrandingSettings } from "src/core/domain/models/IBrandingSettings";
import { ILoggedInUserInfo, initialLoggedInUserInfo } from 'src/core/domain/viewModels/ILoggedInUserInfo';
import { ITaxDocumentModel, initialTaxDocumentModel } from "src/core/domain/models/ITaxDocumentModel";

export interface IHeaderInfoViewModel {
    clientName: string,
    taxYear: number;
    companyName: string,
    companyLogoPath: string,
    companyWhiteLogoPath: string,
    contactPerson: IUserModel,
    brandingSettings: IBrandingSettings,
    data: ITaxDocumentModel,
    loggedInUserInfo: ILoggedInUserInfo
}

export const initialHeaderInfoViewModel: IHeaderInfoViewModel = {
    clientName: "",
    taxYear: 0,
    companyName: "",
    companyLogoPath: "",
    companyWhiteLogoPath: "",
    contactPerson: initialUserModel,
    brandingSettings: initialBrandingSettings,
    data: initialTaxDocumentModel,
    loggedInUserInfo: initialLoggedInUserInfo,
}