import * as React from 'react';
import { Shimmer } from '../../../components/Common/Shimmer/Shimmer';
import { FormType } from 'src/core/domain/models/ITaxReturn';
import { History } from 'history';
import { Row } from 'react-bootstrap';
import { WelcomeMessage } from '../../CompletedSummary/Parts/WelcomeMessage';
import { CompletedSummaryConstant } from '../../Common/Constants';
import { DelegateePathConstants } from '../../Common/Constants';
import { ISignedDocument } from 'src/core/domain/models/manualsign/SignedDocument';
import { IAdditionalEsignDocument } from 'src/core/domain/models/IAdditionalEsignDocument';
import { logger } from '../../../routes';
import { LogEventConstants } from '../../Helper/Constants';
import { IDownloadableDocumentsViewModel } from 'src/core/domain/viewModels/IDownloadableDocumentsViewModel';
import { DocumentStatus, SignatureType } from 'src/core/common/Enums';

export interface CompletedWelcomePageProps {
    taxYear: number;
    param: any;
    clientName: string;
    taxReturn: any;
    history: History;
    downloadSignedEFileDocument: (clientId: string, filename: string, callback?: () => void) => any;
    signedDocument: ISignedDocument[];
    downloadAllAdditionEsignDocuments: (clientId: string, filename: string) => any;
    downloadableDocumentsViewModel: IDownloadableDocumentsViewModel;
    downloadEFileDocument: (clientId: string, callback?: () => void) => any;
}

const pageTitle = "Delegatee Summary Page";
export class CompletedSummaryPage extends React.Component<CompletedWelcomePageProps, {}> {
    constructor(props: CompletedWelcomePageProps) {
        super(props);
        this.state = {
            showModal: false,
        }
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.Open}  ${pageTitle}`,
                { count: 1, page: pageTitle, clientId: this.props.param.clientId }
            )
        );
    }

    getSignedEfileName = () => {
        let fileName = this.getFileNamePrefix();
        if (this.props.signedDocument && this.props.signedDocument.length == 1) {
            fileName = fileName + CompletedSummaryConstant.FileDownload.SignedPdfFile;
        }
        else if (this.props.signedDocument && this.props.signedDocument.length > 1) {
            fileName = fileName + CompletedSummaryConstant.FileDownload.SignedZipFile;
        }
        return fileName;
    }

    getFileNamePrefix = () => {
        return this.props.clientName + "_" + this.props.taxYear + "_" + this.props.taxReturn.engagementType.toString().substr(1);
    }


    getOtherSignedDocumentFileName = () => {
        return this.getFileNamePrefix() + "_ Other Signed Document.zip";;
    }

    downloadSignedDocument = () => {
        const _self = this;
        const additionalEsignDocuments: IAdditionalEsignDocument[] = this.props.downloadableDocumentsViewModel && this.props.downloadableDocumentsViewModel.additionalEsignDocuments;
        const efile = this.props.downloadableDocumentsViewModel && this.props.downloadableDocumentsViewModel.downloadableDocuments.find(x => x.groupId === FormType.EFile);

        if (this.props.taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType === SignatureType.ESignWhenAllIncludedReturnsAllowed) {
            if (efile && efile.documentId > 0) {
                this.props.taxReturn.documentStatus.toString() === DocumentStatus[DocumentStatus.USERSIGNED] ?
                    this.props.downloadSignedEFileDocument(this.props.param.clientId, this.getSignedEfileName(), () => {
                        if (additionalEsignDocuments && additionalEsignDocuments.length > 0) {
                            _self.props.downloadAllAdditionEsignDocuments(_self.props.param.clientId, _self.getOtherSignedDocumentFileName());
                        }
                    })
                    : this.props.downloadEFileDocument(this.props.param.clientId, () => {
                        if (additionalEsignDocuments && additionalEsignDocuments.length > 0) {
                            _self.props.downloadAllAdditionEsignDocuments(_self.props.param.clientId, _self.getOtherSignedDocumentFileName());
                        }
                    })
            }

            logger.trackEvent(
                logger.buildEvent(
                    `${LogEventConstants.Common.Delegatee.DownloadSignedDocuments}  ${pageTitle}`,
                    { count: 1, page: pageTitle, cleintId: _self.props.param.clientId, filename: _self.getOtherSignedDocumentFileName }
                )
            );
        }

        else {
            this.props.history.push(`${DelegateePathConstants.ManualSign}${this.props.param.clientId}`);
        }

    }

    public render() {

        const imgBtnDivClass = "col-lg-4 col-md-4";
        const welcomeAlertMessage = CompletedSummaryConstant.CompletedMessage.replace("<TAXYEAR>", this.props.taxYear.toString())

        return (
            <div id="welcomeMessage">
                {this.props.clientName && this.props.taxReturn.signedDetails && this.props.taxYear > 0 ?

                    (<React.Fragment>
                        <div className="welcome-container">
                            <WelcomeMessage message={CompletedSummaryConstant.WelcomeMessage.replace("<TAXPAYERNAME>", this.props.clientName)} />
                            <div className="margin-top-20">
                                <WelcomeMessage message={welcomeAlertMessage} />
                            </div>
                            <div className="welcome-intro">{CompletedSummaryConstant.InstructionText}</div>
                        </div>
                    </React.Fragment>) : <Shimmer height={200} />
                }

                {
                    this.props.clientName && this.props.taxReturn.signedDetails ?
                        (<div>
                            <Row className="step-img-btn center-align margin-top-70">

                                <div className={imgBtnDivClass}>
                                    <CompletedSummaryConstant.Images.ViewDownloadSignedDocument
                                        className="button---signed-documents"
                                        onClick={this.downloadSignedDocument} />
                                    <div className="img-caption" onClick={this.downloadSignedDocument}>{CompletedSummaryConstant.ImageCaption.ViewDownloadSignedDocument}</div>
                                </div>

                            </Row>
                        </div>) : <Shimmer height={500} />
                }
            </div>);
    }
}