import { AxiosResponse } from 'axios';
import { Action, Reducer } from 'redux';
import { initializeAxios } from './../../../../core/services/dataAccess/DataService.Axios';
import { actionTypes } from './../../../ActionTypes';
import { NotificationAction, StatusType } from './../../../Common/NotificationStore';
import { AppThunkAction } from './../../../index';
import { OTPPageConstants } from './../../../../components/Common/Constants';
import { OTPMobilePageConstants, DisplayError } from './../../../../components/Common/Constants';
import {
    CCRecipientOtpPageLoaderAction, CCRecipientOTPPageStoreState,
    initialCCRecipientOTPPageStoreState, LoginResponse,
    ReceiveCCRecipientOtpPageDetailsAction,
    ErrorMessageAction
} from '../../Models/StoreModels';

type KnownAction =
    DispatchAction |
    NotificationAction |
    ErrorMessageAction;

type DispatchAction = ReceiveCCRecipientOtpPageDetailsAction |
    CCRecipientOtpPageLoaderAction;

export const actionCreators = {
    pageValidity: (clientId: string, navigate: (path: string) => void, handleError: (message: string) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {

        dispatch({ type: actionTypes.CCRECIPIENT_OTP_PAGE_LOADER, loading: true });

        return initializeAxios().get<LoginResponse>("/api/CcRecipient/" + clientId)
            .then((response: AxiosResponse<LoginResponse>) => {

                const result = response.data;

                if (result.isSuccess) {
                    dispatch({ type: actionTypes.CCRECIPIENT_OTP_PAGE_LOADER, loading: false });
                    navigate(result.data);
                } else {
                    const errorDesc = result.errorDescription;
                    const errorCode = result.errorCode;

                    if (errorCode && DisplayError.includes(errorCode)) {
                        dispatch({
                            type: actionTypes.SET_ERROR_MESSAGE,
                            message: errorDesc,
                        });
                        handleError(errorDesc);

                    } else {
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: errorDesc,
                            statusType: StatusType.Error
                        });
                    }
                }
            })
            .catch((e: any) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: OTPPageConstants.ErrorMessage.serverError,
                    statusType: StatusType.Error
                });
            });

    },
    getClientInfo: (clientId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {

        dispatch({ type: actionTypes.CCRECIPIENT_OTP_PAGE_LOADER, loading: true });

        return initializeAxios().get<CCRecipientOTPPageStoreState>("/api/CcRecipient/GetClientInfo/" + clientId)
            .then((response: AxiosResponse<CCRecipientOTPPageStoreState>) => {

                const result = response.data;

                dispatch({ type: actionTypes.RECEIVE_CCRECIPIENT_OTP_PAGE_DETAILS, data: { ...result, loading: false } });

            })
            .catch((e: any) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: OTPPageConstants.ErrorMessage.serverError,
                    statusType: StatusType.Error
                });
            });

    },
    generateMobileOTP: (clientId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {

        return initializeAxios().postJson<boolean>(null, '/api/CcRecipient/Mobile/Generate/' + clientId)
            .then((response: AxiosResponse<boolean>) => {
                const result = response.data;
                if (result) {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: OTPMobilePageConstants.SuccessMessage.OTPTextGenerateSuccess,
                        statusType: StatusType.Success
                    });
                } else {

                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: OTPPageConstants.ErrorMessage.OTPGenerateFailed,
                        statusType: StatusType.Error,
                    });
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: OTPMobilePageConstants.ErrorMessage.serverError,
                    statusType: StatusType.Error
                });
            });
    },
    verifyMobileOTP: (OTP: string, clientId: string, handleVerify: (guid: string) => void, handleError: (message: string) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {

        return initializeAxios().postJson<LoginResponse>('"' + OTP + '"', '/api/CcRecipient/Text/Validate/' + clientId)
            .then((response: AxiosResponse<LoginResponse>) => {
                const result = response.data;
                if (result.isSuccess) {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: "valid OTP",
                        statusType: StatusType.Success
                    });
                    handleVerify(result.data);
                } else {
                    const errorDesc = result.errorDescription;
                    const errorCode = result.errorCode;

                    if (errorCode && DisplayError.includes(errorCode)) {
                        dispatch({
                            type: actionTypes.SET_ERROR_MESSAGE,
                            message: errorDesc,
                        });
                        handleError(errorDesc);
                    } else {
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: errorDesc,
                            statusType: StatusType.Error
                        });
                    }
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: OTPMobilePageConstants.ErrorMessage.serverError,
                    statusType: StatusType.Error
                });
            });
    },
    generateOTP: (clientId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        return initializeAxios().postJson<boolean>(null, '/api/CcRecipient/Generate/' + clientId)
            .then((response: AxiosResponse<boolean>) => {
                const result = response.data;
                if (result) {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: OTPPageConstants.SuccessMessage.OTPEmailGenerateSuccess,
                        statusType: StatusType.Success
                    });
                } else {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: OTPPageConstants.ErrorMessage.OTPGenerateFailed,
                        statusType: StatusType.Error
                    });
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: OTPMobilePageConstants.ErrorMessage.serverError,
                    statusType: StatusType.Error
                });
            });
    },
    verifyOTP: (OTP: string, clientId: string, handleVerify: (guid: string) => void, handleError: (message: string) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        return initializeAxios().postJson<LoginResponse>('"' + OTP + '"', `/api/CcRecipient/Validate/${clientId}`)
            .then((response: AxiosResponse<LoginResponse>) => {
                const result = response.data;

                if (result.isSuccess) {
                    handleVerify(result.data);

                } else {
                    const errorDesc = result.errorDescription;
                    const errorCode = result.errorCode;

                    if (errorCode && DisplayError.includes(errorCode)) {
                        dispatch({
                            type: actionTypes.SET_ERROR_MESSAGE,
                            message: errorDesc,
                        });
                        handleError(errorDesc);
                    } else {
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: errorDesc,
                            statusType: StatusType.Error
                        });
                    }
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: OTPPageConstants.ErrorMessage.serverError,
                    statusType: StatusType.Error
                });

            });
    },
};

export const reducer: Reducer<CCRecipientOTPPageStoreState> = (state: CCRecipientOTPPageStoreState = initialCCRecipientOTPPageStoreState, incomingAction: Action) => {

    const action = incomingAction as DispatchAction;

    const currentState = Object.assign({}, state);

    switch (action.type) {
        case actionTypes.CCRECIPIENT_OTP_PAGE_LOADER:
            return { ...currentState, loading: action.loading };

        case actionTypes.RECEIVE_CCRECIPIENT_OTP_PAGE_DETAILS:
            return { ...currentState, ...action.data };

        default:
            return currentState || initialCCRecipientOTPPageStoreState;
    }
};
