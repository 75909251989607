import * as React from 'react';
import { CustomButton, CustomButtonSize } from '../../Common/CustomButton/CustomButton';
import { ManualSignConstants, Common } from '../../Common/Constants';


export interface DownloadDocumentsProps {
    clientId: string;
    downloadEfileForms(clientId: string, successCallback?: () => void): void;
}

export const DownloadDocuments: React.FunctionComponent<DownloadDocumentsProps> = ({
    clientId, downloadEfileForms
}) => {

    let handleEfileDownload = () => {
        downloadEfileForms(clientId);
    }

    return (<div className={"col-lg-12 no-padding download-documents-area"} data-test-auto="6EBE6B0F-EAAA-4CEB-983C-B2ED0B28F82F">

        <strong> {ManualSignConstants.DownloadDocumentTitle} </strong>

        <div className={"downlod-button-container"}>
            <CustomButton automationTestId={"EAB719C4-9C65-4C57-86F0-895D008B558A"}
                size={CustomButtonSize.XL}
                icon={Common.Images.download}
                text={ManualSignConstants.DownloadDocumentButtonText}
                onClick={handleEfileDownload} />
        </div>

        <hr />

    </div>);
};
