import * as React from 'react';
import { ITaxReturn } from '../../core/domain/models/ITaxReturn';
import { GetK1EngagementType } from '../../core/domain/models/IK1Distribute';
import { DistributeType } from '../../core/common/Enums';
import { Common } from '../Common/Constants';
import { StepLayout } from '../Common/StepLayout/StepLayout';
import { Body } from '../Common/StepLayout/Body';
import { CustomLargeButton } from '../Common/CustomButton/CustomLargeButton';

interface K1DistributionMethodProps {
    taxReturn: ITaxReturn;
    clearParentState?: () => void;
    onSetDistributeMethod: (type: DistributeType) => void;
}

export class K1DistributionMethod extends React.Component<K1DistributionMethodProps, {}>{
    constructor(props: any) {
        super(props);
        this.props.clearParentState && this.props.clearParentState();
    }


    public render() {
        let type = this.props.taxReturn && GetK1EngagementType(this.props.taxReturn.engagementType);

        return (<div className={"row col-lg-12"} style={{ height: "100%" }}>

            <div style={{ margin: "auto", textAlign: "center" }}>
                <CustomLargeButton text={`Electronically`}
                    className={"margin-bottom-10"}
                    icon={Common.Images.SendElectronically}
                    onClick={() => { this.props.onSetDistributeMethod(DistributeType.Electronic) }}
                    automationTestId={"F9465A41-0FAD-48F4-948D-D2427987F24D"}/>
                <span className="large-button-footer-text" data-test-auto="D7A5DC2A-2F4D-42F8-8CBA-5B7B251E105E" >{`Email them from this website`}</span>
            </div>

            <div style={{ margin: "auto", textAlign: "center" }}>
                <CustomLargeButton text={`Mail Hard Copies`}
                    className={"margin-bottom-10"}
                    icon={Common.Images.SendManually}
                    onClick={() => { this.props.onSetDistributeMethod(DistributeType.Manual) }}
                    automationTestId={"7DB4B109-0662-4BC7-B88A-1E000C182EC8"}/>
                <span className="large-button-footer-text" data-test-auto="49D75876-02D6-4DA4-9F92-AC756270AA4F">{`Download Print, and Mail`}</span>
            </div>

        </div>);
    }
}