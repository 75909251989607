import * as React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { ITaxReturn } from '../../core/domain/models/ITaxReturn';
import { match } from 'react-router';
import { IAccessCodeValidation } from '../../core/domain/viewModels/ICommon';
import { SvgIconRequestScreenShare } from '../Common/Icons/SvgIcons';
import { logger } from '../../routes';
import { LogEventConstants } from '../Helper/Constants';

export interface AccessCodeProps {
    match: match;
    showState: boolean;
    inProgress: boolean;
    taxDocument: ITaxReturn;
    onHide: () => void;
    onScreenSharingStart: (accessCode: IAccessCodeValidation, callback: () => void) => void;
    onValidationSuccessful: () => void;
}

interface AccessCodeState {
    accessCode: IAccessCodeValidation;
}

const pageTitle = "AccessCode layout";
export class AccessCode extends React.Component<AccessCodeProps, AccessCodeState> {
    constructor(props: any) {
        super(props);
        this.state = {
            accessCode: {
                clientGuid: '',
                code: ''
            }
        }
        let param: any = this.props.match.params;
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.Open}  ${pageTitle}`,
                { count: 1, page: pageTitle, clientId: param.clientId }
            )
        );
    }

    onEnterAccessCode = (e: any) => {
        let param: any = this.props.match.params;
        this.setState({ accessCode: { clientGuid: param.clientId, code: e.target.value } });
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.Layout.AccessCodeEntered}  ${pageTitle}`,
                { count: 1, page: pageTitle, code: this.state.accessCode,  clientId: param.clientId  }
            )
        );
    }

    public render() {
        return (
            <div>
                <Modal show={this.props.showState} className="myaccount accesscode" onHide={this.props.onHide}
                    data-test-auto="689A5D12-FAFD-44B8-8C11-373C29A7C1BF">
                    <Modal.Header closeButton >
                        <Modal.Title style={{ fontSize: '16px' }}>
                            <SvgIconRequestScreenShare className='modalIcon' />
                            Share Screen
                    </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-lg-12" style={{ display: 'inline' }}>
                                <div>
                                    <div className="row myaccount-text" >
                                        {this.props.inProgress ?
                                            <div className="col-sm-7 col-md-7 col-xl-7">
                                                <span> The sharing session has been terminated </span>
                                            </div> :
                                            <div className="col-sm-7 col-md-7 col-xl-7">
                                                <span> Enter Code </span>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    data-test-auto="CDAB9814-31A5-4543-8B0B-DE8606825993"
                                                    value={this.state.accessCode.code}
                                                    onChange={(e) => this.onEnterAccessCode(e)}
                                                    style={{ fontSize: '12px' }} />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {this.props.inProgress ?
                            <div className="accesscode footer">
                                <Button
                                    id="btnHideMyaccountPopUp"
                                    onClick={this.props.onHide}
                                    data-test-auto="4EB783A4-84B4-4BDF-B412-71B3E1385AEA">
                                    <span>OK</span>
                                </Button>
                            </div> :
                            <div className="accesscode footer">
                                <Button
                                    id="btnHideMyaccountPopUp"
                                    onClick={this.props.onHide}
                                    variant={"light"}
                                    data-test-auto="CEC8781D-ED44-4B24-AF4A-8005A00B59FC"
                                    className="btn btn-white btn-default rounded">
                                    <i className="fa fa-times btn-icon"></i>Cancel
                                </Button>
                                <Button
                                    onClick={() => { this.props.onScreenSharingStart(this.state.accessCode, this.props.onValidationSuccessful) }}
                                    data-test-auto="56A7B8B9-02D6-498E-8953-F7F56C4478AE"
                                    className="btn btn-primary rounded"
                                    variant={"primary"}> Start
                                </Button>
                            </div>
                        }
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
export default AccessCode;