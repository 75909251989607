import { History } from 'history';
import * as React from 'react';
import { match } from 'react-router';
import Tour, { ReactourStep } from 'reactour';
import { SignProcessSteps } from '../../../components/Home/TaxpayerSignFlowManager';
import { SignatureType } from '../../../core/common/Enums';
import { ITutorialInfo } from '../../../core/domain/models/ITutorialInfo';
import { ICommonDataViewModel } from '../../../core/domain/viewModels/ICommonDataViewModel';
import { IHeaderInfoViewModel } from '../../../core/domain/viewModels/IHeaderInfoViewModel';
import { ISignProcessInfoViewModel } from '../../../core/domain/viewModels/ISignProcessInfoViewModel';
import { ISummaryViewModel } from '../../../core/domain/viewModels/ISummaryViewModel';
import { SummaryTurorialConstants } from '../../Common/Constants';
import { EstimatedVoucher } from './EstimatedVoucher';
import { LeftBar } from './LeftBar';
import { Main } from './Main';
import { PaymentAndRefund } from './PaymentAndRefund';
import { Welcome } from './Welcome';
import { logger } from '../../../routes';
import { LogEventConstants } from '../../Helper/Constants';

export interface WizardBodyProps {
    summaryData: ISummaryViewModel;
    commonData: ICommonDataViewModel;
    requestSummaryDetails: (clientId: string) => any;
    initiateDocumentStatusPolling?: (retrycount: number, step: number, callback: (step: number) => void) => void;
    match: match;
    history: History;
    goToStep: (stepNumber: number) => void;
    goToStepById: (id: any) => void;
    showPaymentRefundPrint: boolean;
    isPreviewReady?: boolean;
    signProcessInfo: ISignProcessInfoViewModel;
    tutorialInfo: ITutorialInfo;
    hideSummaryTutorial: (clientId: string, doNotShowNextTime: boolean) => void;
    headerInfo: IHeaderInfoViewModel;
    signingRequiredForSigner: boolean;
    signatureFormSelectionType: SignatureType;
    showPreparerMessage?: boolean;
    relatedTaxReturnDelivered: boolean;
}
export interface WizardBodyState {
    isTourOpen: boolean;
    isDoNotShowCkbChecked: boolean;
}


const NO_INDEX = -1;
const pageTitle = "Welcome Summary Page";
export class WizardBody extends React.Component<WizardBodyProps, WizardBodyState> {

    static getDerivedStateFromProps(
        nextProps: WizardBodyProps,
        prevState: WizardBodyState) {

        const sideMenu: any = document.getElementById("openSidebarMenu");
        if (sideMenu) {
            sideMenu.checked = true;
        }
        return { isTourOpen: false };
    }

    constructor(props: WizardBodyProps) {
        super(props);
        this.state = {
            isTourOpen: true,
            isDoNotShowCkbChecked: false,
        };
    }

    componentDidMount() {
        const param: any = this.props.match.params;
        if (param.clientId && this.props.summaryData.documentId === 0) {
            this.props.requestSummaryDetails(param.clientId);
        }
        logger.trackPageView('Welcome Summary Page');
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.Open}  ${pageTitle}`,
                { count: 1, page: pageTitle, clientId: param.clientId }
            )
        );
    }

    //pollPreviewDocumentStatus = (tryCount: number) => {
    //    const param: any = this.props.match.params;
    //    let timeout: number = 10000;
    //    if (tryCount > 10) {
    //        timeout = 60000;
    //    }
    //    if (tryCount == 16) {
    //        //clearTimeout(timeoutFunc);
    //        this.setState({ isLoading: false, isDocumentError: true });
    //        return;
    //    }
    //    if (!this.props.previewReady) {
    //        this.props.requestDocumentPreviewStatus && this.props.requestDocumentPreviewStatus(param.clientId);
    //        timeoutFunc = setTimeout(this.pollPreviewDocumentStatus, timeout, tryCount + 1);
    //    }
    //}

    goToStep = (step: SignProcessSteps) => {

        logger.trackTrace(`WizardBody page : goToStep begins execution`);
        const param: any = this.props.match.params;
        const {
            isPreviewReady,
            initiateDocumentStatusPolling,
            signProcessInfo: { visitedSteps },
            goToStepById,
        } = this.props;

        if (!isPreviewReady && initiateDocumentStatusPolling) {
            initiateDocumentStatusPolling(0, step, goToStepById);
        } else {

            let index = NO_INDEX;
            index = visitedSteps && visitedSteps.indexOf(step);

            if (index != null && index != NO_INDEX) {
                logger.trackEvent(
                    logger.buildEvent(
                        `Go To Step: ${step.toString()}`,
                        {
                            count: 1, page: pageTitle,
                            CustomEventType: LogEventConstants.Common.DocumentEventName,
                            DocumentId: this.props.summaryData.documentId,
                            clientId: param.clientId
                        }
                    )
                );
                goToStepById(step);
            }
            else if (!this.props.showPreparerMessage) {
                goToStepById(SignProcessSteps.Review.toString());
                logger.trackEvent(
                    logger.buildEvent(
                        `Go To Step: ${SignProcessSteps.Review.toString()}`,
                        {
                            count: 1, page: pageTitle,
                            CustomEventType: LogEventConstants.Common.DocumentEventName,
                            DocumentId: this.props.summaryData.documentId,
                            clientId: param.clientId
                        }
                    )
                );
            }
            else {
                goToStepById(SignProcessSteps.PreparerMessage.toString());
                logger.trackEvent(
                    logger.buildEvent(
                        `Go To Step: ${SignProcessSteps.PreparerMessage.toString()}`,
                        {
                            count: 1, page: pageTitle,
                            CustomEventType: LogEventConstants.Common.DocumentEventName,
                            DocumentId: this.props.summaryData.documentId,
                            clientId: param.clientId
                        }
                    )
                );
            }
        }
        logger.trackTrace(`WizardBody page : goToStep completed execution`);
    }

    onTutorialDone = () => {
        const param: any = this.props.match.params;
        if (param.clientId) {
            this.props.hideSummaryTutorial(param.clientId, this.state.isDoNotShowCkbChecked);
        }
    }

    onDoNotShowCkbChecked = (event: any) => {
        this.setState({ isDoNotShowCkbChecked: event.target.checked });
    }

    getTaxYearModel = (goTo: any) => {
        return (
            <div>
                <div className="tutorial-header"><span>{SummaryTurorialConstants.TaxYearModal.header}</span></div>
                <div className="tutorial-body"><p>{SummaryTurorialConstants.TaxYearModal.body}</p></div>
                <div className="tutorial-footer">
                    <div className="tutorial-checkbox">
                        <input data-test-auto="2dd867ee-894f-4aaa-8bde-8487e3bf84bf"
                            type="checkbox" id="chkDoNotShowInst"
                            name="chkDoNotShowInst" className="form-check-inline"
                            onChange={this.onDoNotShowCkbChecked} checked={this.state.isDoNotShowCkbChecked} />
                        <span className="tutorial-checkmark"></span>
                        <label className="tutorial-checkbox-label" htmlFor="chkDoNotShowInst">
                            {SummaryTurorialConstants.TaxYearModal.doNotShowInstruction}
                        </label>

                        <button className="btn btn-primary flrt" data-test-auto="8a19e5ba-5523-4391-857d-dea4f4fa262b"
                            onClick={() => {
                                if (this.state.isDoNotShowCkbChecked) {
                                    this.onTutorialDone();
                                } else {
                                    goTo(1);
                                }
                            }}>
                            {this.state.isDoNotShowCkbChecked ?
                                SummaryTurorialConstants.Buttons.done : SummaryTurorialConstants.Buttons.next}
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    public render() {

        const { signingRequiredForSigner, signatureFormSelectionType, relatedTaxReturnDelivered } = this.props;

        return (
            <div id="summary-review" className="row no-margin width-100 summary-step-container">
                <LeftBar data-test-auto="58D54D95-2CCA-482C-B110-4990338FA73E">
                    <PaymentAndRefund taxYear={this.props.summaryData.taxYear} addedRefunds={this.props.summaryData.addedRefunds}
                        vouchers={this.props.summaryData.vouchers} refunds={this.props.summaryData.refunds}
                        taxingAuthorities={this.props.commonData.taxingAuthorities}
                        showPaymentRefundPrint={this.props.showPaymentRefundPrint}
                        data-test-auto="127CE518-D505-4AE9-BA47-E207BDA7FD47"
                        headerInfo={this.props.headerInfo}
                    />
                    <EstimatedVoucher taxYear={this.props.summaryData.taxYear}
                        vouchers={this.props.summaryData.vouchers}
                        taxingAuthorities={this.props.commonData.taxingAuthorities}
                        data-test-auto="8E209449-6AE0-4C69-8206-69A98972158B" />
                </LeftBar>
                <Main data-test-auto="E3AB8F34-5E77-420C-855D-EAE43876B695">

                    <Welcome
                        clientName={this.props.summaryData.clientName}
                        hasShareholders={this.props.summaryData.hasShareholders}
                        taxYear={this.props.summaryData.taxYear}
                        goToStep={this.goToStep}
                        goToStepById={this.props.goToStepById}
                        showSignStep={
                            (signingRequiredForSigner ||
                                signatureFormSelectionType === SignatureType.ManualSign) && (signatureFormSelectionType !== SignatureType.ReviewOnly)
                        }
                        showPreparerMessage={this.props.showPreparerMessage}
                        data-test-auto="4F0A55EC-2D9C-4A27-9A90-3D161C2BD7DB"
                        relatedTaxReturnDelivered={relatedTaxReturnDelivered}
                        voucherExists={this.props.summaryData.vouchers.length > 0}
                        signingRequiredForSigner={signingRequiredForSigner}
                        signatureFormSelectionType={signatureFormSelectionType}
                    />
                </Main>
                <Tour
                    steps={[]}
                    isOpen={this.state.isTourOpen}
                    onRequestClose={() => { }}
                    disableDotsNavigation={true}
                    showNavigation={false}
                    showNumber={false}
                    showCloseButton={false}
                    showButtons={false}
                    closeWithMask={false}

                />
            </div>
        );
    }
}
