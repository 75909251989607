import { ClientType } from "../../../common/Enums";

export interface ISignedDocument {
    id: number;
    documentId: number;
    uploadedOn: Date;
    clientType: ClientType;
    fileName: string;
    ClientId: string;
}


export class SignedDocument implements ISignedDocument {

    id: number;
    documentId: number;
    uploadedOn: Date;
    clientType: ClientType;
    fileName: string;
    ClientId: string;

    constructor(id: number,
        documentId: number,
        uploadedOn: Date,
        clientType: ClientType,
        fileName: string,
        ClientId: string) {

        this.id = id;
        this.documentId = documentId;
        this.uploadedOn = uploadedOn;
        this.clientType = clientType;
        this.fileName = fileName;
        this.ClientId = ClientId;
    }

    public static createNullObject(): ISignedDocument {
        return new SignedDocument(0, 0, new Date(), ClientType.Undefined, "", "");
    }

    public static create(id: number,
        documentId: number,
        uploadedOn: Date,
        clientType: ClientType,
        fileName: string,
        ClientId: string): ISignedDocument {
        return new SignedDocument(id, documentId, uploadedOn, clientType, fileName, ClientId);
    }

    public static createModelToServer(fileName: string): ISignedDocument {
        return new SignedDocument(0, 0, new Date(), ClientType.Undefined, fileName, "");
    }
}