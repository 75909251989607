import { Next } from 'awesome-multi-steps/dist/Footer/Next';
import { Previous } from 'awesome-multi-steps/dist/Footer/Previous';
import { History } from 'history';
import * as React from 'react';
import { match } from 'react-router';
import { DocumentStatus, EngagementTypeS } from '../../core/common/Enums';
import { IAdditionalEsignDocument } from '../../core/domain/models/IAdditionalEsignDocument';
import { IAttachment } from '../../core/domain/models/IAttachment';
import { FormType, IDownloadableDocuments, ITaxReturn } from '../../core/domain/models/ITaxReturn';
import { ISignedDocument } from '../../core/domain/models/manualsign/SignedDocument';
import { IClientProcessViewModel } from '../../core/domain/viewModels/IClientProcessViewModel';
import { IDownloadableDocumentsViewModel } from '../../core/domain/viewModels/IDownloadableDocumentsViewModel';
import { IHeaderInfoViewModel } from '../../core/domain/viewModels/IHeaderInfoViewModel';
import { Common, PathConstants } from '../Common/Constants';
import { CustomButton, CustomButtonSize } from '../Common/CustomButton/CustomButton';
import { Shimmer } from '../Common/Shimmer/Shimmer';
import { Body } from '../Common/StepLayout/Body';
import { Footer } from '../Common/StepLayout/Footer';
import { StepLayout } from '../Common/StepLayout/StepLayout';
import { DownloadAll } from '../Download/DownloadAll';
import TaxpayerHelper from '../Helper/TaxpayerHelper';
import { DownloadList, DownloadListItem } from './DownloadList';
var htmlencode = require('htmlencode');

export interface DownloadProps {
    match: match;
    history: History;
    DownloadableDocuments: IDownloadableDocumentsViewModel;
    TaxDocument: ITaxReturn;
    signedDouments: ISignedDocument[];
    headerInfo: IHeaderInfoViewModel;
    requestDownloadableDocuments: (clientId: string) => any;
    refreshTaxDocument: (clientId: string) => any;
    downloadTaxReturnDocument: (clientGuid: string, fileName: string) => any;
    downloadEFileDocument: (clientGuid: string, fileName: string, callback?: () => void) => any;
    downloadSignedEFileDocument: (clientGuid: string, fileName: string, callback?: () => void) => any;
    downloadTransmittalDocument: (clientGuid: string, fileName: string) => any;
    downloadVoucherDocument: (clientGuid: string, fileName: string) => any;
    downloadAttachment: (clientGuid: string, fileName: string) => any;
    downloadAllDocuments: (clientGuid: string, fileName: string) => any;
    downloadAllAdditionEsignDocuments: (clientGuid: string, fileName: string) => any;
    requestSignedDocuments: (clientGuid: string, forceRefresh?: boolean) => any;
    requestCurrentStepAndUserRole: (clientId: string, successCallback?: (clientProcessState: IClientProcessViewModel) => void) => any;
}

export interface DownloadState {
    showDownloadAllModal: boolean;
    isProcessCompleted: boolean;
    isPreviewMode: boolean;
}

export class Main extends React.Component<DownloadProps, DownloadState> {

    constructor(props: DownloadProps) {
        super(props);
        this.state = {
            showDownloadAllModal: false,
            isProcessCompleted: false,
            isPreviewMode: false,
        };
    }

    FilingForms = "Filing Forms";
    componentDidMount() {
        const param: any = this.props.match.params;
        this.props.requestDownloadableDocuments(param.clientId);
        if (this.props.TaxDocument.id === 0) {
            this.props.refreshTaxDocument(param.clientId);
        }
        if (this.props.signedDouments && this.props.signedDouments.length == 0) {
            this.props.requestSignedDocuments(param.clientId, true);
        }
        this.getSignProcessStatus(param.clientId);
    }

    hideDownloadAllModal = () => {
        this.setState({ showDownloadAllModal: false });
    }

    showDownloadAllModal = () => {
        this.setState({ showDownloadAllModal: true });
    }

    getSignedDocumentFileName = () => {

        let fileName = this.getFileNamePrefix();
        if (this.props.signedDouments && this.props.signedDouments.length === 1) {
            fileName = `${fileName}_Extensions_Signed ${this.FilingForms}.pdf`;
        } else if (this.props.signedDouments && this.props.signedDouments.length > 1) {
            fileName = `${fileName}_Extensions_Signed ${this.FilingForms}.zip`;
        }
        return fileName;
    }

    getFileNamePrefix = () => {
        return this.props.headerInfo.clientName + "_" + this.props.headerInfo.taxYear + "_" + this.getEngagementType(this.props.TaxDocument.engagementType);
    }

    getEngagementType = (engagementType: EngagementTypeS) => {
        return EngagementTypeS[EngagementTypeS[engagementType]] === EngagementTypeS[EngagementTypeS.E990] ? "990T" : engagementType.toString().replace('E', '');
     }

    getOtherSignedDocumentFileName = () => {
        return this.getFileNamePrefix()
            + "_Extensions_Other Signature Documents.zip";;
    }

    private getSignProcessStatus = (clientId: any) => {
        this.props.requestCurrentStepAndUserRole(clientId, this.handleRedirect);
    }

    private handleRedirect = (clientProcessState: IClientProcessViewModel) => {
        const param: any = this.props.match.params;
        const url = TaxpayerHelper.getSignCompletedRedirectUrl(clientProcessState, param.clientId);
        if (url && url.trim()) {
            this.props.history.push(url);
        }
        else {
            this.setState({ isProcessCompleted: true });
        }

        this.setState({ isPreviewMode: TaxpayerHelper.isPreviewMode(clientProcessState) });
    }

    private getHeader = () => {
        return (<div className={"step-layout-top-bar no-padding"}> <div className="col-lg-6 inline-block-container no-padding">
            <span className="step-layout-body-header no-padding">Download Tax Documents</span>
        </div>
            <div className="col-lg-6 inline-block-container">
                <div className={"downlod-button-container flrt"} data-test-auto="CFECFF0E-B615-49C7-8FAD-826ED0C87EE7">
                    <CustomButton icon={Common.Images.download}
                        size={CustomButtonSize.LG}
                        text="Download all my tax documents" onClick={this.showDownloadAllModal} />
                </div>
            </div>
        </div>);
    }

    public render() {
        const param: any = this.props.match.params;

        const vouchers: IDownloadableDocuments[] = this.props.DownloadableDocuments.
            downloadableDocuments.filter(x => x.groupId === FormType.Vouchers);

        const attachments: IAttachment[] = this.props.DownloadableDocuments
            .attachments;

        const additionalEsignDocuments: IAdditionalEsignDocument[] = this.props.DownloadableDocuments
            .additionalEsignDocuments;

        let numberOfDivision: number = 1;

        if (vouchers && vouchers.length > 0) {
            numberOfDivision = numberOfDivision + 1;
        }

        let listStyleClass = "inline-block-container col-lg-" + (12 / numberOfDivision);

        const transmittals = this.props.DownloadableDocuments.
            downloadableDocuments.find(x => x.groupId === FormType.Transmittals);

        const efile = this.props.DownloadableDocuments.
            downloadableDocuments.find(x => x.groupId === FormType.EFile);

        const taxDocumentListItem: DownloadListItem[] = [];


        if (transmittals && transmittals.documentId > 0) {
            taxDocumentListItem.push({
                onClick: this.props.downloadTransmittalDocument,
                testAutoId: 'E4EBE666-FDEE-4DB7-9566-436D36FDB392',
                text: "Filing Instructions.pdf",
                args: [param.clientId, transmittals.fileName],
                isPreviewMode: this.state.isPreviewMode
            });
        }

        if (efile && efile.documentId > 0) {
            taxDocumentListItem.push({
                onClick: this.props.signedDouments.length > 0 ?
                    this.props.downloadSignedEFileDocument :
                    this.props.downloadEFileDocument,
                testAutoId: '5000B40B-2EC9-4DAA-A2EF-B683503E289F',
                text: this.props.signedDouments.length > 0 ?
                    "Signed Filing Forms.pdf" :
                    "Filing Forms.pdf",

                args: [param.clientId,
                (
                    this.props.TaxDocument.documentStatus.toString() === DocumentStatus[DocumentStatus.USERSIGNED] ||
                    this.props.TaxDocument.documentStatus.toString() === DocumentStatus[DocumentStatus.USERREVIEWED]
                )
                    ? this.getSignedDocumentFileName() : `Extensions_${this.FilingForms}.pdf`],
                isPreviewMode: this.state.isPreviewMode
            });
        }

        if (additionalEsignDocuments && additionalEsignDocuments.length > 0) {
            taxDocumentListItem.push({
                onClick: this.props.downloadAllAdditionEsignDocuments,
                testAutoId: 'B1CD56B3-439C-421E-8996-EEC082949D3A',
                text: "Other Signed Documents",
                args: [param.clientId, this.getOtherSignedDocumentFileName()],
                isPreviewMode: this.state.isPreviewMode
            });
        }

        const voucherListItem: DownloadListItem[] = [];
        let isSignedVoucher = this.props.signedDouments?.length > 0 ? true : false;
        if (vouchers) {
            vouchers.map((value, index) => {
                voucherListItem.push({
                    onClick: this.props.downloadVoucherDocument,
                    testAutoId: '',
                    text: value.fileName,
                    args: [param.clientId, value.fileName, isSignedVoucher],
                    isPreviewMode: this.state.isPreviewMode
                });
            });
        }

        return (this.state.isProcessCompleted && <StepLayout className={"with-footer"} marginTop={20}>
            <Body automationTestId={"11BB28B5-42F3-4571-B567-20700B1740B6"} lg={10} md={12} sm={12} xs={12} bodyClassName={"no-padding"} >
                {this.props.DownloadableDocuments.downloadableDocuments.length === 0 || this.props.TaxDocument.id === 0 ? (<Shimmer height={550} />) :
                    (<React.Fragment>
                        {this.getHeader()}
                        <div className="col-lg-12 inline-block-container">
                            <p>
                                Your {this.props.TaxDocument.taxYear} tax documents are listed below.
                                If you have not already downloaded them during previous steps in the process,
                                you may download them all as a ZIP file or optionally select a single document to download.
                            </p>
                        </div>

                        <div className="col-lg-12 inline-block-container no-padding margin-top-20">
                            <div className={listStyleClass} data-test-auto="EB9A6226-E38F-46CA-AB9A-4C3F946066EB">
                                <DownloadList title="Tax Documents" items={taxDocumentListItem} />
                            </div>

                            {
                                vouchers && vouchers.length > 0 && (
                                    <div className={listStyleClass} data-test-auto="8D6413F7-759D-4AD2-A852-D1C0265854EE">
                                        <DownloadList title="Payment Vouchers" items={voucherListItem} />
                                    </div>
                                )
                            }

                        </div>
                    </React.Fragment>)
                }

                <DownloadAll
                    DownloadableDocuments={this.props.DownloadableDocuments}
                    downloadAttachment={this.props.downloadAttachment}
                    downloadTaxReturnDocument={this.props.downloadTaxReturnDocument}
                    downloadTransmittalDocument={this.props.downloadTransmittalDocument}
                    downloadVoucherDocument={this.props.downloadVoucherDocument}
                    onDownloadAll={this.props.downloadAllDocuments}
                    onHide={this.hideDownloadAllModal}
                    showModal={this.state.showDownloadAllModal}
                    match={this.props.match}
                    history={this.props.history}
                    fileNamePrefix={this.getFileNamePrefix()}
                    isPreviewMode={this.state.isPreviewMode}
                />
            </Body>

            <Footer lg={10} md={12} sm={12} xs={12}>
                <div className={"col-lg-12 steps-footer "}>
                    <Previous automationTestId={"3F43C07D-73AD-4BB5-83D1-346B1E62ABC6"}
                        text={"Back"}
                        goToPreviousStep={() => { this.props.history.push(`${PathConstants.CompletionWizard}${param.clientId}`) }}
                        isActive={true} />

                    <Next automationTestId={"21596F53-89B5-4830-812E-96D126E7C1AE"}
                        text={"Done"}
                        goToNextStep={() => { this.props.history.push(`${PathConstants.CompletionWizard}${param.clientId}`) }}
                        isActive={true} />
                </div>
            </Footer>

        </StepLayout>);

    }
}