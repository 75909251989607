import * as React from 'react';
import { UploadedFile } from './UploadDocuments';
import { ISignedDocument } from '../../../core/domain/models/manualsign/SignedDocument';
import { container } from '../../../startup/inversify.config';
import { IDialogBox } from '../../../core/utilities/ui/DialogBox';
import { TYPES } from '../../../startup/types';
import { Common, ManualSignConstants } from '../../Common/Constants';
import { IDateUtilities } from '../../../core/utilities/date/DateUtilities';

interface UploadedDocumentTableProps {
    clientId: string;
    files: UploadedFile[];
    deleteSignedDocument?(clientId: string, id: number): void;
}


const dialogBox = container.get<IDialogBox>(TYPES.IDialogBox);
const dateUtilities = container.get<IDateUtilities>(TYPES.IDateUtilities);

export const UploadedDocumentTable: React.FunctionComponent<UploadedDocumentTableProps> = ({
    clientId, files, deleteSignedDocument
}) => {

    let handleDeleteDocument = (id: number) => {

        dialogBox.confirm(Common.DialogBox.ConfirmationTitle, ManualSignConstants.FileDeleteConfirmation, Common.DialogBox.Cancel, Common.DialogBox.Confrim, (result: boolean) => {
            result && deleteSignedDocument &&  deleteSignedDocument(clientId, id);
        });

    }

    let createFileList = (): any[] => {

        let collection: any[] = [];

        for (var i = 0; i <= files.length - 1; i++) {

            const tmpFile: UploadedFile = files[i];

            collection.push(<li key={"file-item-" + i} className="item type1">
                <div className="task col-lg-8 no-padding">
                    <div className="icon"> <i className={"fa fa-file-pdf-o fa-lg"} /></div>
                    <div>
                        <div className="name" title={tmpFile.name}> {tmpFile.name}</div>
                        <div className="uploaded-date-mobile d-sm-block d-md-none" title={dateUtilities.getFormattedDate(tmpFile.uploadedOn)}>
                            {dateUtilities.getFormattedDate(tmpFile.uploadedOn)}</div>
                    </div>
                </div>
                <div className="dates col-lg-3 no-padding d-none d-lg-block d-md-block">
                    <div className="bar" title={dateUtilities.getFormattedDate(tmpFile.uploadedOn)}>
                        {dateUtilities.getFormattedDate(tmpFile.uploadedOn)}</div>
                </div>

                <div className="d-sm-block d-md-none">
                    {tmpFile.id > 0 ? (<button className={"btn btn-default btn-sm manual-sign-uploads-table-action-btn"} title={"Delete"}
                        onClick={() => { tmpFile.id && handleDeleteDocument(tmpFile.id) }}>
                        <i className={"fa fa-trash"}></i>
                    </button>) : (<i className="fa fa-spinner fa-spin" />)
                    }
                </div>

                <div className="priority col-lg-1 no-padding d-none d-lg-block d-md-block">
                    {tmpFile.id > 0 ? (<button className={"btn btn-default btn-sm manual-sign-uploads-table-action-btn"} title={"Delete"}
                        onClick={() => { tmpFile.id && handleDeleteDocument(tmpFile.id) }}>
                        Delete
                    </button>) : (<i className="fa fa-spinner fa-spin" />)
                    }
                </div>

            </li>);
        }

        return collection;
    }

    return (<div className="file-list-container filepicker">
        <div className={(files.length > 0 ? ("filepicker uploaded-files-list minimized") : ("filepicker uploaded-files-list"))} data-test-auto="AA01CE21-29D4-42E6-9F0C-2E058D3E9A46">
            <ul className="task-items">
                {createFileList()}
            </ul>
        </div>
    </div>);
};