import * as React from 'react';
import { DelegateeHeader } from './DelegateeHeader';
import Notification from '../../Common/Notification/NotificationContainer'
import { Toaster } from '../../../components/Common/Notification/MarsNotifier';
import { RouteComponentProps } from 'react-router';
import { ILocalStore } from '../../../core/utilities/LocalStore';
import { container } from '../../../startup/inversify.config';
import { TYPES } from '../../../startup/types';
import { match } from 'react-router';
import { History } from 'history';
import { IHeaderInfoViewModel } from '../../../core/domain/viewModels/IHeaderInfoViewModel';
import * as AccountStore from '../../../store/Common/AccountStore';
import { DelegateeSessionTimeout } from '../../Delegatee/Account/DelegateeSessionTimeout';
import { OverlayLoader } from '../../Common/Loader/OverlayLoader';
import { IDelegateeInfo } from '../../../core/domain/models/DelegateeModels';
import * as DelegateeSignerStore from '../../../store/Delegatee/DelegateeSignerStore';
import { IProfileData } from '../DefaultLayout';
import { getClientBasicInfo, injectPendoScript } from 'src/components/Helper/HelperFunction';

export type DelegateeDefaultLayoutProps =
    {
        delegateeHeaderInfo: IHeaderInfoViewModel;
        delegateeSigner: IDelegateeInfo;
        loading: boolean;
        match: match;
        history: History;
        logOutDelegatee:(clientGuid:string,callback: (url: string) => void) => void;
    }
    & typeof AccountStore.actionCreators
    & typeof DelegateeSignerStore.actionCreators
    & RouteComponentProps<{}>;

interface IDelegateeDefaultLayoutState {
    idleSessionTimeoutInSeconds: number;
    profileData: IProfileData | undefined;
	isPendoInjected: boolean;
};
const localStore = container.get<ILocalStore>(TYPES.ILocalStore);



export class DelegateeDefaultLayout extends React.Component<DelegateeDefaultLayoutProps, IDelegateeDefaultLayoutState> {

    constructor(props: DelegateeDefaultLayoutProps, states: any) {
        super(props, states);
        this.state = {
            idleSessionTimeoutInSeconds: 0,
            profileData: undefined,
            isPendoInjected: false
        };
    }

    componentDidMount() {
        let param: any = this.props.match.params;
        this.props.requestDelegateeHeaderInfo(param.clientId);
        this.props.requestDelegateeDetails(param.clientId);
        this.props.getSessionTimeOutSeconds(param.clientId, this.setSessionTimout);
        let screenData: any = {};
        history.pushState(screenData, window.document.title, window.location.href);
        window.onpopstate = function (event: any) {
            history.go(1);
        };
        getClientBasicInfo(param.clientId, this.updateProfileData);
    }

    componentDidUpdate(prevProps: Readonly<DelegateeDefaultLayoutProps>, prevState: Readonly<IDelegateeDefaultLayoutState>): void {
		if (!this.state.isPendoInjected && this.state.profileData) {
			const { companyId, companyName, clientGuid, clientName, emailAddress } = this.state.profileData;
			injectPendoScript(
				companyId,
				companyName,
				clientGuid,
				clientName,
				emailAddress,
				this.state.isPendoInjected,
				this.setIsPendoInjected
			);
		}
	}

    setIsPendoInjected = (isPendoInjected: boolean) => {
		this.setState({ isPendoInjected });
	}

	updateProfileData = (data: IProfileData) => {
		this.setState({ profileData: data });
	}

    setSessionTimout = (sessionTimeoutValue: number) => {
        this.setState({ idleSessionTimeoutInSeconds: sessionTimeoutValue })
    }

    public render() {
        return (
            <div>
                <DelegateeHeader
                    delegateeHeaderInfo={this.props.delegateeHeaderInfo}
                    delegateeSigner={this.props.delegateeSigner}
                    updateDelegateeMobileNumber={this.props.updateDelegateeMobileNumber}
                    match={this.props.match}
                    history={this.props.history}
                    logout={this.props.logout} />

                <div id="content-wrapper" className="content-wrapper" >
                    <Notification />
                    {this.props.children}
                </div>

                {this.state.idleSessionTimeoutInSeconds > 0 && <DelegateeSessionTimeout
                    match={this.props.match}
                    idleSessionTimeoutInSeconds={this.state.idleSessionTimeoutInSeconds}
                    refreshToken={this.props.refreshToken}
                    logOutDelegatee={this.props.logOutDelegatee}
                />}

                <OverlayLoader />
                <Toaster />

            </div>);
    }
}
