import {
    initialSignProcessInfoViewModel,
    ISignProcessInfoViewModel,
} from "../viewModels/ISignProcessInfoViewModel";

import { Role } from "../../common/Enums";

export interface IClientProcessViewModel {
    role: Role;
    currentstep: ISignProcessInfoViewModel;
    permission: string;
    isSigned: boolean;
    isDelegated: boolean;
}

export const initialClientProcessModel: IClientProcessViewModel = {
    role: Role.None,
    currentstep: initialSignProcessInfoViewModel,
    permission: "",
    isSigned: false,
    isDelegated: false
};
