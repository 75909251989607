import { connect } from 'react-redux';
import { actionCreators as HeaderActions } from "./../../../../store/Login/Common/Header/HeaderStore";
import { actionCreators as DelegateeActions } from "./../../../../store/Login/Delegatee/OTP/OTPStore";
import { actionCreators as CCRecipientActions } from "./../../../../store/Login/CCRecipient/OTP/OTPStore";
import { actionCreators as CoverPageActions } from "./../../../../store/Login/TP/CoverPage/CoverPageStore";
import { ApplicationState } from '../../../../store';
import MobileRestrict from './MobileRestrict';

export default connect(
    (state: ApplicationState) => ({
        headerStoreState: state.headerPageStore,
        clientStore: state.coverPageStore,
        delegateeStore: state.delegateeOTPPageStore,
        ccStore: state.ccRecipientOTPPageStore,
    }),
    {
        requestHeaderDetails: (clientId: string) =>
            HeaderActions.requestHeaderDetails(clientId),

        requestDelegateeHeaderDetails: (clientId: string) =>
            HeaderActions.requestDelegateeHeaderDetails(clientId),

        requestCCHeaderDetails: (clientId: string) =>
            HeaderActions.requestCcRecipientHeaderDetails(clientId),

        requestClientInfo: (clientId: string, callback: () => void) =>
            CoverPageActions.requestCoverClientData(clientId, callback),

        requestCCInfo: (clientId: string) =>
            CCRecipientActions.getClientInfo(clientId),

        requestDelegateeInfo: (clientId: string) =>
            DelegateeActions.getClientInfo(clientId),

        requestSenderName: (role: string, clientId: string) =>
            HeaderActions.requestSenderName(role, clientId),
    },
)(MobileRestrict);
