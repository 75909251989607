import * as React from 'react';
import { Shimmer } from '../../../components/Common/Shimmer/Shimmer';
import { WelcomeSummaryConstants, CompletedSummaryConstant } from '../../../components/Common/Constants';
import { WelcomeMessage } from '../../../components/CompletedSummary/Parts/WelcomeMessage';
import { SignProcessSteps } from '../../Home/TaxpayerSignFlowManager';
import {
    SvgIconReviewDocumentsSummary,
    SvgIconSignDocumentsSummary,
} from '../../Common/Icons/SvgIcons';
import { Row } from 'react-bootstrap';
import { logger } from '../../../routes';
import { LogEventConstants } from '../../Helper/Constants';

export interface WelcomeProps {
    taxYear: number;
    clientName: string;
    goToStep: (stepNumber: SignProcessSteps) => void;
    goToStepById: (id: any) => void;
}

const pageTitle = "Deletage Welcome page";
export class Welcome extends React.Component<WelcomeProps, {}> {

    constructor(props: WelcomeProps) {
        super(props);
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.Open}  ${pageTitle}`,
                { count: 1, page: pageTitle }
            )
        );
    }

    public render() {
        const imgBtnDivClass = "col-lg-4 col-md-4";

        return (<div id="welcomeMessage">
            {this.props.clientName && this.props.taxYear > 0 ?

                (<div className="welcome-container">
                    <WelcomeMessage message={CompletedSummaryConstant.WelcomeMessage.replace("<TAXPAYERNAME>", this.props.clientName)} />
                    <div className="margin-top-20">   <WelcomeMessage message={WelcomeSummaryConstants.WelcomeMessage.replace("<TAXYEAR>", this.props.taxYear.toString())} /></div>
                    <div className="welcome-intro">{WelcomeSummaryConstants.InstructionText}</div>
                </div>) : <Shimmer height={200} />
            }

            {
                this.props.clientName ?

                    (<div>
                        <Row className="step-img-btn center-align margin-top-20">
                            <div className={imgBtnDivClass}>
                                <SvgIconSignDocumentsSummary
                                    className="icon---summary-sign-documents"
                                    onClick={() => this.props.goToStep(SignProcessSteps.SignMethod)} />
                                <div className="img-caption" onClick={() => this.props.goToStep(SignProcessSteps.SignMethod)}>Sign Documents</div>
                            </div>

                        </Row>
                        <Row>
                            <div className="summary-start-step-container">
                                <button className="btn btn-primary summary-begin-btn margin-top-20"
                                    onClick={() => this.props.goToStep(SignProcessSteps.PreparerMessage)}>
                                    Click Here to Begin
                                </button>
                            </div>
                        </Row>
                    </div>) : <Shimmer height={500} />
            }

        </div>);
    }
}
