import { MarsNotifier } from '../Common/Notification/MarsNotifier';
import { ValidationContants } from '../Common/Constants';

export function isValidEmailAddresses(emailAddress: string[]): boolean {
    var pattern = new RegExp(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i);
    let isAllEmailVaild: boolean = true;
    emailAddress.forEach(function (emailId: any) {
        if (!pattern.test(emailId)) { isAllEmailVaild = false; }
    });
    return isAllEmailVaild;
};

export function isValidEmailAddress(emailAddress: string): boolean {
    var pattern = new RegExp(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i);
    let isAllEmailVaild: boolean = true;
    if (!pattern.test(emailAddress)) { isAllEmailVaild = false; }
    return isAllEmailVaild;
};



export function isValidDOB(DOB: Date) {
    return DOB < new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
};

export function ValidateTenDigitphoneNumber(value: string) {
    var pattern = new RegExp(/^[0-9\-\s)\(]{0,14}$/);
    return pattern.test(value);
};

export function phoneNumberDisplay(phoneNumber: string): string {
    return phoneNumber && phoneNumber.length == 10 ? "(" + phoneNumber.substring(0, 3) + ") " + phoneNumber.substring(3, 6) + "-" + phoneNumber.substring(6, 10) : phoneNumber;
};

export function validatePhone(phoneNoId: any) {
    var phoneNo = phoneNoId.trim();
    if (phoneNo.trim() == "") {
        MarsNotifier.Warning(ValidationContants.PhoneNumberWarning, "");
        return false;
    }
    else if (phoneNo.trim().length < 10 || /^[0-9]{1,10}$/.test(phoneNo) == false) {
        MarsNotifier.Warning(ValidationContants.PhoneNumberLengthWarning, "");
        return false;
    }
    else
        return true;
};

export function validateCountryCode(countryCode: any) {
    if (countryCode.trim() === "") {
        MarsNotifier.Warning(ValidationContants.CountryCodeWarning, "");
        return false;
    } else
        return true;
}

export function NullandEmptyCheck(text: string) {
    if (text == undefined || text == null || text.trim() == "") {
        return false;
    }
    return true;
}

export function validateMobileLength(phoneNoId: any) {
    var phoneNo = phoneNoId.trim();
    if (phoneNo.length < 10 || /^[0-9]{1,10}$/.test(phoneNo) == false) {
        return false;
    }
    return true;
};

export function countryCodeDisplay(countryCode: string): string {
    return countryCode ? "(" + countryCode + ") " : "";
};

export function ssnDisplay(SSN: string): string {
    return SSN && SSN.length == 9 ? SSN.replace(/(\d{3})(\d{2})(\d{4})/, "$1-$2-$3") : SSN;
}

export function einDisplay(EIN: string): string {
    return EIN && EIN.length == 9 ? EIN.replace(/(\d{2})(\d{7})/, "$1-$2") : EIN;
}