import * as React from 'react';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { match } from 'react-router';
import { History } from 'history'
import { IHeaderInfoViewModel, initialHeaderInfoViewModel } from '../../core/domain/viewModels/IHeaderInfoViewModel';
import { TYPES } from '../../startup/types';
import { container } from '../../startup/inversify.config';
import { IUtilities } from '../../core/utilities/Utilities';
import { Shimmer } from '../Common/Shimmer/Shimmer';
import { SvgIconContactHeader } from '../Common/Icons/SvgIcons';
import { PathConstants } from '../Common/Constants';
import { logger } from '../../routes';
import { LogEventConstants } from '../Helper/Constants';
const utilities = container.get<IUtilities>(TYPES.IUtilities);


export interface RecipientHeaderProps {
    headerInfo: IHeaderInfoViewModel,
    match: match;
    history: History;
    logout: (clientGuid: string, callback: (id: string) => void) => void;
}
export interface RecipientHeaderState {
    headerInfo: IHeaderInfoViewModel
}

const pageTitle = "Recipient Header";

export class RecipientHeader extends React.Component<RecipientHeaderProps, RecipientHeaderState> {
    constructor(props: RecipientHeaderProps, states: RecipientHeaderState) {
        super(props, states);
        this.state = {
            headerInfo: initialHeaderInfoViewModel
        }
    }

    static getDerivedStateFromProps(nextProps: RecipientHeaderProps, prevState: RecipientHeaderState) {
        if (nextProps.headerInfo && (nextProps.headerInfo.brandingSettings.coverPageSetting.bgColorCode !== prevState.headerInfo.brandingSettings.coverPageSetting.bgColorCode
            || nextProps.headerInfo.brandingSettings.coverPageSetting.foreColorCode !== prevState.headerInfo.brandingSettings.coverPageSetting.foreColorCode)) {
            utilities.applyBrandingSettings(nextProps.headerInfo.brandingSettings);
            return {
                headerInfo: nextProps.headerInfo
            }
        }
        else {
            return null;
        }
    }

    Logout = () => {
        let param: any = this.props.match.params;
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.UserLogout}  ${pageTitle}`,
                { count: 1, page: pageTitle, clienId: param.clientId }
            )
        );
        this.props.logout(param.clientId, this.onLogOutCompletion);
    }

    onLogOutCompletion = (id: string) => {
        const url = PathConstants.CCRecipientLogin + id;
        this.props.history.push(url);
    }

    public render() {
        let param: any = this.props.match.params;
        let contactPersonFullName: string = "";
        const contactPerson = this.state.headerInfo.contactPerson;
        contactPersonFullName += contactPerson.firstName;
        if (contactPerson.middleName && contactPerson.middleName.length > 0) {
            contactPersonFullName += " " + contactPerson.middleName;
        }
        if (contactPerson.lastName && contactPerson.lastName.length > 0) {
            contactPersonFullName += " " + contactPerson.lastName;
        }
        let companyLogo = null;
        if (this.state.headerInfo.companyWhiteLogoPath == "") {
            companyLogo = <span className="company-name" data-test-auto="A2150408-8C6E-4460-9F2F-BA9DF37B09CA">{this.state.headerInfo.companyName}</span>;
        }
        else {
            companyLogo = <img className="logo-img" data-test-auto="A2150408-8C6E-4460-9F2F-BA9DF37B09CA"  src={this.state.headerInfo.companyWhiteLogoPath} />;
        }
        const contactInfoPopover = (
            <Popover id="contactInfoPopover" placement="bottom" data-test-auto="832DA592-EB4D-4463-998F-819121409D4B">
                <h3 className="popover-header">Contact Information</h3>
                <div className="popover-body" data-test-auto="AB7B10F9-DABF-433B-B6CB-E54D40F5913C">
                    <div className='medium'>{contactPersonFullName} </div>
                    <div>
                        <strong>{utilities.formateFax(contactPerson.phoneNumber)}</strong>
                        {contactPerson && contactPerson.extension && contactPerson.extension.length > 0 && <strong>Ext: {contactPerson.extension}  </strong>}
                    </div>
                    <div className='mail' data-test-auto="AE6CABBD-ADBE-4A12-96C2-EB5621BE1C6A">
                        <a href={"mailto:" + contactPerson.email}>{contactPerson.email}</a>
                    </div>
                </div>
            </Popover>
        );

        return (
            this.props.headerInfo.brandingSettings &&
                this.props.headerInfo.brandingSettings.coverPageSetting.bgColorCode != "" ?
                <header className="app-header" data-test-auto="72FDDFDB-0D5C-475A-BFE1-560FC5E95118">
                    <div className="header-left-container">

                        <input type="checkbox" className="openSidebarMenu" id="openSidebarMenu" />
                        <label htmlFor="openSidebarMenu" className="sidebarIconToggle">
                            <div className="spinner diagonal part-1"> </div>
                            <div className="spinner horizontal"> </div>
                            <div className="spinner diagonal part-2"> </div>
                        </label>
                        <div id="sidebarMenu">
                            <ul className="sidebar navbar-nav">
                            </ul>
                        </div>

                        {companyLogo}

                    </div>
                    <div className="header-right-container">
                        <span className="header-account">
                            <span onClick={() => this.Logout()} style={{ color: 'var(--headerForeColor)' }} data-test-auto="4AC1F751-00D6-4014-8633-02D244124744">
                                <i className="fa fa-sign-out" style={{ marginRight: '14px' }}></i>
                                <span>Logout</span>
                            </span>
                        </span>

                        <span className="header-contact-info" title="Contact Person's Information">
                            <OverlayTrigger
                                data-test-auto="2E7FCEA3-31A4-4A53-BBE2-7AA5156B6F8C"
                                rootClose trigger="click"
                                onEnter={() => document.body.click()}
                                overlay={contactInfoPopover}
                                placement="bottom">
                                <a>
                                    <SvgIconContactHeader
                                        fillColor="var(--headerForeColor)"
                                        height={20} />
                                    Contact Person
                             </a>
                            </OverlayTrigger>
                        </span>

                    </div>
                </header> : <Shimmer height={55} />)
    }
}