import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { actionTypes } from './ActionTypes';
import { StatusType, NotificationAction } from './Common/NotificationStore';
import {
    ICCRecipientDownloadableDocuments, initialModalState, initialDownloadModalState,
    ICCRecipientModel, ICcRecipientDownloadModel, CCRecipientDocumentGroups
} from 'src/core/domain/models/ICCRecipientModel';
import { initializeAxios } from 'src/core/services/dataAccess/DataService.Axios';
import { AxiosResponse, AxiosRequestConfig } from 'axios';
import { DocumentStatus } from '../core/common/Enums';
import { ILoader } from '../core/utilities/ui/Loader';
import { TYPES } from '../startup/types';
import { container } from '../startup/inversify.config';
import { DisplayDownloadFile } from '../components/Common/DisplayDownloadFile';
import { CCRecipientDownloadEndPoints } from '../components/Common/Constants';
import { TelemetryLogger } from '../components/Logger/AppInsights';

const logger = TelemetryLogger.getInstance();


interface RequestCCRecipientDownloadableDocumentsAction {
    type: actionTypes.CCRECIPIENT_DOWNLOADABLE_DOCUMENTS_REQUEST;
    clientId: string
}

interface ResponseCCRecipientDownloadableDocumentsAction {
    type: actionTypes.CCRECIPIENT_DOWNLOADABLE_DOCUMENTS_RESPONSE;
    data: ICCRecipientDownloadableDocuments[];
}

interface FailureCCRecipientDownloadableDocumentsAction {
    type: actionTypes.CCRECIPIENT_DOWNLOADABLE_DOCUMENTS_FAILURE;
    clientId: string
}

interface RequestCCRecipientDocumentsForDownloadAction {
    type: actionTypes.CCRECIPIENT_DOCUMENTS_FOR_DOWNLOAD_REQUEST;
    clientId: string
}

interface ResponseCCRecipientDocumentsForDownloadAction {
    type: actionTypes.CCRECIPIENT_DOCUMENTS_FOR_DOWNLOAD_RESPONSE;
    downloadableDocuments: ICcRecipientDownloadModel[];
}

interface FailureCCRecipientDocumentsForDownloadAction {
    type: actionTypes.CCRECIPIENT_DOCUMENTS_FOR_DOWNLOAD_FAILURE;
    clientId: string
}

type KnownAction =
    DispatchAction |
    NotificationAction;

type DispatchAction = RequestCCRecipientDownloadableDocumentsAction
    | ResponseCCRecipientDownloadableDocumentsAction
    | FailureCCRecipientDownloadableDocumentsAction
    | RequestCCRecipientDocumentsForDownloadAction
    | ResponseCCRecipientDocumentsForDownloadAction
    | FailureCCRecipientDocumentsForDownloadAction;


const loader = container.get<ILoader>(TYPES.ILoader);

export const actionCreators = {

    requestCCRecipientDownloadableDocuments: (clientId: string, documentStatus: DocumentStatus): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();
        dispatch({ type: actionTypes.CCRECIPIENT_DOWNLOADABLE_DOCUMENTS_REQUEST, clientId: clientId });
        return initializeAxios().get<ICCRecipientDownloadableDocuments>('/api/CCRecipient/GetDownloadableDocuments/'
            + documentStatus + '/' + clientId)
            .then(function (response: AxiosResponse<ICCRecipientDownloadableDocuments[]>) {

                dispatch({
                    type: actionTypes.CCRECIPIENT_DOWNLOADABLE_DOCUMENTS_RESPONSE, data: response.data
                });
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText
                        : "Failed to fetch document list",
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.CCRECIPIENT_DOWNLOADABLE_DOCUMENTS_FAILURE, clientId: clientId });
                logger.trackError(`requestCCRecipientDownloadableDocuments failed with error ${error.message} for client: ${clientId}`);
            });
    },
    forwardDocumentsToRecipients: (clientId: string, recipientInfo: ICCRecipientModel,
        callback: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
            loader.show();
            return initializeAxios().postJson<boolean>(recipientInfo, '/api/CCRecipient/SendDownloadableDocumentsToRecipients/' + clientId)
                .then(function (response: any) {
                    loader.hide();
                    callback();
                })
                .catch(function (error: any) {
                    dispatch({
                        type: actionTypes.NOTIFICATION, statusMessage: error.response,
                        statusType: StatusType.Error
                    });
                    logger.trackError(`forwardDocumentsToRecipients failed with error ${error.message} for client: ${clientId}`);
                });
        },
    requestCCRecipientDownloadableDocumentsForDownload: (clientId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();
        dispatch({ type: actionTypes.CCRECIPIENT_DOCUMENTS_FOR_DOWNLOAD_REQUEST, clientId: clientId });
        return initializeAxios().get<ICcRecipientDownloadModel>('/api/CCRecipient/GetTaxdocumentsForDownloading/' + clientId)
            .then(function (response: AxiosResponse<ICcRecipientDownloadModel[]>) {
                dispatch({
                    type: actionTypes.CCRECIPIENT_DOCUMENTS_FOR_DOWNLOAD_RESPONSE, downloadableDocuments: response.data
                });
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText
                        : "Failed to fetch document list for download",
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.CCRECIPIENT_DOWNLOADABLE_DOCUMENTS_FAILURE, clientId: clientId });
                logger.trackError(`requestCCRecipientDownloadableDocumentsForDownload failed with error ${error.message} for client: ${clientId}`);
            });
    },
    downloadTaxDocument: (clientId: string, documentId: string, taxYear: number,
        fileName: string, groupId: CCRecipientDocumentGroups): AppThunkAction<KnownAction> => (dispatch, getState) => {
            loader.show();
            return initializeAxios().get(getApiEndPoint(groupId, clientId, documentId, taxYear, fileName))
                .then(function (response: any) {
                    let displayDownloadFile = new DisplayDownloadFile();
                    displayDownloadFile.directDownload(response.data);
                    loader.hide();
                }).catch(function (error: any) {
                    dispatch({
                        type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : "Failed to download selected document",
                        statusType: StatusType.Error
                    });
                    logger.trackError(`downloadTaxDocument failed with error ${error.message} for client: ${clientId}`);
                });
        },

    downloadAll: (clientId: string, downloadableDocuments: ICcRecipientDownloadModel[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        loader.show();
        let fileName: string;
        let config: AxiosRequestConfig = { responseType: 'arraybuffer', headers: { 'Content-Type': 'application/json;utf-8' } };
        return initializeAxios().post('/api/CCRecipient/GetDownloadableZipFile/' + clientId, downloadableDocuments, config)
            .then(function (response: any) {
                const contentDisposition = response.headers["content-disposition"];
                const fileNameMatch = contentDisposition ? /filename="?([^"]*)"?;/g.exec(contentDisposition) : undefined;
                if (fileNameMatch && fileNameMatch.length > 1) {
                    fileName = fileNameMatch[1];
                }
                let displayDownloadFile = new DisplayDownloadFile();
                displayDownloadFile.showFile(response.data, fileName);
                loader.hide();
            }).catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : "Failed to download selected document",
                    statusType: StatusType.Error
                });
                logger.trackError(`downloadAll failed with error ${error.message} for client: ${clientId}`);
            });
    },
    downloadAllAdditionEsignDocuments: (clientId: string, documentId: string, taxYear: number,
        groupId: CCRecipientDocumentGroups): AppThunkAction<KnownAction> => (dispatch, getState) => {
            loader.show();
            let fileName: string;
            return initializeAxios().get(getApiEndPoint(groupId, clientId, documentId, taxYear, ""))
                .then(function (response: any) {
                    let displayDownloadFile = new DisplayDownloadFile();
                    if (response.data && response.data.length > 0) {
                        displayDownloadFile.directDownload(response.data);
                        loader.hide();
                    }
                    else {
                        let config: AxiosRequestConfig = { responseType: 'arraybuffer', headers: { 'Content-Type': 'application/json;utf-8' } };
                        return initializeAxios().get('/api/CCRecipient/GetAllAdditionalEsignDocumentsZipStreamAsync/' + clientId, config)
                            .then(function (response: any) {
                                const contentDisposition = response.headers["content-disposition"];
                                const fileNameMatch = contentDisposition ? /filename="?([^"]*)"?;/g.exec(contentDisposition) : undefined;
                                if (fileNameMatch && fileNameMatch.length > 1) {
                                    fileName = fileNameMatch[1];
                                }
                                displayDownloadFile.showFile(response.data, fileName);
                                loader.hide();
                            }).catch(function (error: any) {
                                dispatch({
                                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : "Failed to download All Attachments",
                                    statusType: StatusType.Error
                                });
                                logger.trackError(`downloadAllAdditionEsignDocuments failed with error ${error.message} for client: ${clientId}`);
                            });
                    }
                }).catch(function (error: any) {
                    dispatch({
                        type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : "Failed to download Other Signed Documents",
                        statusType: StatusType.Error
                    });
                });
        },
}

function getApiEndPoint(documentGroup: CCRecipientDocumentGroups, clientId: string, documentId: string, taxYear: number, fileName: string) {
    let endPoint: string = "";
    switch (documentGroup) {
        case CCRecipientDocumentGroups.Transmittals:
            endPoint = CCRecipientDownloadEndPoints.Transmittals + clientId + "?documentGuid=" + documentId + "&taxYear=" + taxYear;
            break;
        case CCRecipientDocumentGroups.SignedEFile:
            endPoint = CCRecipientDownloadEndPoints.Efile + clientId + "?documentGuid=" + documentId + "&taxYear=" + taxYear;
            break;
        case CCRecipientDocumentGroups.Vouchers:
            endPoint = CCRecipientDownloadEndPoints.Voucher + clientId + "?documentGuid=" + documentId + "&taxYear=" + taxYear + "&fileName=" + fileName;
            break;
        case CCRecipientDocumentGroups.TaxReturns:
            endPoint = CCRecipientDownloadEndPoints.TaxReturns + clientId + "?documentGuid=" + documentId + "&taxYear=" + taxYear;
            break;
        case CCRecipientDocumentGroups.AdditionalEsignDocument:
            endPoint = CCRecipientDownloadEndPoints.AdditionalEsignDocument + clientId + "?documentGuid=" + documentId + "&taxYear=" + taxYear;
            break;
        case CCRecipientDocumentGroups.Attachments:
            endPoint = CCRecipientDownloadEndPoints.Attachments + clientId + "?documentGuid=" + documentId + "&taxYear=" + taxYear + "&fileName=" + encodeURIComponent(fileName);
            break;
    }

    return endPoint;
}


export const reducer: Reducer<ICCRecipientDownloadableDocuments[]> = (state: ICCRecipientDownloadableDocuments[] = initialModalState,
    incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    var data = Object.assign({}, state);
    switch (action.type) {
        case actionTypes.CCRECIPIENT_DOWNLOADABLE_DOCUMENTS_REQUEST:
            return initialModalState;
        case actionTypes.CCRECIPIENT_DOWNLOADABLE_DOCUMENTS_RESPONSE:
            return action.data;
        default:
            return state || initialModalState;
    }
};

export const downloadReducer: Reducer<ICcRecipientDownloadModel[]> = (state: ICcRecipientDownloadModel[] = initialDownloadModalState,
    incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    var data = Object.assign({}, state);
    switch (action.type) {
        case actionTypes.CCRECIPIENT_DOCUMENTS_FOR_DOWNLOAD_REQUEST:
            return initialDownloadModalState;
        case actionTypes.CCRECIPIENT_DOCUMENTS_FOR_DOWNLOAD_RESPONSE:
            return action.downloadableDocuments;
        default:
            return state || initialDownloadModalState;
    }
};