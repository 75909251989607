import * as React from 'react'
import { ISelectItem } from '../../core/domain/viewModels/ICommon';
import { Dropdown } from 'react-bootstrap';
import DropdownItem from 'react-bootstrap/DropdownItem';

export interface IDropdownComponentProps {
    selectedValue?: string;
    options: ISelectItem[];
    onChange?: (selectedValue: any) => void;
    disabled?: boolean;
    customPlaceHolder?: any;
    size?: "lg" | "sm";
    className?: string;
}

export interface IDropdownComponentState {
    placeHolder: string
}

export class DropdownComponent extends React.Component<IDropdownComponentProps, IDropdownComponentState> {

    constructor(props: any) {
        super(props);

        this.state = {
            placeHolder: this.props.customPlaceHolder
        };
    }

    handleChange = (item: ISelectItem) => {
        this.setState({ placeHolder: item.value }, () => {
            this.props.onChange ? this.props.onChange(item.value) : null;
        });
    }

    public render() {
        return (
            <Dropdown>
                <Dropdown.Toggle
                    data-test-auto="3AEAEB96-0327-4893-BCE5-7A375DCDA4F8"
                    size={this.props.size}
                    variant="success"
                    id="dropdown-basic"
                    className={this.props.className}
                    disabled={this.props.disabled ? this.props.disabled : false}>
                    {this.props.selectedValue ? this.props.selectedValue : this.state.placeHolder ? this.state.placeHolder : "Select"}
                </Dropdown.Toggle>
                <Dropdown.Menu className="scrollable-menu text-center" data-test-auto="623B9A86-7263-4491-95A2-F1E231901580">
                    {this.props.options.map((item, index) => {
                        //return <a className="dropdown-item" key={item.key} onClick={() => this.handleChange(item)}>{item.value}</a>;
                        return <Dropdown.Item as="button" key={item.key} onClick={() => this.handleChange(item)}>{item.value}</Dropdown.Item>
                    })}
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}