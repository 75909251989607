import { ApplicationState } from "../../../store/index";
import { actionCreators as accountAction } from '../../../store/Common/AccountStore';
import { DelegateeDefaultLayout } from "./DelegateeDefaultLayout";
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { ClientType } from '../../../core/common/Enums';
import { createLoadingSelector } from '../../../store/selectors';
import { actionCreators as delegateeActionCreators } from '../../../store/Delegatee/DelegateeSignerStore';
import { IDelegateeConsentInfo, IDelegateeSignerDetails } from '../../../core/domain/models/DelegateeModels';
const loadingSelector = createLoadingSelector(['DELEGATEE_HEADERINFO', 'CCRECIPIENT_DOCUMENTS_FOR_DOWNLOAD']);

function mapStateToProps(state: ApplicationState) {
    return {
        delegateeHeaderInfo: state.delegateeHeaderInfo,
        delegateeSigner: state.delegateeSigner,
        loading: loadingSelector(state),
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        requestDelegateeHeaderInfo: (id: string) =>
            dispatch(accountAction.requestDelegateeHeaderInfo(id)),

        requestHeaderInfo: (id: string) =>
            dispatch(accountAction.requestHeaderInfo(id)),

        refreshToken: (clientId: string, callback: any) =>
            dispatch(accountAction.refreshToken(clientId, callback())),

        getSessionTimeOutSeconds: (clientId: string, callBack?: (sessionTimeout: number) => void) => dispatch(accountAction.getSessionTimeOutSeconds(clientId, callBack)),

        refreshCookies: (clientId: string) =>
            dispatch(accountAction.refreshCookies(clientId)),

        updateMobileNumber: (clientGuid: string, id: string, number: string, countryCodeValue: string,
            ssnValue: string, clientType: ClientType, callback: () => void) =>
            dispatch(accountAction.updateMobileNumber(clientGuid, id, number, countryCodeValue, ssnValue, clientType, callback)),

        requestRecipientHeaderInfo: (id: string) =>
            dispatch(accountAction.requestRecipientHeaderInfo(id)),

        requestDelegateeDetails: (clientId: string) =>
            dispatch(delegateeActionCreators.requestDelegateeDetails(clientId)),

        updateDelegateeMobileNumber: (clientId: string, countryCode: string, mobileNumber: string, callback?: () => void) =>
            dispatch(delegateeActionCreators.updateDelegateeMobileNumber(clientId, countryCode, mobileNumber, callback)),

        assignToDelegatee: (clientId: string, delegatee: IDelegateeSignerDetails, callback: () => void) =>
            dispatch(delegateeActionCreators.assignToDelegatee(clientId, delegatee, callback)),

        updateDelegatee: (clientId: string, delegatee: IDelegateeSignerDetails, taxyear: number, callback: () => void) =>
            dispatch(delegateeActionCreators.updateDelegatee(clientId, delegatee, taxyear, callback)),

        cancelDelegation: (clientId: string, remarks: string, taxYear: number, callback: () => void) =>
            dispatch(delegateeActionCreators.cancelDelegation(clientId, remarks, taxYear, callback)),

        requestDelegateeDocumentReviewModel: (clientId: string) =>
            dispatch(delegateeActionCreators.requestDelegateeDocumentReviewModel(clientId)),

        requestDelegateeDownloadableDocuments: (clientId: string) =>
            dispatch(delegateeActionCreators.requestDelegateeDownloadableDocuments(clientId)),

        saveDeclinedRemarks: (clientId: string, remarks: string, callback: () => void) =>
            dispatch(delegateeActionCreators.saveDeclinedRemarks(clientId, remarks, callback)),

        requestTaxClientDelegateeDetails: (clientId: string) =>
            dispatch(delegateeActionCreators.requestTaxClientDelegateeDetails(clientId)),

        saveDelegateeConsentDetails: (clientId: string, consentDetails: IDelegateeConsentInfo, callback: () => void) =>
            dispatch(delegateeActionCreators.saveDelegateeConsentDetails(clientId, consentDetails, callback)),

        logout: (clientGuid: string, callback: (id: string) => void) =>
            dispatch(accountAction.logout(clientGuid, callback)),
        logOutDelegatee:(clientGuid:string,callback:(id:string)=>void)=>
            dispatch(accountAction.logOutDelegatee(clientGuid,callback)),

        dispatch
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(DelegateeDefaultLayout));

