import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { actionTypes } from './ActionTypes';
import { StatusType, NotificationAction } from './Common/NotificationStore';
import { AxiosResponse, AxiosRequestConfig } from 'axios';
import { DisplayDownloadFile } from '../components/Common/DisplayDownloadFile';
import { IDownloadedZipFilesModel, MyDownloadStatus } from '../components/Layout/MyDownload';
import { MyDownloadsConstants } from '../components/Common/Constants';
import { ILoader } from '../core/utilities/ui/Loader';
import { TYPES } from '../startup/types';
import { container } from '../startup/inversify.config';
import { TelemetryLogger } from '../components/Logger/AppInsights';
import { IDownloadableDocumentsViewModel, initialDownloadableDocumentsViewModel } from 'src/core/domain/viewModels/IDownloadableDocumentsViewModel';
import { IAdditionalEsignDocument } from 'src/core/domain/models/IAdditionalEsignDocument';
import { initializeAxios } from 'src/core/services/dataAccess/DataService.Axios';

const logger = TelemetryLogger.getInstance();


interface RequestDownloadableDocumentsAction {
    type: actionTypes.DOWNLOADABLE_DOCUMENTS_REQUEST;
    clientId: string
}

interface ResponseDownloadableDocumentsAction {
    type: actionTypes.DOWNLOADABLE_DOCUMENTS_RESPONSE;
    data: IDownloadableDocumentsViewModel;
}

interface FailureDownloadableDocumentsAction {
    type: actionTypes.DOWNLOADABLE_DOCUMENTS_FAILURE;
    clientId: string

}

interface RequestMyDownloadAction {
    type: actionTypes.MY_DOWNLOAD_REQUEST;
}

export interface ResponseMyDownloadAction {
    type: actionTypes.MY_DOWNLOAD_RESPONSE;
    data: IDownloadedZipFilesModel[];
}
interface FailureMyDownloadAction {
    type: actionTypes.MY_DOWNLOAD_FAILURE;
}

interface RequestAdditionalEsignDocument {
    type: actionTypes.ADDITIONAL_ESIGN_DOCUMENT_REQUEST;
}

export interface ResponseAdditionalEsignDocument {
    type: actionTypes.ADDITIONAL_ESIGN_DOCUMENT_RESPONSE;
    data: IAdditionalEsignDocument[];
}

type KnownAction =
    DispatchAction |
    NotificationAction;

type DispatchAction = ResponseDownloadableDocumentsAction
    | RequestDownloadableDocumentsAction
    | FailureDownloadableDocumentsAction
    | RequestMyDownloadAction
    | ResponseMyDownloadAction
    | RequestAdditionalEsignDocument
    | ResponseAdditionalEsignDocument
    | FailureMyDownloadAction;

const loader = container.get<ILoader>(TYPES.ILoader);

export const actionCreators = {

    requestDownloadableDocuments: (clientId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();
        dispatch({ type: actionTypes.DOWNLOADABLE_DOCUMENTS_REQUEST, clientId: clientId });
        return initializeAxios().get<IDownloadableDocumentsViewModel>('/api/DownloadableDocument/GetAllAsync/' + clientId)
            .then(function (response: AxiosResponse<IDownloadableDocumentsViewModel>) {

                dispatch({
                    type: actionTypes.DOWNLOADABLE_DOCUMENTS_RESPONSE, data: response.data
                });

                if (state.downloadableDocuments.myDownloadList === undefined || state.downloadableDocuments.myDownloadList.length === 0) {
                    dispatch(actionCreators.requestMyDownload(clientId));
                }
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : "Failed to fetch document list",
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.DOWNLOADABLE_DOCUMENTS_FAILURE, clientId: clientId });
                logger.trackError(`requestDownloadableDocuments failed with error ${error.message} for client: ${clientId}`);
            });
    },
    downloadTaxReturnDocument: (clientId: string, fileName: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        loader.show();
        return initializeAxios().get('/api/Download/GetTaxReturnDocumentSasAsync/' + clientId)
            .then(function (response: any) {
                let displayDownloadFile = new DisplayDownloadFile();
                displayDownloadFile.directDownload(response.data);
                loader.hide();
            }).catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : "Failed to download Tax Return",
                    statusType: StatusType.Error
                });
                logger.trackError(`downloadTaxReturnDocument failed with error ${error.message} for client: ${clientId}`);
            });
    },
    downloadEFileDocument: (clientId: string, fileName: string, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        loader.show();
        return initializeAxios().get('/api/Download/GetEfileDocumentSas/' + clientId)
            .then(function (response: any) {
                let displayDownloadFile = new DisplayDownloadFile();
                displayDownloadFile.showFile(response.data, fileName, true);
                loader.hide();
            }).then(() => {
                callback && callback();
            }).catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : "Failed to download E-File",
                    statusType: StatusType.Error
                });
                logger.trackError(`downloadEFileDocument failed with error ${error.message} for client: ${clientId}`);
            });
    },
    downloadSignedEFileDocument: (clientId: string, fileName: string, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        loader.show();
        let config: AxiosRequestConfig = { responseType: 'arraybuffer', headers: { 'Content-Type': 'application/json;utf-8' } };
        return initializeAxios().get('/api/Download/GetSignedEfileDocumentStreamAsync/' + clientId, config)
            .then(function (response: any) {
                let displayDownloadFile = new DisplayDownloadFile();
                displayDownloadFile.showFile(response.data, fileName);
                loader.hide();
            }).then(() => {
                callback && callback();
            }).catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : "Failed to download E-File",
                    statusType: StatusType.Error
                });
                logger.trackError(`downloadSignedEFileDocument failed with error ${error.message} for client: ${clientId}`);
            });
    },
    downloadTransmittalDocument: (clientId: string, fileName: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        loader.show();
        return initializeAxios().get('/api/Download/GetTransmittalDocumentSasAsync/' + clientId)
            .then(function (response: any) {
                let displayDownloadFile = new DisplayDownloadFile();
                displayDownloadFile.directDownload(response.data);
                loader.hide();
            }).catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : "Failed to download Transmittal",
                    statusType: StatusType.Error
                });
                logger.trackError(`downloadTransmittalDocument failed with error ${error.message} for client: ${clientId}`);
            });
    },

    downloadVoucherDocument: (clientId: string, fileName: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        loader.show();

        const path = '/api/Download/GetVoucherDocumentSasAsync/' + clientId + "?fileName=" + fileName;

        return initializeAxios().get(path)
            .then(function (response: any) {
                let displayDownloadFile = new DisplayDownloadFile();
                displayDownloadFile.directDownload(response.data);
                loader.hide();
            }).catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : "Failed to download Voucher",
                    statusType: StatusType.Error
                });
                logger.trackError(`downloadVoucherDocument failed with error ${error.message} for client: ${clientId}`);
            });
    },

    downloadAttachment: (clientId: string, fileName: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        loader.show();
        return initializeAxios().get('/api/Download/GetAttachmentSasAsync/' + clientId + "?fileName=" + fileName)
            .then(function (response: any) {
                let displayDownloadFile = new DisplayDownloadFile();
                displayDownloadFile.directDownload(response.data);
                loader.hide();
            }).catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : "Failed to download Attachments",
                    statusType: StatusType.Error
                });
                logger.trackError(`downloadAttachment failed with error ${error.message} for client: ${clientId}`);
            });
    },
    downloadAllDocuments: (clientId: string, fileName: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        loader.show();
        return initializeAxios().get('/api/Download/GetAllDocumentZipSasAsync/' + clientId)
            .then(function (response: any) {
                let displayDownloadFile = new DisplayDownloadFile();
                if (response.data && response.data.length > 0) {
                    displayDownloadFile.directDownload(response.data);
                    loader.hide();
                }
                else {
                    let config: AxiosRequestConfig = { responseType: 'arraybuffer', headers: { 'Content-Type': 'application/json;utf-8' } };
                    return initializeAxios().get('/api/Download/GetAllDocumentsZipStreamAsync/' + clientId, config)
                        .then(function (response: any) {
                            displayDownloadFile.showFile(response.data, fileName);
                            loader.hide();
                        }).catch(function (error: any) {
                            dispatch({
                                type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : "Failed to download All Attachments",
                                statusType: StatusType.Error
                            });
                        });
                }
            }).catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : "Failed to download All Documents",
                    statusType: StatusType.Error
                });
                logger.trackError(`downloadAllDocuments failed with error ${error.message} for client: ${clientId}`);
            });
    },

    downloadAllAdditionEsignDocuments: (clientId: string, fileName: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        loader.show();
        return initializeAxios().get('/api/Download/GetAllAdditionEsignDocumentsSasAsync/' + clientId)
            .then(function (response: any) {
                let displayDownloadFile = new DisplayDownloadFile();
                if (response.data && response.data.length > 0) {
                    displayDownloadFile.directDownload(response.data);
                    loader.hide();
                }
                else {
                    let config: AxiosRequestConfig = { responseType: 'arraybuffer', headers: { 'Content-Type': 'application/json;utf-8' } };
                    return initializeAxios().get('/api/Download/GetAllAdditionalEsignDocumentsZipStreamAsync/' + clientId, config)
                        .then(function (response: any) {
                            displayDownloadFile.showFile(response.data, fileName);
                            loader.hide();
                        }).catch(function (error: any) {
                            dispatch({
                                type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : "Failed to download All Attachments",
                                statusType: StatusType.Error
                            });
                        });
                }
            }).catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : "Failed to download Other Signed Documents",
                    statusType: StatusType.Error
                });
                logger.trackError(`downloadAllAdditionEsignDocuments failed with error ${error.message} for client: ${clientId}`);
            });
    },

    requestMyDownload: (clientId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        //API disabled as of now
        //const state = getState();
        //dispatch({ type: actionTypes.MY_DOWNLOAD_REQUEST });
        //return initializeAxios().get<IDownloadedZipFilesModel[]>('/api/Download/GetMyDownload/' + clientId)
        //    .then(function (response: AxiosResponse<IDownloadedZipFilesModel[]>) {
        //        dispatch({
        //            type: actionTypes.MY_DOWNLOAD_RESPONSE, data: response.data
        //        });
        //    })
        //    .catch(function (error: any) {
        //        dispatch({
        //            type: actionTypes.NOTIFICATION, statusMessage: error.response.statusText,
        //            statusType: StatusType.Error
        //        });
        //        dispatch({ type: actionTypes.MY_DOWNLOAD_FAILURE });
        //        logger.trackError(`requestMyDownload failed with error ${error.message} for client: ${clientId}`);
        //    });
    },

    deleteMyDownloads: (downloadId: string, clientId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();
        return initializeAxios().post('/api/Download/DeleteMyDownloadAsync/' + clientId + "?downloadId=" + downloadId)
            .then(function (response: any) {

                let data = state.downloadableDocuments.myDownloadList;
                data.splice(data.findIndex(m => m.id === downloadId), 1);

                dispatch({
                    type: actionTypes.MY_DOWNLOAD_RESPONSE, data: data
                });

                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: MyDownloadsConstants.DeleteMyDownload,
                    statusType: StatusType.Success
                });

            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : "Failed to Delete the Selected File",
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.MY_DOWNLOAD_FAILURE });
                logger.trackError(`deleteMyDownloads failed with error ${error.message} for client: ${clientId}`);
            });
    },

    clearAllMyDownloads: (clientId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();
        return initializeAxios().post('/api/Download/ClearAllDownloadsAsync/' + clientId)
            .then(function (response: any) {

                let data = state.downloadableDocuments.myDownloadList;
                data = data.filter(function (download) {
                    return download.status === MyDownloadStatus.InProgress;
                });

                dispatch({
                    type: actionTypes.MY_DOWNLOAD_RESPONSE, data: data
                });

                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: MyDownloadsConstants.ClearAllMyDownloads,
                    statusType: StatusType.Success
                });

            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : "Failed to Clear your Downloads",
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.MY_DOWNLOAD_FAILURE });
                logger.trackError(`clearAllMyDownloads failed with error ${error.message} for client: ${clientId}`);
            });
    },

    requestAdditionalEsignDocument: (clientId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const state = getState();
        dispatch({ type: actionTypes.ADDITIONAL_ESIGN_DOCUMENT_REQUEST });
        return initializeAxios().get<IAdditionalEsignDocument[]>('/api/DownloadableDocument/GetAdditionalEsignDocumentsAsync/' + clientId)
            .then(function (response: AxiosResponse<IAdditionalEsignDocument[]>) {
                dispatch({
                    type: actionTypes.ADDITIONAL_ESIGN_DOCUMENT_RESPONSE, data: response.data
                });
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response.statusText,
                    statusType: StatusType.Error
                });
                logger.trackError(`requestAdditionalEsignDocument failed with error ${error.message} for client: ${clientId}`);
            });
    },
}

export const reducer: Reducer<IDownloadableDocumentsViewModel> = (state: IDownloadableDocumentsViewModel = initialDownloadableDocumentsViewModel, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    var data = Object.assign({}, state);
    switch (action.type) {
        case actionTypes.DOWNLOADABLE_DOCUMENTS_REQUEST:
            return initialDownloadableDocumentsViewModel;
        case actionTypes.DOWNLOADABLE_DOCUMENTS_RESPONSE:
            return action.data;
        case actionTypes.MY_DOWNLOAD_REQUEST:
            data.myDownloadList = [];
            return data;
        case actionTypes.MY_DOWNLOAD_RESPONSE:
            data.myDownloadList = action.data;
            return data;
        case actionTypes.ADDITIONAL_ESIGN_DOCUMENT_REQUEST:
            data.additionalEsignDocuments = [];
            return data;
        case actionTypes.ADDITIONAL_ESIGN_DOCUMENT_RESPONSE:
            data.additionalEsignDocuments = action.data;
            return data;
        default:
            return state || initialDownloadableDocumentsViewModel;
    }
};