import { connect } from 'react-redux';
import { actionCreators as HeaderActions } from "./../../../../store/Login/Common/Header/HeaderStore";
import { actionCreators as OtpActions } from "./../../../../store/Login/TP/OTP/OTPStore";
import { ApplicationState } from '../../../../store';
import MobileOTP from '../../Common/OTP/MobileOTP';
import { OtpPageStoreState } from '../../../../store/Login/Models/StoreModels';

export default connect(
    (state: ApplicationState) => ({
        headerStoreState: state.headerPageStore,
        backToHomePath: "/CoverPage/Index",
        successRedirectPath: "/Taxpayer/",
        mobileOtpPageStoreState: {
            countryCode: state.otpPageStore?.countryCode ?? "",
            mobileNo: state.otpPageStore?.mobileNo ?? "",
            loading: state.otpPageStore?.loading ?? false
        } as OtpPageStoreState,
        backToHome: true,
    }),
    {
        requestHeaderDetails: (clientId: string) =>
            HeaderActions.requestHeaderDetails(clientId),

        pageValidity: (clientId: string, errorCallback: (message?: string) => void) =>
            OtpActions.pageValidity(clientId, errorCallback),

        generateOtp: (clientId: string) =>
            OtpActions.generateMobileOTP(clientId),

        verifyOtp: (otp: string, clientId: string, handelOtpVerification: (guid: string) => void, handleError: () => void) =>
            OtpActions.verifyOTP(otp, clientId, handelOtpVerification, handleError),
    },
)(MobileOTP);
