import * as React from 'react';
import { match } from 'react-router';
import * as signalR from '@microsoft/signalr';
import { logger } from '../../routes';

export interface WebrtcSignalRState {
    apiBaseUrl: any;
    isCallInitiated: boolean;
};

type WebrtcSignalRProps =
    {
        match: match;
        isDebugEnabled: boolean;
        isCPAMode: boolean;
        iceServers: RTCIceServer[];
        getIceServers: (id: string) => void;
        handleStream?: () => void;
        handleTrack?: (evt: RTCTrackEvent) => void;
        handleRemoveStream?: () => void;
    }

export class WebrtcSignalR extends React.Component<WebrtcSignalRProps, WebrtcSignalRState> {
    signalrConnection: signalR.HubConnection;
    localStream: MediaStream | null;
    connections: RTCPeerConnection[] = [];
    webrtcConstraints = {};
    displayMediaOptions = {};
    cpaConnectionId = "";
    tpConnectionId = "";

    constructor(props: WebrtcSignalRProps) {
        super(props);
        const url: Location = window.location;
        this.state = {
            apiBaseUrl: `${url.protocol}//${url.host}/WebRtcHub`,
            isCallInitiated: false
        }

        const protocol = new signalR.JsonHubProtocol();
        const transport = signalR.HttpTransportType.WebSockets;
        const options = {
            transport,
            logMessageContent: true,
            logger: signalR.LogLevel.Trace
        };

        // create the connection instance
        this.signalrConnection = new signalR.HubConnectionBuilder()
            .withUrl(this.state.apiBaseUrl, options)
            .withAutomaticReconnect()
            .withHubProtocol(protocol)
            .build();

        this.localStream = null;
        this.displayMediaOptions = { video: { cursor: "always", }, audio: false }
        //this.webrtcConstraints = { audio: true, video: false };
    }

    componentDidMount() {
        const param: any = this.props.match.params;
        this.props.getIceServers(param.clientId);
        logger.trackPageView(`WebrtcSignalR page`);
    }

    componentWillUnmount() {
        this.signalrConnection.stop();
    }

    bootstrapSignalR = () => {

        this.signalrConnection.serverTimeoutInMilliseconds = 1000 * 60;

        this.signalrConnection.onclose((e: any) => {
            if (e) {
                this.consoleLogger("SignalR: closed with error.");
                this.consoleLogger(e);
            } else {
                this.consoleLogger("Disconnected");
            }
        });

        //called for both CPA and TP
        this.signalrConnection.on("updateUserList", (userList: any) => {
            this.consoleLogger("SignalR: called updateUserList" + JSON.stringify(userList));
            const _self = this;
            userList.map((item: any, index: number) => {
                const username: string = userList[index].username;
                if (username.startsWith("cpa")) {
                    _self.cpaConnectionId = userList[index].connectionId;
                }
                else {
                    _self.tpConnectionId = userList[index].connectionId;
                }
            });
            if (this.cpaConnectionId && this.tpConnectionId) {
                //STEP 1
                this.initiateCallFromTaxpayer();
            }
        });

        // Hub Callback: Call Accepted : STEP 3 - for CPA
        this.signalrConnection.on("callAccepted", async (acceptingUser: any) => {
            this.consoleLogger(
                "SignalR: call accepted from: " +
                JSON.stringify(acceptingUser) +
                ".  Initiating WebRTC call and offering my stream up..."
            );

            // Callee accepted our call, let's send them an offer with our screen sharing video stream
            await this.initiateOffer(acceptingUser.connectionId);
        });

        // Hub Callback: Call Declined
        this.signalrConnection.on("callDeclined", (decliningUser: any, reason: string) => {
            this.consoleLogger("SignalR: call declined from: " + decliningUser.connectionId);
        });

        // Hub Callback: Incoming Call : STEP 2 - for TP
        this.signalrConnection.on("incomingCall", (callingUser: any) => {
            this.consoleLogger("SignalR: incoming call from: " + JSON.stringify(callingUser));

            // Callee wants to answer the call
            this.signalrConnection
                .invoke("AnswerCall", true, callingUser)
                .catch((err: any) => this.consoleLogger(err));
        });

        // Hub Callback: WebRTC Signal Received : STEP 4 - for TP, STEP 5 - for CPA
        this.signalrConnection.on("receiveSignal", (signalingUser: any, signal: any) => {
            this.newSignal(signalingUser.connectionId, signal);
        });

        // Hub Callback: Call Ended
        this.signalrConnection.on("callEnded", (signalingUser: any, signal: any) => {
            this.consoleLogger(
                "SignalR: call with " + signalingUser.connectionId + " has ended: " + signal
            );
            // Close the WebRTC connection
            this.closeConnection(signalingUser.connectionId);
        });

        //TEMP COMMENT
        //this.signalrConnection.on("screenShareStopped", (signalingUser: any) => {
        //    this.consoleLogger(
        //        "SignalR: call with " + signalingUser.connectionId + " has ended"
        //    );
        //    // Close the WebRTC connection
        //    //this.stopCapture(signalingUser.connectionId);
        //});

        this.signalrConnection.start()
            .then(() => {
                this.consoleLogger('SignalR Connected');
                this.webrtcLogin();
            })
            .catch((err: any) => this.consoleLogger('SignalR Connection Error: ' + err));
    }

    webrtcLogin = () => {
        this.consoleLogger("SignalR: Joining Session...");
        let username = "";
        const params: any = this.props.match.params;
        const clientid = params.clientId;
        if (this.props.isCPAMode) {
            username = "cpa@" + clientid;
        }
        else {
            username = "tp@" + clientid;
        }
        this.signalrConnection.invoke("Join", {
            connectionId: this.signalrConnection.connectionId,
            username: username,
        })
            .catch((err: any) => {
                this.consoleLogger(JSON.stringify(err));
            });
    };

    initiateCallFromTaxpayer = () => {
        if (!this.props.isCPAMode && !this.state.isCallInitiated) {
            this.consoleLogger("calling user... ");
            this.signalrConnection.invoke("callUser", { connectionId: this.cpaConnectionId });
        }
    }

    initializeUserMedia = async () => {
        this.consoleLogger("WebRTC: InitializeUserMedia: ");
        try {
            await this.setLocalStream();
        } catch (e) {
            this.consoleLogger("Error occurred while reading media devices." + e);
        }
    };

    initiateOffer = async (partnerClientId: any) => {
        this.consoleLogger("WebRTC: called initiateoffer: ");
        if (!this.state.isCallInitiated) {
            this.setState({ isCallInitiated: true });
            const connection = this.getConnection(partnerClientId); // // get a connection for the given partner
            await this.initializeUserMedia();
            if (this.localStream) {
                for (const track of this.localStream.getTracks()) {
                    connection.addTrack(track);
                }
            }

            connection
                .createOffer()
                .then((offer: any) => {
                    this.consoleLogger("WebRTC: created Offer: ");
                    this.consoleLogger("WebRTC: Description after offer: " + offer);
                    connection
                        .setLocalDescription(offer)
                        .then(() => {
                            this.consoleLogger("WebRTC: set Local Description: ");
                            this.consoleLogger("connection before sending offer " + connection);
                            this.sendHubSignal(
                                JSON.stringify({ ss: connection.localDescription }),
                                partnerClientId
                            );
                        })
                        .catch((err: any) =>
                            this.consoleLogger("WebRTC: Error while setting local description" + err)
                        );
                })
                .catch((err: any) => this.consoleLogger("WebRTC: Error while creating offer" + err));
        }
    };

    getConnection = (partnerClientId: any) => {
        this.consoleLogger("WebRTC: called getConnection");
        if (this.connections[partnerClientId]) {
            this.consoleLogger("WebRTC: connections partner client exist");
            return this.connections[partnerClientId];
        } else {
            this.consoleLogger("WebRTC: initialize new connection");
            return this.initializeConnection(partnerClientId);
        }
    };

    initializeConnection = (partnerClientId: any) => {
        this.consoleLogger("WebRTC: Initializing connection...");

        const peerConnectionConfig = {
            iceServers: this.props.iceServers
        };

        const connection = new RTCPeerConnection(peerConnectionConfig);

        connection.ontrack = (evt) => this.onStreamTrack(evt);
        connection.onicecandidate = (evt) =>
            this.callbackIceCandidate(evt, connection, partnerClientId); // ICE Candidate Callback

        this.connections[partnerClientId] = connection; // Store away the connection based on username
        return connection;
    };

    setLocalStream = async () => {
        const media: any = navigator.mediaDevices;
        this.localStream = await media.getDisplayMedia(this.displayMediaOptions);
        this.consoleLogger("WebRTC: got media stream");
        const videoTracks = this.localStream && this.localStream.getVideoTracks();
        if (videoTracks && videoTracks.length > 0) {
            this.consoleLogger(`Using Video device: ${videoTracks[0].label}`);
            //we are using index 0, since we have only one video track and that is screenshare, if in future more tracks like camera is introduced then loop through all tracks
            videoTracks[0].onended = (evt) => this.onStreamTrackEnd(evt);
        }
    };

    onStreamTrack = (evt: RTCTrackEvent) => {
        this.props.handleTrack && this.props.handleTrack(evt);
    };

    onStreamTrackEnd = (evt: any) => {
        this.onHangup();
    };

    sendHubSignal = (candidate: any, partnerClientId: any) => {
        this.consoleLogger("candidate : " + candidate);
        this.consoleLogger("SignalR: called sendhubsignal ");
        this.signalrConnection.invoke("sendSignal", candidate, partnerClientId).catch(this.errorHandler);
    };

    newSignal = (partnerClientId: any, data: any) => {
        this.consoleLogger("WebRTC: called newSignal");

        const signal = JSON.parse(data);
        const connection = this.getConnection(partnerClientId);
        this.consoleLogger("connection: " + connection);

        // Route signal based on type
        if (signal.sdp) {
            this.consoleLogger("WebRTC: sdp signal");
            this.receivedSdpSignal(connection, partnerClientId, signal.sdp);
        } else if (signal.candidate) {
            this.consoleLogger("WebRTC: candidate signal");
            this.receivedCandidateSignal(connection, partnerClientId, signal.candidate);
        } else if (signal.ss) {
            this.consoleLogger("WebRTC: screen share signal");
            this.receivedScreenShareSignal(connection, partnerClientId, signal.ss);
        } else {
            this.consoleLogger("WebRTC: adding null candidate");
            connection.addIceCandidate(new RTCIceCandidate(undefined));
        }
    };

    receivedSdpSignal = (connection: RTCPeerConnection, partnerClientId: any, sdp: any) => {
        this.consoleLogger("connection: " + connection);
        this.consoleLogger("sdp" + sdp);
        this.consoleLogger("WebRTC: called receivedSdpSignal");
        this.consoleLogger("WebRTC: processing sdp signal");
        connection.setRemoteDescription(
            new RTCSessionDescription(sdp)).then(async () => {
                this.consoleLogger("WebRTC: set Remote Description");
                if (connection.remoteDescription && connection.remoteDescription.type == "offer") {
                    this.consoleLogger("WebRTC: remote Description type offer");
                    this.consoleLogger("WebRTC: added stream");
                    connection.createAnswer().then((desc: any) => {
                        this.consoleLogger("WebRTC: create Answer...");
                        connection.setLocalDescription(desc).then(() => {
                            this.consoleLogger("WebRTC: set Local Description...");
                            this.consoleLogger("connection.localDescription: " + connection.localDescription);
                            this.sendHubSignal(
                                JSON.stringify({ sdp: connection.localDescription }),
                                partnerClientId
                            );
                        }).catch(() => {
                            this.errorHandler
                        });
                    }, this.errorHandler);
                }
                else if (connection.remoteDescription && connection.remoteDescription.type == "answer") {
                    this.consoleLogger("WebRTC: remote Description type answer");
                }
            }).catch(() => {
                this.errorHandler
            });
    };

    receivedScreenShareSignal = (connection: RTCPeerConnection, partnerClientId: any, ss: any) => {
        this.consoleLogger("connection: " + connection);
        this.consoleLogger("ss" + ss);
        this.consoleLogger("WebRTC: called receivedScreenShareSignal");
        this.consoleLogger("WebRTC: processing ScreenShare signal");
        this.props.handleStream && this.props.handleStream();

        connection.setRemoteDescription(new RTCSessionDescription(ss)).then(async () => {
            this.consoleLogger("WebRTC: set Remote Description");
            if (connection.remoteDescription && connection.remoteDescription.type == "offer") {
                this.consoleLogger("WebRTC: remote Description type offer");
                this.consoleLogger("WebRTC: added stream");
                connection.createAnswer().then((desc: any) => {
                    this.consoleLogger("WebRTC: create Answer...");
                    connection.setLocalDescription(desc).then(() => {
                        this.consoleLogger("WebRTC: set Local Description...");
                        this.consoleLogger("connection.localDescription: " + connection.localDescription);
                        this.sendHubSignal(
                            JSON.stringify({ ss: connection.localDescription }),
                            partnerClientId
                        );
                    }).catch(() => {
                        this.errorHandler
                    });
                }, this.errorHandler);
            } else if (connection.remoteDescription && connection.remoteDescription.type == "answer") {
                this.consoleLogger("WebRTC: remote Description type answer");
            }
        }).catch(() => {
            this.errorHandler
        });
    };

    receivedCandidateSignal = (connection: RTCPeerConnection, partnerClientId: any, candidate: any) => {
        this.consoleLogger("WebRTC: adding full candidate");
        connection.addIceCandidate(new RTCIceCandidate(candidate));
    };

    callbackIceCandidate = (evt: any, connection: RTCPeerConnection, partnerClientId: any) => {
        this.consoleLogger("WebRTC: Ice Candidate callback");
        if (evt.candidate) {
            // Found a new candidate
            this.consoleLogger("WebRTC: new ICE candidate");
            this.sendHubSignal(
                JSON.stringify({ candidate: evt.candidate }),
                partnerClientId
            );
        } else {
            // Null candidate means we are done collecting candidates.
            this.consoleLogger("WebRTC: ICE candidate gathering complete");
            this.sendHubSignal(JSON.stringify({ candidate: null }), partnerClientId);
        }
    };

    onHangup = () => {
        this.signalrConnection.invoke("hangUp");
        this.closeAllConnections();
    }

    closeAllConnections = () => {
        this.consoleLogger("WebRTC: call closeAllConnections ");
        for (const connectionId in this.connections) {
            this.closeConnection(connectionId);
        }
    };

    closeConnection = (partnerClientId: any) => {
        this.consoleLogger("WebRTC: called closeConnection ");
        const connection = this.connections[partnerClientId];

        if (connection) {
            // Stop screensharing
            this.stopScreenShare();
            // Close the connection
            connection.close();
            delete this.connections[partnerClientId]; // Remove the property
        }
    };

    stopScreenShare = () => {
        this.consoleLogger("WebRTC: onStreamRemoved -> Removing stream: ");
        if (this.localStream) {
            this.localStream.getTracks().forEach((track) => {
                track.stop();
            });
            this.localStream = null;
        }
        this.props.handleRemoveStream && this.props.handleRemoveStream(); // remove our audio/video stream
    };

    errorHandler = (error: any) => {
        if (error.message) {
            this.consoleLogger(error.message);
        }
        else {
            this.consoleLogger(JSON.stringify(error));
        }
    };

    consoleLogger = (val: any) => {
        if (this.props.isDebugEnabled) {
            this.consoleLogger(val);
        }
    };

    render() {
        this.bootstrapSignalR();
        return <span />;
    };
};