import * as React from 'react';
import Skeleton from "react-loading-skeleton";
import { RouteComponentProps } from 'react-router';
import {
    CCRecipientOTPPageStoreState, CoverPageStoreState,
    DelegateeOTPPageStoreState, HeaderStoreState
} from '../../../../store/Login/Models/StoreModels';

interface MobileRestrictProps extends RouteComponentProps<{ role: string, clientId: string }> {
    clientStore: CoverPageStoreState;
    delegateeStore: DelegateeOTPPageStoreState;
    ccStore: CCRecipientOTPPageStoreState;
    headerStoreState: HeaderStoreState;
    requestHeaderDetails: (clientId: string) => void;
    requestDelegateeHeaderDetails: (clientId: string) => void;
    requestCCHeaderDetails: (clientId: string) => void;
    requestClientInfo: (clientId: string, errorCallBack: () => void) => void;
    requestCCInfo: (clientId: string) => void;
    requestDelegateeInfo: (clientId: string) => void;
    requestSenderName: (role: string, clientId: string) => void;
}

const MobileRestrict: React.FC<MobileRestrictProps> = ({
    requestClientInfo,
    requestHeaderDetails,
    match: { params: { clientId, role } },
    history,
    clientStore,
    headerStoreState,
    requestCCHeaderDetails,
    requestCCInfo,
    ccStore,
    delegateeStore,
    requestDelegateeHeaderDetails,
    requestDelegateeInfo,
    requestSenderName,
}) => {

    React.useEffect(() => {
        if (role === "CC") {
            requestCCInfo(clientId);
            requestCCHeaderDetails(clientId);
        } else if (role === "Delegatee") {
            requestDelegateeInfo(clientId);
            requestDelegateeHeaderDetails(clientId);
        } else {
            requestHeaderDetails(clientId);
            requestClientInfo(clientId, goToErrorPage);
        }
        if (role === "CC" || role === "Delegatee") {
            requestSenderName(role, clientId);
        }
    }, []);

    const goToErrorPage = () => {
        history.push("/Error");
    };

    const getClientName = (): string => {
        if (role === "CC") {
            return ccStore.ccRecipientModel.emailAddress;
        } else if (role === "Delegatee") {
            return delegateeStore.delegatee.name;
        } else {
            return clientStore.clientName;
        }
    };

    const getSenderName = () => {
        if (role === "CC" || role === "Delegatee") {
            return headerStoreState.senderName;
        } else {
            return headerStoreState.companyName;
        }
    };

    const borderColor = headerStoreState?.brandingSettings?.coverPageSetting?.bgColorCode ?? "";
    const textColorCode = headerStoreState?.brandingSettings?.coverPageSetting?.foreColorCode ?? "";

    let company;
    if (headerStoreState.companyWhiteLogoPath.length === 0) {
        company = <span className="company-name" style={{ color: textColorCode }}>{headerStoreState.companyName}</span>;
    } else {
        company = <img className="logo-img" src={headerStoreState.companyWhiteLogoPath} />;
    }

    return (<>

        <>
            {
                clientStore.loading || ccStore.loading || delegateeStore.loading || headerStoreState.loading ?
                    <Skeleton circle={false} height={"100vh"} width={"100vw"} /> :
                    <>
                        <header className="app-header" style={{ backgroundColor: borderColor, color: textColorCode, width: "100%" }}>
                            <div style={{ margin: 'auto' }} data-test-auto="951602DF-76D9-480A-BA0F-D12E216FBB2B">
                                {company}
                            </div>
                        </header>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 temp-mobile">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 temp-mobile-container" style={{ textAlign: "center", border: `solid ${borderColor} 1px` }}>
                                <div className="temp-mobile-header">
                                    Welcome {getClientName()}, {getSenderName()} has sent you some tax documents that need to be reviewed.
                                </div>
                                <div className="temp-mobile-desc">
                                    To complete  your extension and view, print, download or forward your tax documents please login using a computer.
                                </div>
                            </div>
                        </div>
                    </>
            }
        </>

    </>);

};

export default MobileRestrict;
