import { injectable } from "inversify";
import * as bootbox from 'bootbox';

export interface IDialogBox {
    alert(message: string, callback?: () => void): void;
    confirm(title: string, message: string, cancel: string, confirm: string, callback?: (result: boolean) => void): void;
    confirmCustom(title: string, message: string, rightButtonText: string, leftButtonText: string, callback?: (result: boolean) => void): void;
}

@injectable()
export class DialogBox implements IDialogBox {

  
    public alert(message: string, callback?: () => void): void {

        bootbox.alert(message, function () {
            callback && callback();
        });
    }

    public confirm(title: string, message: string, cancel: string, confirm: string, callback?: (result: boolean) => void): void {

        bootbox.confirm({
            title: '<i className="text-secondary"></i>' + title,
            message: message,
            buttons: {
                cancel: {
                    label: '<i class="glyphicon glyphicon-remove"></i> ' + cancel,
                    className: 'btn-white btn-default rounded'
                },
                confirm: {
                    label: '<i class="glyphicon glyphicon-ok"></i>' + confirm,
                    className: 'btn-primary rounded'
                }
            },
            callback: (result: boolean) => {
                if (callback) {
                    callback(result);
                }
            }
        });
    }


    public confirmCustom(title: string, message: string, rightButtonText: string, leftButtonText: string, callback?: (result: boolean) => void): void {

        bootbox.confirm({
            title: '<i className="text-secondary"></i>' + title,
            message: message,
            buttons: {
                cancel: {
                    label: '<i class="glyphicon glyphicon-remove"></i> ' + leftButtonText,
                    className: 'btn-white btn-default rounded'
                },
                confirm: {
                    label: '<i class="glyphicon glyphicon-ok"></i>' + rightButtonText,
                    className: 'btn-primary rounded'
                }
            },
            callback: (result: boolean) => {
                if (callback) {
                    callback(result);
                }
            }
        });
    }
}