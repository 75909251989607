import { ApplicationState } from "../../../store/index";
import { connect } from 'react-redux';
import { K1DistributionCompleted } from "./K1DistributionCompleted";
import { createLoadingSelector } from '../../../store/selectors';
import { actionCreators as TaxDocumentStore } from '../../../store/Common/TaxDocumentStore';
import { actionCreators as SignProcessStore } from '../../../store/SignProcess/SignProcessStore';
import { IClientProcessViewModel } from '../../../core/domain/viewModels/IClientProcessViewModel'




const loadingSelector = createLoadingSelector(['HEADERINFO', 'SUMMARY_DETAILS',
    'TAXING_AUTHORITY', 'DOCUMENT_REVIEW_MODEL', 'DOCUMENT_SETTINGS']);


function mapStateToProps(state: ApplicationState) {
    return {
        companyData: state.companyData.data,
        commonData: state.commonData,
        headInfoViewModel: state.headerInfo,
        taxReturn: state.taxReturn.taxDocument,
    };
}
//temperroy fix for webpack failure need to invetigate further when we have time
const mapDispatchToProps = (dispatch: any) => {
    return {
        requestTaxDocument: (clientGuid: string) => dispatch(TaxDocumentStore.requestTaxDocument(clientGuid)),
        requestCurrentStepAndUserRole: (clientGuid: string, successCallback?: (clientProcessState: IClientProcessViewModel) => void) => dispatch(SignProcessStore.requestCurrentStepAndUserRole(clientGuid, successCallback)),
        dispatch
    }
}


const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(K1DistributionCompleted);



