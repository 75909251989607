import * as React from 'react';
import { IHeaderInfoViewModel } from '../../core/domain/viewModels/IHeaderInfoViewModel';
import { Body } from '../Common/StepLayout/Body';
import { StepLayout } from '../Common/StepLayout/StepLayout';
import { PreparerMessage } from './PreparerMessage';
import { logger } from '../../routes';
import { IDelegateeInfo } from '../../core/domain/models/DelegateeModels';
import { LogEventConstants } from '../Helper/Constants';

interface PreparerMessageWizardProps {
    preparerMessage: string;
    headerInfo: IHeaderInfoViewModel;
    delegateeInfo?: IDelegateeInfo;
    isDelegatee: boolean;
}

const pageTitle = "Message view step";

export class PreparerMessageWizardBody extends React.Component<PreparerMessageWizardProps, {}> {

    constructor(props: PreparerMessageWizardProps) {
        super(props);
        logger.trackPageView('Preparer Message Page');
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.Open}  ${pageTitle}`,
                { count: 1, CustomEventType: LogEventConstants.Common.DocumentEventName, page: pageTitle, }
            )
        );
    }

    public render() {

        return (<StepLayout>
            <Body lg={10} md={12} sm={12} xs={12} data-test-auto="07145D3B-50E2-48A1-9E85-E771F8896C74">
                <PreparerMessage
                    message={this.props.preparerMessage}
                    headerInfo={this.props.headerInfo}
                    data-test-auto="2885B489-4B5F-40CF-BDFC-7C9C5D282523"
                    delegateeInfo={this.props.delegateeInfo}
                    isDelegatee={this.props.isDelegatee}
                />
            </Body>
        </StepLayout>
        );
    }
}
