import * as React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { IDelegateeInfo } from '../../../core/domain/models/DelegateeModels';
import { logger } from '../../../routes';
import { LogEventConstants } from '../../Helper/Constants';

export interface DelegateeAccountModalProps {
    showState: boolean;
    onHide: () => void;
    onChangeNumberClick: () => void;
    delegateeSigner: IDelegateeInfo
}

const pageTitle = "Deletegateee account Modal";
export class DelegateeAccountModal extends React.Component<DelegateeAccountModalProps, {}> {
    constructor(props: any) {
        super(props);
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.Open}  ${pageTitle}`,
                { count: 1, page: pageTitle }
            )
        );
    }


    onHide = () => {
        this.props.onHide();
    }

    phoneNumbermasking() {
        let number = this.props.delegateeSigner.mobileNumber;
        let n1, n2, n3, maskedNumber;
        if (number && number.length == 10) {
            n1 = number.substring(0, 3);
            n2 = number.substring(3, 6);
            n3 = number.substring(6, 10);
            maskedNumber = "(" + n1 + ")" + " " + + n2 + "-" + n3;
        } else {
            maskedNumber = number;
        }
        return maskedNumber;
    }


    public render() {
        const maskedPhoneNumber: string | undefined = this.phoneNumbermasking();
        const mobileNumber: string = this.props.delegateeSigner?.mobileNumber?.length > 5 ? "Change Number" : " Enter Mobile Number";
        return (
            <div>
                <Modal show={this.props.showState} className="myaccount" onHide={this.onHide}>
                    <Modal.Header closeButton >
                        <Modal.Title style={{ fontSize: '16px' }}>
                            <span className="fa fa-user ddl-icon" style={{ padding: '2px' }}></span>
                            My Account
                    </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-lg-12" style={{ display: 'inline' }}>

                                <div>
                                    <span className="myaccount modal-title">Mobile Login Number</span>
                                </div>
                                <div className="myaccount-text">
                                    <span style={{ fontSize: '14px' }}>
                                        An access code will be sent to the following mobile device number every time you login
                                        </span>
                                </div>
                                <div className="myaccount-text" style={{ display: "inline-flex" }}>
                                    <span style={{ paddingRight: '10px' }}>
                                        <b>Mobile Device Number: </b>
                                    </span>
                                    <span className="myaccount-countrycode">
                                        {this.props.delegateeSigner.countryCode}
                                    </span>
                                    <span className="myaccount-changenumber">
                                        {maskedPhoneNumber}
                                    </span>
                                    <a href="javascript:void(0);" className="tab" style={{ color: '#00cc00' }}
                                        onClick={() => { this.props.onChangeNumberClick() }} > {mobileNumber}</a>

                                </div>
                                <br />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            id="btnHideMyaccountPopUp"
                            onClick={this.onHide}
                            variant={"primary"}
                            className="btn btn-primary rounded">
                            <span>Done</span>
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
export default DelegateeAccountModal;