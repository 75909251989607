import {
    SvgIconSummaryWizardActive,
    SvgIconReviewWizardActive,
    SvgIconSignWizardActive,
    SvgIconDistributeK1sWizardActive,
    SvgIconMakePaymentsWizardActive,
    SvgIconSendElectronically,
    SvgIconSendManually,
    SvgIconDownloadAll,
    SvgIconContactHeader,
    SvgIconCalendarHeader,
    SvgIconAccountHeader,
    SvgIconManualSignature,
    SvgIconEsign,
    SvgIconReviewDocumentsSummary,
    SvgIconSignDocumentsSummary,
    SvgIconSignDocumentsCompletedSummary,
    SvgIconDownloadDocumentsSummary,
    SvgIconTaxPaymentsSummary,
    SvgIconTaxPaymentsCompletedSummary,
    SvgIconPrintAndSend,
    SvgIconPayTaxPrep,
    SvgIconDistributeK1sSummary,
    SvgIconDistributeK1sCompletedSummary,
    SvgIconInfoCircle,
    SvgIconDownloadAllSm
} from './Icons/SvgIcons';
export const SiginingConstants = {
    StatusMessage: {
        ManualSignSelectConfirmationMessage: 'Please confirm whether you want to continue with manual sign?',
        EditSpouseEmail: 'Edit Spouse Mail',
        EditSpouseEmailSuccess: 'Spouse E-Mail updated successfully',
        EditMobileNumber: 'Mobile number updated successfully'
    },
    WarningMessage:
    {
        InvalidEmailAddress: 'Please enter valid email address',
        InvalidMobileNumber: 'Please enter valid number',
        InvalidDOB: 'Please select valid date of birth',
        CountryCodeWarning: 'Please select country code.',
        InvalidSSN: 'Please enter a valid 4 digit ssn',
    },
    SelectSignMessage: "Select the method you would like to use to sign your documents",
    SelectSignTitle: "Sign Tax Documents",
    ESignImageCaption: "e-Sign",
    EsignCaption: "Sign electronically from this website",
    ManuallySignImageCaption: "Manually Sign",
    ManuallySignCaption: "Print, Sign and Return the forms",
    DelegateToSomeOne: "Delegate Signing to Someone Else",
    DelegationDescription: `Send tax forms to partners or \n shareholders for signatures`
}

export const ErrorMessages = {
    PreparerDocumentSettingError: "Error occured while loading DocumentSettings",
    HeaderInfoError: "Error occurred while loading Header Information",
    TaxingAuthorityError: "Error occured while loading Taxing Authority",
    SignedDocumentError: "Error occurred while loading signed documents model",
    AddSignedDocument: "Error occcured while uploading Signed document",
    DeleteSignedDocument: "Error occured while deleting Signed document",
    CompleteManualSign: "Error occured while completing the signed document",
    SummaryDetails: "Error occured while loading the Summary of the document",
    EsignModel: "Error occured while loading Esign Page",
    Signers: "Error occured while loading Signer information",
    ClientAuthentication: "Error occurred while authenticating user",
    ClientValidation: "Error occurred while validating client answer",
    UpadateSpouseMail: "Error occurred while updating spouse email",
    UpdatingMobileNumber: "Error occurred while updating mobile number",
    UpdatingDob: "Error occured while updating Date of Birth",
    SingatureControl: "Error occurred while requesting signature controls",
    DocumentSignatureSetting: "Error occurred while updating document signature settings",
    CompanyDetails: "Error occured while loading Company Details",
    DocumentReviewModel: "Error occured while loading Review Documents",
    RequestDocumentError: "Error occurred while requesting Tax Document model",
    RequestClientType: "Error occurred while fetching the Client Type",
    RequestKBAStatus: "Error occurred while loading Knowledge Based Authentication",
    RequestNextSigner: "Error occured while loading Next Signer Details",
    UpdateProcessInfoError: "Error occurred while updating process information",
    GetLastVisitedStepError: "Error occurred while requesting GetLastVisitedStep",
    GetCurrentStepAndRoleError: "Error occurred while requesting GetCurrentStepAndRole",
    ChangeMobileNumberError: "Unable to update the Mobile Number",
    CodeValidationError: "Error occured while validating screen share code",
    CodeValidationFailed: "The code you entered is not valid",
    GetTutorialInfoError: "Error occurred while requesting Tutorial Info",
    HideSummaryTutorialError: "Error occurred while hiding Summary Tutorial",
    HideReviewTutorialError: "Error occurred while hiding Review Tutorial",
    GetIsSignatureRequiredForSigner: "Error occurred while requesting GetIsSignatureRequiredForSigner",
    GetIsSignatureRequiredForDocument: "Error occurred while requesting GetIsSignatureRequiredForDocument",
    GetFilingFormsExists: "Error occurred while requesting CheckFilingFormsExists",
    GetNextFilingFormsSignerExists: "Error occurred while requesting CheckNextFilingFormsSignerExists",
    GetSessionTimeOutSecondsError: "Error occured while fetching Idle session timeout seconds"
};

export const MyAccountConstants = {
    StatusMessage: {
        EditSpouseDetails: 'Spouse details updated successfully',
        EditTaxpayerDetails: 'Taxpayer details updated successfully',
        EditMobileNumber: 'Mobile number updated successfully',
        UpdateInformation: 'Account details updated successfully',
    },
    WarningMessage:
    {
        InvalidEmailAddress: 'Please enter valid email address',
        PhoneNumberWarning: 'Please enter the phone number.',
        CountryCodeWarning: 'Please select country code.'
    }
}

export const MyDownloadsConstants = {
    StatusMessage: 'Download process may take some time, the downloaded <FILENAME> will be available in "Downloads"',
    DeleteMyDownload: 'Selected File has been deleted',
    ClearAllMyDownloads: 'All Downloaded files have been deleted',
    DeleteSingleFileWarning: 'File cannot be deleted, since Download is in progress.',
    DialogBox: {
        ConfirmDeletionSingle: 'Are you sure you want to delete this file?',
        ConfirmDeletionAll: 'Are you sure you want to clear all files?',
        Confirm: 'Yes',
        Cancel: 'Cancel',
        Title: 'Confirm Deletion'
    },
    DownloadFiles: "Choose the document(s) to download:",
    DownloadDocument: "Download Tax Document(s)",
    DocumentsNotSelectedWarning: "Please make sure you have selected atleast one document to download",

}

export const ValidationContants = {
    PhoneNumberWarning: 'Please enter the phone number.',
    PhoneNumberLengthWarning: 'Please enter a 10 digit phone number.',
    CountryCodeWarning: 'Please select the country code',
}

export const Common = {
    Wizard: {
        PreviousButtonLabel: "Back ",
        NextButtonLabel: "Continue ",
        SendLater: "Send Later ",
        ForwardDocuments: "Forward a copy of my tax documents",
        PayNow: "Pay Now",
        Submit: "Submit"
    },
    DialogBox: {
        ConfirmationTitle: "Confirmation",
        WarningTitle: "Warning",
        Cancel: "Cancel",
        Confrim: "Confirm",
        Yes: "Yes",
        No: "No"
    },
    Images: {
        download: SvgIconDownloadAll,
        SummaryStepIcon: SvgIconSummaryWizardActive,
        ReviewStepIcon: SvgIconReviewWizardActive,
        SignStepIcon: SvgIconSignWizardActive,
        PayStepIcon: SvgIconMakePaymentsWizardActive,
        DistributeStepIcon: SvgIconDistributeK1sWizardActive,
        SendElectronically: SvgIconSendElectronically,
        SendManually: SvgIconSendManually,
        ContactIcon: SvgIconContactHeader,
        CalendarIcon: SvgIconCalendarHeader,
        HeaderAccountIcon: SvgIconAccountHeader,
        ElectronicalSignIcon: SvgIconEsign,
        ManuallySignIcon: SvgIconManualSignature,
        DownloadSmIcon: SvgIconDownloadAllSm
    }
}


export const ManualSignConstants = {
    Title: "Manual Signatures Required",
    DownloadDocumentTitle: "Please download documents to continue",
    DownloadDocumentButtonText: "Download documents requiring signature",
    UploadDocumentTitle: "After manually signing documents please select a method to send them to your tax preparer",
    UploadDocumentSecondaryTitle: "Upload Signed Documents",
    SendByMailText: "Send by Mail",
    SendByFaxText: "Send by Fax",
    FileDeleteConfirmation: "Are you sure you want to delete the file ?",
    DownloadEfileLink: "/api/Download/GetManualSignDocument/",
    UploadAreaButtonText: "Click to Upload",
    UploadAreaText: "Drag documents here",
    UploadAreaMinimizedText: "Drag documents here or click here to upload",
    UploadAreaTextSeperation: "Or",
    UploadNotAllowedMessage: "Upload not allowed in preview mode",
    SupportedFilesMessage: "Unsupported file type attached. We support PDF, DOC, Excel, PNG and JPEG file types.",
    ConfirmMessage: "All document(s) have been successfully uploaded and will be forwarded to <COMPANYNAME>. Do you want to proceed?",
    AuditFileName: "Signature_Audit_Report.pdf",
    DownloadZipSignedFileName: "_Extensions_Other Signed Documents.zip",
    DownloadZipUnSignedFileName: "_Extensions_Other UnSigned Documents.zip",
    DownloadZipMultipleUnSignedFileName: "_Extensions_Other UnSigned Documents.zip"
}

export const K1EngagementType = {
    E1065: "Partners",
    E1120S: "Shareholders",
    E1041: "Beneficiaries"
}

export const K1ShareHolderConstants = {
    DownloadAllK1Link: "/api/Download/GetAllK1DocumentAsync/",
    WarningMessage:
    {
        EmailAdrressWarning: 'Please enter email address.',
        InvalidEmailAddress: 'Please enter valid email Address.',
        CheckBoxSelect: 'Please select atleast one',
        EmailAdrressEmpty: 'Email address can not be blank',
        EntityTypeWarning: 'Please select entity type',
        K1DistributionWarning: 'Are you sure you would like to continue without distributing your K-1’s',
        Cancel: 'No, Return',
        Confirm: 'Yes, I\'ll do it later'
    },
    SuccessMessage:
    {
        emailUpdated: 'Email address updated successfully',
        detailsUpdated: 'K1 details updated successfully',
        emailSent: 'Email sent successfully'
    }
}


export const SignProcessConstants = {
    ProcessRecoveryConfirmation: "Sign Process Recovery Confirmation",
    ProcessRecoveryMessage: "Would like to resume where you left off or start over ?",
    ProcessRecoveryConfirmationDialogLeftButton: "Resume",
    ProcessRecoveryConfirmationDialogRightButton: "Start over",
    ProcessRecoveryFailedMessage: "some settings has been changed, starting from first",
}

export const CompletedSummaryConstant = {
    SpouseReminderAlertMessage: "Spouse electronic signature required to file taxes",
    TaxpayerReminderAlertMessage: "Taxpayer electronic signature required to file taxes",
    InstructionText: "You can access and manage all of your tax documents by selecting an option below",
    SecondSignerAlertMessage: "You have completed all required steps, we are awaiting <SIGNERNAME>",
    CompletedMessage: "All required steps for your <TAXYEAR> tax extensions have been completed!",
    WelcomeMessage: "Welcome <TAXPAYERNAME>",
    SendSigningReminderSuccess: "Signing Reminder has been successfully sent",
    SendSigningReminderFailure: "Failed to send the Signing Reminder",
    SecondManualNosignAlertMessage:"You have opted for Manual Signature, please have <SIGNERNAME> sign the documents as well and return the signed documents to your tax preparer.",
    Images: {
        InfoCircle: SvgIconInfoCircle,
        ViewDocument: SvgIconDownloadDocumentsSummary,
        ViewDownloadSignedDocument: SvgIconSignDocumentsCompletedSummary,
        MakeTaxPayment: SvgIconTaxPaymentsCompletedSummary,
        PrintFiledReturn: SvgIconPrintAndSend,
        PayTaxPrepFee: SvgIconPayTaxPrep,
        DistributeK1: SvgIconDistributeK1sCompletedSummary
    },
    ImageCaption: {
        ViewDownloadDocument: 'View or Download Tax Documents',
        ViewDownloadSignedDocument: 'View or Download Signed Documents',
        MakeTaxPayment: 'Make Tax Payments',
        PrintFiledReturn: 'Print & Mail Paper Filed Returns',
        PayTaxPrepFee: 'Pay Tax Preparation Fees',
        DistributeK1: 'Distribute K-1s',
        UpdateDelegatee: "Update Delegatee"
    },
    FileDownload:{
        SignedPdfFile: "_Extensions_Signed Documents.pdf",
        SignedZipFile: "_Extensions_Signed Documents.zip"
    }
}

export const PayConstants = {
    VoucherSettingUpdated: "Payment Voucher Notification Settings Updated Successfully.",
    ErrorVoucherSettingUpdated: "Error occurred while updating payment Voucher document settings",
    SuccesVoucherPayementMessage: "Voucher Payment Updated Successfully",
    ErrorVoucherPaymentMessage: "Failed to Update Voucher Payment",
    InvalidVoucherAmount: "Amount can not be greater than the Voucher amount",
}

export const WelcomeSummaryConstants = {
    InstructionText: "Please follow these easy steps so your extensions can be filed.",
    WelcomeMessage: "Your <TAXYEAR> Tax Return Extension is ready!",
}

export const PathConstants = {
    DistributeK1: "/distributeK1/",
    DistributeK1Electronically: "/distributeK1/electronic/",
    DistributeK1Manually: "/distributeK1/manual/",
    CompletionWizard: "/signcompleted/",
    Pay: "/pay/",
    ManualSign: "/manualsign/",
    SignFlow: "/signflow/",
    PreviewFlow: "/previewflow/",
    Download: "/download/",
    ClientLogin: "/CoverPage/Index/",
    ControllerLogin: "/GroupedReturns/Coverpage/Index/",
    CCRecipientLogin: "/CCRecipient/",
    DelegateeLogin: "/Delegatee/",
}

export const Permissions = {
    Read: "Read",
}
export const KBAConstants = {
    KBARetryExceedMessage: "As per IRS regulations after three failures at  Identity Verification you must manually sign Form(s) 8879",
    KBAFailureMessage: "KBA validation failed. Please enter correct answers.",
    KBAIdentificationFailure: "You do not qualify for the IRS required Knowledge Based Authentication.  As a result, you are unable to electronically sign your documents and you must manually sign Form(s) 8879."
}

export const SummaryTurorialConstants = {
    TaxYearModal: {
        header: "Multiple Tax Year Available",
        body: "You may switch between different tax years by using the drop-down list located here.",
        doNotShowInstruction: "Do not show me this instruction again",
    },
    ContactPersonModal: {
        header: "Contact Person",
        body: "Please contact this person if you are experiencing issues navigating this website or completing the process.",
        doNotShowInstruction: "Do not show me this instruction again",
    },
    Buttons: {
        next: "Next",
        done: "Done",
    }
}

export const ReviewTurorialConstants = {
    ReviewModal: {
        header: "Select Documents To Review",
        body: "Please select all the Document Groups and review before proceeding to signing.",
        doNotShowInstruction: "Do not show me this instruction again",
    },
    Buttons: {
        next: "Next",
        done: "Done",
    }
}

export const CCRecipientConstants = {
    ModalDescription: 'Please enter the email addresses that you would like to share the tax documents with. ' +
        'Documents will be sent from <span class="ssr-default-email">noreply@safesendreturns.com</span>. Recipients will be required to enter an ' +
        'Access Code which will be sent in a secondary email or with text message authentication',
    Note: "Note: Recipients will only be able to download copies of tax documents. They will not be able to sign forms or manage payments.",
    DocumentForwardText: "Choose the document(s) to forward:",
    CcEmptyWarning: "Please make sure you have entered recipient email address",
    DocumentsNotSelectedWarning: "Please make sure you have selected atleast one document to forward",
    SuccessfulyMailedDocuments: "Email Sent Successfully",
    InvalidEmail: "Please make sure you have entered valid email address"
}

export const CCRecipientDownloadEndPoints = {
    TaxReturns: '/api/CCRecipient/DownloadTaxReturnDocumentSasAsync/',
    Transmittals: '/api/CCRecipient/DownloadTransmittalDocumentSasAsync/',
    Efile: '/api/CCRecipient/DownloadEfileDocumentSasAsync/',
    Invoice: '/api/CCRecipient/DownloadInvoiceDocumentSasAsync/',
    Voucher: '/api/CCRecipient/DownloadVoucherDocumentSasAsync/',
    AdditionalEsignDocument: '/api/CCRecipient/DownloadAdditionEsignDocumentSasAsync/',
    Attachments: '/api/CCRecipient/DownloadAttachmentSasAsync/',
}

export const VoucherPaymentConstants = {
    AddingVoucherPaymentNotAllowed: 'Adding payment details is not allowed in preview mode',
};

export const DownloadDocumentsConstants = {
    DownloadNotAllowed: "Documents are not available for download within Client View"
};

export const AppInsightsConstants = {
    Role: "EXTTaxpayer-Client",
    Product: "SafeSend Extensions"
};

export const DelegateePathConstants = {
    SignFlow: "/Delegatee/SignFlow/",
    ManualSign: "/Delegatee/ManualSign/",
    CompletionWizard: "/Delegatee/SignCompleted/",
    Logout: "/Authentication/DelegateeLogout/",
};

export const DelegateeSignerConstants = {
    WarningMessage:
    {
        EmptyEmailAdrress: "Email address cannot be empty.",
        InvalidEmailAddress: "Please enter valid email Address.",
        EmptyFirstName: "Please enter delegatee first name.",
        EmptyLastName: "Please enter delegatee last name.",
        EmptyDescription: "Please enter the reason for delegation",
        EmptyCancelDelegationRemarks: "Please enter the reason for canceling the delegation",
        EmptyDeclineSignRemarks: "Please enter the reason for declining",
        InvalidConfirmEmail: "Please enter valid confirm email Address.",
        EmailDontMatch: "Actual email address and confirm email address don't match.",
        InvalidMobileNumber: "Please enter a valid 10 digit phone number.",
        WarningUpdatingDelegatee: "You are not allowed to update delegatee as signing is completed by delegatee.",
        WarningCancelDelegation: "You are not allowed to cancel the delegation as signing is completed by delegatee."
    },
    SuccessMessage: {
        SavedDelegateeDetailsSuccess: "Delegatee details saved successfully",
        UpdateDelegateeSuccess: "Updated delegatee details successfully",
        SavedCancelDelegationSuccess: "Delegation canceled successfully",
        UpdateMobileNumberSuccess: "Delegatee mobile number updated successfully",
        DeclineSignSuccess: "Document signing declined successfully",
    },
    ErrorMessage: {
        ErrorSavingDelegateeDetails: "Error occured while saving delegatee details",
        ErrorUpdatingDelegatee: "Error occured while updating delegatee details",
        ErrorCancelingDelegation: "Error occured while canceling delegation",
        ErrorUpdatingMobileNumber: "Error occured while updating mobile number",
        ErrorFetchingDelegatee: "Error occured while fetching delegatee details",
    },
};

export const CoverPageConstants = {
    SuccessMessage: {

    },
    ErrorMessage: {
        clientFetchFailed: "Unable to fetch client Data, Please try again",
        serverError: "Internal Server Error, Please try again",
    },
};

export const OTPPageConstants = {
    SuccessMessage: {
        OTPEmailGenerateSuccess: "A one-time access code was sent to your email",
    },
    ErrorMessage: {
        lockedByOTP: "Maximum retry reached for one-time access code",
        serverError: "Internal Server Error, Please try again",
        OTPGenerateFailed: "Unable to generate one-time access code, Please try again",
        OTPExpired: "Entered one-time access code has Expired",
        OTPError: "Provided one-time access code is invalid",

    },
};

export const OTPMobilePageConstants = {
    SuccessMessage: {
        OTPTextGenerateSuccess: "A one-time access code was sent to you via a text message",
    },
    ErrorMessage: {
        lockedByOTP: "Maximum retry reached for one-time access code",
        serverError: "Internal Server Error, Please try again",
    },
};

export const SSNPageConstants = {
    SuccessMessage: {

    },
    ErrorMessage: {
        lockedBySSN: "Maximum retry reached for SSN",
        OTPLocked: "Maximum retry reached for one-time access code",
        InvalidSSN: "Provided SSN is invalid",
        serverError: "Internal Server Error, Please try again",
    },
};

export const HeaderConstants = {
    ErrorMessage: {
        RetrieveFailed: "Unable to fetch Details, Please try again",
    },
};

export const spouseEmailPopUpConstants={
    PopUpMessage:`Please provide an email address for your spouse. It may be the same as your own if you share an email account. They will
        receive a similar request asking them to sign their forms electronically.`
}


export const DisplayError = [
    "ERROR_DELETED_RETURN",
    "ERROR_RECALLED_RETURN",
    "ERROR_INVALID_LINK",
    "OTP_LOCKED_MESSAGE",
    "SSN_LOCKED_MESSAGE",
    "SSN_LOCKED",
    "OTP_LOCKED",
    "RETURN_DECLINED_FOR_SIGN"
];
