import * as React from "react";
import { IKBAAnswers, IKBAAnswer, IKBAAnswerChoice, IKBATransactionResponse } from "../../core/domain/models/IKBA";
import { Form, Row, Col } from "react-bootstrap";
import { match } from "react-router";
import { Shimmer } from "../Common/Shimmer/Shimmer";
import { KBATransactionResultType } from "../../core/common/Enums";
import { StepLayout } from "../Common/StepLayout/StepLayout";
import { Body } from "../Common/StepLayout/Body";
import { SignatureType } from '../../core/common/Enums';
import { IDialogBox } from '../../core/utilities/ui/DialogBox';
import { container } from '../../startup/inversify.config';
import { TYPES } from '../../startup/types';
import { Common, KBAConstants } from '../Common/Constants';
import { MarsNotifier } from '../Common/Notification/MarsNotifier';
import { logger } from '../../routes';
import { SignProcessSteps } from '../../components/Home/TaxpayerSignFlowManager';
import { LogEventConstants } from '../Helper/Constants';

export type IKBAComponentProps =
    {
        match: match;
        response: IKBATransactionResponse;
        loadKBAQuestions(clientGuid: string, failureCallback: () => void): void;
        validateKBAAnswers(clientGuid: string, data: IKBAAnswers, successCallback: (isNextQuestionSet?: boolean) => void, failureCallback: () => void): void;
        clearParentState: () => void;
        refreshKBAStatus(clientGuid: string, successCallback?: () => void): void;
        onCompletion?(): void;
        updateDocumentSignatureSettingModel(clientId: string, signatureType: number, callback: any): void;
        onKBAFailure(): void;
        refreshTaxDocument: (clientGuid: string, callback?: (data: any) => void) => void;
        updateLastVisitedStep: (clientGuid: string, step: SignProcessSteps, successCallback?: () => void) => void;
    }

export interface IKBAComponentState {
    currentQuestionIndex: number;
    remainingQuestions: number;
    isChoiceSelected: boolean;
    errorText: string;
    kbaAnswer: IKBAAnswers;
    answer: IKBAAnswer;
    choice: IKBAAnswerChoice;
    retryCount: number;
}
const dialogBox = container.get<IDialogBox>(TYPES.IDialogBox);
const pageTitle = "KBA page";

export class KBAComponent extends React.Component<IKBAComponentProps, IKBAComponentState> {

    constructor(props: IKBAComponentProps) {
        super(props);
        this.props.clearParentState();
        this.clearComponentState(true);
    }

    componentDidMount() {
        const param: any = this.props.match.params;
        this.props.loadKBAQuestions(param.clientId, this.onKBAIdentityVerificationFailure);
        logger.trackPageView('KBA Page');
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.Open} ${pageTitle}`,
                { count: 1, page: pageTitle, clientId: param.clientId }
            )
        );
    }

    static getDerivedStateFromProps(props: IKBAComponentProps, state: IKBAComponentState) {
        if (!props.response.questions || !props.response.questions.question) {
            return null;
        } else {
            return {
                remainingQuestions: state.remainingQuestions
                    ? state.remainingQuestions
                    : props.response.questions.question.length
            } as IKBAComponentState;
        }
    }

    private clearComponentState = (isConstructor: boolean) => {
        if (isConstructor) {
            this.state = {
                currentQuestionIndex: 0,
                remainingQuestions: 0,
                isChoiceSelected: false,
                errorText: "",
                kbaAnswer: {
                    transactionId: 0,
                    tryCount: 0,
                    questionSetId: 0,
                    answers: []
                },
                answer: {
                    questionId: 0,
                    choice:
                    {
                        choiceId: 0,
                        choiceText: ''
                    }
                },
                choice: {
                    choiceId: 0,
                    choiceText: ''
                },
                retryCount: 0
            }
        }
        else {
            this.setState({
                currentQuestionIndex: 0,
                remainingQuestions: 0,
                isChoiceSelected: false,
                errorText: "",
                kbaAnswer: {
                    transactionId: 0,
                    tryCount: 0,
                    questionSetId: 0,
                    answers: []
                },
                answer: {
                    questionId: 0,
                    choice:
                    {
                        choiceId: 0,
                        choiceText: ''
                    }
                },
                choice: {
                    choiceId: 0,
                    choiceText: ''
                }
            });
        }
    }

    private handleRadioChange = (e: any) => {
        this.setState({ isChoiceSelected: true, errorText: "", choice: { choiceId: e.target.value, choiceText: e.target.label } });
        logger.trackTrace(`selected choiceId: ${e.target.value} choiceText: ${e.target.label}`);
    }

    private handleQuestionChange = () => {
        this.validateCurrentQuestion();
    }

    private validateCurrentQuestion = () => {
        if (!this.state.isChoiceSelected) {
            this.setState({ errorText: "Please select a valid choice" });
        } else {
            logger.trackTrace(`questions loaded: ${this.props.response.questions}`);
            const currIndex: number = this.state.currentQuestionIndex;
            logger.trackTrace(`current question index: ${currIndex}`);
            const count = this.props.response.questions.question.length - 1;
            logger.trackTrace(`totals questions: ${count}`);
            const remQuestions = this.state.remainingQuestions;
            logger.trackTrace(`remaining questions: ${remQuestions}`);
            const allChoices: IKBAAnswerChoice[] = this.props.response.questions.question[this.state.currentQuestionIndex].choices;
            logger.trackTrace(`choices loaded for current question: ${this.props.response.questions.question[this.state.currentQuestionIndex].choices}`);
            const selectedChoice: IKBAAnswerChoice | undefined = allChoices.find((el) => {
                if (el.choiceId == this.state.choice.choiceId) {
                    return el;
                }
            });
            const userChoice: IKBAAnswerChoice = selectedChoice ? selectedChoice : allChoices[0];
            const answer: IKBAAnswer = {
                questionId: this.props.response.questions.question[this.state.currentQuestionIndex].questionId,
                choice: userChoice
            };
            const kbaAnswer = this.state.kbaAnswer;

            kbaAnswer.answers.push(answer);
            kbaAnswer.transactionId = this.props.response.transactionId;
            kbaAnswer.questionSetId = this.props.response.questions.questionSetId;

            if (currIndex < count) {
                const newIndex = currIndex + 1;
                this.setState({
                    currentQuestionIndex: newIndex, remainingQuestions: (remQuestions - 1), isChoiceSelected: false,
                    choice: {
                        choiceId: 0, choiceText: ''
                    },
                    kbaAnswer: {
                        transactionId: this.props.response.transactionId,
                        questionSetId: this.props.response.questions.questionSetId,
                        tryCount: this.state.retryCount,
                        answers: kbaAnswer.answers
                    }
                });
            }
            const param: any = this.props.match.params;
            logger.trackEvent(
                logger.buildEvent(
                    `${LogEventConstants.Common.Esign.ValidateKBAQuestion} ${pageTitle}`,
                    { count: count, page: pageTitle, clientId: param.clientId, questionSetId: kbaAnswer.questionSetId, transactionId: kbaAnswer.transactionId, index: currIndex, questionId: answer.questionId, choiseId: answer.choice.choiceId }
                )
            );
        }
    }

    private handleSubmit = (e: any) => {
        const param: any = this.props.match.params;
        this.validateCurrentQuestion();
        if (this.state.isChoiceSelected) {
            this.props.validateKBAAnswers(param.clientId, { ...this.state.kbaAnswer, tryCount: this.state.retryCount }, this.onSubmitSuccessful, this.onSubmitFailed);
        }

        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.Esign.KbaSubmit} ${pageTitle}`,
                { count: 1, page: pageTitle, clientId: param.clientId }
            )
        );
    }

    private onSubmitSuccessful = (isNextQuestionSet?: boolean) => {
        const param: any = this.props.match.params;
        this.clearComponentState(false);
        if (!isNextQuestionSet) {
            this.props.refreshKBAStatus(param.clientId, () => { this.props.onCompletion && this.props.onCompletion() });
        }
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.Esign.SubmitSuccess} ${pageTitle}`,
                { count: 1, page: pageTitle, clientId: param.clientId }
            )
        );
    }

    confirmRedirectToManualSign = () => {
        const param: any = this.props.match.params;
        //Updating last visited step in case TP refreshes the page on KBA failure
        this.props.updateLastVisitedStep(param.clientId, SignProcessSteps.ManualSignDownload);
        dialogBox.alert(KBAConstants.KBARetryExceedMessage,
            () => {
                this.onKBARetryExceed()
            });
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.Esign.RedirectToManualSign} ${pageTitle}`,
                { count: 1, page: pageTitle, clientId: param.clientId }
            )
        );
    }

    onKBAIdentityVerificationFailure = () => {
        const param: any = this.props.match.params;
        this.props.updateLastVisitedStep(param.clientId, SignProcessSteps.ManualSignDownload);
        dialogBox.alert(KBAConstants.KBAIdentificationFailure,
            () => {
                this.onKBARetryExceed()
            });
    }

    private onKBARetryExceed = () => {
        const param: any = this.props.match.params;
        this.props.refreshTaxDocument(param.clientId, (data) => { this.props.onKBAFailure(); });

    }

    private onSubmitFailed = () => {
        const param: any = this.props.match.params;
        this.setState({ retryCount: this.state.retryCount + 1 });

        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.Esign.SubmitFailed} ${pageTitle}`,
                { count: 1, page: pageTitle, clientId: param.clientId }
            )
        );

        if (this.state.retryCount >= 3) {
            this.confirmRedirectToManualSign();
        }
        else {
            MarsNotifier.Error(KBAConstants.KBAFailureMessage, "");
            this.props.loadKBAQuestions(param.clientId, this.onKBAIdentityVerificationFailure);
            this.clearComponentState(false);
        }
    }

    private isErrorState = () => {
        return (this.props.response.transactionStatus.toString() === KBATransactionResultType[KBATransactionResultType.error] || this.props.response.transactionStatus.toString() === KBATransactionResultType[KBATransactionResultType.failed])
    }

    public render() {

        return (<StepLayout>

            <Body height={100}
                automationTestId={"11BB28B5-42F3-4571-B567-20700B1740B6"} >

                <div className="col-xs-6 col-sm-6 col-md-10 col-lg-12" style={{ height: "88%", padding: "20px" }}>
                    <h4>
                        Security Questions
                </h4>
                    <div className="content-paragraph">
                        IRS required Disclosure. Please verify your identity by answering the following questions. These questions are generated from a third
                        party's database. This process does not access or impact your credit report and the third party does not have access to your taxpayer information.
                </div>
                    <div className="content-paragraph">
                        Please answer the following.
                 </div>
                    <div data-test-auto="3A17285E-0BA8-4410-B006-ECAAAEE56BAE">
                        {this.state.errorText ? <div className="error-container">{this.state.errorText}</div> : null}
                        <div className="kba-questions content-paragraph" data-test-auto="816B863F-7F5E-4A93-8321-2F6EA081A301">
                            {this.props.response.questions?.question?.length > 0 ?
                                this.props.response.questions.question[this.state.currentQuestionIndex]?.question :
                                this.isErrorState() ?
                                    null : <Shimmer height={25} />}
                        </div>
                        <div className="content-paragraph" data-test-auto="22A75A11-9F78-4C90-9E2A-31271A2A8518">
                            <Form.Group as={Row}>
                                <Col sm={10}>
                                    {
                                        this.props.response.questions?.question?.length > 0 ?
                                            this.props.response.questions?.question[this.state.currentQuestionIndex]?.choices?.map((choice, index) => {
                                                return <Form.Check
                                                    name="kbaChoiceGroup"
                                                    type={"radio"}
                                                    key={choice.choiceId}
                                                    id={"kba-radio-" + (index + 1)}
                                                    label={choice.choiceText}
                                                    value={choice.choiceId}
                                                    checked={choice.choiceId == this.state.choice.choiceId ? true : false}
                                                    onChange={(e: any) => this.handleRadioChange(e)} />;
                                            }) :
                                            this.isErrorState() ?
                                                null : <Shimmer lineCount={5} />
                                    }
                                </Col>
                            </Form.Group>
                        </div>
                    </div>

                </div>
                <div className="col-xs-6 col-sm-6 col-md-10 col-lg-12 download-footer steps-footer">
                    {this.state.remainingQuestions > 1 ? <button disabled={!this.state.isChoiceSelected} onClick={this.handleQuestionChange} data-test-auto="73A4B994-8D10-40E8-A98B-C41FCFE78E44" className="btn btn-primary flrt">Continue</button> :
                        <button onClick={this.handleSubmit} disabled={!this.state.isChoiceSelected} data-test-auto="A2C1D707-9F35-44AA-9E07-D9E37C179DCD" className="btn btn-primary flrt" hidden={this.state.remainingQuestions < 1}>Continue</button>}
                </div>

            </Body>
        </StepLayout>);

    }
}