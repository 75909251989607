import { ToastContainer, toast } from 'react-toastify';
import * as React from 'react';
import 'react-toastify/dist/ReactToastify.css';

export class MarsNotifier extends React.Component<any, any> {

    static Error = (message: string, title: any) =>
        toast.error(message, { position: toast.POSITION.TOP_CENTER });


    static Warning = (message: string, title: any) =>
        toast.warn(message, { position: toast.POSITION.TOP_CENTER });


    static Success = (message: string, title: any) =>
        toast.success(message, { position: toast.POSITION.TOP_CENTER });


    static Info = (message: string, title: any) =>
        toast.info(message, { position: toast.POSITION.TOP_CENTER });

}

export class Toaster extends React.Component<any, any> {

    render() {
        return (
            <div>
                <ToastContainer
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick={true}
                    rtl={false}
                    pauseOnHover
                />
            </div>
        );
    }
}