import { SignatureControlRole } from "../IGroup";

export interface IControl {
    id: string;
    top: number;
    left: number;
    tooltip: string;
    required: boolean;
    customData: ICustomData[];
    data?: IControlData;
    disabled?: boolean
}



export interface ISignatureControl extends IControl {

}

export interface ITextBoxControl extends IControl {
    readOnly: boolean;
}

export interface ILabelControl extends IControl {

}



export interface IInitialControl extends ITextBoxControl {


}

export interface ITitleControl extends ITextBoxControl {


}

export interface ICompanyControl extends ITextBoxControl {


}

export interface ICustomTextControl extends ITextBoxControl {


}

export interface IDateControl extends ILabelControl {


}



export interface ICustomData {
    role?: SignatureControlRole;
}



export interface IControlData {


}

export interface ISignatureData extends IControlData {

    name: string;
    signature: string;
    signatureMode: any;
}

export interface ITextData extends IControlData {
    text: string;
}

export class SignatureControl implements ISignatureControl {

    id: string;
    top: number;
    left: number;
    tooltip: string;
    required: boolean;
    customData: ICustomData[];
    data?: IControlData;
    disabled?: boolean;

    constructor(id: string,
        top: number,
        left: number,
        tooltip: string,
        required: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean) {

        this.id = id;
        this.top = top;
        this.left = left;
        this.tooltip = tooltip;
        this.required = required;
        this.customData = customData;
        this.data = data;
        this.disabled = disabled;
    }

    static create(id: string,
        top: number,
        left: number,
        tooltip: string,
        required: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean): ISignatureControl {

        return new SignatureControl(id,
            top,
            left,
            tooltip,
            required,
            customData,
            data,
            disabled);

    }
}

export class InitialControl implements IInitialControl {

    readOnly: boolean;
    id: string;
    top: number;
    left: number;
    tooltip: string;
    required: boolean;
    customData: ICustomData[];
    data?: IControlData;
    disabled?: boolean;

    constructor(id: string,
        top: number,
        left: number,
        tooltip: string,
        required: boolean,
        readOnly: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean) {

        this.id = id;
        this.top = top;
        this.left = left;
        this.tooltip = tooltip;
        this.required = required;
        this.readOnly = readOnly;
        this.customData = customData;
        this.data = data;
        this.disabled = disabled;
    }

    static create(id: string,
        top: number,
        left: number,
        tooltip: string,
        required: boolean,
        readOnly: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean): IInitialControl {

        return new InitialControl(id,
            top,
            left,
            tooltip,
            required,
            readOnly,
            customData,
            data,
            disabled);

    }
}

export class TitleControl implements ITextBoxControl {

    readOnly: boolean;
    id: string;
    top: number;
    left: number;
    tooltip: string;
    required: boolean;
    customData: ICustomData[];
    data?: IControlData;
    disabled?: boolean;

    constructor(id: string,
        top: number,
        left: number,
        tooltip: string,
        required: boolean,
        readOnly: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean) {

        this.id = id;
        this.top = top;
        this.left = left;
        this.tooltip = tooltip;
        this.required = required;
        this.readOnly = readOnly;
        this.customData = customData;
        this.data = data;
        this.disabled = disabled;
    }

    static create(id: string,
        top: number,
        left: number,
        tooltip: string,
        required: boolean,
        readOnly: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean): ITextBoxControl {

        return new TitleControl(id,
            top,
            left,
            tooltip,
            required,
            readOnly,
            customData,
            data,
            disabled);

    }

}

export class CompanyControl implements ITextBoxControl {

    readOnly: boolean;
    id: string;
    top: number;
    left: number;
    tooltip: string;
    required: boolean;
    customData: ICustomData[];
    data?: IControlData;
    disabled?: boolean;

    constructor(id: string,
        top: number,
        left: number,
        tooltip: string,
        required: boolean,
        readOnly: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean) {

        this.id = id;
        this.top = top;
        this.left = left;
        this.tooltip = tooltip;
        this.required = required;
        this.readOnly = readOnly;
        this.customData = customData;
        this.data = data;
        this.disabled = disabled;
    }

    static create(id: string,
        top: number,
        left: number,
        tooltip: string,
        required: boolean,
        readOnly: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean): ITextBoxControl {

        return new CompanyControl(id,
            top,
            left,
            tooltip,
            required,
            readOnly,
            customData,
            data,
            disabled);

    }

}

export class PrintNameControl implements ITextBoxControl {

    readOnly: boolean;
    id: string;
    top: number;
    left: number;
    tooltip: string;
    required: boolean;
    customData: ICustomData[];
    data?: IControlData;
    disabled?: boolean;

    constructor(id: string,
        top: number,
        left: number,
        tooltip: string,
        required: boolean,
        readOnly: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean) {

        this.id = id;
        this.top = top;
        this.left = left;
        this.tooltip = tooltip;
        this.required = required;
        this.readOnly = readOnly;
        this.customData = customData;
        this.data = data;
        this.disabled = disabled;
    }

    static create(id: string,
        top: number,
        left: number,
        tooltip: string,
        required: boolean,
        readOnly: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean): ITextBoxControl {

        return new PrintNameControl(id,
            top,
            left,
            tooltip,
            required,
            readOnly,
            customData,
            data,
            disabled);

    }
}

export class CustomTextControl implements ITextBoxControl {

    readOnly: boolean;
    id: string;
    top: number;
    left: number;
    tooltip: string;
    required: boolean;
    customData: ICustomData[];
    data?: IControlData;
    disabled?: boolean;

    constructor(id: string,
        top: number,
        left: number,
        tooltip: string,
        required: boolean,
        readOnly: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean) {

        this.id = id;
        this.top = top;
        this.left = left;
        this.tooltip = tooltip;
        this.required = required;
        this.readOnly = readOnly;
        this.customData = customData;
        this.data = data;
        this.disabled = disabled;
    }

    static create(id: string,
        top: number,
        left: number,
        tooltip: string,
        required: boolean,
        readOnly: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean): ITextBoxControl {

        return new CustomTextControl(id,
            top,
            left,
            tooltip,
            required,
            readOnly,
            customData,
            data,
            disabled);

    }
}

export class DateControl implements ILabelControl {

    id: string;
    top: number;
    left: number;
    tooltip: string;
    required: boolean;
    customData: ICustomData[];
    data?: IControlData;
    disabled?: boolean;

    constructor(id: string,
        top: number,
        left: number,
        tooltip: string,
        required: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean) {

        this.id = id;
        this.top = top;
        this.left = left;
        this.tooltip = tooltip;
        this.required = required;
        this.customData = customData;
        this.data = data;
        this.disabled = disabled;

    }

    static create(id: string,
        top: number,
        left: number,
        tooltip: string,
        required: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean): ILabelControl {

        return new DateControl(id,
            top,
            left,
            tooltip,
            required,
            customData,
            data,
            disabled);

    }
}

export class SignatureData implements ISignatureData {

    name: string;
    signature: string;
    signatureMode: string;

    constructor(name: string, sign: string, signmode: string) {

        this.name = name;
        this.signature = sign;
        this.signatureMode = signmode;
    }

    static create(name: string, sign: string, signmode: string): ISignatureData {
        return new SignatureData(name, sign, signmode);
    }
}

export class NameAndTitleControl implements ITextBoxControl {

    readOnly: boolean;
    id: string;
    top: number;
    left: number;
    tooltip: string;
    required: boolean;
    customData: ICustomData[];
    data?: IControlData;
    disabled?: boolean;

    constructor(id: string,
        top: number,
        left: number,
        tooltip: string,
        required: boolean,
        readOnly: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean) {

        this.id = id;
        this.top = top;
        this.left = left;
        this.tooltip = tooltip;
        this.required = required;
        this.readOnly = readOnly;
        this.customData = customData;
        this.data = data;
        this.disabled = disabled;
    }

    static create(id: string,
        top: number,
        left: number,
        tooltip: string,
        required: boolean,
        readOnly: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean): ITextBoxControl {

        return new NameAndTitleControl(id,
            top,
            left,
            tooltip,
            required,
            readOnly,
            customData,
            data,
            disabled);

    }

}


export class TextData implements ITextData {

    text: string;

    constructor(text: string) {
        this.text = text;
    }

    static create(text: string): ITextData {
        return new TextData(text);
    }
}

export interface ISignerInfo extends ICustomData {
    email: string;
}

export interface IControlRole extends ICustomData {
    role: SignatureControlRole;
}

export class SignerInfo implements ISignerInfo {
    email: string;

    constructor(email: string) {
        this.email = email;
    }

    static create(email: string): ISignerInfo {
        return new SignerInfo(email);
    }
}

export class ControlRole implements IControlRole {
    role: SignatureControlRole;

    constructor(role: SignatureControlRole) {
        this.role = role;
    }

    static create(role: SignatureControlRole): IControlRole {
        return new ControlRole(role);
    }
}