    import * as React from 'react';
import { match } from 'react-router';
import { DownloadAllK1 } from '../K1Distribution/DownloadAllK1';
import { DistributeManualTable } from '../K1Distribution/DistributeManualTable';
import { IK1ShareHolderDetails } from '../../core/domain/models/IK1Distribute';
import { ITaxReturn } from '../../core/domain/models/ITaxReturn';
import { MarsNotifier } from '../Common/Notification/MarsNotifier';
import { K1ShareHolderConstants } from '../Common/Constants';
import { EngagementTypeS } from '../../core/common/Enums';

interface DistributeManualProps {
    requestK1ShareHolderDetails: (
        clientGuid: string,
        pageNo: number,
        pageSize: number,
        sortBy: string,
        sortOrder: string,
        searchText: string
    ) => void;
    downloadK1DocumentAsync: (clientId: string, engagementTpe: EngagementTypeS) => void;
    downloadIndividualK1DocumentAsync: (
        clientId: string,
        shareHolder: IK1ShareHolderDetails,
        engagementTpe: EngagementTypeS
    ) => void;
    downloadSelectedK1DocumentAsync: (
        clientId: string,
        shareHolders: IK1ShareHolderDetails[],
        engagementTpe: EngagementTypeS,
        callback?: () => void
    ) => void;
    k1Data: IK1ShareHolderDetails[];
    match: match;
    taxReturn: ITaxReturn;
}

interface DistributeManualState {
    selectedRows: number[];
    sortName: string;
    sortOrder: string;
    searchText: string;
    page: number;
}
const pageSize: number = 10;

export class DistributeManual extends React.Component<DistributeManualProps, DistributeManualState>{
    constructor(props: any) {
        super(props);
        this.state = {
            selectedRows: [],
            sortName: 'Name',
            sortOrder: 'asc',
            searchText: ' ',
            page: 1
        }
    }

    componentDidMount() {
        let param: any = this.props.match.params;
        this.props.requestK1ShareHolderDetails(
            param.clientId,
            this.state.page,
            pageSize,
            this.state.sortName,
            this.state.sortOrder,
            this.state.searchText);
    }

    loadK1ShareHolderDetails = () => {
        let param: any = this.props.match.params;
        this.props.requestK1ShareHolderDetails(
            param.clientId,
            this.state.page,
            pageSize,
            this.state.sortName,
            this.state.sortOrder,
            this.state.searchText);
    }

    private onPageChange = (page: number, sizePerPage: number) => {
        this.setState({
            page: page,
            selectedRows: []
        }, () => this.loadK1ShareHolderDetails());
    }

    private onSortChange: any = (sortName: any, sortOrder: any, column: any) => {
        this.setState({
            sortName: sortName,
            sortOrder: sortOrder == "asc" ? "asc" : "desc",
        }, () => this.loadK1ShareHolderDetails());
    }

    private onSearchChange = (searchString: string) => {
        let temp = searchString !== '' ? searchString : ' ';
        this.setState({
            searchText: temp,
            page: 1
        }, () => this.loadK1ShareHolderDetails());
    }

    private onRowSelect = (row: any, isSelected: any, e: any) => {
        let newList = [...this.state.selectedRows];
        if (e.target.tagName !== 'BUTTON' && e.target.tagName !== 'I'
            && e.target.tagName !== 'SPAN' && e.target.tagName !== 'A') {
            if (isSelected) {
                newList.push(row.rowIndex);
            } else {
                var index = newList.indexOf(row.rowIndex);
                if (index > -1) {
                    newList.splice(index, 1);
                }
            }
            this.setState({ selectedRows: newList });
        }
        //this.forceUpdate();
    }

    private onSelectAll = (isSelected: any, rows: any, e: any) => {
        let selectedRows: number[] = [];
        if (isSelected) {
            let count = rows && rows.length ? rows.length : 0;
            Array.from(Array(count).keys());
            selectedRows = Array.from(Array(count).keys());
        }
        this.setState({ selectedRows: selectedRows });
    }

    private onDownloadDocument = () => {
        let param: any = this.props.match.params;
        let partners: IK1ShareHolderDetails[] = [];
        if (this.state.selectedRows.length == 0) {
            MarsNotifier.Warning(K1ShareHolderConstants.WarningMessage.CheckBoxSelect, null);
        }
        else {
            for (var index in this.state.selectedRows) {
                let rowIndex = this.state.selectedRows[index];
                let partner = this.props.k1Data[rowIndex];
                if (partner) {
                    partners.push(partner);
                }
            }
            this.props.downloadSelectedK1DocumentAsync(param.clientId, partners, this.props.taxReturn.engagementType, this.onClearSelection);
        }
    }

    private onClearSelection = () => {
        this.setState({
            selectedRows: []
        });
    }


    public render() {
        let text: string = 'Select K-1 Documents to Print';
        return (
            <div className="col-xs-6 col-sm-6 col-md-12 col-lg-12 content-wrapper-2">
                <div className="displayFlex">
                    <h3 className="step-layout-body-header displayFlex3 no-padding" data-test-auto="5DE433E2-EFAF-44A3-A116-E8EB5403B87F">Distribute K-1 Documents by Mail </h3>
                    <DownloadAllK1
                        downloadK1DocumentAsync={this.props.downloadK1DocumentAsync}
                        match={this.props.match}
                        taxReturn={this.props.taxReturn}
                    />
                </div>
                <div className="download-helper">
                    <span className="sub-title" data-test-auto="3732082C-B09E-491A-A31D-654F3905B886"> {text} </span>
                </div>
                <DistributeManualTable
                    onPageChange={this.onPageChange}
                    onSortChange={this.onSortChange}
                    onSearchChange={this.onSearchChange}
                    onRowSelect={this.onRowSelect}
                    onSelectAll={this.onSelectAll}
                    pageNo={this.state.page}
                    pageSize={pageSize}
                    k1Data={this.props.k1Data}
                    selectedRows={this.state.selectedRows}
                    taxReturn={this.props.taxReturn}
                    match={this.props.match}
                    downloadIndividualK1DocumentAsync={this.props.downloadIndividualK1DocumentAsync}
                />
            </div>
        )
    }
}