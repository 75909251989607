import { connect } from 'react-redux';
import { actionCreators as HeaderActions } from "./../../../../store/Login/Common/Header/HeaderStore";
import { actionCreators as OtpActions } from "./../../../../store/Login/TP/OTP/OTPStore";
import { ApplicationState } from '../../../../store';
import EmailOtp from '../../Common/OTP/EmailOTP';

export default connect(
    (state: ApplicationState) => ({
        headerStoreState: state.headerPageStore,
        backToHomePath: "/CoverPage/Index",
        successRedirectPath: "/Taxpayerflow/",
        backToHome: true,
    }),
    {
        requestHeaderDetails: (clientId: string) =>
            HeaderActions.requestHeaderDetails(clientId),

        pageValidity: (clientId: string, errorCallback: (message?: string) => void) =>
            OtpActions.pageValidity(clientId, errorCallback),

        generateOtp: (clientId: string) =>
            OtpActions.generateOTP(clientId),

        verifyOtp: (otp: string, clientId: string, handelOtpVerification: (guid: string) => void, handleError: () => void) =>
            OtpActions.verifyOTP(otp, clientId, handelOtpVerification, handleError)
    },
)(EmailOtp);
