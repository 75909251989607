//<reference path="../../reports/filters.tsx" />
import * as React from 'react';
import { RouteComponentProps, match } from 'react-router';
import { InputGroup, Modal, Button, ListGroup, Row, Col } from 'react-bootstrap';
import 'isomorphic-fetch';
import { container } from '../../startup/inversify.config';
import { IDialogBox } from '../../core/utilities/ui/DialogBox';
import { TYPES } from '../../startup/types';
import { MyDownloadsConstants } from '../Common/Constants';
import Moment from 'moment/moment';
import { MarsNotifier } from '../Common/Notification/MarsNotifier';
import { logger } from '../../routes';
import { LogEventConstants } from '../Helper/Constants';

const dialogBox = container.get<IDialogBox>(TYPES.IDialogBox);

export interface IDownloadedZipFilesModel {
    id: string;
    clientGuid: string;
    documentId: number;
    fileName: string;
    createdOn?: Date;
    fileSize: number;
    status: MyDownloadStatus;
}


export enum MyDownloadStatus {
    None = 0,
    InProgress = 1,
    Ready = 2,
    Error = 3
}

export interface MyDownloadsProps {
    match: match;
    downloadsList: IDownloadedZipFilesModel[];
    showState: boolean;
    onHide(): void
    deleteMyDownloads: (downloadId: string, clientId: string) => void;
    clearAllMyDownloads: (clientId: string) => void;
    downloadK1Zip: (clientId: string, downloadId: string) => void;
}

interface MyDownloadsState {
    row: IDownloadedZipFilesModel[];
    collapsibleRowId: number
}

const pageTitle = "My Download Page";

export class MyDownload extends React.Component<MyDownloadsProps, MyDownloadsState> {
    constructor(props: MyDownloadsProps) {
        super(props);
        this.state = {
            collapsibleRowId: 0,
            row: []
        }

        const param: any = this.props.match.params;
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.Open}  ${pageTitle}`,
                { count: 1, page: pageTitle, clientId: param.clienId}
            )
        );

    }


    componentWillReceiveProps(nextProps: MyDownloadsProps) {
        if (nextProps.downloadsList) {
            this.setState({ row: nextProps.downloadsList });
        }
    }

    rowToggleOpen = (event: any) => {
        if (event.target.nextElementSibling.style['display'] == "none") {
            event.target.style['display'] = "none";
            event.target.nextElementSibling.style['display'] = "block";
            event.target.parentElement.parentElement.nextElementSibling.style['display'] = 'block'
        } else {
            event.target.style['display'] = "block";
            event.target.nextElementSibling.style['display'] = "none";
            event.target.parentElement.parentElement.nextElementSibling.style['display'] = 'none'
        }
    }

    rowToggleClose = (event: any) => {
        if (event.target.previousElementSibling.style['display'] == "none") {
            event.target.style['display'] = "none";
            event.target.previousElementSibling.style['display'] = "block";
            event.target.parentElement.parentElement.nextElementSibling.style['display'] = 'none'
        } else {
            event.target.style['display'] = "block";
            event.target.previousElementSibling.style['display'] = "none";
            event.target.parentElement.parentElement.nextElementSibling.style['display'] = 'block'
        }
    }

    formatBytes = (bytes: number, decimals: number = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    getDownloadList = () => {

        const param: any = this.props.match.params;
        const clientId = param.clientId;

        let rowCollection: any = [];
        for (var i = 0; i < this.state.row.length; i++) {
            let tmpForm: any = this.state.row[i];
            //  this.setState({ collapsibleRowId: i });
            rowCollection.push(<li className="delivered-return-download-link" style={{ display: 'block' }}>
                <Row lg={12} md={12} sm={12} xs={12}>
                    <Col lg={2} md={2} sm={2} xs={2}>
                        {
                            tmpForm.fileName.indexOf('.zip') > -1 ?
                                <span className="fa fa-file-archive-o fa-4x" style={{ color: '#1a8fbf' }}></span> :
                                <span className="fa fa-file-pdf-o fa-4x" style={{ color: '#1a8fbf' }}></span>
                        }
                    </Col>
                    <Col lg={5} md={5} sm={5} xs={5}
                        style={{ marginTop: '10px' }}>
                        <span className="list-sub-title" data-test-auto="e6ac69a1-a518-43e6-8d3c-5cdea820efab" style={{ fontSize: '100%' }}> {tmpForm.fileName}</span> <br />
                    </Col>
                    <Col lg={2} md={2} sm={2} xs={2}>
                        <span onClick={this.rowToggleOpen} data-test-auto="b33ce3e5-51e5-49e2-83c1-88e18de7eaf9" className="fa fa-chevron-down" style={{ color: 'grey', marginTop: '10px', float: 'right' }}></span>
                        <span onClick={this.rowToggleClose} data-test-auto="c90895b7-c279-478b-9ff5-0c9e86290fd8" className="fa fa-chevron-up" style={{ display: 'none', color: 'grey', marginTop: '10px', float: 'right' }}></span>
                    </Col>
                    <Col lg={2} md={2} sm={2} xs={1}>
                        {
                            tmpForm.status == MyDownloadStatus.Ready ?
                                <span className="fa fa-arrow-circle-down fa-2x"
                                    title="Download"
                                    data-test-auto="6c5355e6-8554-4106-82f0-059caef7c50e"
                                    onClick={() => this.onDownloadClick(clientId, tmpForm.id)}
                                    style={{ color: 'blue', marginTop: '10px', cursor: 'pointer' }}>
                                </span>
                                : <span className="fa fa-spinner fa-pulse fa-2x fa-fw"
                                    title="Loading"
                                    style={{ color: 'grey', marginLeft: '-7px', marginTop: '10px', cursor: 'pointer' }}>
                                </span>
                        }
                    </Col>
                    <Col className="mydownload-popup-delete" lg={1} md={1} sm={1} xs={1}>
                        <a className="myDownload-delete fa fa-times fa-2x "
                            title="Remove from list"
                            aria-hidden="true"
                            data-test-auto="C598C7E7-E3BA-4F3D-AFAD-69C29C5C69AF"
                            onClick={() => this.onDeletion(tmpForm.status, tmpForm.id, clientId)}>
                        </a>
                    </Col>
                </Row>
                <Row id={this.state.collapsibleRowId.toString()} style={{ display: 'none' }}>
                    <Col>
                        <span data-test-auto="02029a10-a305-4855-aa98-3dac79620b5f" style={{ color: 'grey', marginLeft: '40%' }}>
                            Date : {Moment(tmpForm.createdOn).format("DD MMM YYYY")} | File Size : {this.formatBytes(tmpForm.fileSize)}
                        </span>
                    </Col>
                </Row>
                <br />
            </li>)
        }
        return rowCollection.length > 0 ? rowCollection : <div data-test-auto="3DD4CC8C-8F99-4792-86C6-215C6780F83A" style={{ textAlign: 'center' }}> No data </div>

    }

    private onDownloadClick(clientId: string, downloadId: string) {
        this.props.downloadK1Zip(clientId, downloadId);
        logger.buildEvent(
            `${LogEventConstants.Common.Layout.ClickedDownload}  ${pageTitle}`,
            { count: 1, page: pageTitle, clienId: clientId, downloadId: downloadId }
        );
    }

    private onDeletion = (status: MyDownloadStatus, downloadId: string, clientId: string) => {
        const _self = this;

        status === MyDownloadStatus.InProgress ?
            MarsNotifier.Warning(MyDownloadsConstants.DeleteSingleFileWarning, null) :
            _self.props.deleteMyDownloads(downloadId, clientId);
        logger.buildEvent(
            `${LogEventConstants.Common.Layout.DeleteDownload}  ${pageTitle}`,
            { count: 1, page: pageTitle, clienId: clientId, downloadId: downloadId, status: status }
        );
    }



    public render() {
        const param: any = this.props.match.params;
        const clientId = param.clientId;

        return <Modal show={this.props.showState} onHide={this.onPopupClose} className="my-settings" bsSize="medium" >
            <Modal.Header closeButton data-test-auto="2DB5023A-6014-4E3E-88BD-1B507912648F">
                <Modal.Title>
                    <span data-test-auto="374ec529-5f4e-4f0d-bcde-a1f8fe09696b">
                        My Downloads
                    </span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                <div>
                    <ListGroup>
                        {
                            this.getDownloadList()

                        }
                    </ListGroup>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <button type="button"
                    className="btn btn-primary btn-radius"
                    data-test-auto="F23398C4-697B-44DD-8194-5F18C6B94EF3"
                    onClick={this.onPopupClose}>
                    Close
                </button>

                <button type="button"
                    id="btnClearAll"
                    data-test-auto="2E167F40-B146-4A8E-986C-61BC77C2929D"
                    disabled={this.state.row && this.state.row.find(m => m.status !== MyDownloadStatus.InProgress) ? false : true}
                    className="btn btn-primary btn-radius"
                    onClick={() => this.props.clearAllMyDownloads(clientId)}>
                    Clear All
                </button>
            </Modal.Footer>
        </Modal>
    }

    onPopupClose = () => {
        this.props.onHide();
    }
}
export default MyDownload;