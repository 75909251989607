export interface ISignerModel {
    documentId: number;
    signer: string;
    signedOn: Date;
    signerType: ClientTypesNumber;
    order: number;
    name: string;
    isDeceased: boolean;
    clientGuid: string;
    signerStatus: SignerStatus;
}

export enum SignerStatus
{
    NotSigned = 0,
    Signed = 1,
    Reviewed = 2,
}

export enum ClientTypesNumber {
    Undefined = 0,
    Taxpayer,
    Spouse,
    Partner,
    Partnership,
    ShareHolder,
}

export const initialSignerData: ISignerModel = {
    documentId: -101,
    signer: "Email Address",
    signedOn: new Date(2020, 8, 15),
    signerType: ClientTypesNumber.Undefined,
    order: 1,
    name: "Taxpayer Name",
    isDeceased: false,
    clientGuid: "Client Guid",
    signerStatus: SignerStatus.NotSigned,
};
