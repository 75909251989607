import * as React from 'react';
import { ClientType, EngagementTypeS, SignatureType } from '../../core/common/Enums';
import { IDialogBox } from '../../core/utilities/ui/DialogBox';
import { container } from '../../startup/inversify.config';
import { TYPES } from '../../startup/types';
import { SiginingConstants, Common, DelegateeSignerConstants } from '../Common/Constants';
import { CustomLargeButton } from '../Common/CustomButton/CustomLargeButton';
import { StepLayout } from '../Common/StepLayout/StepLayout';
import { Body } from '../Common/StepLayout/Body';
import { logger } from '../../routes';
import { AssignToDelegatee } from '../Delegatee/AssignToDelegatee';
import { IDelegateeSignerDetails } from '../../core/domain/models/DelegateeModels';
import { match } from 'react-router';
import { History } from 'history';
import { ITaxReturn, isMutual } from '../../core/domain/models/ITaxReturn';
import { LogEventConstants } from '../Helper/Constants';
import SpouseMailPopup from '../Delegatee/SpouseDetailUpdatePopup';
import TaxpayerHelper from '../Helper/TaxpayerHelper';
import { ISignerModel } from 'src/core/domain/models/ISignerModel';
import IconSendForSignature from "src/assets/images/icon-send-for-signature.svg";


interface SelectSignMethodProps {
    onSetSignMethod: (signatureType: SignatureType) => void;
    clearParentState: () => void;
    match?: match;
    history?: History;
    taxReturn?: ITaxReturn;
    saveDelegateeDetails?: (clientId: string,
        delegatee: IDelegateeSignerDetails, callback: () => void) => void;
    isDelegateeAssigned?: boolean;
    goToStep?: (stepNumber: number) => void;
    saveDelegateeState?: () => void;
    isPreviewMode: boolean;
    voucherExists?: boolean;
    relatedTaxReturnDelivered?: boolean;
    goToCompletionWizard: (isDocSettingsUpdated: boolean) => void;
    signerData: ISignerModel[]
}

interface SelectSignMethodState {
    showModal: boolean;
    showSpouseModal: boolean;
    clientType: ClientType
}

const dialogBox = container.get<IDialogBox>(TYPES.IDialogBox);
const pageTitle = "Select Sign Method Step";

export class SelectSignMethod extends React.Component<SelectSignMethodProps, SelectSignMethodState>
{
    private _params: any;

    constructor(props: any) {
        super(props);

        this.state = {
            showModal: false,
            showSpouseModal: false,
            clientType: ClientType.Undefined
        }

        this.props.clearParentState();
    }
    componentDidMount() {
        this._params = this.props.match && this.props.match.params;
        console.log('new params');
        console.log(this._params);
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.Open} ${pageTitle}`,
                { count: 1, page: pageTitle, clientId: this._params?.clientId }
            )
        );
    }

    goToDelegateeModal = () => {
        this.setState({ showSpouseModal: false, showModal: true })
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.Open} Assign To delegatee Modal`,
                { count: 1, page: "Assign To delegatee Modal", clientId: this._params?.clientId }
            )
        );
    }


    private openDelegationPopUp = () => {
        this._params = this.props.match && this.props.match.params;
        if (isMutual(this.props.taxReturn) && TaxpayerHelper.isFirstSignerLoggedIn(this.props.taxReturn, this.props.signerData, this._params?.clientId)) {
            const secondSignerType = TaxpayerHelper.getSecondSignerType(this.props.taxReturn, this.props.signerData, this._params?.clientId)
            if (secondSignerType === ClientType.Taxpayer) {
                if (this.props.taxReturn?.engagementType === EngagementTypeS.E1040 &&
                    this.props.taxReturn?.taxpayer?.isDeceased === false && this.props.taxReturn?.clientType === ClientType.Spouse) {
                    this.setState({ ...this.state, showSpouseModal: true, clientType: secondSignerType })
                    return
                }
            }
            else if (secondSignerType === ClientType.Spouse) {
                if (this.props.taxReturn?.engagementType === EngagementTypeS.E1040 &&
                    this.props.taxReturn?.spouse?.isDeceased === false && this.props.taxReturn?.clientType === ClientType.Taxpayer) {
                    this.setState({ ...this.state, showSpouseModal: true, clientType: secondSignerType })
                    return
                }
            }
        }
        this.setState({ ...this.state, showModal: true })
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.Open} Assign To delegatee Modal`,
                { count: 1, page: "Assign To delegatee Modal", clientId: this._params?.clientId }
            )
        );
    }

    private closeDelegationPopUp = () => {
        this.setState({ ...this.state, showModal: false });
    }

    private selectSignMethodConfirm = (signatureType: SignatureType) => {
        dialogBox.confirm(
            Common.DialogBox.ConfirmationTitle,
            SiginingConstants.StatusMessage.ManualSignSelectConfirmationMessage,
            Common.DialogBox.No,
            Common.DialogBox.Yes,
            (result: boolean) => { result && this.setSignMethod(signatureType) });
    }

    private setSignMethod = (signatureType: SignatureType) => {
        const { onSetSignMethod } = this.props;
        onSetSignMethod(signatureType);
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.Esign.SelectSignMethod}`,
                {
                    count: 1, page: pageTitle,
                    CustomEventType: LogEventConstants.Common.DocumentEventName,
                    DocumentGuid: this.props.taxReturn?.documentGuid,
                    signMethod: signatureType
                }
            )
        );
    }




    render() {


        return (<StepLayout>
            <Body title={SiginingConstants.SelectSignTitle}
                subTitle={SiginingConstants.SelectSignMessage}
                lg={10} md={12} sm={12} xs={12}
                automationTestId={"11BB28B5-42F3-4571-B567-20700B1740B6"}>

                <div className={"row col-lg-12"} style={{ height: "100%" }}>

                    <div style={{ margin: "14% auto auto auto", textAlign: "center" }}>
                        <CustomLargeButton text={SiginingConstants.ESignImageCaption}
                            className={"margin-bottom-10"}
                            icon={Common.Images.ElectronicalSignIcon}
                            onClick={() => { this.setSignMethod(SignatureType.ESignWhenAllIncludedReturnsAllowed) }}
                            automationTestId={"FAA7FF6F-F7EA-4456-A439-7D6B041F7928"} />
                        <span className="large-button-footer-text" data-test-auto="AB352E1B-AA25-414E-82C4-02700354F333">{SiginingConstants.EsignCaption}</span>
                    </div>

                    <div style={{ margin: "14% auto auto auto", textAlign: "center" }}>
                        <CustomLargeButton text={SiginingConstants.ManuallySignImageCaption}
                            className={"margin-bottom-10"}
                            icon={Common.Images.ManuallySignIcon}
                            onClick={() => { this.selectSignMethodConfirm(SignatureType.ManualSign) }}
                            automationTestId={"DCBE68E2-CFDA-4130-AA64-9E1551E5715F"} />
                        <span className="large-button-footer-text" data-test-auto="896B28DD-80A1-4678-B863-9A5AAA52A5B7">{SiginingConstants.ManuallySignCaption}</span>
                    </div>
                    {
                        (this.props.taxReturn?.documentSettings.documentSignatureSetting.enableSignerDelegation ?? false) &&
                        <div style={{ margin: "14% auto auto auto", textAlign: "center" }}>
                            <div className={"custom-large-button margin-bottom-10"} data-test-auto="HLKB28DD-80A1-4678-B863-9A5BBB52A897" onClick={this.openDelegationPopUp}>
                                <div className={"custom-large-button-icon-container"} style={{ marginTop: 10 }}>
                                    <img src={IconSendForSignature} style={{ height: "68px" }} />
                                </div>

                                <div className={"custom-large-button-text-container"} style={{ marginTop: 14 }}>
                                    {SiginingConstants.DelegateToSomeOne}
                                </div>

                            </div>
                            <span className="large-button-footer-text">
                                <pre className="large-button-footer-text" style={{ fontSize: '14px' }}>
                                    {SiginingConstants.DelegationDescription}
                                </pre>
                            </span>
                        </div>
                    }
                </div>


                <AssignToDelegatee
                    show={this.state.showModal}
                    onCancel={this.closeDelegationPopUp}
                    param={this._params}
                    history={this.props.history}
                    taxReturn={this.props.taxReturn}
                    saveDelegateeSignerDetails={this.props.saveDelegateeDetails}
                    isDelegateeAssigned={this.props.isDelegateeAssigned}
                    goToStep={this.props.goToStep}
                    saveDelegateeState={this.props.saveDelegateeState}
                    successMsg={DelegateeSignerConstants.SuccessMessage.SavedDelegateeDetailsSuccess}
                    isPreviewMode={this.props.isPreviewMode}
                    voucherExists={this.props.voucherExists}
                    relatedTaxReturnDelivered={this.props.relatedTaxReturnDelivered}
                    goToCompletionWizard={this.props.goToCompletionWizard}
                />



                {
                    this.props.taxReturn?.engagementType === EngagementTypeS.E1040 &&
                    this.state.clientType !== ClientType.Undefined &&
                    <SpouseMailPopup
                        show={this.state.showSpouseModal}
                        onHide={() => { this.setState({ ...this.state, showSpouseModal: false }) }}
                        goToDelegateeModal={this.goToDelegateeModal}
                        param={this._params}
                        secondSignerType={this.state.clientType}
                        isPreviewMode={this.props.isPreviewMode} />
                }

            </Body>
        </StepLayout>);

    }
}
export default SelectSignMethod;