import * as React from 'react';
import { WelcomeSummaryConstants } from '../../Common/Constants';

export interface WelcomeMessageProps {
    message: string;
}

export const WelcomeMessage: React.FunctionComponent<WelcomeMessageProps> = ({
    message
}) => {
    return (<div className="welcome"> {message}</div>);
}