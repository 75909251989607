import { History } from 'history';
import * as React from 'react';
import { match, RouteComponentProps } from 'react-router';
import { IDownloadableDocumentsViewModel } from '../../../../src/core/domain/viewModels/IDownloadableDocumentsViewModel';
import * as TaxDocument from '../../../core/domain/models/ITaxReturn';
import { ISignedDocument } from '../../../core/domain/models/manualsign/SignedDocument';
import { ICommonDataViewModel } from '../../../core/domain/viewModels/ICommonDataViewModel';
import { IHeaderInfoViewModel, initialHeaderInfoViewModel } from '../../../core/domain/viewModels/IHeaderInfoViewModel';
import { ISummaryViewModel } from '../../../core/domain/viewModels/ISummaryViewModel';
import * as CompanyStore from '../../../store/Common/Company/CompanyStore';
import * as EsignStore from '../../../store/Common/EsignStore';
import * as HelperStore from '../../../store/Common/HelperStore';
import * as TaxDocumentStore from '../../../store/Common/TaxDocumentStore';
import * as DownloadableDocumentStore from '../../../store/DownloadableDocumentsStore';
import * as ManualSignStore from '../../../store/Sign/ManualSignStore';
import * as SignProcessStore from '../../../store/SignProcess/SignProcessStore';
import * as SummaryStore from '../../../store/SummaryStore';
import { CompletedSummaryPage } from '../../CompletedSummary/CompletedSummaryPage';
import * as AccountStore from '../../../store/Common/AccountStore';
import {
    actionCreators as SigningRequiredStoreActionCreators,
    SigningRequiredStoreState,
} from "../../../store/SignProcess//SigningRequiredStore";
import { ISignerModel, SignerStatus } from '../../../core/domain/models/ISignerModel';
import { getDecodedClientId, } from '../../Helper/HelperFunction';
import * as DelegateeSignerStore from '../../../store/Delegatee/DelegateeSignerStore';
import { IDelegateeInfo } from '../../../core/domain/models/DelegateeModels';
import { Alert } from 'react-bootstrap';
import { SvgAlertIcon } from '../../Common/Icons/SvgIcons';

export type CompleteWizardComponentProps = {
    summaryData: ISummaryViewModel,
    commonData: ICommonDataViewModel,
    history: History;
    match: match;
    taxReturn: TaxDocument.ITaxReturn,
    headInfoViewModel: IHeaderInfoViewModel,
    signedDocument: ISignedDocument[];
    downloadableDocument: IDownloadableDocumentsViewModel;
    signingRequiredState: SigningRequiredStoreState;
    delegateeSigner: IDelegateeInfo;
    showPaymentAlert: boolean;

}
    & typeof SummaryStore.actionCreators
    & typeof HelperStore.actionCreators
    & typeof EsignStore.actionCreators
    & typeof CompanyStore.actionCreators
    & typeof TaxDocumentStore.actionCreators
    & typeof DownloadableDocumentStore.actionCreators
    & typeof ManualSignStore.actionCreators
    & typeof AccountStore.actionCreators
    & typeof SignProcessStore.actionCreators
    & typeof SigningRequiredStoreActionCreators
    & typeof DelegateeSignerStore.actionCreators
    & RouteComponentProps<{}>;

interface CompleteWizardComponentState {

}

export class CompletedWizardFlow extends React.Component<CompleteWizardComponentProps, CompleteWizardComponentState> {
    constructor(props: CompleteWizardComponentProps) {
        super(props);

    }

    componentDidMount() {
        let param: any = this.props.match.params;
        this.props.requestHeaderInfo(param.clientId);
        if (this.props.commonData && this.props.commonData.taxingAuthorities.length === 0) {
            this.props.requestAllTaxingAuthorities(param.clientId);
        }

        this.props.requestSigners(param.clientId,
            (signers: ISignerModel[]) => {

                var param: any = this.props.match.params;
                const clientGuid = getDecodedClientId(param.clientId);

                const nextSinger = signers.filter((signer) => signer.clientGuid !== clientGuid);

                if (nextSinger.length === 1 &&
                    nextSinger[0].signerStatus === SignerStatus.NotSigned) {

                    this.props.checkNextSignerExists(param.clientId, nextSinger[0].signerType);

                }

            });

        this.props.checkSignatureRequiredForDocument(param.clientId);
        this.props.checkSignatureRequiredForSigner(param.clientId);
        this.props.checkFilingFormsExists(param.clientId);
        this.props.requestDownloadableDocuments(param.clientId);
        this.props.requestTaxDocument(param.clientId, (taxReturn: TaxDocument.ITaxReturn) => {
            this.props.relatedTaxReturnDelivered(param.clientId);
        });
        this.props.requestSignedDocuments(param.clientId, true);
        this.props.requestNextSigner(param.clientId);
    }

    public render() {

        const {
            signingRequiredForDocument,
            filingFormsExists,
            nextSignerExists,
            signingRequiredForSigner,
        } = this.props.signingRequiredState;

        const {
            taxReturn: {
                relatedTaxReturnDelivered,
                taxYear
            },
            showPaymentAlert
        } = this.props;

        const paymentAlert = relatedTaxReturnDelivered ?
            <Alert
                dismissible={true}
                show={showPaymentAlert}
                className="payment-alert"
                style={{ margin: '20px 0px 5px 15px' }}
                onClose={() => {
                    this.props.togglePaymentAlert(false);
                }}>
                <span className="payment-alert-icon">
                    <SvgAlertIcon />
                </span>
                <span className="payment-alert-text">
                    A link to your completed {taxYear} tax return has been sent to you via email.
                    Payments can be made by accessing the link contained in the email you received.</span>
            </Alert> :
            null;

        return (
            <>
                {paymentAlert}
                <CompletedSummaryPage
                    commonData={this.props.commonData}
                    history={this.props.history}
                    match={this.props.match}
                    requestSummaryDetails={this.props.requestSummaryDetails}
                    summaryData={this.props.summaryData}
                    taxReturn={this.props.taxReturn}
                    requestSignerDetails={this.props.requestNextSigner}
                    sendSigningReminder={this.props.sendSigningReminder}
                    downloadSignedEFileDocument={this.props.downloadSignedEFileDocument}
                    signedDocument={this.props.signedDocument}
                    downloadAllAdditionEsignDocuments={this.props.downloadAllAdditionEsignDocuments}
                    downloadableDocumentsViewModel={this.props.downloadableDocument}
                    requestDownloadableDocuments={this.props.requestDownloadableDocuments}
                    requestSignedDocuments={this.props.requestSignedDocuments}
                    refreshTaxDocument={this.props.requestTaxDocument}
                    downloadEFileDocument={this.props.downloadEFileDocument}
                    requestCurrentStepAndUserRole={this.props.requestCurrentStepAndUserRole}
                    headerInfo={this.props.headInfoViewModel}
                    signingRequiredForDocument={signingRequiredForDocument}
                    filingFormsExists={filingFormsExists}
                    nextSignerExists={nextSignerExists}
                    updateDelegateeDetails={this.props.updateDelegatee}
                    cancelDelegation={this.props.cancelDelegation}
                    resetLastVisitedSteps={this.props.resetLastVisitedSteps}
                    delegateeSigner={this.props.delegateeSigner}
                    requestTaxClientDelegateeDetails={this.props.requestTaxClientDelegateeDetails}
                    signingRequiredForSigner={signingRequiredForSigner}
                />
            </>
        );
    }

}
