import * as React from 'react';
import Select from 'react-select';

export interface ISelectComponentProps {
    selectedValue?: any;
    options: any;
    onChange?: (selectedValue: any) => void;
    clearable?: boolean;
    disabled?: boolean;
    customPlaceHolder?: any;
    id: string;
    onFocus?: (event: any) => void;
    trackKeyEvent?: (event: any) => void;
    isCcCountrySelector?: boolean;
}

export class SelectComponent extends React.Component<ISelectComponentProps, {}> {
    constructor(props: any) {
        super(props);
    }

    private onChange = (event: any) => {
        const selectedValue = event;
        if (this.props.onChange) {
            this.props.onChange(selectedValue.value);
        }
    }

    public render() {
        const { clearable } = this.props;
        let selectedValue = this.props.selectedValue && this.props.options.find((option: any) => option.value === this.props.selectedValue.toString());

        return <Select
            id={this.props.id}
            value={selectedValue}
            onChange={this.onChange}
            options={this.props.options}
            placeholder={this.props.customPlaceHolder && this.props.customPlaceHolder.length > 0 ? this.props.customPlaceHolder : "Select..."}
            clearable={clearable == undefined ? true : clearable}
            disabled={this.props.disabled ? this.props.disabled : false}
            data-toggle="dropdown" data-boundary="window"
            onFocus={this.props.onFocus}
            onKeyDown={(e: any) => { this.props.isCcCountrySelector && this.props.trackKeyEvent && this.props.trackKeyEvent(e) }}
        />;
    }
} 
