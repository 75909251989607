import * as React from 'react';
import { match } from 'react-router';
import { IK1ShareHolderDetails, initialK1ShareHolderDetails } from '../../core/domain/models/IK1Distribute';
import { DistributeElectronicTable } from '../K1Distribution/DistributeElectronicTable';
import { ITaxReturn } from '../../core/domain/models/ITaxReturn';
import { GetK1EngagementType } from '../../core/domain/models/IK1Distribute';
import { DownloadAllK1 } from '../K1Distribution/DownloadAllK1';
import { EditK1PartnerDetails } from '../K1Distribution/EditK1PartnerDetails';
import { EditK1PartnerEmail } from '../K1Distribution/EditK1PartnerEmail';
import { EngagementTypeS } from '../../core/common/Enums';
import { Button } from 'react-bootstrap';
import { MarsNotifier } from '../Common/Notification/MarsNotifier';
import { K1ShareHolderConstants, Permissions } from '../Common/Constants';
import { IClientProcessViewModel } from '../../core/domain/viewModels/IClientProcessViewModel';
import { ILoggedInUserInfo } from '../../core/domain/viewModels/ILoggedInUserInfo';
import { DocumentStatus } from '../../core/common/Enums';
import { Role } from '../../core/common/Enums';



interface DistributeElectronicProps {
    requestK1ShareHolderDetails: (
        clientGuid: string,
        pageNo: number,
        pageSize: number,
        sortBy: string,
        sortOrder: string,
        searchText: string
    ) => void;
    k1Data: IK1ShareHolderDetails[];
    match: match;
    taxReturn: ITaxReturn;
    updateK1ShareHolderEmail?: (clientGuid: string, k1Partner: IK1ShareHolderDetails) => void;
    downloadK1DocumentAsync: (clientId: string, engagementTpe: EngagementTypeS) => void;
    updateK1ShareHolderDetail?: (
        clientGuid: string,
        k1Partner: IK1ShareHolderDetails,
        isEmailChange: boolean,
        callback: () => void
    ) => void;

    downloadIndividualK1DocumentAsync: (
        clientId: string,
        shareHolder: IK1ShareHolderDetails,
        engagementTpe: EngagementTypeS
    ) => void;
    sendMailToShareHolder?: (
        clientId: string,
        partners: IK1ShareHolderDetails[],
        callback: () => void
    ) => void;
    requestCurrentStepAndUserRole?: (clientId: string, successCallback?: (clientProcessState: IClientProcessViewModel) => void) => void;
    loggedInUserInfo: ILoggedInUserInfo,
}

interface DistributeElectronicState {
    selectedRows: number[];
    sortName: string;
    sortOrder: string;
    searchText: string;
    showDetailPopup: boolean;
    showEmailPopup: boolean;
    k1Partner: IK1ShareHolderDetails;
    page: number;
    readonly: boolean;
}

const pageSize: number = 10;

export class DistributeElectronic extends React.Component<DistributeElectronicProps, DistributeElectronicState>{
    constructor(props: any) {
        super(props);
        this.state = {
            selectedRows: [],
            sortName: 'Name',
            sortOrder: 'asc',
            searchText: ' ',
            showDetailPopup: false,
            showEmailPopup: false,
            k1Partner: initialK1ShareHolderDetails,
            page: 1,
            readonly: false
        }
    }

    componentDidMount() {
        let param: any = this.props.match.params;
        this.props.requestK1ShareHolderDetails(
            param.clientId,
            this.state.page,
            pageSize,
            this.state.sortName,
            this.state.sortOrder,
            this.state.searchText);

    }

    loadK1ShareHolderDetails = () => {
        let param: any = this.props.match.params;
        this.props.requestK1ShareHolderDetails(
            param.clientId,
            this.state.page,
            pageSize,
            this.state.sortName,
            this.state.sortOrder,
            this.state.searchText);
    }

    static getDerivedStateFromProps(nextProps: DistributeElectronicProps, nextState: DistributeElectronicState) {
        if (nextProps.loggedInUserInfo.role.toString() === Role[Role.CPA].toString() && nextProps.loggedInUserInfo.permission === Permissions.Read) {
            return { readonly: true }
        }
        else {
            return { readonly: false }
        }
    }

    private onPageChange = (page: number, sizePerPage: number) => {
        this.setState({
            page: page,
            selectedRows: []
        }, () => this.loadK1ShareHolderDetails());
    }

    private onSortChange: any = (sortName: any, sortOrder: any, column: any) => {
        this.setState({
            sortName: sortName,
            sortOrder: sortOrder == "asc" ? "asc" : "desc",
        }, () => this.loadK1ShareHolderDetails());
    }

    private onSearchChange = (searchString: string) => {
        let temp = searchString !== '' ? searchString : ' ';
        this.setState({
            searchText: temp,
            page: 1
        }, () => this.loadK1ShareHolderDetails());
    }

    private onRowSelect = (row: any, isSelected: any, e: any) => {
        let newList = [...this.state.selectedRows];
        if (e.target.tagName !== 'BUTTON' && e.target.tagName !== 'I'
            && e.target.tagName !== 'SPAN' && e.target.tagName !== 'A') {
            if (isSelected) {
                newList.push(row.rowIndex);
            } else {
                var index = newList.indexOf(row.rowIndex);
                if (index > -1) {
                    newList.splice(index, 1);
                }
            }
            this.setState({ selectedRows: newList });
        }
        //this.forceUpdate();
    }

    private onSelectAll = (isSelected: any, rows: any, e: any) => {
        let selectedRows: number[] = [];
        if (isSelected) {
            let count = rows && rows.length ? rows.length : 0;
            Array.from(Array(count).keys());
            selectedRows = Array.from(Array(count).keys());
        }
        this.setState({ selectedRows: selectedRows });
    }

    onCloseDetailModal = () => {
        this.setState({
            showDetailPopup: false
        });
    }

    onCloseEmailModal = () => {
        this.setState({
            showEmailPopup: false
        });
    }

    private editPartnerDetails = (rowIndex: number) => {
        let financialYear  = this.props.k1Data[rowIndex].financialYearEnd;
        let partner = this.props.k1Data[rowIndex];
        if (!financialYear) {
            this.setState({
                showEmailPopup: true,
                k1Partner: partner
            })
        }
        else {
            this.setState({
                showDetailPopup: true,
                k1Partner: partner
            })
        }
    }

    private onSendMail = () => {
        let emptyEmail: boolean = false;
        let param: any = this.props.match.params;
        let partners: IK1ShareHolderDetails[] = [];
        if (this.state.selectedRows.length == 0) {
            MarsNotifier.Warning(K1ShareHolderConstants.WarningMessage.CheckBoxSelect, null);
        }
        else {
            for (var index in this.state.selectedRows) {
                let rowIndex = this.state.selectedRows[index];
                let partner = this.props.k1Data[rowIndex];
                if (partner && partner.email) {
                    partners.push(partner);
                }
                else {
                    emptyEmail = true;
                    break;
                }
            }
            if (!emptyEmail) {
                this.props.sendMailToShareHolder && this.props.sendMailToShareHolder(param.clientId, partners, this.loadK1ShareHolderDetails);
                
            }
            else {
                MarsNotifier.Warning(K1ShareHolderConstants.WarningMessage.EmailAdrressEmpty, null);
            }
        }
    }

    private onResendMail = (shareHolderId: number) => {
        const shareHolder = this.props.k1Data.filter(x => x.shareHolderId === shareHolderId);
        if (shareHolder) {
            const param: any = this.props.match.params;
            this.props.sendMailToShareHolder && this.props.sendMailToShareHolder(param.clientId, shareHolder, this.loadK1ShareHolderDetails);
        }
    }

    validateEmail = (partner: IK1ShareHolderDetails) => {
        if (!partner.email) {
            MarsNotifier.Warning(K1ShareHolderConstants.WarningMessage.EmailAdrressEmpty, null);
            return false;
        }
        return true;
    }


    public render() {
        let type: string = this.props.taxReturn && GetK1EngagementType(this.props.taxReturn.engagementType);
        let text: string = 'Select ' + type + ' to Email';
        return (
            <div className="col-xs-6 col-sm-6 col-md-12 col-lg-12 content-wrapper-2">
                <div className="displayFlex">
                    <h3 className="step-layout-body-header displayFlex3 no-padding" data-test-auto="12789752-d436-4c73-bb14-2415a364883f">Distribute K-1 Documents Electronically </h3>
                    <DownloadAllK1
                        downloadK1DocumentAsync={this.props.downloadK1DocumentAsync}
                        match={this.props.match}
                        taxReturn={this.props.taxReturn}
                    />
                </div>
                <div className="download-helper">
                    <span className="sub-title" data-test-auto="54514737-3cfa-48e0-a9fb-a7d2dde17064"> {text} </span>
                </div>
                <DistributeElectronicTable
                    onPageChange={this.onPageChange}
                    onSortChange={this.onSortChange}
                    onSearchChange={this.onSearchChange}
                    onRowSelect={this.onRowSelect}
                    onSelectAll={this.onSelectAll}
                    pageNo={this.state.page}
                    pageSize={pageSize}
                    k1Data={this.props.k1Data}
                    selectedRows={this.state.selectedRows}
                    editPartnerDetails={this.editPartnerDetails}
                    downloadIndividualK1DocumentAsync={this.props.downloadIndividualK1DocumentAsync}
                    taxReturn={this.props.taxReturn}
                    match={this.props.match}
                    onResendMail={this.onResendMail}
                />
                <EditK1PartnerDetails
                    showState={this.state.showDetailPopup}
                    onCancel={this.onCloseDetailModal}
                    k1Partner={this.state.k1Partner}
                    match={this.props.match}
                    updateK1ShareHolderDetail={(this.state.readonly === true) ? undefined : this.props.updateK1ShareHolderDetail}
                    loadK1ShareHolderDetails={this.loadK1ShareHolderDetails}
                    type={type}
                />
                <EditK1PartnerEmail
                    showState={this.state.showEmailPopup}
                    onCancel={this.onCloseEmailModal}
                    k1Partner={this.state.k1Partner}
                    updateK1ShareHolderEmail={(this.state.readonly === true) ? undefined : this.props.updateK1ShareHolderEmail}
                    match={this.props.match}
                    type={type}
                />

            </div>
        )
    }

}