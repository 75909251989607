import { connect } from 'react-redux';
import RouteSelector from '../../Common/RouteSelector/RouteSelector';
import { actionCreators } from "./../../../../store/Login/CCRecipient/OTP/OTPStore";

export default connect(
    (state: any) => ({
        mobileOtpPath: "/CCRecipient/MobileOTP/Index/",
        emailOtpPath: "/CCRecipient/OTP/Index/",
    }),
    {
        pageValidity: (clientId: string, selectPath: (path: string) => void, handleError: (message: string) => void) =>
            actionCreators.pageValidity(clientId, selectPath, handleError),
    },
)(RouteSelector);
