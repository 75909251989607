import * as React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { IK1ShareHolderDetails } from '../../core/domain/models/IK1Distribute';
import { EngagementTypeS } from '../../core/common/Enums';
import { ITaxReturn } from '../../core/domain/models/ITaxReturn';
import { GetK1EngagementType } from '../../core/domain/models/IK1Distribute';
import { match } from 'react-router';
import { GetFormatedDateTime } from '../Helper/HelperFunction';
import { phoneNumberDisplay, countryCodeDisplay } from '../Common/Validations';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';

interface DistributeElectronicTableProps {
    k1Data: IK1ShareHolderDetails[];
    onSortChange: any;
    onPageChange: any;
    pageNo: number;
    pageSize: number;
    onSearchChange: (searchString: string) => void;
    onRowSelect: (row: any, isSelected: any, e: any) => void;
    onSelectAll: (isSelected: any, rows: any, e: any) => void;
    selectedRows: number[];
    taxReturn: ITaxReturn;
    match: match;
    editPartnerDetails: (rowIndex: number) => void;
    downloadIndividualK1DocumentAsync: (
        clientId: string,
        shareHolder: IK1ShareHolderDetails,
        engagementTpe: EngagementTypeS
    ) => void;
    onResendMail: (shareHolderId: number) => void;
}

export class DistributeElectronicTable extends React.Component<DistributeElectronicTableProps, {}>{
    constructor(props: any) {
        super(props);
    }

    handleK1Download = (shareHolderId: number) => {
        let shareHolder = this.props.k1Data.filter(x => x.shareHolderId === shareHolderId);
        if (shareHolder) {
            let param: any = this.props.match.params;
            this.props.downloadIndividualK1DocumentAsync(param.clientId, shareHolder[0], this.props.taxReturn.engagementType);
        }
    }

    private k1StatusAction = (cell: any, row: any) => {
        return (
            <div>
                <button
                    title={"Edit Details"}
                    className="btn-white"
                    type="button"
                    onClick={() => { this.props.editPartnerDetails(row.rowIndex) }}
                    data-test-auto="A853852E-ED23-4372-A5F9-D0A4CDB0812B">
                    <i className='fa fa-pencil-square-o'></i>
                </button>
                <button
                    title={"Download"}
                    className="btn-white"
                    type="button"
                    onClick={() => { this.handleK1Download(row.shareHolderId) }}
                    data-test-auto="C73C9574-8EED-47B1-A492-AEA62F71B6E4">
                    <i className='fa fa-download'></i>
                </button>
                {this.props.k1Data && this.props.k1Data[row.rowIndex].k1SendDate != null ?
                    <button
                        title={"Resend"}
                        className="btn-white"
                        type="button"
                        onClick={() => { this.props.onResendMail(row.shareHolderId) }}
                        data-test-auto="D67456A1-CC27-4D75-9CB5-EDF39F302B93" >
                        <i className='fa fa-repeat'></i>
                    </button>
                    : ""
                }
            </div>
        );
    }

    private defaultType = (cell: any, row: any) => {
        return <span title={cell} className="ellipsis" data-test-auto="BB075771-FC0D-479D-8F19-CF5AFD3F57CA">{cell}</span>;
    }

    private customStatusFormat = (cell: any, row: any) => {
        let color: string = '';
        if ((cell as string).startsWith('Downloaded on')) {
            color = 'green';
        }
        else if ((cell as string).startsWith('Sent on')) {
            color = 'blue';
        }
        else {
            color = 'red';
        }
        if ((cell as string).startsWith('Declined')) {
            let content = cell.split('~');
            return <div title={content[0] + '\n' + content[1]}>
                <span style={{ color: 'red' }} className="ellipsis" data-test-auto="FEF2FAAB-A29C-45C4-8CAA-81E0A0CD9A36"> {content[0]}</span>
                <br />
                <span style={{ color: 'blue', fontSize: '12px' }} className="ellipsis" data-test-auto="D88C9C9B-60CF-4FFB-9C27-5B8D880ACB8F">{content[1]}</span>
            </div>
        }
        return <span style={{ color: color }} title={cell} className="ellipsis" data-test-auto="5C9FD01D-E0A1-4E42-B104-8CBD5B8BEEDB">{cell}</span>;
    }

    private customHeaderName = () => {
        return this.props.taxReturn && GetK1EngagementType(this.props.taxReturn.engagementType);
    }

    private renderShowsTotal = (start: number, to: number, total: number) => {
        return (
            <p>
                Showing {start} to {to} of {total} entries
            </p>
        );
    }

    private columns = [
        {
            header: '',
            key: 'rowIndex',
            isKey: true,
            dataFormat: this.defaultType,
            columnClassName: '',
            dataSort: true,
            toolTip: false,
            isHidden: true,
            width: 'auto',

        },
        {
            header: this.customHeaderName(),
            key: 'name',
            isKey: false,
            dataFormat: this.defaultType,
            columnClassName: '',
            dataSort: true,
            toolTip: false,
            isHidden: false,
            width: 'auto',
        },
        {
            header: 'Email',
            key: 'email',
            isKey: false,
            dataFormat: this.defaultType,
            columnClassName: '',
            dataSort: true,
            toolTip: false,
            isHidden: false,
            width: 'auto',
        },
        {
            header: 'Mobile',
            key: 'mobileNumber',
            isKey: false,
            dataFormat: this.defaultType,
            columnClassName: '',
            dataSort: false,
            toolTip: false,
            isHidden: false,
            width: 'auto',
        },
        {
            header: 'Status',
            key: 'status',
            isKey: false,
            dataFormat: this.customStatusFormat,
            columnClassName: '',
            dataSort: false,
            toolTip: false,
            isHidden: false,
            width: 'auto',
        },
        {
            header: 'Action',
            key: 'button',
            isKey: false,
            dataFormat: this.k1StatusAction,
            columnClassName: '',
            dataSort: false,
            toolTip: false,
            isHidden: false,
            width: 'auto',
        },
    ];

    public render() {

        const options = {
            onSortChange: this.props.onSortChange,
            onSearchChange: this.props.onSearchChange,
            onPageChange: this.props.onPageChange,
            sizePerPage: this.props.pageSize,
            page: this.props.pageNo,
            paginationShowsTotal: this.renderShowsTotal,
            clearSearch: false,
            hideSizePerPage: true
        };

        const data = this.props.k1Data && this.props.k1Data.map((model, index) => {
            const k1Status = model.k1DownloadDate ? 'Downloaded on ' + GetFormatedDateTime(model.k1DownloadDate) :
                model.isK1Decline == true ? 'Declined Consent ~' + 'Sent on ' + GetFormatedDateTime(model.k1SendDate) :
                    model.k1SendDate ? 'Sent on ' + GetFormatedDateTime(model.k1SendDate) : 'Not Sent';
            return {
                name: model.name,
                email: model.email,
                status: k1Status,
                mobileNumber: model.mobileNumber && model.countryCode ? countryCodeDisplay(model.countryCode) + phoneNumberDisplay(model.mobileNumber) : model.mobileNumber,
                shareHolderId: model.shareHolderId,
                rowIndex: index,
                button: 'Actions',
            }
        });

        let selected: number[] = [];
        if (this.props.selectedRows.length > 0) {
            for (var i in this.props.selectedRows) {
                let rowIndex = this.props.selectedRows[i];
                selected.push(rowIndex);
            }
        }

        let selectRowProp: any =
        {
            mode: 'checkbox',
            onSelect: this.props.onRowSelect,
            onSelectAll: this.props.onSelectAll,
            selected: selected,
            className: 'row-selected',
            columnWidth: '5%'
        };
        let totalCount = this.props && this.props.k1Data.length > 0 ? this.props.k1Data[0].count : 0
        let watermarkName = this.props.taxReturn && 'Search ' + GetK1EngagementType(this.props.taxReturn.engagementType);
        return (
            <div className="distributeK1Tbl">
                <BootstrapTable
                    ref='table'
                    data={data}
                    remote={true}
                    striped
                    pagination={totalCount > this.props.pageSize}
                    hover={true}
                    search={true}
                    options={options}
                    selectRow={selectRowProp}
                    searchPlaceholder={watermarkName}
                    fetchInfo={{ dataTotalSize: this.props && this.props.k1Data.length > 0 ? this.props.k1Data[0].count : 0 }}
                >
                    {this.columns.map((value, index) => {
                        return <TableHeaderColumn
                            key={index}
                            ref={value.key}
                            isKey={value.isKey}
                            dataField={value.key}
                            hidden={value.isHidden}
                            width={value.width}
                            dataFormat={value.dataFormat}
                            columnClassName={value.columnClassName}
                            columnTitle={value.toolTip}
                            dataSort={value.dataSort}


                        >{value.header}
                        </TableHeaderColumn>;
                    })}
                </BootstrapTable>
            </div >
        )
    }
}