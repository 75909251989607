import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { ErrorStoreState } from '../../../../store/Login/Models/StoreModels';

interface ErrorPageProps extends RouteComponentProps<{ message: string }> {
    errorMessageStore: ErrorStoreState;
}

export const ErrorPage: React.FC<ErrorPageProps> = ({ errorMessageStore, match: { params: { message } } }) => {
    return <div>
        <div className="invalid-container">

            <div className="invalid-header">

            </div>

            <div className="content-wrapper">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 error-page">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 error-page-container">
                        <div className="message">
                            {errorMessageStore.message || message }
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>;
};
