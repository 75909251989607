import * as React from 'react';
import { Shimmer } from '../../../components/Common/Shimmer/Shimmer';
import { WelcomeSummaryConstants, CompletedSummaryConstant } from '../../../components/Common/Constants';
import { WelcomeMessage } from '../../../components/CompletedSummary/Parts/WelcomeMessage';
import { SignProcessSteps } from '../../Home/TaxpayerSignFlowManager';
import {
    SvgIconReviewDocumentsSummary,
    SvgIconSignDocumentsSummary,
    SvgIconTaxPaymentsSummary
} from '../../Common/Icons/SvgIcons';
import { logger } from '../../../routes';
import { SignatureType } from '../../../core/common/Enums';

export interface WelcomeProps {
    taxYear: number;
    clientName: string;
    hasShareholders: boolean;
    goToStep: (stepNumber: SignProcessSteps) => void;
    goToStepById: (id: any) => void;
    showSignStep: boolean;
    showPreparerMessage?: boolean;
    relatedTaxReturnDelivered: boolean;
    voucherExists: boolean;
    signingRequiredForSigner: boolean;
    signatureFormSelectionType: SignatureType;
}

export class Welcome extends React.Component<WelcomeProps, {}> {

    constructor(props: WelcomeProps) {
        super(props);
        logger.trackPageView(`Welcome page`);
    }

    public render() {

        const { showSignStep, relatedTaxReturnDelivered, voucherExists } = this.props;

        const paymentSectionDisabled = !voucherExists || relatedTaxReturnDelivered;

        let reviewButtonClass = ""
        let signButtonClass = "";
        let imgBtnDivClass = "";
        let reviewBtnDivClass = "";

        if (paymentSectionDisabled && (this.props.signatureFormSelectionType == SignatureType.ReviewOnly || !showSignStep)) {
            reviewBtnDivClass = "col-lg-12 col-md-12";
        } else if (!paymentSectionDisabled && showSignStep) {
            imgBtnDivClass = "col-lg-4 col-md-4";
            reviewBtnDivClass = "col-lg-4 col-md-4";
        } else {
            reviewButtonClass = "download-button-padding";
            signButtonClass = "sign-button-padding";
            imgBtnDivClass = "col-lg-6 col-md-6";
            reviewBtnDivClass = "col-lg-6 col-md-6";
        }

        return (<div id="welcomeMessage" data-test-auto="43EFC177-DFB7-4DCA-9CA0-36F39098289F">

            {this.props.clientName ?

                (<div className="welcome-container">
                    <WelcomeMessage
                        message={CompletedSummaryConstant.WelcomeMessage.replace("<TAXPAYERNAME>", this.props.clientName)} />
                    <div className="margin-top-20">
                        <WelcomeMessage
                            message={WelcomeSummaryConstants.WelcomeMessage.replace("<TAXYEAR>", this.props.taxYear.toString())} />
                    </div>
                    <div className="welcome-intro">{WelcomeSummaryConstants.InstructionText}</div>
                </div>) : <Shimmer height={200} />
            }

            {
                this.props.clientName ?

                    (<div><div className="row step-img-btn" data-test-auto="55D2107E-AFAF-4A21-BAB1-A66DEB6AA8CD">

                        <div className={`${reviewButtonClass} ${reviewBtnDivClass}`}
                            data-test-auto="PPJQW037X0PMJGHV10WPAQA5TW">
                            <SvgIconReviewDocumentsSummary
                                data-test-auto="62D257EA-93C3-41E6-9E47-464110924398"
                                className="icon---summary-review-documents"
                                onClick={() => this.props.goToStep(SignProcessSteps.Invoice)} />
                            <div className="img-caption" onClick={() => this.props.goToStep(SignProcessSteps.Invoice)}>
                                Review Documents
                            </div>
                        </div>

                        {
                            showSignStep && (
                                <div className={`${signButtonClass} ${imgBtnDivClass}`}
                                    data-test-auto="7F5V0W3F0MN4J1X7EHCN2P39MW">
                                    <SvgIconSignDocumentsSummary
                                        className="icon---summary-sign-documents"
                                        data-test-auto="EXZYX16V8VT451HPFZ6FLC7V6H"
                                        onClick={() => this.props.goToStep(SignProcessSteps.SignMethod)} />
                                    <div className="img-caption"
                                        onClick={() => this.props.goToStep(SignProcessSteps.SignMethod)}>
                                        Sign Documents
                                    </div>
                                </div>)
                        }
                        {
                            !paymentSectionDisabled &&

                            <div className={imgBtnDivClass}
                                data-test-auto="V5TJR4QH0P84V2YUJ1EPZNJ934">
                                <SvgIconTaxPaymentsSummary
                                    data-test-auto="9CD8CB75-5602-4393-831E-4627EC43FDF1"
                                    className="icon---summary-make-payments"
                                    onClick={() => this.props.goToStep(SignProcessSteps.Pay)} />
                                <div className="img-caption" onClick={() => this.props.goToStep(SignProcessSteps.Pay)} >
                                    Make Payments
                                </div>
                            </div>
                        }

                    </div>
                        <div className="summary-start-step-container">
                            {this.props.showPreparerMessage ?
                                <button className="btn btn-primary summary-begin-btn margin-top-20"
                                    data-test-auto="87742DFF-5468-44D2-B959-090842533E9E"
                                    onClick={() => this.props.goToStep(SignProcessSteps.PreparerMessage)}>
                                    Click Here to Begin
                                </button>
                                :
                                <button className="btn btn-primary summary-begin-btn margin-top-20"
                                    data-test-auto="87742DFF-5468-44D2-B959-090842533E9E"
                                    onClick={() => this.props.goToStep(SignProcessSteps.Review)}>
                                    Click Here to Begin
                                </button>
                            }

                        </div></div>) : <Shimmer height={500} />
            }

        </div>);
    }
}
