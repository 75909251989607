import * as React from 'react';
import { match, RouteComponentProps } from 'react-router';
import * as SignProcessStore from '../../store/SignProcess/SignProcessStore';
import { SignProcessSteps } from './TaxpayerSignFlowManager';
import { History } from 'history';
import { DelegateePathConstants } from '../Common/Constants';
import { IClientProcessViewModel } from '../../core/domain/viewModels/IClientProcessViewModel'

const NO_INDEX = -1;

export type DelegateeRouteMangerProps = {
    match: match;
    history: History;

}
    & typeof SignProcessStore.actionCreators

export class DelegateeRouteManager extends React.Component<DelegateeRouteMangerProps, {}> {
    private _params: any;
    constructor(props: DelegateeRouteMangerProps) {
        super(props);
    }
    componentDidMount() {
        this._params = this.props.match.params;
        this.getSignProcessStatus(this._params.clientId);
    }

    private getSignProcessStatus = (clientId: any) => {
        this.props.requestDelegateeCurrentStepAndUserRole(clientId, this.handleRedirect);
    }

    private handleRedirect = (clientProcessState: IClientProcessViewModel) => {
        let index = NO_INDEX;

        if (clientProcessState.currentstep.visitedSteps) {
            index = clientProcessState.currentstep.visitedSteps.findIndex((x: SignProcessSteps) => x == SignProcessSteps.SignerProcessComplete);
        }

        if (clientProcessState.isSigned === true
            || (clientProcessState.isSigned === false && index != NO_INDEX)) {
            this.props.history.push(`${DelegateePathConstants.CompletionWizard}${this._params.clientId}`);
        }
        else {
            this.props.history.push(`${DelegateePathConstants.SignFlow}${this._params.clientId}`);
        }
    }

    public render() {
        return (<div></div>);
    }
}

