import PdfViewer from 'awesome-pdf-viewer';
import {
	ControlData, LabelData, SignatureData, SignatureMode,
	TextBoxData, ControlBase
} from 'awesome-pdf-viewer/dist/Controls/ControlBase';
import { PdfSource, ViewerMode } from 'awesome-pdf-viewer/dist/viewer/ViewerBase';
import LabelControl from 'awesome-pdf-viewer/dist/Controls/LabelControl';
import SignatureControl, { SignatureControlProps } from 'awesome-pdf-viewer/dist/Controls/SignatureControl';
import TextBoxControl, { TextBoxControlProps } from 'awesome-pdf-viewer/dist/Controls/TextBoxControl';
import ControlLayer from 'awesome-pdf-viewer/dist/Layers/ControlLayer';
import { ControlBaseProps } from 'awesome-pdf-viewer/dist/Layers/ControlLayerBase';
import Header from 'awesome-pdf-viewer/dist/layout/Header';
import LeftPanel from 'awesome-pdf-viewer/dist/layout/LeftPanel';
import Main from 'awesome-pdf-viewer/dist/layout/Main';
import ViewPanel from 'awesome-pdf-viewer/dist/layout/ViewPanel';
import Toolbar from 'awesome-pdf-viewer/dist/toolbar/Toolbar';
import * as React from 'react';
import { IPage } from '../../../core/domain/models/esign/Document';
import * as Controls from '../../../core/domain/models/esign/IControls';
import Progress from '../../Common/Progress/Progress';
import { PageProperties } from 'awesome-pdf-viewer/dist/layout/LayoutBase';
import Pagination from 'awesome-pdf-viewer/dist/toolbar/Pagination';
import Zoom from 'awesome-pdf-viewer/dist/toolbar/Zoom';
import { PageSize } from 'awesome-pdf-viewer/dist/layout/LayoutBase';
import { IHeaderInfoViewModel } from 'src/core/domain/viewModels/IHeaderInfoViewModel';
import { Role } from '../../../core/common/Enums';
import { logger } from '../../../routes';
import { LogEventConstants } from '../../Helper/Constants';

export interface ViewProps {
	url: string;
	pages: IPage[];
	fileList: any;
	startNavigationOnDocumentLoad?: boolean;
	finishTarget?: string;
	progress: number;
	onNavigationFinish(): void;
	onAddControlData(controlData: ControlData, controlProps: ControlBaseProps): void;
	onViewModeChange?(viewerMode: ViewerMode): void;
	headerInfo: IHeaderInfoViewModel;
	onPageChanging(pageProperties: PageProperties): void;
	doNotAllowToEditSignatureName?: boolean;
	hideNavigationControl?: boolean;
	hideSignatureControls?: boolean;
	documentId?: number;
}


export interface ViewState {
	hideStartNavigationControl: boolean;
	signatureData: SignatureData | undefined;
	initialcontrlData: SignatureData | undefined;
	isDocumentLoaded: boolean;
	loggedControls: string[];
}

const pageTitle = "PDF Viewer";
export class Viewer extends React.Component<ViewProps, ViewState> {

	private _viewPanel: any;
	private _controlLayer: any;
	private _controlList: any[] = [];
	private _toolbar: any;

	constructor(props: any) {
		super(props);
		this.state = {
			hideStartNavigationControl: this.props.hideNavigationControl != undefined ? this.props.hideNavigationControl : false,
			signatureData: {
				name: (this.props.doNotAllowToEditSignatureName && this.props.headerInfo?.loggedInUserInfo?.role?.toString() != Role[Role.Delegatee]) ? this.props.headerInfo.clientName : "",
				signature: undefined,
				signatureMode: SignatureMode.Type
			},
			initialcontrlData: undefined,
			isDocumentLoaded: false,
			loggedControls: []
		}
	}

	componentDidMount() {
		this.setReferences();
		logger.trackEvent(
			logger.buildEvent(
				`${LogEventConstants.Common.Open}  ${pageTitle}`,
				{ count: 1, page: pageTitle }
			)
		);
	}

	setReferences() {
		this._toolbar && this._toolbar.setViewerReference(this._viewPanel);
		this._viewPanel && this._viewPanel.setToolbarReference(this._toolbar);
		this._viewPanel && this._viewPanel.setControlsReference(this._controlList);
		this._viewPanel && this._viewPanel.setControlLayerReference(this._controlLayer);
		this._controlLayer && this._controlLayer.setControlsReference(this._controlList);
		this._controlLayer && this._controlLayer.setViewerReference(this._viewPanel);
	}

	componentDidUpdate() {
		this.setReferences();
	}

	public showValidationMessage = (page: number, control: Controls.IControl) => {

		this._viewPanel.gotoPage(page);
		const tmpControl: any = this._controlList.find(x => x && x.props.id == "document-control-" + control.id);
		tmpControl && tmpControl.setValidationStatus(false);

	}

	highlightSelectedFile = (fileId: number, pageNo: number) => {

		const currentfileitem: any = document.getElementsByClassName("file-node-selected")[0];
		currentfileitem && currentfileitem.classList.remove("file-node-selected");

		const nextFileitem: any = document.getElementsByClassName("file-node-" + fileId + "_" + pageNo)[0];
		nextFileitem && nextFileitem.classList.add("file-node-selected");
	}

	private startNavigation = () => {

		this.setState({ isDocumentLoaded: true }, () => {

			let _self = this;
			this.closeNavigation();

			// find the page it contains controls and navigate to that page


			if (_self.props.startNavigationOnDocumentLoad) {
				const page: IPage | undefined = this.props.pages.find((x: IPage) => x.controls.length > 0);
				page && _self._viewPanel.gotoPage(page.page);
			}

			// some delay to load the pdf pages with controls

			setTimeout(
				function () {
					_self.props.startNavigationOnDocumentLoad && _self.state.hideStartNavigationControl && _self._controlLayer?.startNavigation();
				}.bind(this),
				2000);
			logger.trackTrace(`${LogEventConstants.Common.Esign.StartNavigation}: ${pageTitle}`);
		});
	}

	private closeNavigation = () => {
		logger.trackTrace(`${LogEventConstants.Common.Esign.EndNavigation}: ${pageTitle}`);
		!this.props.hideSignatureControls && this._controlLayer.closeNavigation();
	}

	private setAllSignatureControlValueToSame = (signatureData: SignatureData, controlProps: SignatureControlProps) => {

		this.setState({ signatureData: signatureData }, () => {

			this._controlList.forEach(function (item) {

				if (item && item.props.data && item.props.data.controlType === 1 && item.isSigned()) { // signature control
					item.setControlData({ name: signatureData.name, signature: signatureData.signature, signatureMode: signatureData.signatureMode });
				}

			});

			this.props.onAddControlData(signatureData, controlProps);
		});
	}


	setSignature = (controlProps: any, controlDisable?: boolean) => {

		const { signatureData } = this.state;
		const control = this._controlList.find(x => x && x.props.id == controlProps.id);

		control && signatureData && control.setControlData({ name: signatureData.name, signature: signatureData.signature, signatureMode: signatureData.signatureMode });

		if (signatureData && signatureData.signature) {
			this.props.onAddControlData(signatureData, controlProps);
		}
		else {
			if (controlDisable == null
				|| (controlDisable && controlDisable !== true)
			) {
				control && control.openSignatureModal();
			}
		}
	}

	private setAllTextControlValueToSame = (textBoxData: TextBoxData, controlPops: TextBoxControlProps) => {

		this._controlList.forEach(function (item: any) {

			if (item && controlPops.id == item.props.id)
				return;

			if (item && item.props.data && item.props.data.controlType == controlPops.data.controlType) {
				item.setControlData(textBoxData);
			}

		});

		this.props.onAddControlData(textBoxData, controlPops);

	}

	private createControls = () => {

		this._controlList = [];
		let controlCollection: any[] = [];
		let _self = this;
		const { pages } = this.props;
		const { isDocumentLoaded, loggedControls } = this.state;

		if (!isDocumentLoaded)
			return this._controlList;

		pages.forEach(function (page: IPage) {

			const pageSize: PageSize = _self._viewPanel.getPageSize(page.page, 1);

			if (pageSize.height > 0) {

				page.controls.forEach(function (control: Controls.IControl, index: number) {

					let top = ControlBase.getPdfViewerControlTopPosition(pageSize.height, control.top);
					const left = ControlBase.getPdfViewerControlLeftPosition(control.left);

					if (control instanceof Controls.SignatureControl) {

						const controlData: Controls.IControlData | undefined = control.data;
						const signatureData: SignatureData | undefined = controlData && SignatureData.create(
							(controlData as Controls.ISignatureData).name,
							(controlData as Controls.ISignatureData).signature,
							(controlData as Controls.ISignatureData).signatureMode);

						controlCollection.push(<SignatureControl isRequired={control.required}
							ref={(ref) => _self._controlList.push(ref)} page={page.page} id={"document-control-" + control.id}
							key={"sig-" + control.id}
							name={"Signature"}
							helptext={control.tooltip} onAddSignature={_self.setAllSignatureControlValueToSame}
							top={top} left={left} width={99} height={20} data={{ controlType: 1, controlId: control.id, role: control.customData.find(x => x instanceof Controls.ControlRole)?.role }}
							signatureData={signatureData} onClick={(controlProps: any) => { _self.setSignature(controlProps, control.disabled) }}
							disabled={true}
							doNotAllowToEditSignatureName={_self.props.doNotAllowToEditSignatureName && _self.props.headerInfo?.loggedInUserInfo?.role?.toString() != Role[Role.Delegatee]}
						/>);
						if (!loggedControls.includes(control.id)) {
							loggedControls.push(control.id);
							_self.setState({ loggedControls: loggedControls });
							logger.trackEvent(
								logger.buildEvent(
									`${LogEventConstants.Common.Esign.AddSignControl}`,
									{ count: 1, CustomEventType: LogEventConstants.Common.DocumentEventName, page: pageTitle, signPage: page.formName, controlIndex: index, type: 'SignatureControl' }
								)
							);
						}
					}
					else if (control instanceof Controls.InitialControl) {

						const controlData: Controls.IControlData | undefined = control.data;
						const textboxData: TextBoxData | undefined = controlData && TextBoxData.create((controlData as Controls.ITextData).text);

						controlCollection.push(<TextBoxControl maxLength={60} ref={(ref) => _self._controlList.push(ref)} page={page.page}
							id={"document-control-" + control.id} isRequired={control.required}
							key={"initial-" + control.id} name={"Initial"}
							placeholder={"Initial"} data={{ controlType: 2, controlId: control.id }}
							helptext={control.tooltip} textboxData={textboxData}
							top={top} left={left} width={250} height={20} onChange={_self.setAllTextControlValueToSame}
							disabled={control.disabled}
						/>);
						if (!loggedControls.includes(control.id)) {
							loggedControls.push(control.id);
							_self.setState({ loggedControls: loggedControls });
							logger.trackEvent(
								logger.buildEvent(
									`${LogEventConstants.Common.Esign.AddInitialControl}`,
									{ count: 1, CustomEventType: LogEventConstants.Common.DocumentEventName, page: pageTitle, signPage: page.formName, controlIndex: index, type: 'InitialControl' }
								)
							);
						}
					}
					else if (control instanceof Controls.DateControl) {

						const controlData: Controls.IControlData | undefined = control.data;
						const labelData: LabelData | undefined = controlData && LabelData.create((controlData as Controls.ITextData).text);

						controlCollection.push(<LabelControl skipNavigation={true} ref={(ref) => _self._controlList.push(ref)} page={page.page}
							id={"document-control-" + control.id}
							key={"date-" + control.id}
							name={"Current Date"} value={labelData || LabelData.createNullObject()}
							helptext={control.tooltip} data={{ controlType: 3, controlId: control.id }}
							top={top} left={left} width={99} height={20}
							disabled={control.disabled}
						/>);
						if (!loggedControls.includes(control.id)) {
							loggedControls.push(control.id);
							_self.setState({ loggedControls: loggedControls });
							logger.trackEvent(
								logger.buildEvent(
									`${LogEventConstants.Common.Esign.AddDateControl}`,
									{ count: 1, CustomEventType: LogEventConstants.Common.DocumentEventName, page: pageTitle, signPage: page.formName, controlIndex: index, type: 'DateControl' }
								)
							);
						}
					}
					else if (control instanceof Controls.CompanyControl) {

						const controlData: Controls.IControlData | undefined = control.data;
						const textboxData: TextBoxData | undefined = controlData && TextBoxData.create((controlData as Controls.ITextData).text);

						controlCollection.push(<TextBoxControl maxLength={60} ref={(ref) => _self._controlList.push(ref)} page={page.page}
							id={"document-control-" + control.id}
							key={"company-" + control.id}
							name={"Company"} isRequired={control.required}
							placeholder={"Company name"} data={{ controlType: 5, controlId: control.id }}
							helptext={control.tooltip} textboxData={textboxData}
							top={top} left={left} width={250} height={20} onChange={_self.setAllTextControlValueToSame}
							disabled={control.disabled}
						/>);
						if (!loggedControls.includes(control.id)) {
							loggedControls.push(control.id);
							_self.setState({ loggedControls: loggedControls });
							logger.trackEvent(
								logger.buildEvent(
									`${LogEventConstants.Common.Esign.AddCompanyControl}`,
									{ count: 1, CustomEventType: LogEventConstants.Common.DocumentEventName, page: pageTitle, signPage: page.formName, controlIndex: index, type: 'InitialControl' }
								)
							);
						}
					}
					else if (control instanceof Controls.CustomTextControl) {

						const controlData: Controls.IControlData | undefined = control.data;
						const textboxData: TextBoxData | undefined = controlData && TextBoxData.create((controlData as Controls.ITextData).text);

						controlCollection.push(<TextBoxControl maxLength={60} ref={(ref) => _self._controlList.push(ref)} page={page.page}
							id={"document-control-" + control.id}
							key={"custom-text-" + control.id}
							name={"Text"} isRequired={control.required}
							placeholder={control.tooltip} data={{ controlType: 6, controlId: control.id }}
							helptext={control.tooltip} textboxData={textboxData}
							top={top} left={left} width={200} height={20}
							onChange={(controlData: ControlData, controlProps: ControlBaseProps) => _self.props.onAddControlData(controlData, controlProps)}
							disabled={control.disabled}
						/>);
						if (!loggedControls.includes(control.id)) {
							loggedControls.push(control.id);
							_self.setState({ loggedControls: loggedControls });
							logger.trackEvent(
								logger.buildEvent(
									`${LogEventConstants.Common.Esign.AddCustomTextControl}`,
									{ count: 1, CustomEventType: LogEventConstants.Common.DocumentEventName, page: pageTitle, signPage: page.formName, controlIndex: index, type: 'CustomTextControl' }
								)
							);
						}
					}
					else if (control instanceof Controls.PrintNameControl) {

						const controlData: Controls.IControlData | undefined = control.data;
						const textboxData: TextBoxData | undefined = controlData && TextBoxData.create((controlData as Controls.ITextData).text);

						controlCollection.push(<TextBoxControl maxLength={60} ref={(ref) => _self._controlList.push(ref)} page={page.page}
							id={"document-control-" + control.id}
							key={"printName-" + control.id}
							name={"Print Name"} isRequired={control.required}
							placeholder={"Print Name"} data={{ controlType: 12, controlId: control.id }}
							helptext={control.tooltip} textboxData={textboxData}
							top={top} left={left} width={250} height={20} onChange={_self.setAllTextControlValueToSame}
							disabled={control.disabled}
						/>);
						if (!loggedControls.includes(control.id)) {
							loggedControls.push(control.id);
							_self.setState({ loggedControls: loggedControls });
							logger.trackEvent(
								logger.buildEvent(
									`${LogEventConstants.Common.Esign.AddPrintNameControl}`,
									{ count: 1, CustomEventType: LogEventConstants.Common.DocumentEventName, page: pageTitle, signPage: page.formName, controlIndex: index, type: 'PrintNameControl' }
								)
							);
						}
					}
					else if (control instanceof Controls.TitleControl) {

						const controlData: Controls.IControlData | undefined = control.data;
						const textboxData: TextBoxData | undefined = controlData && TextBoxData.create((controlData as Controls.ITextData).text);

						controlCollection.push(<TextBoxControl maxLength={60} ref={(ref) => _self._controlList.push(ref)} page={page.page}
							id={"document-control-" + control.id}
							key={"title-" + control.id}
							name={"Title"} isRequired={control.required}
							placeholder={"Title"} data={{ controlType: 13, controlId: control.id }}
							helptext={control.tooltip} textboxData={textboxData}
							top={top} left={left} width={250} height={20} onChange={_self.setAllTextControlValueToSame}
							disabled={control.disabled}
						/>);
						if (!loggedControls.includes(control.id)) {
							loggedControls.push(control.id);
							_self.setState({ loggedControls: loggedControls });
							logger.trackEvent(
								logger.buildEvent(
									`${LogEventConstants.Common.Esign.AddTitleControl}`,
									{ count: 1, CustomEventType: LogEventConstants.Common.DocumentEventName, page: pageTitle, signPage: page.formName, controlIndex: index, type: 'TitleControl' }
								)
							);
						}
					}
					else {

					}
				});
			}
		});

		return controlCollection;

	}

	public render() {

		let navigationFinishTarget: any = undefined;

		if (this.props.finishTarget) {
			navigationFinishTarget = {
				targetId: this.props.finishTarget,
				text: "Finish",
			};
		}

		var pdfSource = PdfSource.createFromUrl(this.props.url);

		return (<PdfViewer id={"esign-aweosme-pdf-viewer"}>
			<Header>
				<Toolbar ref={(ref: any) => this._toolbar = ref} hideRightPanel={true} onViewModeChange={this.props.onViewModeChange} >
					<Pagination />
					<Zoom />
				</Toolbar>

			</Header>
			{this.props.progress > 0 && <Progress status={this.props.progress} />}
			<Main>

				<LeftPanel>
					{
						this.props.fileList

					}
				</LeftPanel>

				{
					!this.props.hideSignatureControls ?
						<ControlLayer ref={(ref: any) => this._controlLayer = ref}
							useDefaultNavigationStartControl={!this.state.hideStartNavigationControl}
							onNavigationComplete={this.props.onNavigationFinish}
							onNavigationBegin={() => { this.setState({ hideStartNavigationControl: true }) }}
							navigationFinishTarget={navigationFinishTarget}>

							{this.createControls()}

						</ControlLayer>
						: ""
				}

				<ViewPanel onDocumentLoad={this.startNavigation}
					ref={(ref: any) => this._viewPanel = ref}
					pdfSource={pdfSource}
					onPageChanging={this.props.onPageChanging}
					onPageChanged={({ page }) => this.props.documentId && this.highlightSelectedFile(this.props.documentId, page)}>
				</ViewPanel>

			</Main>

		</PdfViewer>);

	}
}