import { injectable } from "inversify";

export interface IJsonUtilities {
    toImmutableObject(object: any): any;
}

@injectable()
export class JsonUtilities implements IJsonUtilities {

    toImmutableObject(object: any): any {
        return JSON.parse(JSON.stringify(object));
    }


}