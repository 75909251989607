import { connect } from 'react-redux';
import { actionCreators as HeaderActions } from "./../../../../store/Login/Common/Header/HeaderStore";
import { actionCreators as CCRecipientActions } from "./../../../../store/Login/CCRecipient/OTP/OTPStore";
import { ApplicationState } from '../../../../store';
import MobileOTP from '../../Common/OTP/MobileOTP';
import { OtpPageStoreState } from '../../../../store/Login/Models/StoreModels';

export default connect(
    (state: ApplicationState) => ({
        headerStoreState: state.headerPageStore,
        backToHomePath: "",
        successRedirectPath: "/Cc/DownloadTaxDocuments/",
        mobileOtpPageStoreState: {
            countryCode: state.ccRecipientOTPPageStore?.ccRecipientModel?.countryCode ?? "",
            mobileNo: state.ccRecipientOTPPageStore?.ccRecipientModel?.mobileNumber ?? "",
            loading: state.ccRecipientOTPPageStore?.loading ?? false
        } as OtpPageStoreState,
        backToHome: false,
    }),
    {
        requestHeaderDetails: (clientId: string) =>
            HeaderActions.requestCcRecipientHeaderDetails(clientId),

        requestClientInfo: (clientId: string) =>
            CCRecipientActions.getClientInfo(clientId),

        generateOtp: (clientId: string) =>
            CCRecipientActions.generateMobileOTP(clientId),

        verifyOtp: (otp: string, clientId: string, handelOtpVerification: (guid: string) => void, handleError: () => void) =>
            CCRecipientActions.verifyMobileOTP(otp, clientId, handelOtpVerification, handleError)
    }
)(MobileOTP);