import * as React from 'react';
import { ISignedDocument, SignedDocument } from '../../../core/domain/models/manualsign/SignedDocument';
import { initializeAxios } from '../../../core/services/dataAccess/DataService.Axios';
import { IFileUtilities } from '../../../core/utilities/File/FileUtilities';
import { IDialogBox } from '../../../core/utilities/ui/DialogBox';
import { container } from '../../../startup/inversify.config';
import { TYPES } from '../../../startup/types';
import { ErrorMessages, ManualSignConstants } from '../../Common/Constants';
import { FileUpload, UploadStatus } from '../../Common/FileUpload/FileUpload';
import { UploadedDocumentTable } from './UploadedDocumentTable';
import { UploadHeader } from './UploadHeader';
import { logger } from '../../../routes';
import { LogEventConstants } from '../../Helper/Constants';
import { UploadFunctions, isFileExist } from "@sssuite-js-packages/file-utility";
import { MarsNotifier } from '../../Common/Notification/MarsNotifier';

export interface UploadDocumentsProps {
    clientId: string;
    signedDocuments: ISignedDocument[];
    addSignedDocument?(clientId: string, signedDocument: ISignedDocument, successCallback?: () => void, failureCallback?: () => void): void;
    deleteSignedDocument?(clientId: string, id: number): void;
    requestSignedDocument(clientId: string, forceRefresh?: boolean): void;
    isDelegatee: boolean;
    disableNextButton?(value: boolean): void;
}

export interface UploadDocumentsState {
    files: UploadedFile[];
}

export interface UploadedFile {
    id: number;
    uploadedOn: Date;
    name: string;
    file: any;
    status: UploadStatus;
}


const fileUtilities = container.get<IFileUtilities>(TYPES.IFileUtilities);
const dialogBox = container.get<IDialogBox>(TYPES.IDialogBox);
//const dataService = container.get<IDataService>(TYPES.IDataService);


const pageTitle = "manual Signed document upload";
export class UploadDocuments extends React.Component<UploadDocumentsProps, UploadDocumentsState> {

    constructor(props: UploadDocumentsProps) {
        super(props);

        this.state = {
            files: []
        }
    }

    componentWillReceiveProps(nextProps: UploadDocumentsProps, nextState: UploadDocumentsState) {
        if((nextProps.signedDocuments.length !== this.props.signedDocuments.length) || 
			(this.props.signedDocuments.length > 0 && this.state.files.length == 0))
		{
        let files: UploadedFile[] = [];

        nextProps.signedDocuments.forEach((item: ISignedDocument, index: number) => {

            files.push({
                id: item.id,
                uploadedOn: item.uploadedOn,
                name: item.fileName,
                file: undefined,
                status: UploadStatus.Uploaded
            });
        });

        this.setState({ files: files.filter(x => x.name != ManualSignConstants.AuditFileName) });
    }
}

    private handleAddFiles = (files: any) => {

        if (this.props.addSignedDocument) {

            let uploadedFiles: UploadedFile[] = this.convertToModel(files);
            if (uploadedFiles.length != files.length) {
                dialogBox.alert(ManualSignConstants.SupportedFilesMessage);
            }
            uploadedFiles = this.handleDuplicates(uploadedFiles);

            if (uploadedFiles.length > 0) {
                this.props.disableNextButton && this.props.disableNextButton(true);
                this.setState((prevState: UploadDocumentsState) => ({
                    files: prevState.files.concat(uploadedFiles)
                }), () => {

                    this.getUploadLink();
                });
            }
            logger.trackEvent(
                logger.buildEvent(
                    `${LogEventConstants.Common.ManualDownload.FileUploaded}  ${pageTitle}`,
                    { count: uploadedFiles.length, page: pageTitle, uploadedFiles: uploadedFiles.map(x => x.name) }
                )
            );
           
        } else {
            dialogBox.alert(ManualSignConstants.UploadNotAllowedMessage);
        }      
    }

    private getUploadLink = () => {

        let fileUploadUtilities = new UploadFunctions()
        let _self = this;
        const { clientId, isDelegatee } = this.props;
        let fileUploaded: number = 0;
        const uploadFiles: number = this.state.files.filter(x => x.status == UploadStatus.Wait).length;
        this.state.files.filter(x => x.status == UploadStatus.Wait).forEach((file: UploadedFile) => {

            file.status = UploadStatus.Initiating;

            initializeAxios().get(`/api/Upload/GetManualSignUploadLink/${clientId}?isDelegatee=${isDelegatee}&fileName=${file.name}`).then((response: any) => {

                this.setState((prevState: UploadDocumentsState) => ({
                    files: prevState.files
                }), () => {

                    fileUploadUtilities.uploadFile(file.file,
                        response.data,
                        file.name,
                        undefined,
                        () => {
                            fileUploaded++;
                            this.handleAddDocument(file.name, uploadFiles, fileUploaded)
                        });
                });

            }, (error: any) => {
                MarsNotifier.Error(ErrorMessages.AddSignedDocument, "");
                var index = _self.state.files.findIndex(x => x.name === file.name);
                _self.state.files.splice(index, 1);
                this.props.disableNextButton && this.props.disableNextButton(false);
                this.setState((prevState: UploadDocumentsState) => ({
                    files: prevState.files
                }));

            });

        });

    }

    private convertToModel = (files: any): UploadedFile[] => {

        let uploadedFiles: UploadedFile[] = [];

        for (let i = 0; i < files.length; i++) {

            let tmpFile = files[i];

            if (fileUtilities.isValidateSize(tmpFile, 536870912)) {

                let tmpFileName = tmpFile.name;

                if (fileUtilities.isValidateExtension(tmpFileName, ["pdf","jpg","png","jpeg","xls","xlsx","doc","docx"])) {

                    uploadedFiles.push({
                        id: 0,
                        uploadedOn: new Date(),
                        name: tmpFileName,
                        file: tmpFile,
                        status: UploadStatus.Wait
                    });
                }
            }
        }

        return uploadedFiles;
    }

    private handleDuplicates = (uploadedFiles: UploadedFile[]): UploadedFile[] => {

        for (let i = 0; i < uploadedFiles.length; i++) {

            let file = uploadedFiles[i];
            let fileName: string = file.name || "";
            let fileExtension = fileUtilities.getExtension(fileName);
            fileName = fileName.replace("." + fileExtension, '');
            fileName = fileUtilities.getSafeFilename(fileName);
            fileName = fileName + "." + fileExtension;
            let filecount = 1;
            let duplicateFileName = fileName;
            while (isFileExist(duplicateFileName, this.state.files)) {
                duplicateFileName = fileName || "";
                duplicateFileName = duplicateFileName.replace("." + fileExtension, '');
                duplicateFileName = duplicateFileName + " (" + filecount + ")." + fileExtension;
                filecount++;
            }
            fileName = duplicateFileName;
            uploadedFiles[i].name = fileName;
        }

        return uploadedFiles;
    }



    private completeAddDocument = () => {
		this.props.requestSignedDocument(this.props.clientId, true);
		this.props.disableNextButton && this.props.disableNextButton(false);
	}

    private handleAddDocument = (fileName: string, uploadFiles: number, fileUploaded: number) => {

        const { clientId, addSignedDocument, requestSignedDocument } = this.props;
        let signedDocument: ISignedDocument = SignedDocument.createModelToServer(fileName);

        uploadFiles == fileUploaded ? addSignedDocument && addSignedDocument(this.props.clientId,
            signedDocument, () => {
                this.completeAddDocument()
            },
            () => {
				this.props.disableNextButton && this.props.disableNextButton(false);
            }) : addSignedDocument && addSignedDocument(clientId, signedDocument,
                () => { this.props.disableNextButton && this.props.disableNextButton(false) });
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.ManualDownload.FileUploaded}  ${pageTitle}`,
                { count: 1, page: pageTitle, filename: fileName }
            )
        );
    }

    public render() {

        const { files } = this.state;
        const isAllFileUploaded = files.length == 0 || files.every(x => x.status == UploadStatus.Uploaded);
        const fileUploadCssClass: string = files.length > 0 ? "" : "with-border";

        return (<div className={"col-lg-8 upload-documents"} data-test-auto="B5EF7D9E-9B8B-49BB-8262-D84557544CA7">

            <h6 className={"text-blue"} data-test-auto={"76268829-97BF-4226-845E-5A30E1A3CE0D"}> {ManualSignConstants.UploadDocumentSecondaryTitle} </h6>

            <FileUpload id={"signed-document-upload"} cssClass={fileUploadCssClass} onAddFiles={this.handleAddFiles}>
            <UploadHeader minimized={files.length > 0} isAllFileUploaded = {isAllFileUploaded} />

                <UploadedDocumentTable clientId={this.props.clientId}
                    files={files}
                    deleteSignedDocument={this.props.deleteSignedDocument} />

            </FileUpload>

        </div>);
    }
}
