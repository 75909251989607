import { ISignerModel } from 'src/core/domain/models/ISignerModel';
import { History } from 'history';
import * as React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { match } from 'react-router';
import { Link } from 'react-router-dom';
import { ChangeNumber } from '../../components/Layout/ChangeNumber';
import { ClientType, Role } from '../../core/common/Enums';
import { ClientTypes, ITaxClient, ITaxpayer } from '../../core/domain/models/ITaxClient';
import { isIndividual, isMutual, isPartnership, ITaxReturn, ToMutual } from '../../core/domain/models/ITaxReturn';
import { IAccessCodeValidation } from '../../core/domain/viewModels/ICommon';
import { IHeaderInfoViewModel, initialHeaderInfoViewModel } from '../../core/domain/viewModels/IHeaderInfoViewModel';
import { IUtilities } from '../../core/utilities/Utilities';
import { container } from '../../startup/inversify.config';
import { TYPES } from '../../startup/types';
import { MyAccountConstants } from '../Common/Constants';
import {
    SvgIconAccountHeader,
    SvgIconCalendarHeader, SvgIconContactHeader
} from '../Common/Icons/SvgIcons';
import { MarsNotifier } from '../Common/Notification/MarsNotifier';
import { isValidEmailAddress, validatePhone, validateCountryCode } from '../Common/Validations';
import TaxpayerHelper from '../Helper/TaxpayerHelper';
import { AccessCode } from '../Layout/AccessCode';
import { MyAccount } from '../Layout/MyAccount';
import MyDownload, { IDownloadedZipFilesModel } from '../Layout/MyDownload';
import { WebrtcSignalR } from '../ScreenShare/WebrtcSignalR';
import { getDecodedClientId } from '../Helper/HelperFunction';
import { PathConstants } from '../Common/Constants';
import { logger } from 'src/routes';
import { LogEventConstants } from '../Helper/Constants';

const utilities = container.get<IUtilities>(TYPES.IUtilities);

export interface HeaderProps {
    headerInfo: IHeaderInfoViewModel,
    match: match;
    history: History;
    taxdocument: ITaxReturn;
    signerData: ISignerModel[];
    iceServers: RTCIceServer[];
    getIceServers: (id: string) => void;
    refreshTaxDocument: (clientGuid: string) => void;
    updateEmailAddress(clientGuid: string, id: string, mail: string, type: ClientType): void;
    updateMobileNumber(clientGuid: string, id: string, number: string, countryCodeValue: string,
        ssnValue: string, clientType: ClientType, callback: () => void): void;
    updateTaxDocument: (taxDocument: ITaxReturn) => void;
    downlistList: IDownloadedZipFilesModel[];
    deleteMyDownloads: (downloadId: string, clientId: string) => void;
    clearAllMyDownloads: (clientId: string) => void;
    validateTimeBasedAccessCode: (accessCode: IAccessCodeValidation, callback: () => void) => void;
    logout: (clientGuid: string, callback: (id: string) => void) => void;
}

export interface HeaderState {
    headerInfo: IHeaderInfoViewModel;
    showMyAccountPopUp: boolean;
    showAccessCodePopUp: boolean;
    emailAddress: string;
    mobileNumber: string;
    countryCode: string;
    ssn: string;
    showChangeNumberPopUp: boolean;
    showMyDownloadPopUp: boolean;
    screenShareInProgress: boolean;
}

export class Header extends React.Component<HeaderProps, HeaderState> {
    webrtcRef: any;

    constructor(props: HeaderProps, states: HeaderState) {
        super(props, states);
        this.state = {
            headerInfo: initialHeaderInfoViewModel,
            showMyAccountPopUp: false,
            showAccessCodePopUp: false,
            emailAddress: "",
            mobileNumber: "",
            countryCode: "",
            ssn: "",
            showChangeNumberPopUp: false,
            showMyDownloadPopUp: false,
            screenShareInProgress: false
        }

        this.webrtcRef = React.createRef();
    }

    static getDerivedStateFromProps(nextProps: HeaderProps, prevState: HeaderState) {
        if (nextProps.headerInfo && (nextProps.headerInfo.brandingSettings.coverPageSetting.bgColorCode !== prevState.headerInfo.brandingSettings.coverPageSetting.bgColorCode
            || nextProps.headerInfo.brandingSettings.coverPageSetting.foreColorCode !== prevState.headerInfo.brandingSettings.coverPageSetting.foreColorCode)) {
            utilities.applyBrandingSettings(nextProps.headerInfo.brandingSettings);
            return {
                headerInfo: nextProps.headerInfo
            }
        }
        else {
            return null;
        }
    }

    closeDownloadPopup = () => {
        this.setState({ showMyDownloadPopUp: false });
    }

    openDownloadPopup = () => {
        this.setState({ showMyDownloadPopUp: true });
    }

    componentDidMount() {

    }

    getMyaccount = () => {
        this.setState({
            showMyAccountPopUp: true,
        })
    }

    screenShareLink = () => {
        this.state.screenShareInProgress ? this.endScreenShareSession() : this.setState({ showAccessCodePopUp: true })
    }

    onCancelMyAccountPopUp = () => {
        this.setState({
            showMyAccountPopUp: false,
        })
    }

    onCancelAccessCodePopUp = () => {
        this.setState({
            screenShareInProgress: false,
            showAccessCodePopUp: false
        })
    }

    onHideChangeNumberPopUp = () => {
        this.setState({
            showChangeNumberPopUp: false,
        })
    }

    private getPartnerShip(): ITaxClient | undefined {
        const taxDocument = this.props.taxdocument;
        if (taxDocument && isPartnership(taxDocument)) {
            return taxDocument.partnership;
        }
        return undefined;
    }

    private getTaxPayerInfo(): ITaxpayer | undefined {
        const taxDocument = this.props.taxdocument;
        if (taxDocument && isIndividual(taxDocument)) {
            return taxDocument.taxpayer
        } else if (isMutual(taxDocument)) {
            if (taxDocument.documentSettings.deliverySettings.deliverTo == ClientTypes.Taxpayer
                && taxDocument.clientType == ClientType.Taxpayer) {
                return taxDocument.taxpayer;
            } else if (taxDocument.documentSettings.deliverySettings.deliverTo == ClientTypes.Taxpayer
                && taxDocument.clientType == ClientType.Spouse) {
                return taxDocument.spouse;
            } else if (taxDocument.documentSettings.deliverySettings.deliverTo == ClientTypes.Spouse
                && taxDocument.clientType == ClientType.Spouse) {
                return taxDocument.spouse;
            } else if (taxDocument.documentSettings.deliverySettings.deliverTo == ClientTypes.Spouse
                && taxDocument.clientType == ClientType.Taxpayer) {
                return taxDocument.taxpayer;
            }
        }
        return undefined;

    }

    private getSpouseInfo(): ITaxpayer | undefined {
        const taxDocument = this.props.taxdocument;
        if (taxDocument && isMutual(taxDocument)) {
            if (taxDocument.clientType == ClientType.Taxpayer) {
                if (taxDocument.documentSettings.deliverySettings.deliverTo == ClientTypes.Taxpayer) {
                    return taxDocument.spouse;
                }
            }
            else {
                if (taxDocument.documentSettings.deliverySettings.deliverTo == ClientTypes.Spouse) {
                    return taxDocument.taxpayer;
                }
            }
        }
        return undefined;
    }

    private getMobileNumber(): string {
        const taxDocument = this.props.taxdocument;

        if (taxDocument != null && taxDocument != undefined) {
            if (isIndividual(taxDocument)) {
                return (taxDocument.taxpayer.mobileNumber != null && taxDocument.taxpayer.mobileNumber != undefined) ?
                    taxDocument.taxpayer.mobileNumber : "";
            }
            else if (isPartnership(taxDocument)) {
                return (taxDocument.partnership.mobileNumber != null && taxDocument.partnership.mobileNumber != undefined) ?
                    taxDocument.partnership.mobileNumber : "";
            }
            else if (isMutual(taxDocument)) {
                if (taxDocument.documentSettings.deliverySettings.deliverTo == ClientTypes.Taxpayer) {
                    if (taxDocument.clientType == ClientType.Taxpayer) {
                        return (taxDocument.taxpayer.mobileNumber != null && taxDocument.taxpayer.mobileNumber != undefined) ?
                            taxDocument.taxpayer.mobileNumber : "";
                    }
                    else {
                        return (taxDocument.spouse.mobileNumber != null && taxDocument.spouse.mobileNumber != undefined) ?
                            taxDocument.spouse.mobileNumber : "";
                    }
                }
                else if (taxDocument.documentSettings.deliverySettings.deliverTo == ClientTypes.Spouse) {

                    if (taxDocument.clientType == ClientType.Taxpayer) {
                        return (taxDocument.taxpayer.mobileNumber != null && taxDocument.taxpayer.mobileNumber != undefined) ?
                            taxDocument.taxpayer.mobileNumber : "";
                    }
                    else {
                        return (taxDocument.spouse.mobileNumber != null && taxDocument.spouse.mobileNumber != undefined) ?
                            taxDocument.spouse.mobileNumber : "";
                    }
                }
            }
        }
        return "";
    }

    private getCountryCode(): string {
        const taxDocument = this.props.taxdocument;

        if (taxDocument != null && taxDocument != undefined) {
            if (isIndividual(taxDocument)) {
                return (taxDocument.taxpayer.countryCode != null && taxDocument.taxpayer.countryCode != undefined) ?
                    taxDocument.taxpayer.countryCode : "";
            }
            else if (isPartnership(taxDocument)) {
                return (taxDocument.partnership.countryCode != null && taxDocument.partnership.countryCode != undefined) ?
                    taxDocument.partnership.countryCode : "";
            }
            else if (isMutual(taxDocument)) {
                if (taxDocument.documentSettings.deliverySettings.deliverTo == ClientTypes.Taxpayer) {
                    if (taxDocument.clientType == ClientType.Taxpayer) {
                        return (taxDocument.taxpayer.countryCode != null && taxDocument.taxpayer.countryCode != undefined) ?
                            taxDocument.taxpayer.countryCode : "";
                    }
                    else {
                        return (taxDocument.spouse.countryCode != null && taxDocument.spouse.countryCode != undefined) ?
                            taxDocument.spouse.countryCode : "";
                    }
                }
                else if (taxDocument.documentSettings.deliverySettings.deliverTo == ClientTypes.Spouse) {

                    if (taxDocument.clientType == ClientType.Taxpayer) {
                        return (taxDocument.taxpayer.countryCode != null && taxDocument.taxpayer.countryCode != undefined) ?
                            taxDocument.taxpayer.countryCode : "";
                    }
                    else {
                        return (taxDocument.spouse.countryCode != null && taxDocument.spouse.countryCode != undefined) ?
                            taxDocument.spouse.countryCode : "";
                    }
                }
            }
        }
        return "";
    }

    onUpdateDetails = (editedEmailAddress: string, editedMobileNumber: string, editedCountryCode: string) => {

        if (this.props.headerInfo.loggedInUserInfo.role.toString() === Role[Role.CPA].toString()) {
            this.setState({ showMyAccountPopUp: false, showAccessCodePopUp: false });
            return false;
        }

        if (editedEmailAddress.trim() === "" || !isValidEmailAddress(editedEmailAddress)) {
            MarsNotifier.Warning(MyAccountConstants.WarningMessage.InvalidEmailAddress, null);
            return false;
        }
        else if (validatePhone(editedMobileNumber) && validateCountryCode(editedCountryCode)) {

            const taxDocument = this.props.taxdocument;
            const taxDocumentClient: any = this.props.match.params;
            const clientGuid = getDecodedClientId(taxDocumentClient.clientId);

            const clientId: number = taxDocument && (taxDocument.clientType == ClientType.Spouse && isMutual(taxDocument)) ? taxDocument.taxpayer.id :
                taxDocument && (taxDocument.clientType == ClientType.Taxpayer && isMutual(taxDocument)) ? taxDocument.spouse.id : 0;

            const ssnValue: string = taxDocument && (taxDocument.clientType == ClientType.Spouse && isMutual(taxDocument)) ? taxDocument.taxpayer.ssn :
                taxDocument && (taxDocument.clientType == ClientType.Taxpayer && isMutual(taxDocument)) ? taxDocument.spouse.ssn : "";

            if (isMutual(taxDocument)) {
                if (taxDocument.clientType == ClientType.Spouse) {
                    taxDocument.taxpayer.email = editedEmailAddress;
                    taxDocument.taxpayer.mobileNumber = editedMobileNumber;
                    taxDocument.taxpayer.countryCode = editedCountryCode;
                    this.props.updateTaxDocument(taxDocument);
                }
                else if (taxDocument.clientType == ClientType.Taxpayer) {
                    taxDocument.spouse.email = editedEmailAddress;
                    taxDocument.spouse.mobileNumber = editedMobileNumber;
                    taxDocument.spouse.countryCode = editedCountryCode;
                    this.props.updateTaxDocument(taxDocument);
                }
            }

            this.setState({ emailAddress: editedEmailAddress, mobileNumber: editedMobileNumber, countryCode: editedCountryCode },
                () => {
                    this.props.updateEmailAddress(taxDocumentClient.clientId, TaxpayerHelper.getSecondSignerId(ToMutual(this.props.taxdocument)
                        , this.props.signerData, clientGuid).toString(), this.state.emailAddress,
                        TaxpayerHelper.getSecondSignerType(ToMutual(this.props.taxdocument), this.props.signerData, clientGuid));
                    this.props.updateMobileNumber(taxDocumentClient.clientId, clientId.toString(), this.state.mobileNumber,
                        this.state.countryCode, ssnValue, TaxpayerHelper.getSecondSignerType(ToMutual(this.props.taxdocument), this.props.signerData, clientGuid),
                        () => { this.onTaxClientMobileUpdateSuccess(taxDocument.clientType, taxDocumentClient.clientId) });
                });

            this.setState({ showChangeNumberPopUp: false });
            this.setState({ showMyAccountPopUp: false });
        } 
    }

    onSaveNumberClick = (changedMobileNumber: string, changedCountryCode: string) => {
        if (this.props.headerInfo.loggedInUserInfo.role.toString() === Role[Role.CPA].toString()) {
            this.onHideChangeNumberPopUp();
        }
        else {

            if (validatePhone(changedMobileNumber) && validateCountryCode(changedCountryCode)) {

                const taxDocument = this.props.taxdocument;
                const taxDocumentClient: any = this.props.match.params;

                const clientId: number = taxDocument && (taxDocument.clientType == ClientType.Taxpayer && isIndividual(taxDocument)) ? taxDocument.taxpayer.id :
                    taxDocument && (taxDocument.clientType == ClientType.Spouse && isMutual(taxDocument)) ? taxDocument.spouse.id :
                        taxDocument && (taxDocument.clientType == ClientType.Taxpayer && isMutual(taxDocument)) ? taxDocument.taxpayer.id :
                            taxDocument && (taxDocument.clientType == ClientType.Partnership && isPartnership(taxDocument)) ? taxDocument.partnership.id : 0;

                const ssnValue: string = taxDocument && (taxDocument.clientType == ClientType.Taxpayer && isIndividual(taxDocument)) ? taxDocument.taxpayer.ssn :
                    taxDocument && (taxDocument.clientType == ClientType.Spouse && isMutual(taxDocument)) ? taxDocument.spouse.ssn :
                        taxDocument && (taxDocument.clientType == ClientType.Taxpayer && isMutual(taxDocument)) ? taxDocument.taxpayer.ssn :
                            taxDocument && (taxDocument.clientType == ClientType.Partnership && isPartnership(taxDocument)) ? taxDocument.partnership.ssn : "";

                this.setState({ mobileNumber: changedMobileNumber, countryCode: changedCountryCode },
                    () => {
                        this.props.updateMobileNumber(taxDocumentClient.clientId, clientId.toString(), this.state.mobileNumber,
                            this.state.countryCode, ssnValue, taxDocument.clientType, () => { this.onTaxClientMobileUpdateSuccess(taxDocument.clientType, taxDocumentClient.clientId) });
                    });

                if (taxDocument.clientType == ClientType.Taxpayer && isIndividual(taxDocument)) {
                    taxDocument.taxpayer.mobileNumber = changedMobileNumber;
                    taxDocument.taxpayer.countryCode = changedCountryCode;
                    this.props.updateTaxDocument(taxDocument);
                }
                else if (taxDocument.clientType == ClientType.Partnership && isPartnership(taxDocument)) {
                    taxDocument.partnership.mobileNumber = changedMobileNumber;
                    taxDocument.partnership.countryCode = changedCountryCode;
                    this.props.updateTaxDocument(taxDocument);
                }
                else if (taxDocument.clientType == ClientType.Spouse && isMutual(taxDocument)) {
                    taxDocument.spouse.mobileNumber = changedMobileNumber;
                    taxDocument.spouse.countryCode = changedCountryCode;
                    this.props.updateTaxDocument(taxDocument);
                }
                else if (taxDocument.clientType == ClientType.Taxpayer && isMutual(taxDocument)) {
                    taxDocument.taxpayer.mobileNumber = changedMobileNumber;
                    taxDocument.taxpayer.countryCode = changedCountryCode;
                }
                this.setState({ showChangeNumberPopUp: false });
            }            
        }
    }

    onChangeMobileNumberClick = () => {
        this.setState({
            showChangeNumberPopUp: true,
        })
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.Open} Change Number layout`,
                { count: 1, page: "Change Number layout" }
            )
        );
    }

    onTaxClientMobileUpdateSuccess = (clientType: ClientType, clientGuid: string) => {
        switch (clientType) {
            /* case ClientType.Taxpayer:
                 MarsNotifier.Success(MyAccountConstants.StatusMessage.EditSpouseDetails, null);
                 break;
             case ClientType.Spouse:
                 MarsNotifier.Success(MyAccountConstants.StatusMessage.EditTaxpayerDetails, null);
                 break;*/
            default:
                MarsNotifier.Success(MyAccountConstants.StatusMessage.UpdateInformation, null);
                this.props.refreshTaxDocument(clientGuid);
                break;
        }
    }

    private getLoggedInUserMobileNumber() {
        const taxDocument = this.props.taxdocument;
        if (taxDocument.clientType == ClientType.Taxpayer && isMutual(taxDocument)) {
            return ((taxDocument !== null && taxDocument !== undefined) &&
                (taxDocument.spouse !== null && taxDocument.spouse !== undefined) ?
                (taxDocument.spouse.mobileNumber !== null && taxDocument.spouse.mobileNumber !== undefined) ?
                    taxDocument.spouse.mobileNumber : "" : "");
        }
        else {
            return ((taxDocument !== null && taxDocument !== undefined) && isMutual(taxDocument) &&
                (taxDocument.taxpayer !== null && taxDocument.taxpayer !== undefined) ?
                (taxDocument.taxpayer.mobileNumber !== null && taxDocument.taxpayer.mobileNumber !== undefined) ?
                    taxDocument.taxpayer.mobileNumber : "" : "");
        }
    }

    handleValidationSuccessful = () => {
        this.setState({
            showAccessCodePopUp: false,
            screenShareInProgress: true
        });

        //call some function in the parent component to display that screen sharing is in progress
    }

    endScreenShareSession = () => {
        this.webrtcRef.onHangup();
        this.setState({ showAccessCodePopUp: true });
    }

    Logout = () => {
        const param: any = this.props.match.params;
        this.props.logout(param.controllerId ? param.controllerId : param.clientId, this.onLogOutCompletion);
    }

    onLogOutCompletion = (id: string) => {
        let param: any = this.props.match.params;
        const url = param.controllerId ? PathConstants.ControllerLogin + id : PathConstants.ClientLogin + id;
        this.props.history.push(url);
    }

    public render() {
        let param: any = this.props.match.params;
        let companyLogo = null;
        if (this.state.headerInfo.companyWhiteLogoPath == "") {
            companyLogo = <span className="company-name">{this.state.headerInfo.companyName}</span>;
        }
        else {
            companyLogo = <img className="logo-img" src={this.state.headerInfo.companyWhiteLogoPath} />;
        }

        let contactPersonFullName: string = "";
        const contactPerson = this.state.headerInfo.contactPerson;
        contactPersonFullName += contactPerson.firstName;
        if (contactPerson.middleName && contactPerson.middleName.length > 0) {
            contactPersonFullName += " " + contactPerson.middleName;
        }
        if (contactPerson.lastName && contactPerson.lastName.length > 0) {
            contactPersonFullName += " " + contactPerson.lastName;
        }

        const contactInfoPopover = (
            <Popover id="contactInfoPopover" placement="bottom">
                <h3 className="popover-header">Contact Information</h3>
                <div className="popover-body" data-test-auto="3464EE21-1DF8-4F1F-BE4B-D838ACE36298">
                    <div className='medium'>{contactPersonFullName} </div>
                    <div>
                        <strong>{utilities.formateFax(contactPerson.phoneNumber)}</strong>
                        {contactPerson && contactPerson.extension && contactPerson.extension.length > 0 && <strong>Ext: {contactPerson.extension}  </strong>}
                    </div>
                    <div className='mail'>
                        <a href={"mailto:" + contactPerson.email}>{contactPerson.email}</a>
                    </div>
                    {/*
                        this.props.match.url.search('screenshare') < 1 &&
                        <div>
                            <Link id="lnkAccessCodePopup" to={"#"} onClick={() => this.screenShareLink()} data-test-auto="CD23630C-42E3-4CF7-A9A9-5EB35C5B0C69">
                                <SvgIconRequestScreenShare className='modalIcon' />
                                <span style={{ verticalAlign: 'middle' }}>{this.state.screenShareInProgress ? " Stop Screen Share" : " Share Screen"}</span></Link>
                        </div>
                        */
                    }
                </div>
            </Popover>
        );
        const accountPopover = (
            <Popover id="accountPopover" placement="bottom-start">
                <div className="popover-body">
                    <ul className='account-menu'>
                        <li><a onClick={() => this.getMyaccount()} data-test-auto="65AD7EA3-7B05-43C9-B862-F079DE711606"><i className="fa fa-user ddl-icon"></i><span>My Account</span></a></li>
                        {
                            this.props.headerInfo && this.props.headerInfo.loggedInUserInfo.role.toString() !== Role[Role.CPA].toString() &&
                            <li><a onClick={() => this.Logout()} data-test-auto="767609CB-7931-49FF-A431-CBF72F015D70"><i className="fa fa-sign-out" style={{ marginRight: '14px' }}></i><span>Logout</span></a></li>
                        }
                    </ul>
                </div>
            </Popover>
        );
        //const taxYearPopover = (
        //    <Popover id="taxYearPopover" placement="bottom-start">
        //        <div className="popover-body">
        //            <ul className='taxYear-menu'>
        //                <li><a href="#"><span>2017</span></a></li>
        //                <li><a href="#"><span>2018</span></a></li>
        //                <li><a href="#"><span>2019</span></a></li>
        //                <li><a href="#"><span>2020</span></a></li>
        //            </ul>
        //        </div>
        //    </Popover>
        //);
        //const taxReturnPopover = (
        //    <Popover id="taxYearPopover" placement="bottom-start">
        //        <div className="popover-body">
        //            <ul className='taxReturn-menu'>
        //                <li><a href="#"><span>Michael Wilson</span></a></li>
        //                <li><a href="#"><span>G-FORM LLC</span></a></li>
        //                <li><a href="#"><span>SURETY AGENCY HOLDING COMPANY, LLC</span></a></li>
        //            </ul>
        //        </div>
        //    </Popover>
        //);

        return (
            <header className="app-header">
                <div className="header-left-container" data-test-auto="951602DF-76D9-480A-BA0F-D12E216FBB2B">

                    <input type="checkbox" className="openSidebarMenu" id="openSidebarMenu" />
                    <label htmlFor="openSidebarMenu" className="sidebarIconToggle">
                        <div className="spinner diagonal part-1"></div>
                        <div className="spinner horizontal"></div>
                        <div className="spinner diagonal part-2"></div>
                    </label>
                    <div id="sidebarMenu">
                        <ul className="sidebar navbar-nav">
                            {
                                //<li className="nav-item layout-dropdown show">
                                //<span className="nav-link layout-dropdown-toggle" role="button" data-toggle="layout-dropdown" aria-haspopup="true" aria-expanded="false">
                                //    <i className="fa fa-file margin-right-10"></i>  <span>Tax Return</span>
                                //</span>
                                //<div className="layout-dropdown-menu show" aria-labelledby="pageslayout-dropdown">
                                //    <a className="active layout-dropdown-item" href="#">Michael Wilson</a>
                                //    <a className="layout-dropdown-item" href="#">JACK ANDERSON</a>
                                //    <a className="layout-dropdown-item" href="#">JILL ANDERSON</a>
                                //</div>
                                //</li>
                            }
                            <li className="nav-item layout-dropdown mobile-header-taxYear show" data-test-auto="1D9B4F96-A523-4A04-AA58-AB62A9B1DD3A">
                                <span className="nav-link layout-dropdown-toggle" role="button" data-toggle="layout-dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fa fa-calendar margin-right-10"></i>
                                    <span>Tax Year</span></span>
                                <div className="layout-dropdown-menu show" aria-labelledby="pageslayout-dropdown">
                                    <a className="layout-dropdown-item active" >{this.state.headerInfo.taxYear}</a>
                                </div>
                            </li>
                            <li className="nav-item layout-dropdown show" data-test-auto="9E3149F1-20E9-48E8-9067-5E70360C410F">
                                <span className="nav-link layout-dropdown-toggle" role="button" data-toggle="layout-dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fa fa-cog margin-right-10"></i>
                                    <span >Account</span></span>
                                <div className="layout-dropdown-menu show" aria-labelledby="pageslayout-dropdown">
                                    <a className="layout-dropdown-item" onClick={() => this.getMyaccount()} data-test-auto="05558ACE-66E2-42FA-A41F-FF6E8AEC1881"><i className="fa fa-user ddl-icon headerForeColor"></i>My Account</a>
                                    <a className="layout-dropdown-item" onClick={() => this.Logout()} data-test-auto="A213D79D-034B-4264-8249-C1D438D433B3"><i className="fa fa-sign-out ddl-icon headerForeColor"></i>Logout</a>
                                </div>
                            </li>
                        </ul>
                    </div>

                    {companyLogo}

                </div>
                <div
                    className="header-right-container"
                    data-test-auto="710F957F-072E-4A27-867F-0B40C2077D3B">

                    <span className="header-account">
                        <OverlayTrigger
                            data-test-auto="D8169D39-B5BF-4166-B3E7-DA97E9F7AECB"
                            rootClose trigger="click"
                            onEnter={() => document.body.click()}
                            overlay={accountPopover}
                            placement="bottom">
                            <a>
                                <SvgIconAccountHeader
                                    fillColor="var(--headerForeColor)"
                                    height={20} />
                                {this.state.headerInfo.clientName}
                            </a>
                        </OverlayTrigger>
                    </span>

                    <span className="header-contact-info" title="Contact Person's Information">
                        <OverlayTrigger
                            data-test-auto="CB7E32C5-518D-40B5-8E8F-E75C9A89CF58"
                            rootClose trigger="click"
                            onEnter={() => document.body.click()}
                            overlay={contactInfoPopover}
                            placement="bottom">
                            <a>
                                <SvgIconContactHeader
                                    fillColor="var(--headerForeColor)"
                                    height={20} />
                                Contact Person
                             </a>
                        </OverlayTrigger>
                    </span>

                    <span className="header-taxYear">
                        {/*<OverlayTrigger rootClose trigger="click" onEnter={() => document.body.click()} overlay={taxYearPopover} placement="bottom">*/}
                        <a data-test-auto="7948215D-22E7-431D-B478-43E85FF56A63">
                            <SvgIconCalendarHeader
                                fillColor="var(--headerForeColor)"
                                height={20} />
                            Tax year: {this.state.headerInfo.taxYear}
                        </a>
                        {/*</OverlayTrigger>*/}
                    </span>


                    {
                        //    <span className="header-taxReturn">
                        //    {/* <OverlayTrigger rootClose trigger="click" onEnter={() => document.body.click()}  overlay={taxReturnPopover} placement="bottom">*/}
                        //    <a><i className="fa fa-file"></i> Tax Return: Michael Wilson</a>
                        //    {/*</OverlayTrigger>*/}
                        //</span>
                    }

                    <MyAccount
                        key="value"
                        onHide={this.onCancelMyAccountPopUp}
                        showState={this.state.showMyAccountPopUp}
                        onChangeNumberClick={this.onChangeMobileNumberClick}
                        onSaveButtonClick={this.onUpdateDetails}
                        taxDocument={this.props.taxdocument}
                        partnerShip={this.getPartnerShip()}
                        taxPayer={this.getTaxPayerInfo()}
                        spouse={this.getSpouseInfo()}
                    />
                    {this.state.showAccessCodePopUp &&
                        <AccessCode
                            key="value"
                            onHide={this.onCancelAccessCodePopUp}
                            showState={this.state.showAccessCodePopUp}
                            onScreenSharingStart={this.props.validateTimeBasedAccessCode}
                            onValidationSuccessful={this.handleValidationSuccessful}
                            inProgress={this.state.screenShareInProgress}
                            taxDocument={this.props.taxdocument}
                            match={this.props.match}
                        />
                    }

                    <ChangeNumber
                        onHideChangeNumberPopUp={this.onHideChangeNumberPopUp}
                        showState={this.state.showChangeNumberPopUp}
                        mobileNumber={this.getMobileNumber()}
                        countryCode={this.getCountryCode()}
                        onSaveNumberClick={this.onSaveNumberClick}
                    />
                    {
                        this.state.screenShareInProgress &&
                        //load a signalr component for webrtc
                        <WebrtcSignalR
                            ref={ref => this.webrtcRef = ref}
                            isDebugEnabled={false}
                            match={this.props.match}
                            isCPAMode={false}
                            handleRemoveStream={() => { this.setState({ showAccessCodePopUp: true }) }}
                            getIceServers={this.props.getIceServers}
                            iceServers={this.props.iceServers}
                        />
                    }
                </div>
            </header>);
    }
}
