import { AxiosResponse } from 'axios';
import { Action, Reducer } from 'redux';
import { actionTypes } from '../ActionTypes';
import { NotificationAction, StatusType } from '../Common/NotificationStore';
import { AppThunkAction } from '../index';
import { ITutorialInfo, initialTutorialInfo } from '../../core/domain/models/ITutorialInfo';
import { ErrorMessages } from '../../components/Common/Constants';
import { TelemetryLogger } from '../../components/Logger/AppInsights';
import { initializeAxios } from 'src/core/services/dataAccess/DataService.Axios';

const logger = TelemetryLogger.getInstance();


interface RequestTutorialInfoAction {
	type: actionTypes.TUTORIAL_INFO_REQUEST;
}

interface ResponseTutorialInfoAction {
	type: actionTypes.TUTORIAL_INFO_RESPONSE;
	data: ITutorialInfo;
}

interface RequestHideSummaryTutorialAction {
	type: actionTypes.HIDE_SUMMARY_TUTORIAL_REQUEST;
}

interface ResponseHideSummaryTutorialAction {
	type: actionTypes.HIDE_SUMMARY_TUTORIAL_RESPONSE;
}

interface RequestHideReviewTutorialAction {
	type: actionTypes.HIDE_REVIEW_TUTORIAL_REQUEST;
}

interface ResponseHideReviewTutorialAction {
	type: actionTypes.HIDE_REVIEW_TUTORIAL_RESPONSE;
}

type KnownAction =
	DispatchAction |
	NotificationAction;

type DispatchAction =
	RequestHideReviewTutorialAction
	| RequestHideSummaryTutorialAction
	| RequestTutorialInfoAction
	| ResponseHideReviewTutorialAction
	| ResponseHideSummaryTutorialAction
	| ResponseTutorialInfoAction

export const actionCreators = {
	requestTutorialInfo: (clientGuid: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const state = getState();
		//dispatch({ type: actionTypes.TUTORIAL_INFO_REQUEST });

		return initializeAxios().get<ITutorialInfo>('/api/Tutorial/GetTutorialInfoAsync/' + clientGuid)
			.then(function (response: AxiosResponse<ITutorialInfo>) {
				dispatch({
					type: actionTypes.TUTORIAL_INFO_RESPONSE, data: response.data
				});
			})
			.catch(function (error: any) {
				dispatch({
					type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : ErrorMessages.GetTutorialInfoError,
					statusType: StatusType.Error
				});
				logger.trackError(`requestTutorialInfo failed with error ${error.message} for client: ${clientGuid}`);
			});
	},

	requestTutorialInfoPreview: (clientGuid: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
		dispatch({
			type: actionTypes.TUTORIAL_INFO_RESPONSE, data: {
				clientId: clientGuid,
				hideReviewTutorial: true,
				hideSummaryTutorial:true,
			}
		});
	},

	hideSummaryTutorial: (clientGuid: string,doNotShowNextTime:boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const state = getState();
		//dispatch({ type: actionTypes.HIDE_SUMMARY_TUTORIAL_REQUEST });
		if (doNotShowNextTime) {
			return initializeAxios().get<ITutorialInfo>('/api/Tutorial/HideSummaryTutorialAsync/' + clientGuid)
				.then(function (response: AxiosResponse<ITutorialInfo>) {
					dispatch({
						type: actionTypes.HIDE_SUMMARY_TUTORIAL_RESPONSE,
					});
				})
				.catch(function (error: any) {
					dispatch({
						type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : ErrorMessages.HideSummaryTutorialError,
						statusType: StatusType.Error
					});
					logger.trackError(`hideSummaryTutorial failed with error ${error.message} for client: ${clientGuid}`);
				});
		}
		else {
			dispatch({
				type: actionTypes.HIDE_SUMMARY_TUTORIAL_RESPONSE,
			});
		}
	},

	hideSummaryTutorialPreview: (clientGuid: string, doNotShowNextTime: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
		dispatch({
			type: actionTypes.HIDE_SUMMARY_TUTORIAL_RESPONSE,
		});
	},

	hideReviewTutorial: (clientGuid: string, doNotShowNextTime: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const state = getState();
		//dispatch({ type: actionTypes.HIDE_REVIEW_TUTORIAL_REQUEST });
		if (doNotShowNextTime) {
			return initializeAxios().get<ITutorialInfo>('/api/Tutorial/HideReviewTutorialAsync/' + clientGuid)
				.then(function (response: AxiosResponse<ITutorialInfo>) {
					dispatch({
						type: actionTypes.HIDE_REVIEW_TUTORIAL_RESPONSE,
					});
				})
				.catch(function (error: any) {
					dispatch({
						type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : ErrorMessages.HideSummaryTutorialError,
						statusType: StatusType.Error
					});
					logger.trackError(`hideReviewTutorial failed with error ${error.message} for client: ${clientGuid}`);
				});
		}
		else {
			dispatch({
				type: actionTypes.HIDE_REVIEW_TUTORIAL_RESPONSE,
			});
		}
	},

	hideReviewTutorialPreview: (clientGuid: string, doNotShowNextTime: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
		dispatch({
			type: actionTypes.HIDE_REVIEW_TUTORIAL_RESPONSE,
		});
	}
}

export const reducer: Reducer<ITutorialInfo> = (state: ITutorialInfo = initialTutorialInfo, incomingAction: Action) => {
	const action = incomingAction as DispatchAction;
	const currentState = Object.assign({}, state);
	switch (action.type) {
		case actionTypes.TUTORIAL_INFO_REQUEST:
			return initialTutorialInfo;
		case actionTypes.TUTORIAL_INFO_RESPONSE:
			return action.data;
		case actionTypes.HIDE_REVIEW_TUTORIAL_REQUEST:
			currentState.hideReviewTutorial = undefined;
			return currentState;
		case actionTypes.HIDE_REVIEW_TUTORIAL_RESPONSE:
			currentState.hideReviewTutorial = true;
			return currentState;
		case actionTypes.HIDE_SUMMARY_TUTORIAL_REQUEST:
			currentState.hideSummaryTutorial = undefined;
			return currentState;
		case actionTypes.HIDE_SUMMARY_TUTORIAL_RESPONSE:
			currentState.hideSummaryTutorial = true;
			return currentState;
		default:
			return currentState || initialTutorialInfo;
	}
}