import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { actionTypes } from '../ActionTypes';
import { StatusType, NotificationAction } from '../Common/NotificationStore';
import { AxiosResponse } from 'axios';
import { ErrorMessages } from '../../components/Common/Constants';
import { IAccessCodeValidation } from '../../core/domain/viewModels/ICommon';
import { TelemetryLogger } from '../../components/Logger/AppInsights';
import { ITaxingAuthority } from 'src/core/domain/models/ITaxingAuthority';
import { initializeAxios } from 'src/core/services/dataAccess/DataService.Axios';
import { ICommonDataViewModel, initialCommonData } from 'src/core/domain/viewModels/ICommonDataViewModel';

const logger = TelemetryLogger.getInstance();

interface RequestTaxingAuthorityAction {
    type: actionTypes.TAXING_AUTHORITY_REQUEST;
    id: string;
}

interface ResponseTaxingAuthorityAction {
    type: actionTypes.TAXING_AUTHORITY_RESPONSE;
    data: ITaxingAuthority[];
}

interface FailureTaxingAuthorityAction {
    type: actionTypes.TAXING_AUTHORITY_FAILURE;
    id: string;
}

interface RequestIceServerAction {
    type: actionTypes.ICE_SERVER_REQUEST;
    id: string;
}

interface ResponseIceServerAction {
    type: actionTypes.ICE_SERVER_RESPONSE;
    data: RTCIceServer[];
}

interface FailureIceServerAction {
    type: actionTypes.ICE_SERVER_FAILURE;
    id: string;
}

type KnownAction =
    DispatchAction |
    NotificationAction;

type DispatchAction = ResponseTaxingAuthorityAction
    | RequestTaxingAuthorityAction
    | FailureTaxingAuthorityAction
    | RequestIceServerAction
    | ResponseIceServerAction
    | FailureIceServerAction;

export const actionCreators = {

    requestAllTaxingAuthorities: (id: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();
        dispatch({ type: actionTypes.TAXING_AUTHORITY_REQUEST, id: id });
        return initializeAxios().get<ITaxingAuthority[]>('/api/Helper/GetAllTaxingAuthorityAsync/' + id)
            .then(function (response: AxiosResponse<ITaxingAuthority[]>) {

                dispatch({
                    type: actionTypes.TAXING_AUTHORITY_RESPONSE, data: response.data
                });

            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : ErrorMessages.TaxingAuthorityError,
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.TAXING_AUTHORITY_FAILURE, id: id });
                logger.trackError(`requestAllTaxingAuthorities failed with error ${error.message} for client: ${id}`);
            });
    },
    validateTimeBasedAccessCode: (accessCode: IAccessCodeValidation, callback: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const options = {
            headers: {
                'Accept': 'application/json, text/plain, *',
                'Content-Type': 'application/json; charset=utf-8'
            }
        };
        return initializeAxios().post<boolean>('/api/Helper/ValidateTimeBasedAccessCode/' + accessCode.clientGuid, accessCode, options)
            .then(function (response: AxiosResponse<boolean>) {
                if (response.data) {
                    callback();
                }
                else {
                    dispatch({
                        type: actionTypes.NOTIFICATION, statusMessage: ErrorMessages.CodeValidationFailed,
                        statusType: StatusType.Warning
                    });
                }

            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : ErrorMessages.CodeValidationError,
                    statusType: StatusType.Error
                });
                logger.trackError(`validateTimeBasedAccessCode failed with error ${error.message} for client: ${accessCode.clientGuid}`);
            });
    },
    getIceServers: (id: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();
        dispatch({ type: actionTypes.ICE_SERVER_REQUEST, id: id });
        return initializeAxios().get<RTCIceServer[]>('/api/Helper/GetIceServers/' + id)
            .then(function (response: AxiosResponse<RTCIceServer[]>) {

                dispatch({
                    type: actionTypes.ICE_SERVER_RESPONSE, data: response.data
                });

            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : ErrorMessages.TaxingAuthorityError,
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.ICE_SERVER_FAILURE, id: id });
                logger.trackError(`getIceServers failed with error ${error.message} for client: ${id}`);
            });
    },
    getWalkMeScript: (id: string, callback: (script: string) => void, isAuthorized?: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let baseUri = 'api/Coverpage/GetWalkMeScript/';

        if (isAuthorized) {
            baseUri = 'api/Helper/GetWalkMeScriptAsync/'
        }

        return initializeAxios().get<string>(`${baseUri}${id}`)
            .then(function (response: AxiosResponse<string>) {
                callback(response.data);
            })
            .catch(function (error: any) {
                logger.trackError(`getWalkMeScriptAsync failed with error ${error.message} for client: ${id}`);
            });
    }
}

export const reducer: Reducer<ICommonDataViewModel> = (state: ICommonDataViewModel = initialCommonData, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    const data = Object.assign({}, state);
    switch (action.type) {
        case actionTypes.TAXING_AUTHORITY_REQUEST:
            return data;
        case actionTypes.TAXING_AUTHORITY_RESPONSE:
            data.taxingAuthorities = action.data;
            return data;
        case actionTypes.ICE_SERVER_REQUEST:
            return data;
        case actionTypes.ICE_SERVER_RESPONSE:
            data.iceServers = action.data;
            return data;
        default:
            return state || initialCommonData;
    }
};