export enum actionTypes {
    NOTIFICATION,
    HEADERINFO_REQUEST,
    HEADERINFO_RESPONSE,
    HEADERINFO_FAILURE,

    ESIGN_REQUEST,
    ESIGN_RESPONSE,
    ESIGN_FAILURE,

    TAXING_AUTHORITY_REQUEST,
    TAXING_AUTHORITY_RESPONSE,
    TAXING_AUTHORITY_FAILURE,

    SUMMARY_DETAILS_REQUEST,
    SUMMARY_DETAILS_RESPONSE,
    SUMMARY_DETAILS_FAILURE,

    DOCUMENT_SETTINGS_REQUEST,
    DOCUMENT_SETTINGS_RESPONSE,
    DOCUMENT_SETTINGS_FAILURE,

    DOCUMENT_REVIEW_MODEL_REQUEST,
    DOCUMENT_REVIEW_MODEL_RESPONSE,
    DOCUMENT_REVIEW_MODEL_FAILURE,

    UPDATE_DOCUMENT_SIGNATURE_SETTINGS_REQUEST,
    UPDATE_DOCUMENT_SIGNATURE_SETTINGS_ERROR,

    SIGNATURE_CONTROLS_DATA_REQUEST,
    SIGNATURE_CONTROLS_DATA_RESPONSE,
    SIGNATURE_CONTROLS_DATA_FAILURE,

    //ClientAuthentication
    CA_REQUEST,
    CA_RESPONSE,
    CA_FAILURE,

    //SkipAuthentication
    SA_REQUEST,
    SA_RESPONSE,
    SA_FAILURE,

    SIGN_SUBMIT_REQUEST,
    SIGN_SUBMIT_RESPONSE,
    SIGN_SUBMIT_FAILURE,

    TAXDOCUMENT_REQUEST,
    TAXDOCUMENT_RESPONSE,
    TAXDOCUMENT_FAILURE,


    MANUAL_SIGN_REQUEST,
    MANUAL_SIGN_RESPONSE,
    MANUAL_SIGN_FAILURE,
    MANUAL_SIGN_CACHE,


    COMPANY_INFO_REQUEST,
    COMPANY_INFO_RESPONSE,
    COMPANY_INFO_FAILURE,
    COMPANY_INFO_CACHE,

    TAXDOCUMENT_CLIENT_TYPE_REQUEST,
    TAXDOCUMENT_CLIENT_TYPE_RESPONSE,
    TAXDOCUMENT_CLIENT_TYPE_FAILURE,

    UPDATE_TAXCLIENT_MOBILE_NUMBER_REQUEST,
    UPDATE_TAXCLIENT_MOBILE_NUMBER_RESPONSE,
    UPDATE_SPOUSE_MOBILE_NUMBER_RESPONSE,

    SIGNER_REQUEST,
    SIGNER_RESPONSE,
    SIGNER_FAILURE,

    PAYSCREEN_DETAILS_REQUEST,
    PAYSCREEN_DETAILS_RESPONSE,
    PAYSCREEN_DETAILS_FAILURE,

    K1DISTRIBUTE_REQUEST,
    K1DISTRIBUTE_RESPONSE,
    K1DISTRIBUTE_FAILURE,
    K1DISTRIBUTE_UPDATE,

    DOWNLOADABLE_DOCUMENTS_REQUEST,
    DOWNLOADABLE_DOCUMENTS_RESPONSE,
    DOWNLOADABLE_DOCUMENTS_FAILURE,

    SIGN_PROCESS_REQUEST,
    SIGN_PROCESS_RESPONSE,
    SIGN_PROCESS_FAILURE,
    SIGN_PROCESS_STEP,
    UPDATE_LAST_VISITED_PROCESS_STEP,

    NEXT_SIGNER_REQUEST,
    NEXT_SIGNER_RESPONSE,
    NEXT_SIGNER_FAILURE,

    SEND_SIGNING_REMINDER_REQUEST,
    SEND_SIGNING_REMINDER_RESPONSE,
    SEND_SIGNING_REMINDER_FAILURE,

    //My Downloads
    MY_DOWNLOAD_REQUEST,
    MY_DOWNLOAD_RESPONSE,
    MY_DOWNLOAD_FAILURE,

    PREVIEW_DOCUMENTS_REQUEST,
    PREVIEW_DOCUMENTS_RESPONSE,
    PREVIEW_DOCUMENTS_FAILURE,

    TUTORIAL_INFO_REQUEST,
    TUTORIAL_INFO_RESPONSE,

    HIDE_REVIEW_TUTORIAL_REQUEST,
    HIDE_REVIEW_TUTORIAL_RESPONSE,

    HIDE_SUMMARY_TUTORIAL_REQUEST,
    HIDE_SUMMARY_TUTORIAL_RESPONSE,

    ICE_SERVER_REQUEST,
    ICE_SERVER_RESPONSE,
    ICE_SERVER_FAILURE,

    ADDITIONAL_ESIGN_DOCUMENT_REQUEST,
    ADDITIONAL_ESIGN_DOCUMENT_RESPONSE,

    CCRECIPIENT_DOWNLOADABLE_DOCUMENTS_REQUEST,
    CCRECIPIENT_DOWNLOADABLE_DOCUMENTS_RESPONSE,
    CCRECIPIENT_DOWNLOADABLE_DOCUMENTS_FAILURE,
    CCRECIPIENT_DOCUMENTS_FOR_DOWNLOAD_REQUEST,
    CCRECIPIENT_DOCUMENTS_FOR_DOWNLOAD_RESPONSE,
    CCRECIPIENT_DOCUMENTS_FOR_DOWNLOAD_FAILURE,

    RECIPIENT_HEADERINFO_REQUEST,
    RECIPIENT_HEADERINFO_RESPONSE,
    RECIPIENT_HEADERINFO_FAILURE,

    VOUCHER_PAYMENT_REQUEST,
    VOUCHER_PAYMENT_RESPONSE,
    VOUCHER_PAYMENT_FAILURE,

    SIGNING_REQUIRED_FOR_SIGNER,
    SIGNING_REQUIRED_FOR_DOCUMENT,
    FILING_FORMS_EXISTS,
    NEXT_SIGNER_EXISTS,

    DELEGATEE_DOCUMENT_REVIEW_MODEL_REQUEST,
    DELEGATEE_DOCUMENT_REVIEW_MODEL_RESPONSE,
    DELEGATEE_DOCUMENT_REVIEW_MODEL_FAILURE,

    DELEGATEE_DETAILS_REQUEST,
    DELEGATEE_DETAILS_RESPONSE,
    DELEGATEE_DETAILS_FAILURE,

    DELEGATEE_HEADERINFO_REQUEST,
    DELEGATEE_HEADERINFO_RESPONSE,
    DELEGATEE_HEADERINFO_FAILURE,

    DELEGATEE_SIGNATURE_CONTROLS_DATA_REQUEST,
    DELEGATEE_SIGNATURE_CONTROLS_DATA_RESPONSE,
    DELEGATEE_SIGNATURE_CONTROLS_DATA_FAILURE,

    DELEGATEE_MANUAL_SIGN_REQUEST,
    DELEGATEE_MANUAL_SIGN_RESPONSE,
    DELEGATEE_MANUAL_SIGN_FAILURE,

    DELEGATEE_DOWNLOADABLE_DOCUMENTS_REQUEST,
    DELEGATEE_DOWNLOADABLE_DOCUMENTS_RESPONSE,
    DELEGATEE_DOWNLOADABLE_DOCUMENTS_FAILURE,

    DELEGATEE_UPDATE_PHONE_NUMBER_REQUEST,
    DELEGATEE_UPDATE_PHONE_NUMBER_RESPONSE,
    DELEGATEE_UPDATE_PHONE_NUMBER_FAILURE,

    TAXCLIENT_DELEGATEE_DETAILS_REQUEST,
    TAXCLIENT_DELEGATEE_DETAILS_RESPONSE,
    TAXCLIENT_DELEGATEE_DETAILS_FAILURE,

    UPDATE_DELEGATEE_DETAILS_REQUEST,
    UPDATE_DELEGATEE_DETAILS_RESPONSE,
    UPDATE_DELEGATEE_DETAILS_FAILURE,

    DELEGATEE_KBA_STATUS_REQUEST,
    DELEGATEE_KBA_STATUS_RESPONSE,
    DELEGATEE_KBA_STATUS_FAILURE,

    DELEGATEE_KBA_AUTH_REQUEST,
    DELEGATEE_KBA_AUTH_RESPONSE,
    DELEGATEE_KBA_AUTH_FAILURE,

    SAVE_DELEGATEE_CONSENT_DETAILS_RESPONSE,
    SAVE_DELEGATEE_CONSENT_DETAILS_FAILURE,

    RECEIVE_COVER_PAGE_DETAILS,
    COVER_PAGE_LOADER,

    RECEIVE_SSN_PAGE_DETAILS,
    SSN_PAGE_LOADER,

    OTP_PAGE_LOADER,
    SET_MOBILE_OTP_PAGE_DATA,

    RECEIVE_SENDER_NAME,
    RECEIVE_HEADER_DETAILS,
    HEADER_LOADER,

    DELEGATEE_OTP_PAGE_LOADER,
    RECEIVE_DELEGATEE_OTP_PAGE_DETAILS,

    CCRECIPIENT_OTP_PAGE_LOADER,
    RECEIVE_CCRECIPIENT_OTP_PAGE_DETAILS,

    SET_ERROR_MESSAGE,
    RELATED_TAXRETURN_DELIVERED,
    PAYMENT_ALERT_TOGGLE
}
