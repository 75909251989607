import * as React from 'react';
import { Body } from '../Common/StepLayout/Body';
import { StepLayout } from '../Common/StepLayout/StepLayout';
import { logger } from '../../routes';
import { LogEventConstants } from '../Helper/Constants';

interface SignatureCompletedPageProps {
    companyName: string;
}
const pageTitle = "Signature Complete page";

export class SignatureCompletedPage extends React.Component<SignatureCompletedPageProps, {}>
{
    constructor(props: any) {
        super(props);
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.Open} ${pageTitle}`,
                { count: 1, page: pageTitle }
            )
        );
    }

    render() {
        return (
            <StepLayout>

                <Body lg={10} md={12} sm={12} xs={12} title={"Signing Completed"}
                    automationTestId={"4903FAD2-DEA0-423F-82E5-22D85B6BD89B"} containerClassName={"signing-completed-wrapper"}>
           
                <br />
                <div className="content-paragraph" data-test-auto="58C9B25A-1816-44A7-BF26-EE614AFC1C24">All documents have been successfully signed and will be forwarded to
                <br />
                        <span data-test-auto="58C9B25ABF26EE614AFC1C24" className="font-weight-normal-bold">{this.props.companyName}</span>
                </div>
                    
                </Body>
            </StepLayout>
        )
    }
}

export default SignatureCompletedPage;