import * as TaxDocument from '../../core/domain/models/ITaxReturn'
import { ClientType, DocumentStatus, Role } from '../../core/common/Enums';
import { ClientTypesNumber, ISignerModel } from '../../core/domain/models/ISignerModel';
import { IClientProcessViewModel } from '../../core/domain/viewModels/IClientProcessViewModel'
import { PathConstants, DelegateePathConstants } from '../Common/Constants';
import { SignProcessSteps } from '../Home/TaxpayerSignFlowManager';
import { Permissions } from '../../components/Common/Constants';
import { ClientTypes } from '../../core/domain/models/ITaxClient';

const NO_INDEX = -1;

export default class TaxpayerHelper {

    static isMarried = (taxReturn: TaxDocument.ITaxReturn) => {
        return (TaxDocument.isMutual(taxReturn) &&
            taxReturn != null &&
            taxReturn != undefined &&
            taxReturn.spouse != null &&
            taxReturn.spouse != undefined) ? true : false;
    }

    static getFirstSignerId = (taxReturn: TaxDocument.IMarriedJointTaxReturn, signerData: ISignerModel[]) => {
        return signerData && signerData.length > 0 ? signerData[0].clientGuid : taxReturn.taxpayer && taxReturn.taxpayer.clientGuid ? taxReturn.taxpayer.clientGuid : "";
    }

    static isFirstSignerLoggedIn = (taxReturn: TaxDocument.IMarriedJointTaxReturn, signerData: ISignerModel[], loggedInUserGuid: string) => {
        const firstSignerId = TaxpayerHelper.getFirstSignerId(taxReturn, signerData);
        return loggedInUserGuid === firstSignerId;
    }

    static isTaxpayerLoggedIn = (taxReturn: TaxDocument.IMarriedJointTaxReturn, signerData: ISignerModel[], loggedInUserGuid: string) => {
        const firstSignerId = TaxpayerHelper.getFirstSignerId(taxReturn, signerData);
        if (!TaxpayerHelper.isMarried(taxReturn)) {
            return true;
        }
        else {
            if (taxReturn.taxpayer.clientGuid === loggedInUserGuid && taxReturn.taxpayer.clientGuid === firstSignerId) {
                return true;
            }
            else if (taxReturn.spouse.clientGuid === loggedInUserGuid && taxReturn.spouse.clientGuid === firstSignerId) {
                return false;
            }
            else {
                return true;
            }
        }
    }

    static getSecondSignerType(taxReturn: TaxDocument.IMarriedJointTaxReturn, signerData: ISignerModel[], loggedInUserGuid: string) {
        const firstSignerId = TaxpayerHelper.getFirstSignerId(taxReturn, signerData);
        if (!TaxpayerHelper.isMarried(taxReturn)) {
            return ClientType.Undefined;
        }
        else {
            if (taxReturn.taxpayer.clientGuid === loggedInUserGuid && taxReturn.taxpayer.clientGuid === firstSignerId) {
                return ClientType.Spouse;
            }
            else if (taxReturn.spouse.clientGuid === loggedInUserGuid && taxReturn.spouse.clientGuid === firstSignerId) {
                return ClientType.Taxpayer;
            }
            else {
                return ClientType.Undefined;
            }
        }
    }

    static getSecondSignerId = (taxReturn: TaxDocument.IMarriedJointTaxReturn, signerData: ISignerModel[], loggedInUserGuid: string) => {
        const firstSignerId = TaxpayerHelper.getFirstSignerId(taxReturn, signerData);
        if (!TaxpayerHelper.isMarried(taxReturn)) {
            return 0;
        }
        else {
            if (taxReturn.taxpayer.clientGuid === loggedInUserGuid && taxReturn.taxpayer.clientGuid === firstSignerId) {
                return taxReturn.spouse.id;
            }
            else if (taxReturn.spouse.clientGuid === loggedInUserGuid && taxReturn.spouse.clientGuid === firstSignerId) {
                return taxReturn.taxpayer.id;
            }
            else {
                return 0;
            }
        }
    }

    static getSecondSignerEmail(taxReturn: TaxDocument.IMarriedJointTaxReturn, signerData: ISignerModel[], loggedInUserGuid: string) {
        const firstSignerId = TaxpayerHelper.getFirstSignerId(taxReturn, signerData);
        if (!TaxpayerHelper.isMarried(taxReturn)) {
            return "";
        }
        else {
            if (taxReturn.taxpayer.clientGuid === loggedInUserGuid && taxReturn.taxpayer.clientGuid === firstSignerId) {
                return taxReturn.spouse.email;
            }
            else if (taxReturn.spouse.clientGuid === loggedInUserGuid && taxReturn.spouse.clientGuid === firstSignerId) {
                return taxReturn.taxpayer.email;
            }
            else {
                return "";
            }
        }
    }
    static getSecondSignerMobileData(taxReturn: TaxDocument.IMarriedJointTaxReturn, signerData: ISignerModel[], loggedInUserGuid: string) {
        const firstSignerId = TaxpayerHelper.getFirstSignerId(taxReturn, signerData);
        if (!TaxpayerHelper.isMarried(taxReturn)) {
            return {};
        }
        else {
            if (taxReturn.taxpayer.clientGuid === loggedInUserGuid && taxReturn.taxpayer.clientGuid === firstSignerId) {
                return { mobileNumber: taxReturn.spouse.mobileNumber, countryCode: taxReturn.spouse.countryCode };
            }
            else if (taxReturn.spouse.clientGuid === loggedInUserGuid && taxReturn.spouse.clientGuid === firstSignerId) {
                return { mobileNumber: taxReturn.taxpayer.mobileNumber, countryCode: taxReturn.taxpayer.countryCode };
            }
            else {
                return {};
            }
        }
    }

    static getSecondSignerMobileNumber(taxReturn: TaxDocument.IMarriedJointTaxReturn, signerData: ISignerModel[], loggedInUserGuid: string) {
        const firstSignerId = TaxpayerHelper.getFirstSignerId(taxReturn, signerData);
        if (!TaxpayerHelper.isMarried(taxReturn)) {
            return "";
        }
        else {
            if (taxReturn.taxpayer.clientGuid === loggedInUserGuid && taxReturn.taxpayer.clientGuid === firstSignerId) {
                return taxReturn.spouse.mobileNumber;
            }
            else if (taxReturn.spouse.clientGuid === loggedInUserGuid && taxReturn.spouse.clientGuid === firstSignerId) {
                return taxReturn.taxpayer.mobileNumber;
            }
            else {
                return "";
            }
        }
    }

    static getSecondSignerCountryCode(taxReturn: TaxDocument.IMarriedJointTaxReturn, signerData: ISignerModel[], loggedInUserGuid: string) {
        const firstSignerId = TaxpayerHelper.getFirstSignerId(taxReturn, signerData);
        if (!TaxpayerHelper.isMarried(taxReturn)) {
            return "";
        }
        else {
            if (taxReturn.taxpayer.clientGuid === loggedInUserGuid && taxReturn.taxpayer.clientGuid === firstSignerId) {
                return taxReturn.spouse.countryCode;
            }
            else if (taxReturn.spouse.clientGuid === loggedInUserGuid && taxReturn.spouse.clientGuid === firstSignerId) {
                return taxReturn.taxpayer.countryCode;
            }
            else {
                return "";
            }
        }
    }

    static getSecondSignerName(taxReturn: TaxDocument.IMarriedJointTaxReturn, signerData: ISignerModel[], loggedInUserGuid: string) {
        const firstSignerId = TaxpayerHelper.getFirstSignerId(taxReturn, signerData);
        if (!TaxpayerHelper.isMarried(taxReturn)) {
            return "";
        }
        else {
            if (taxReturn.taxpayer.clientGuid === loggedInUserGuid && taxReturn.taxpayer.clientGuid === firstSignerId) {
                return taxReturn.spouse.name;
            }
            else if (taxReturn.spouse.clientGuid === loggedInUserGuid && taxReturn.spouse.clientGuid === firstSignerId) {
                return taxReturn.taxpayer.name;
            }
            else {
                return "";
            }
        }
    }

    static isNextSignerDeceased = (taxReturn: TaxDocument.IMarriedJointTaxReturn, signerData?: ISignerModel[]) => {
        if (!TaxpayerHelper.isMarried(taxReturn)) {
            return false;
        }
        if (signerData && signerData.length > 0) {
            if (signerData[0].signerType == ClientTypesNumber.Taxpayer) {
                return (taxReturn && taxReturn.spouse && taxReturn.spouse.isDeceased);
            }
            if (signerData[0].signerType == ClientTypesNumber.Spouse) {
                return (taxReturn && taxReturn.taxpayer && taxReturn.taxpayer.isDeceased);
            }
        }
        else {
            if (taxReturn.documentSettings.deliverySettings.deliverTo == ClientTypes.Taxpayer) {
                return (taxReturn && taxReturn.spouse && taxReturn.spouse.isDeceased);
            }
            if (taxReturn.documentSettings.deliverySettings.deliverTo == ClientTypes.Spouse) {
                return (taxReturn && taxReturn.taxpayer && taxReturn.taxpayer.isDeceased);
            }
        }
        return false;
    }

    static isPreviewMode(clientProcessState: IClientProcessViewModel) {
        return (clientProcessState.role.toString() === Role[Role.CPA].toString());
    }

    static isPreviewModeWithWritePermission(clientProcessState: IClientProcessViewModel) {
        return (clientProcessState.role.toString() === Role[Role.CPA].toString()) &&
            (clientProcessState.permission !== Permissions.Read);
    }

    static getSignFlowRedirectUrl(clientProcessState: IClientProcessViewModel, clientId: string) {
        if (
            (clientProcessState.isDelegated &&
                clientProcessState.currentstep.lastVisitedStep === SignProcessSteps.SignComplete)
            ||
            clientProcessState.currentstep.lastVisitedStep === SignProcessSteps.SignerProcessComplete) {
            return `${PathConstants.CompletionWizard}${clientId}`;
        }
        else if (clientProcessState.role.toString() === Role[Role.CPA].toString()) {
            return `${PathConstants.PreviewFlow}${clientId}`;
        }
        return "";

    }
    static getSignCompletedRedirectUrl(clientProcessState: IClientProcessViewModel, clientId: string) {
        let index = NO_INDEX;

        if (clientProcessState.currentstep.visitedSteps) {
            index = clientProcessState.currentstep.visitedSteps.findIndex(
                (x: SignProcessSteps) => x === SignProcessSteps.SignerProcessComplete);
        }

        if (
            !clientProcessState.isSigned &&
            index === NO_INDEX &&
            !clientProcessState.isDelegated
        ) {
            if (clientProcessState.role.toString() !== Role[Role.CPA].toString()) {
                return `${PathConstants.SignFlow}${clientId}`;
            }
            else if (clientProcessState.permission === Permissions.Read) {
                return `${PathConstants.PreviewFlow}${clientId}`;
            }
        }
        return "";
    }


    static getDelegateeSignFlowRedirectUrl(
        clientProcessState: IClientProcessViewModel,
        clientId: string) {
        if (clientProcessState.currentstep.lastVisitedStep === SignProcessSteps.SignComplete
            || clientProcessState.currentstep.lastVisitedStep === SignProcessSteps.SignerProcessComplete) {
            return `${DelegateePathConstants.CompletionWizard}${clientId}`;
        }
        return "";

    }

    static ifDelegateeExistsAndSigned(isDelegated: boolean, isSigned: boolean): boolean {
        if (isDelegated) {
            return isSigned;
        }
        return true;
    }
}
