export interface ISelectItem {
    key: string,
    value: string
}

export enum DateType {
    Date,
    Month,
    Year
}

export interface IAccessCodeValidation {
    clientGuid: string;
    code: string;
}