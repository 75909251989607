import { Next } from 'awesome-multi-steps/dist/Footer/Next';
import { Previous } from 'awesome-multi-steps/dist/Footer/Previous';
import { CustomButton } from 'awesome-multi-steps/dist/Footer/CustomButton';
import { History } from 'history';
import * as React from 'react';
import { match } from 'react-router';
import { ICompany } from '../../../core/domain/models/company/Company';
import { IK1ShareHolderDetails } from '../../../core/domain/models/IK1Distribute';
import * as TaxDocument from '../../../core/domain/models/ITaxReturn';
import { IClientProcessViewModel } from '../../../core/domain/viewModels/IClientProcessViewModel';
import { IHeaderInfoViewModel } from '../../../core/domain/viewModels/IHeaderInfoViewModel';
import * as CompanyStore from '../../../store/Common/Company/CompanyStore';
import * as TaxDocumentStore from '../../../store/Common/TaxDocumentStore';
import * as K1DistributeStore from '../../../store/K1/K1DistributeStore';
import * as SignProcessStore from '../../../store/SignProcess/SignProcessStore';
import { PathConstants } from '../../Common/Constants';
import { Body } from '../../Common/StepLayout/Body';
import { Footer } from '../../Common/StepLayout/Footer';
import { StepLayout } from '../../Common/StepLayout/StepLayout';
import TaxpayerHelper from '../../Helper/TaxpayerHelper';
import { DistributeManual } from '../../K1Distribution/DistributeManual';
import { Role } from '../../../core/common/Enums';


type ManualDistributionProps = {
    taxReturn: TaxDocument.ITaxReturn,
    headInfoViewModel: IHeaderInfoViewModel,
    companyData: ICompany,
    match: match;
    history: History;
    k1Data: IK1ShareHolderDetails[];
}
    & typeof CompanyStore.actionCreators
    & typeof K1DistributeStore.actionCreators
    & typeof TaxDocumentStore.actionCreators
    & typeof SignProcessStore.actionCreators;


interface ManualDistributionState {
    isProcessCompleted: boolean;
}

export class ManualDistribution extends React.Component<ManualDistributionProps, ManualDistributionState> {
    private _params: any;
    private _distributeManualRef: any = React.createRef();
    constructor(props: ManualDistributionProps) {
        super(props);
        this.state = {
            isProcessCompleted: false,
        }
    }

    componentDidMount() {
        const param: any = this.props.match.params;
        this.getSignProcessStatus(param.clientId);
        this.props.requestTaxDocument(param.clientId);
    }
    gotoCompletionWizard = () => {
        const param: any = this.props.match.params;
        this.props.history.push(`${PathConstants.CompletionWizard}${param.clientId}`);
    }

    gotoPreviousStep = () => {
        const param: any = this.props.match.params;
        this.props.history.push(`${PathConstants.DistributeK1}${param.clientId}`);
    }
    private getSignProcessStatus = (clientId: any) => {
        this.props.requestCurrentStepAndUserRole(clientId, this.handleRedirect);
    }
    private handleRedirect = (clientProcessState: IClientProcessViewModel) => {
        this._params = this.props.match.params;
        const url = TaxpayerHelper.getSignCompletedRedirectUrl(clientProcessState, this._params.clientId);
        if (url && url.trim()) {
            this.props.history.push(url);
        }
        else {
            this.setState({ isProcessCompleted: true });
        }
    }
    public render() {
        const role = this.props.headInfoViewModel?.loggedInUserInfo?.role;
        var downloadFunction = (role.toString() == Role[Role.CPA] || role == Role.CPA) ? this.props.downloadSelectedK1DocumentForPreviewAsync : this.props.downloadSelectedK1DocumentAsync
         

        return (
            (this.state.isProcessCompleted) &&
            <StepLayout className={"with-footer"} marginTop={20}>
                <Body lg={10} md={12} sm={12} xs={12} bodyClassName={ "no-padding"} automationTestId={"11BB28B5-42F3-4571-B567-20700B1740B6"}>
                    {this.props.taxReturn.formGroups.length > 0 &&
                        <DistributeManual
                            ref={(ins) => this._distributeManualRef = ins}
                            requestK1ShareHolderDetails={this.props.requestK1ShareHolderDetails}
                            k1Data={this.props.k1Data}
                            match={this.props.match}
                            taxReturn={this.props.taxReturn}
                            downloadK1DocumentAsync={this.props.downloadK1DocumentAsync}
                            downloadIndividualK1DocumentAsync={this.props.downloadIndividualK1DocumentAsync}
                        downloadSelectedK1DocumentAsync={ downloadFunction }
                        /> 
                    }
                </Body>

                <Footer lg={10} md={12} sm={12} xs={12}>
                    <div className={"col-lg-12 steps-footer "}>
                        <Previous automationTestId={"3F43C07D-73AD-4BB5-83D1-346B1E62ABC6"}
                            text={"Back"} goToPreviousStep={this.gotoPreviousStep} isActive={true} />

                        <Next automationTestId={"21596F53-89B5-4830-812E-96D126E7C1AE"}
                            text={"Done"} goToNextStep={this.gotoCompletionWizard} isActive={true} />

                        <CustomButton automationTestId={"21596F53-89B5-4830-812E-96D126E7C1AE"}
                            text={"Download Documents"}
                            className={"margin-right-10 btn-secondary"}                          
                            onClick={() => this._distributeManualRef.onDownloadDocument()} isActive={true} />

                    </div>
                </Footer>

            </StepLayout>);

    }
}
