import { injectable } from "inversify";

export interface IWindowUtilities {
    goFullScreen(element: HTMLElement): void;
    exitFullScreen(): void;
    startTutorial(jsonData: string): void;
}

declare var introJs: any;

@injectable()
export class WindowUtilities implements IWindowUtilities {

    goFullScreen(element: HTMLElement): void {
        
        let body: any = document.getElementsByTagName("html")[0];

        if (body.requestFullscreen) {
            body.requestFullscreen();
        } else if (body.msRequestFullscreen) {
            body.msRequestFullscreen();
        } else if (body.mozRequestFullScreen) {
            (body as any).mozRequestFullScreen();
        } else if (body.webkitRequestFullscreen) {
            body.webkitRequestFullscreen();
        } else {
            alert("Full screen not available");
        }

        element.classList.add("fit-to-screen");
    }

    exitFullScreen(): void {

        //if (document.exitFullscreen) {
        //    document.exitFullscreen();
        //} else if (document.webkitExitFullscreen) {
        //    document.webkitExitFullscreen();
        //} else if ((document as any).mozCancelFullScreen) {
        //    (document as any).mozCancelFullScreen();
        //} else if ((document as any).msExitFullscreen) {
        //    (document as any).msExitFullscreen();
        //} else {
        //    alert("Full screen not available");
        //}

        document.getElementsByClassName("fit-to-screen")[0].classList.remove("fit-to-screen");

    }

    public startTutorial(jsonData: string): void {

        introJs().setOptions({
            exitOnEsc: false,
            exitOnOverlayClick: false,
            steps: jsonData
        }).onchange(function () {


        }).onexit(function () {


        }).oncomplete(function () {


        }).start();
    }
}