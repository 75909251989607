export interface ITaxClient {
    clientGuid: string;
    id: number,
    name: string,
    ssn: string,
    email: string,
    isModified: boolean,
    mobileNumber: string,
    countryCode: string
}
export interface ITaxpayer extends ITaxClient {
    address: string,
    city: string,
    state: string,
    zip: string,
    dob: Date,
    isDeceased: boolean
}

export enum ClientTypes {
    Undefined = "none",
    Taxpayer = "Taxpayer",
    Spouse = "Spouse",
    Partner = "Partner",
    PartnerShip = "PartnerShip",
    ShareHolder = "ShareHolder"
}

export const initialTaxclient = {
    clientGuid: "",
    email: "",
    id: 0,
    isModified: false,
    name: "",
    ssn: "",
    mobileNumber: ""
}

export const initialTaxpayer = {
    ...initialTaxclient,
    address: "",
    city: "",
    dob: new Date(1990, 1, 1),
    isDeceased: false,
    state: "",
    zip: ""
}