import * as React from 'react';

interface FooterProps { 
    className?: string;
    width?: number;
    lg?: number;
    md?: number;
    sm?: number;
    xs?: number;
}


const DEFAULT_WIDTH_LG: number = 9;
const DEFAULT_WIDTH_MD: number = 11;
const DEFAULT_WIDTH_SM: number = 12;
const DEFAULT_WIDTH_XS: number = 12;

export const Footer: React.FunctionComponent<FooterProps> = ({
    className, width, lg,
    md,
    sm,
    xs, children
}) => {



    let getBodyWidthClass = () => {

        let bodyWidthClass: string = "";

        if (lg) {
            bodyWidthClass = bodyWidthClass.concat(' col-lg-', lg.toString());
        }
        else {
            bodyWidthClass = bodyWidthClass.concat(' col-lg-', DEFAULT_WIDTH_LG.toString());
        }

        if (md) {
            bodyWidthClass = bodyWidthClass.concat(' col-md-', md.toString());
        }
        else {
            bodyWidthClass = bodyWidthClass.concat(' col-md-', DEFAULT_WIDTH_MD.toString());
        }

        if (sm) {
            bodyWidthClass = bodyWidthClass.concat(' col-sm-', sm.toString());
        }
        else {
            bodyWidthClass = bodyWidthClass.concat(' col-sm-', DEFAULT_WIDTH_SM.toString());
        }

        if (xs) {
            bodyWidthClass = bodyWidthClass.concat(' col-xs-', xs.toString());
        }
        else {
            bodyWidthClass = bodyWidthClass.concat(' col-xs-', DEFAULT_WIDTH_XS.toString());
        }

        return bodyWidthClass;
    }



    return (<div className={"step-layout-footer no-padding " + getBodyWidthClass() + " " + className}
        data-test-auto="38F52C49-46A4-46BA-8D30-17E1EA95F2EF">
        {children}
    </div>);
};
