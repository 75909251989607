import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store';
import { ErrorPage } from "./ErrorPage";

export default connect(
    (state: ApplicationState) => ({
        errorMessageStore: state.errorStore,
    }),
    {

    },
)(ErrorPage);
