import * as React from 'react';
import { match } from 'react-router';
import * as SignProcessStore from '../../store/SignProcess/SignProcessStore';
import { SignProcessSteps } from './TaxpayerSignFlowManager';
import { History } from 'history';
import { PathConstants } from '../Common/Constants';
import { IClientProcessViewModel } from '../../core/domain/viewModels/IClientProcessViewModel'
import { Role } from '../../core/common/Enums'

const NO_INDEX = -1;

export type RouteMangerProps = {
    match: match;
    history: History;
} & typeof SignProcessStore.actionCreators

export class RouteManager extends React.Component<RouteMangerProps, {}> {
    private _params: any;
    constructor(props: RouteMangerProps) {
        super(props);
    }
    componentDidMount() {
        this._params = this.props.match.params;
        this.getSignProcessStatus(this._params.clientId);
    }

    private getSignProcessStatus = (clientId: any) => {
        this.props.requestCurrentStepAndUserRole(clientId, this.handleRedirect);
    }

    private handleRedirect = (clientProcessState: IClientProcessViewModel) => {
        let index = NO_INDEX;

        if (clientProcessState.currentstep.visitedSteps) {
            index = clientProcessState.currentstep.visitedSteps.findIndex((x: SignProcessSteps) => x == SignProcessSteps.SignerProcessComplete);
        }

        if (clientProcessState.isSigned === true ||
            (clientProcessState.isSigned === false && index != NO_INDEX) ||
            clientProcessState.isDelegated) {
            this.props.history.push(`${PathConstants.CompletionWizard}${this._params.clientId}`);
        } else {
            if (clientProcessState.role.toString() === Role[Role.CPA].toString()) {
                this.props.history.push(`${PathConstants.PreviewFlow}${this._params.clientId}`);
            }
            else {
                this.props.history.push(`${PathConstants.SignFlow}${this._params.clientId}`);
            }
        }

    }
    public render() {
        return (<div></div>);
    }
}

