import * as React from 'react';
import { Consent } from './Consent'
import { SpouseEmail } from './SpouseEmail'
import { DateType } from '../../core/domain/viewModels/ICommon';
import { History } from 'history';
import { match } from 'react-router';
import { IIndividualTaxReturn, IMarriedJointTaxReturn, ITaxReturn } from '../../core/domain/models/ITaxReturn';
import { ISignerModel } from 'src/core/domain/models/ISignerModel';
import { ClientType } from 'src/core/common/Enums';
import TaxpayerHelper from '../Helper/TaxpayerHelper';
import { isValidDOB, validatePhone, isValidEmailAddress } from '../Common/Validations';
import { MarsNotifier } from '../Common/Notification/MarsNotifier';
import { SiginingConstants } from '../Common/Constants';
import { StepLayout } from '../Common/StepLayout/StepLayout';
import { Body } from '../Common/StepLayout/Body';
import { getDecodedClientId } from '../Helper/HelperFunction';
import { logger } from '../../routes';
import Moment from 'moment/moment';
import { LogEventConstants } from '../Helper/Constants';

export type EsignConsentEmailProps =
    {
        history: History;
        match: match;
        ssnValue: string;
        individual: IIndividualTaxReturn;
        mutual: IMarriedJointTaxReturn
        consent: boolean;
        isPreviewMode: boolean;
        signerData: ISignerModel[];
        nextSignerExists: boolean;

        updateSpouseMailChange?(
            clientGuid: string,
            id: string,
            mail: string,
            type: ClientType,
            step: boolean,
            callback?: (step: boolean) => void): void;
        updateSpouseMobileChange?(
            clientGuid: string,
            id: string,
            number: string,
            countryCodeValue: string,
            ssnValue: string,
            clientType: ClientType,
            callback: () => void): void;
        updateDOBChange?(
            clientGuid: string,
            id: string,
            dob: string,
            step: boolean,
            callback: (step: boolean) => void): void;
        onConsentAgreed(value: boolean): void;
        prevStep: () => void;
        nextStep: () => void;
        refreshTaxDocument: (clientGuid: string) => void;
        disableNextButton: (value: boolean) => void;
        clearParentState: () => void;
    }

export interface IEsignConsentEmailState {
    emailAddress: string,
    dateOfBirth: Date,
    chainedOperationCount: number,
    noOfSteps: number,
    mobileData: any,
    isDOBUpdated: boolean,
}

const pageTitle = "Esign Consent Email Step";

export class EsignConsentEmail extends React.Component<EsignConsentEmailProps, IEsignConsentEmailState> {

    constructor(props: EsignConsentEmailProps) {
        super(props);
        const param: any = this.props.match.params;
        let clientId = getDecodedClientId(param.clientId);
        this.state = {
            emailAddress: TaxpayerHelper.getSecondSignerEmail(props.mutual, props.signerData, clientId),
            dateOfBirth: EsignConsentEmail.getCurrentSignerDOB(props),
            chainedOperationCount: 1,
            noOfSteps: 0,
            mobileData: TaxpayerHelper.getSecondSignerMobileData(props.mutual, props.signerData, clientId),
            isDOBUpdated: false
        }
        this.props.clearParentState();
        this.props.disableNextButton(!this.props.consent);
        this.onPrevious = this.onPrevious.bind(this);
        this.onNext = this.onNext.bind(this);
    }

    static getDerivedStateFromProps(props: EsignConsentEmailProps, state: IEsignConsentEmailState) {
        //const param: any = props.match.params;
        if ((!props.individual || !props.individual.taxpayer || !props.individual.taxpayer.dob) &&
            (!props.mutual || !props.mutual.spouse || !props.mutual.spouse.email)) {
            return null;
        }

        else {
            if ((props.individual.taxpayer && props.individual.taxpayer.dob &&
                (props.individual.taxpayer.dob !== state.dateOfBirth) ||
                props.individual.taxpayer.countryCode !== state.mobileData?.countryCode ||
                props.individual.taxpayer.mobileNumber !== state.mobileData?.mobileNumber)
                ||
                (props.mutual.spouse && props.mutual.spouse.email &&
                    (props.mutual.spouse.email !== state.emailAddress ||
                        props.mutual.spouse.countryCode !== state.mobileData?.countryCode ||
                        props.mutual.spouse.mobileNumber !== state.mobileData?.mobileNumber
                    ))) {
                return {
                    dateOfBirth: state.dateOfBirth,
                    emailAddress: state.emailAddress,
                    mobileData: { countryCode: state.mobileData?.countryCode, mobileNumber: state.mobileData?.mobileNumber }
                };
            }
        }
        return null;
    }

    private handleSpouseMailChange = (mail: string) => {
        this.setState({ emailAddress: mail });
    }
    private handleSpouseCountryCodeChange = (countryCode: string) => {
        var mobileData = { ...this.state.mobileData };
        mobileData.countryCode = countryCode;
        this.setState({ mobileData: mobileData });
    }
    private handleSpouseMobileChange = (mobile: string) => {
        var mobileData = { ...this.state.mobileData };
        mobileData.mobileNumber = mobile;
        this.setState({ mobileData: mobileData });
    }

    private handleConsent = (isChecked: boolean) => {
        this.props.onConsentAgreed(isChecked);
    }

    private handleDOBChange = (dob: string, type: DateType) => {
        const birthDate: Date = this.state.dateOfBirth;
        switch (type) {
            case DateType.Date:
                birthDate.setDate(parseInt(dob));
                break;
            case DateType.Month:
                birthDate.setMonth(parseInt(dob) - 1);
                break;
            case DateType.Year:
                birthDate.setFullYear(parseInt(dob));
                break;
        }
        this.setState({ dateOfBirth: birthDate, isDOBUpdated: true });
    }

    updateEsignState = (step: boolean) => {
        const param: any = this.props.match.params;
        let clientId = getDecodedClientId(param.clientId);
        if (this.state.chainedOperationCount === this.state.noOfSteps) {
            this.props.refreshTaxDocument(param.clientId);
            if (step) {
                this.props.nextStep();
            }
            else {
                this.props.prevStep();
            }
        }
        else {
            this.setState({ chainedOperationCount: this.state.chainedOperationCount + 1 })
        }
    }

    updateConsentAndEmail(step: boolean) {

        if (!isValidDOB(this.state.dateOfBirth)) {
            MarsNotifier.Warning(SiginingConstants.WarningMessage.InvalidDOB, null);
            return;
        }
        let countryCodeSelected = this.state.mobileData.countryCode && this.state.mobileData.countryCode !== "" ? true : false;
        let isMobileEmpty = (this.state.mobileData.mobileNumber === undefined || this.state.mobileData.mobileNumber === null || this.state.mobileData.mobileNumber === "") ? true : false;



        if (countryCodeSelected) {
            if (!validatePhone(this.state.mobileData.mobileNumber)) {
                return;
            }
        } else {
            if (isMobileEmpty) {
            }
            else if (!validatePhone(this.state.mobileData.mobileNumber)) {
                return;
            } else if (validatePhone(this.state.mobileData.mobileNumber)) {
                MarsNotifier.Warning("Please select country code.", null);
                return;
            }
        }



        const param: any = this.props.match.params;
        let clientId = getDecodedClientId(param.clientId);
        let steps = 1;
        if (!this.props.isPreviewMode) {
            this.setState({ chainedOperationCount: 1 })
            if (this.props.mutual && TaxpayerHelper.isMarried(this.props.mutual) && clientId == TaxpayerHelper.getFirstSignerId(this.props.mutual, this.props.signerData)) {
                if (!TaxpayerHelper.isNextSignerDeceased(this.props.mutual, this.props.signerData) && step && (!this.state.emailAddress || !isValidEmailAddress(this.state.emailAddress))) {
                    MarsNotifier.Warning(SiginingConstants.WarningMessage.InvalidEmailAddress, null);
                    return;
                }
                var mobileData = TaxpayerHelper.getSecondSignerMobileData(this.props.mutual, this.props.signerData, clientId);
                var email = TaxpayerHelper.getSecondSignerEmail(this.props.mutual, this.props.signerData, clientId);

                if (email !== this.state.emailAddress || mobileData.countryCode !== this.state.mobileData.mobileData || mobileData.mobileNumber !== this.state.mobileData.mobileNumber) {
                    steps = steps + 1;

                    if (this.state.mobileData?.mobileNumber && this.state.mobileData?.countryCode) {
                        this.props.updateSpouseMobileChange && this.props.updateSpouseMobileChange(param.clientId,
                            TaxpayerHelper.getSecondSignerId(this.props.mutual,
                                this.props.signerData, clientId).toString(),
                            this.state.mobileData?.mobileNumber,
                            this.state.mobileData?.countryCode,
                            this.props.ssnValue,
                            TaxpayerHelper.getSecondSignerType(this.props.mutual, this.props.signerData, clientId),
                            () => { }
                        );
                        logger.trackTrace(`Second signer mobile updated to: ${this.state.mobileData.countryCode} ${this.state.mobileData.mobileNumber}`);
                    }

                    if (this.props.updateSpouseMailChange) {
                        this.props.updateSpouseMailChange(param.clientId,
                            TaxpayerHelper.getSecondSignerId(this.props.mutual,
                                this.props.signerData,
                                clientId).toString(),
                            this.state.emailAddress,
                            TaxpayerHelper.getSecondSignerType(this.props.mutual,
                                this.props.signerData,
                                clientId),
                            step, this.updateEsignState);
                        logger.trackTrace(`Second signer email updated to: ${this.state.emailAddress}`);
                    }



                }
            }

            if (this.state.isDOBUpdated) {
                this.props.updateDOBChange &&
                    this.props.updateDOBChange(
                        param.clientId,
                        EsignConsentEmail.getCurrentUserId(this.props).toString(),
                        Moment(this.state.dateOfBirth).format('MM/DD/YYYY'),
                        step,
                        this.updateEsignState);
                logger.trackTrace(`DOB updated to: ${this.state.dateOfBirth.toLocaleDateString()}`);
            }
            else {
                if (step) {
                    this.props.nextStep();
                }
                else {
                    this.props.prevStep();
                }

            }

        }
        this.setState({ noOfSteps: steps }, () => {
            this.props.isPreviewMode && this.updateEsignState(step);
        });
    }

    onPrevious(index: number) {
        this.updateConsentAndEmail(false);
    }

    onNext(index: number) {
        this.updateConsentAndEmail(true);
    }

    static getCurrentSignerDOB(props: EsignConsentEmailProps) {
        const param: any = props.match.params;
        let clientId = getDecodedClientId(param.clientId);
        if (!TaxpayerHelper.isMarried(EsignConsentEmail.getTaxReturn(props)) || //if individual return
            (props.mutual === null || props.mutual === undefined) || //if joint tax return but taxreturn object is null
            ((props.mutual.taxpayer === null || props.mutual.taxpayer === undefined) && //if joint tax return but both taxpayer and spouse object are null
                (props.mutual.spouse === null || props.mutual.spouse === undefined))) {
            return (props.individual.taxpayer && props.individual.taxpayer.dob) ? new Date(props.individual.taxpayer.dob) : new Date();
        }
        else {
            if (props.mutual.taxpayer.clientGuid === clientId) {
                return (props.mutual.taxpayer && props.mutual.taxpayer.dob) ? new Date(props.mutual.taxpayer.dob) : new Date();
            }
            else if (props.mutual.spouse.clientGuid === clientId) {
                return (props.mutual.spouse && props.mutual.spouse.dob) ? new Date(props.mutual.spouse.dob) : new Date();
            }
            else {
                return new Date();
            }
        }
    }

    static getCurrentUserId = (props: EsignConsentEmailProps) => {
        const param: any = props.match.params;
        let clientId = getDecodedClientId(param.clientId);
        if (TaxpayerHelper.isMarried(EsignConsentEmail.getTaxReturn(props))) {
            if (props.mutual.taxpayer.clientGuid === clientId) {
                return props.mutual.taxpayer.id;
            }
            else {
                return props.mutual.spouse.id;
            }
        } else {
            return props.individual.taxpayer.id;
        }
    }

    static getTaxReturn = (props: EsignConsentEmailProps) => {
        return props.mutual ? props.mutual : props.individual
    }

    public render() {
        const param: any = this.props.match.params;
        let clientId = getDecodedClientId(param.clientId);
        return (<StepLayout>
            <Body lg={10} md={12} sm={12} xs={12}
                containerClassName={"content-wrapper-full"}
                title={"Sign Tax Documents Electronically"} bodyClassName={"esign-consent-container"}>
                <Consent consentChecked={this.props.consent} handleConsent={this.handleConsent} handleDOBChange={this.handleDOBChange} dateOfBirth={this.state.dateOfBirth} />
                {
                    (TaxpayerHelper.isMarried(EsignConsentEmail.getTaxReturn(this.props)) &&
                        TaxpayerHelper.isFirstSignerLoggedIn(this.props.mutual, this.props.signerData, clientId) &&
                        !TaxpayerHelper.isNextSignerDeceased(this.props.mutual, this.props.signerData)) ?
                        <><div className="black-border-line" />
                            <SpouseEmail
                                handleSpouseMailChange={this.handleSpouseMailChange}
                                onChangeCountryCode={this.handleSpouseCountryCodeChange}
                                onMobileNumberChange={this.handleSpouseMobileChange}
                                editedCountryCode={this.state.mobileData.countryCode}
                                editedMobileNumber={this.state.mobileData.mobileNumber}
                                spouseEmail={this.state.emailAddress}
                                spouseName={
                                    TaxpayerHelper.getSecondSignerName(
                                        this.props.mutual,
                                        this.props.signerData,
                                        clientId)
                                }
                                nextSignerExists={this.props.nextSignerExists}
                            />

                        </> : null
                }
            </Body>
        </StepLayout>);

    }
}