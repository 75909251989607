import { Action, Reducer } from "redux";
import { actionTypes } from "../../../ActionTypes";
import { ErrorStoreState, initialErrorStoreState, ErrorMessageAction } from "../../Models/StoreModels";

export const reducer: Reducer<ErrorStoreState> = (state: ErrorStoreState = initialErrorStoreState, incomingAction: Action) => {
    const action = incomingAction as ErrorMessageAction;
    switch (action.type) {
        case actionTypes.SET_ERROR_MESSAGE:
            return {
                message: action.message
            };

        default:
            return state || initialErrorStoreState;
    }
};
