
export interface IGroup {
    group: DocumentGroups;
    forms: IFormBase[];
}

export interface IK1 extends IGroup {
    shareHolder: IShareHolder,
}

export interface IShareHolder {
    name: string,
    ssn: string,
    id: number,
    emailAddress: string,
    k1SendDate: Date,
    isK1Decline: boolean,
    k1DownloadDate: Date,
}

export enum DocumentGroups {
    None = 0,
    Transmittals = 1,
    EFile = 2,
    Vouchers = 3,
    TaxReturns = 4,
    Deleted = 5,
    ClientInfo = 6
}

export interface IGroup2 {
    groupId: number,
    groupName: string,
    pages: IFormBase[]
}

export interface IFormBase {
    formName: string;
    bookmark: string;
    pageNo: number[];
}

export interface IVoucherFormBase extends IFormBase {
    voucherNo: number;
}

export interface IVoucher extends IFormBase {
    authorityID: number;
    dueDate: Date | undefined;
    amount: number;
    voucherNo: VoucherNo;
    paymentType: VoucherTypes;
    customType: CustomType;
    onlinePaymentUri: string;
    customText: string;
    authorityImage: string;
    authorityName: string;
    groupId?: number;
    isMasterPaymentUrl: boolean;
    vocherMode: VoucherMode;
    amountPaid: number;
    checkNumber: string;
    paidDate: Date;
    isPaid: boolean;
    signatureControls: ISignatureControl[];
}

export interface IK1ShareHolder extends IFormBase {
    shareHolderId: number,
    ssn: string
}

export interface SelectOptions {
    value: string,
    label: string
}

export enum CustomType {
    None = 0,
    Select,
    DoNotPayScheduledAutomaticWithdrawal,
    OnlinePaymentRequired,
    AddCustom
}

export enum VoucherProperty {
    authorityID,
    dueDate,
    amount,
    voucherNo,
    voucherTypes,
    customType,
    onlinePaymentUri,
    customText,
    formName,
    bookMark,
    pageNo,
    isMasterPaymentUrl
}

export enum IEFileProperty {
    authorityID,
    formName
}

export enum K1Property {
    shareHolderID,
    formName
}

export enum VoucherNo {
    None = 0,
    PaymentVoucher,
    Q1,
    Q2,
    Q3,
    Q4
}

export interface IEFile extends IFormBase {
    authorityID: number;
    signatureControls: ISignatureControl[];
}

export interface ISignatureControl {
    type?: SignatureControlTypes,
    top: number,
    left: number,
    signatureControlRole?: SignatureControlRole,
    selectedSignStyle?: string,
    selectedEroImage?: string
}

export enum SignatureControlRole {
    None = 0,
    Taxpayer,
    Spouse,
    ERO,
    PartnerShip
}

export enum SignatureControlTypes {
    None = 0,
    Signature = 1,
    Initial = 2,
    Date = 3,
    NameAndTitle = 4,
    Text = 10,
    Company = 11
}

export enum SignatureGroupType {
    None = 0,
    Esign,
    Stamp
}

export interface IPayments extends IFormBase {
    autorityID: number,
    autorityName: string,
    amount: number,
    paymentType: PaymentType,
    voucherDueDate: Date
}

export enum PaymentType {
    DUE = 0,
    REFUND = 1
}

export enum VoucherTypes {
    None = 0,
    PaymentVoucher = 1,
    EstimatedVoucher
}

export enum VoucherMode {
    None = 0,
    Recognized = 1,
    CustomVoucher = 2
}

export interface IRefund extends IFormBase {
    authorityID: number,
    overPayment: number,
    overPaymentApplied: number,
    groupId?: number;
}

