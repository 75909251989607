import { Step } from 'awesome-multi-steps/dist/Body/Step';
import { Wizard } from 'awesome-multi-steps/dist/Body/Wizard';
import { ISignerModel, SignerStatus } from '../../core/domain/models/ISignerModel';
import { History } from 'history';
import * as React from 'react';
import { match, RouteComponentProps } from 'react-router';
import { ClientType, DistributeType, DocumentStatus, EngagementTypeS, KBATransactionResultType, SignatureStatus, SignatureType } from '../../core/common/Enums';
import { ICompany } from '../../core/domain/models/company/Company';
import { IDocument } from '../../core/domain/models/esign/Document';
import { IDocumentSettings } from '../../core/domain/models/IDocumentSettings';
import { IVoucher } from '../../core/domain/models/IGroup';
import { IK1ShareHolderDetails } from '../../core/domain/models/IK1Distribute';
import { IKBATransactionResponse } from '../../core/domain/models/IKBA';
import { initailsignatureSettingsModel, ISignatureSettingsModel } from '../../core/domain/models/ISignatureSettingsModel';
import { ITaxDocumentModel } from '../../core/domain/models/ITaxDocumentModel';
import * as TaxDocument from '../../core/domain/models/ITaxReturn';
import { ITutorialInfo } from '../../core/domain/models/ITutorialInfo';
import { ISignedDocument } from '../../core/domain/models/manualsign/SignedDocument';
import { IClientProcessViewModel } from '../../core/domain/viewModels/IClientProcessViewModel';
import { ICommonDataViewModel } from '../../core/domain/viewModels/ICommonDataViewModel';
import { IDocumentReviewModel } from '../../core/domain/viewModels/IDocumentReviewModel';
import { IHeaderInfoViewModel, initialHeaderInfoViewModel } from '../../core/domain/viewModels/IHeaderInfoViewModel';
import { ILoggedInUserInfo } from '../../core/domain/viewModels/ILoggedInUserInfo';
import { IPayScreenViewModel } from '../../core/domain/viewModels/IPayScreenViewModel';
import { ISignProcessInfoViewModel } from '../../core/domain/viewModels/ISignProcessInfoViewModel';
import { ISummaryViewModel } from '../../core/domain/viewModels/ISummaryViewModel';
import { IDialogBox } from '../../core/utilities/ui/DialogBox';
import { container } from '../../startup/inversify.config';
import { TYPES } from '../../startup/types';
import * as AccountStore from '../../store/Common/AccountStore';
import * as CompanyStore from '../../store/Common/Company/CompanyStore';
import * as EsignStore from '../../store/Common/EsignStore';
import * as HelperStore from '../../store/Common/HelperStore';
import * as TaxDocumentStore from '../../store/Common/TaxDocumentStore';
import * as K1DistributeStore from '../../store/K1/K1DistributeStore';
import * as PayStore from '../../store/PayStore';
import * as PreparerMessageStore from '../../store/PreparerMessageStore';
import * as DocumentReviewStore from '../../store/ReviewStore';
import * as ManualSignStore from '../../store/Sign/ManualSignStore';
import * as SignProcessStore from '../../store/SignProcess/SignProcessStore';
import * as SummaryStore from '../../store/SummaryStore';
import * as TutorialStore from '../../store/Tutorial/TutorialStore';
import { Common, K1ShareHolderConstants, ManualSignConstants, PathConstants } from '../Common/Constants';
import {
    SvgIconDownloadWizardActive,
    SvgIconMakePaymentsWizardActive,
    SvgIconReviewWizardActive,
    SvgIconSignWizardActive,
    SvgIconSummaryWizardActive,
    SvgAlertIcon
} from '../Common/Icons/SvgIcons';
import { Body } from '../Common/StepLayout/Body';
import { Header } from '../Common/StepLayout/Header';
import { StepLayout } from '../Common/StepLayout/StepLayout';
import { EsignConsentEmail } from '../Esign/EsignConsentEmail';
import { KBAComponent } from "../Esign/KBAComponent";
import { Esign } from '../Esign/Main/Esign';
import { PdfViewManager } from '../Esign/Main/EsignBase';
import SelectSignMethod from '../Esign/SelectSignMethod';
import SignatureCompletedPage from '../Esign/SignatureCompletedPage';
import SpouseSignRequired from '../Esign/SpouseSignRequired';
import { hideReviewStep, isIndividualEngagementType, getDecodedClientId } from '../Helper/HelperFunction';
import TaxpayerHelper from '../Helper/TaxpayerHelper';
import { ManualSign } from '../ManualSign/ManualSign';
import { ManualSignDownloadFiles } from '../ManualSign/ManualSignDownloadFiles';
import * as PayWizard from '../Pay/Main/Pay';
import { PaymentInstruction } from '../Pay/PaymentInstruction';
import * as PreparerMessageWizard from '../PreparerMessage/MessageWizard';
//import * as InvoiceWizard from '../Review/Main/Invoice';
import * as ReviewWizard from '../Review/Main/Review';
import * as SummaryWizard from '../Summary/Main/WizardBody';
import { SignProcessSteps, TaxpayerSignFlowManager } from './TaxpayerSignFlowManager';
import { IAdditionalEsignDocument } from '../../core/domain/models/IAdditionalEsignDocument';
import * as CCRecipientStore from '../../store/CCRecipientStore';
import { ICCRecipientDownloadableDocuments } from '../../core/domain/models/ICCRecipientModel';
import {
    actionCreators as SigningRequiredStoreActionCreators,
    SigningRequiredStoreState,
} from "../../store/SignProcess/SigningRequiredStore";
import { logger } from '../../routes';
import * as DelegateeSignerStore from '../../store/Delegatee/DelegateeSignerStore';
import { Alert } from 'react-bootstrap';

const NO_INDEX = -1;

export type MainComponentProps =
    {
        summaryData: ISummaryViewModel;
        esignData: ITaxDocumentModel;
        signerData: ISignerModel[];
        manualSignData: ISignedDocument[];
        companyData: ICompany;
        kbaData: IKBATransactionResponse;
        payScreenData: IPayScreenViewModel;
        signatureControlsData: IDocument[];
        commonData: ICommonDataViewModel;
        signatureSettingModel: ISignatureSettingsModel;
        documentSettings: IDocumentSettings;
        documentReviewModel: IDocumentReviewModel[];
        headInfoViewModel: IHeaderInfoViewModel;
        taxReturn: TaxDocument.ITaxReturn;
        isKBAPassed: boolean;
        match: match;
        history: History;
        loading: boolean;
        k1Data: IK1ShareHolderDetails[];
        signProcessInfo: ISignProcessInfoViewModel;
        loggedInUserInfo: ILoggedInUserInfo;
        tutorialInfo: ITutorialInfo;
        additionalEsignDocuments: IAdditionalEsignDocument[];
        downloadAllAdditionEsignDocuments: (clientId: string, fileName: string) => any;
        requestAdditionalEsignDocument(clientGuid: string): void;
        ccRecipientDownloadableDocuments: ICCRecipientDownloadableDocuments[];
        signingRequiredState: SigningRequiredStoreState;
        showPaymentAlert: boolean;
    }
    & typeof SummaryStore.actionCreators
    & typeof HelperStore.actionCreators
    & typeof EsignStore.actionCreators
    & typeof AccountStore.actionCreators
    & typeof ManualSignStore.actionCreators
    & typeof CompanyStore.actionCreators
    & typeof PreparerMessageStore.actionCreators
    & typeof DocumentReviewStore.actionCreators
    & typeof TaxDocumentStore.actionCreators
    & typeof K1DistributeStore.actionCreators
    & typeof SignProcessStore.actionCreators
    & typeof PayStore.actionCreators
    & typeof TutorialStore.actionCreators
    & typeof CCRecipientStore.actionCreators
    & typeof SigningRequiredStoreActionCreators
    & typeof DelegateeSignerStore.actionCreators
    & RouteComponentProps<{}>;

interface MainComponentState {
    signatureSettings: ISignatureSettingsModel;
    headInfoViewModel: IHeaderInfoViewModel;
    hideNextButton: boolean;
    hidePreviousButton: boolean;
    isNextButtonDisable: boolean;
    isConsentAgreed: boolean;
    hideNavigationBar: boolean;
    hideFooter: boolean;
    isPreviewMode: boolean;
    showCCModal: boolean;
    isDelegateeAssigned: boolean;
}

const dialogBox = container.get<IDialogBox>(TYPES.IDialogBox);

export class TaxpayerSignFlow extends React.Component<MainComponentProps, MainComponentState> {
    private _wizardRef: any;
    private _reviewRef: any;
    private _consentRef: any;
    private _esignRef: any;
    private _spouseSignRequiredRef: any;
    private _manualSign: any;
    private _params: any;
    private _taxpayerSignFlowManager: TaxpayerSignFlowManager = TaxpayerSignFlowManager.createNullObject();
    private _distributeManualRef: any = React.createRef();
    private _distributeElectronicRef: any = React.createRef();
    private _payRef: any;

    constructor(props: MainComponentProps) {
        super(props);
        this.state = {
            signatureSettings: initailsignatureSettingsModel,
            headInfoViewModel: initialHeaderInfoViewModel,
            hideNextButton: false,
            hidePreviousButton: false,
            isNextButtonDisable: false,
            isConsentAgreed: false,
            hideNavigationBar: false,
            hideFooter: false,
            isPreviewMode: false,
            showCCModal: false,
            isDelegateeAssigned: false
        };
    }

    componentDidMount() {
        const param: any = this.props.match.params;
        this._params = this.props.match.params;
        this.getSignProcessStatus(param.clientId);
        this.props.checkSignatureRequiredForDocument(param.clientId);
        this.props.checkSignatureRequiredForSigner(param.clientId);
        this.props.requestDocumentSettings(param.clientId);
        this.props.requestDocumentReviewModel(param.clientId);
        if (this.props.commonData && this.props.commonData.taxingAuthorities.length === 0) {
            this.props.requestAllTaxingAuthorities(param.clientId);
        }
        this.props.requestCompanyDetails(param.clientId, true, true);
        this.props.requestTaxDocument(param.clientId, (taxReturn: TaxDocument.ITaxReturn) => {
            this.props.relatedTaxReturnDelivered(param.clientId);
            this.props.requestCCRecipientDownloadableDocuments(param.clientId, taxReturn.documentStatus);
            this.props.requestLastVisitedStep(
                this._params.clientId,
                (lastVisitedStep: SignProcessSteps) => {
                    this.signProcessRecoverConfirmation(lastVisitedStep);
                });
        });
        this.props.requestSigners(param.clientId,
            (signers: ISignerModel[]) => {

                var param: any = this.props.match.params;
                const clientGuid = getDecodedClientId(param.clientId);

                const nextSigner = signers.filter((signer) => signer.clientGuid !== clientGuid);

                if (nextSigner.length === 1 &&
                    nextSigner[0].signerStatus === SignerStatus.NotSigned) {

                    this.props.checkNextSignerExists(param.clientId, nextSigner[0].signerType);

                }

            });
        this.props.requestKBAStatus(param.clientId);
        this.props.requestSignedDocuments(param.clientId);
        this.props.requestAdditionalEsignDocument(this._params.clientId);

        logger.trackPageView(`TaxpayerSignFlow page`);
    }

    getSignProcessStatus = (clientId: any) => {
        this.props.requestCurrentStepAndUserRole(clientId, this.handleRedirect);
    }

    handleRedirect = (clientProcessState: IClientProcessViewModel) => {
        this._params = this.props.match.params;
        const url = TaxpayerHelper.getSignFlowRedirectUrl(clientProcessState, this._params.clientId);
        if (url && url.trim()) {
            this.props.history.push(url);
        }
        this.setState({
            isPreviewMode: TaxpayerHelper.isPreviewMode(clientProcessState),
            isDelegateeAssigned: clientProcessState.isDelegated
        });
    }

    signProcessRecoverConfirmation = (lastVisitedStep: SignProcessSteps) => {
        let param: any = this.props.match.params;
        if (lastVisitedStep !== SignProcessSteps.SignerProcessComplete) {
            this._taxpayerSignFlowManager
                .signProcessRecoverConfirmation(
                    lastVisitedStep,
                    param.clientId,
                    this.props.requestTutorialInfo);
        }
    }

    /* TODO Take from props(redux), move the method to SelectSignMethod component */

    handleSetSignMethod = (signatureType: SignatureType) => {

        const param: any = this.props.match.params;

        const tempSignatureSetting: ISignatureSettingsModel = this.state.signatureSettings as ISignatureSettingsModel;
        tempSignatureSetting.signatureFormSelectionType = signatureType;

        this.setState({ signatureSettings: tempSignatureSetting }, () => {
            this.props.updateDocumentSignatureSettingModel(param.clientId, signatureType, () => {
                this.updateSettingsAndRefreshTaxDocument(param.clientId);
            });
        });
    }

    updateSettingsAndRefreshTaxDocument = (clientId: string) => {
        this.props.requestTaxDocument(clientId);
        if (this.state.signatureSettings.signatureFormSelectionType === SignatureType.ESignWhenAllIncludedReturnsAllowed) {
            this.goToNextStep();
        } else {
            this.goToStepById(SignProcessSteps.ManualSignDownload.toString());
        }
    }

    isSpouseOperationRequired = (): boolean => {
        let param: any = this.props.match.params;
        let clientGuid = getDecodedClientId(param.clientId);
        return (this.props.taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType !== SignatureType.ManualSign
            && TaxDocument.isMutual(this.props.taxReturn)
            && this.props.taxReturn.engagementType == EngagementTypeS.E1040
            && (TaxpayerHelper.isMarried(this.props.taxReturn)
                && TaxpayerHelper.isFirstSignerLoggedIn(this.props.taxReturn, this.props.signerData, clientGuid)
                && !TaxpayerHelper.isNextSignerDeceased(this.props.taxReturn, this.props.signerData)));
    }

    isSecondSignerExists = (): boolean => {
        let param: any = this.props.match.params;
        let clientGuid = getDecodedClientId(param.clientId);
        return (this.props.taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType == SignatureType.ESignWhenAllIncludedReturnsAllowed
            && TaxDocument.isMutual(this.props.taxReturn)
            && TaxpayerHelper.isMarried(this.props.taxReturn)
            && (ClientType.Undefined != TaxpayerHelper.getSecondSignerType(this.props.taxReturn, this.props.signerData, clientGuid))
            && !TaxpayerHelper.isNextSignerDeceased(this.props.taxReturn, this.props.signerData));
    }

    hideSignComplete = (): boolean => {
        if (this.props.taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType != SignatureType.ESignWhenAllIncludedReturnsAllowed) {
            return true;
        }
        else {
            if (this.isSecondSignerExists()) {
                return true;
            }
            else {
                return false;
            }
        }
    }

    clearState = () => {
        this.setState({
            hideNextButton: false,
            hidePreviousButton: false,
            isNextButtonDisable: false
        })
    }

    goToStepById = (id: any) => {
        this._wizardRef && this._wizardRef.goToStepById(id)
    }

    thumbNailClick = (stepNumber: number) => {
        switch (stepNumber) {
            case SignProcessSteps.SignMethod:
                if (this.props.taxReturn?.documentSettings?.documentSignatureSetting?.signatureFormSelectionType
                    == SignatureType.ESignWhenAllIncludedReturnsAllowed) {
                    if (this._taxpayerSignFlowManager.isSigningProcessCompleted()) {
                        if (this.isSpouseOperationRequired()) {
                            stepNumber = SignProcessSteps.SpouseSignature;
                        } else {
                            stepNumber = SignProcessSteps.SignComplete;
                        }
                    }
                } else {
                    if (this._taxpayerSignFlowManager.isLinkVisited(SignProcessSteps.ManualSign)) {
                        stepNumber = SignProcessSteps.ManualSign;
                    } else {
                        stepNumber = SignProcessSteps.ManualSignDownload;
                    }
                }
                break;
            default: break;
        }
        this.goToStepById(stepNumber);
    }


    goToStep = (stepNumber: number) => {
        this._wizardRef && this._wizardRef.goToStep(stepNumber);
    }

    goToNextStep = () => {
        this._wizardRef && this._wizardRef.goToNextStep();
    };

    handleHeaderAndFooterVisibility = (hidden: boolean) => {
        this.setState({ hideNavigationBar: hidden, hideFooter: hidden });
    }

    goToPreviousStep = () => {
        this._wizardRef && this._wizardRef.goToPreviousStep();
    };

    goToCompletionWizard = (isDocSettingsUpdated: boolean = false) => {
        const param: any = this.props.match.params;
        if (isDocSettingsUpdated) {

            this.props.requestDocumentSettings(param.clientId);
        }
        this.props.history.push(`${PathConstants.CompletionWizard}${param.clientId}`);
    };


    disableNextButton = (value: boolean) => {
        this.setState({ isNextButtonDisable: value });
    }

    onReviewWizardPreviousBtnClick = () => {
        if (this.props.documentSettings.deliverySettings.preparerMessage.body) {
            this._reviewRef.onPrevious(this._reviewRef.state.currentStep);
        }
        else {
            this.goToStepById(SignProcessSteps.Summary.toString())
        }
    }

    onReviewWizardNextBtnClick = () => {
        this._reviewRef.onNext(this._reviewRef.state.currentStep);
    }

    onConsentWizardNextBtnClick = (currStep: number) => {
        this._consentRef.onNext(currStep);
    }

    onConsentWizardPreviousBtnClick = (currStep: number) => {
        this._consentRef.onPrevious(currStep);
    }

    onEsignWizardNextBtnClick = (currStep: number) => {
        this._esignRef.onNext(currStep);
    }

    onSpouseSignRequiredNextBtnClick = (currStep: number) => {
        this._spouseSignRequiredRef.onNext(currStep);
    }

    onConsentAgreed = (value: boolean) => {
        this.disableNextButton(!value);
        this.setState({ isConsentAgreed: value });
    }

    onEsignComplete = () => {
        this.goToNextStep();
    }

    onK1DistributionNextBtnClick = (currStep: number) => {
        this.goToStepById(SignProcessSteps.Pay.toString())
    }
    onK1DistributionPreviousBtnClick = () => {
        this.goToStepById(SignProcessSteps.DistributeSelection.toString())
    }
    onKBAFailure = () => {
        this.goToStepById(SignProcessSteps.ManualSignDownload.toString());
    }

    k1DistributionMethodConfirm = (currStep: number) => {
        dialogBox.confirm(
            Common.DialogBox.WarningTitle,
            K1ShareHolderConstants.WarningMessage.K1DistributionWarning,
            K1ShareHolderConstants.WarningMessage.Cancel,
            K1ShareHolderConstants.WarningMessage.Confirm,
            (result: boolean) => { result && this.onK1DistributionNextBtnClick(currStep) });
    }

    handleSetDistributeMethod = (type: DistributeType) => {
        DistributeType.Manual === type ?
            this.goToStepById(SignProcessSteps.DistributeManual.toString()) :
            this.goToStepById(SignProcessSteps.DistributeElectronic.toString());
    }

    handleStepChange = (step: number, stepId: string) => {
        if (
            this.state.isDelegateeAssigned &&
            stepId == SignProcessSteps.Pay.toString() ||
            ((this.props.taxReturn.relatedTaxReturnDelivered || this.props.summaryData.vouchers.length == 0) &&
                (stepId == SignProcessSteps.ManualSign.toString() ||
                    stepId == SignProcessSteps.SpouseSignature.toString()) ||
                stepId == SignProcessSteps.SignComplete.toString()) ||
            ((this.props.summaryData.vouchers.length == 0 || this.props.taxReturn.relatedTaxReturnDelivered) &&
                (this.props.documentSettings.documentSignatureSetting.signatureFormSelectionType == SignatureType.ReviewOnly ||
                    !this.props.signingRequiredState.signingRequiredForSigner)
            )) {
            stepId = SignProcessSteps.SignerProcessComplete.toString();
        }
        this.props.updateLastVisitedStep(this._params.clientId, Number(stepId));
    }

    handleSubmit = () => {
        this.props.updateLastVisitedStep(this._params.clientId, SignProcessSteps.SignerProcessComplete,
            () => {
                this._payRef && this._payRef.updateVoucherReminderDetails()
            }
        );
    }

    disableNavigationLink = (step: SignProcessSteps) => {
        let index = NO_INDEX;
        index = this.props.signProcessInfo.visitedSteps && this.props.signProcessInfo.visitedSteps.indexOf(step);
        if (index == null || (index != null && index === NO_INDEX) || this.state.isDelegateeAssigned) {
            return true;
        }
        else {
            return false;
        }
    }

    confirmRedirectToPay = () => {

        const {
            manualSignData,
            summaryData: { vouchers: { length } },
            taxReturn: { relatedTaxReturnDelivered },
            headInfoViewModel: { companyName }
        } = this.props;

        if (manualSignData.length > 0) {
            dialogBox.confirm(
                Common.DialogBox.ConfirmationTitle,
                ManualSignConstants.ConfirmMessage.replace("<COMPANYNAME>", companyName),
                Common.DialogBox.Cancel,
                Common.DialogBox.Confrim,
                (result: boolean) => { result && this._manualSign && this._manualSign.completeManualSign() });
        }
        else {
            if (!length || relatedTaxReturnDelivered) {
                this.goToCompletionWizard();
            } else {
                this.goToNextStep();
            }
        }
    }

    showCCRecipientModal = () => {
        this.setState({ showCCModal: true });
    }

    closeCCRecipientModal = () => {
        this.setState({ showCCModal: false });
    }

    saveVoucherPayment = (voucher: IVoucher) => {
        const param: any = this.props.match.params;
        if (voucher !== null && voucher !== undefined) {
            if (!voucher.isPaid) {
                this.props.addVoucherPayemnt(param.clientId, voucher);
            }
            else {
                this.props.updateVoucherPayemnt(param.clientId, voucher);
            }
        }

    }

    openInvoiceUrl = () => {
        window.open(this.props.companyData.onlinePaymentUrl, '_blank');
    }

    showCustomBtnInReviewStep = () => {
        return true;
    }


    downloadAllDocuments = () => {
        const param: any = this.props.match.params;
        let documentGroups: number[] = [];
        this.props.ccRecipientDownloadableDocuments.forEach((document, i) => {
            documentGroups.push(document.documentGroup)
        });
        this.props.downloadSelectedDocuments(param.clientId, documentGroups);
    }

    downloadSelectedDocuments = (documentGroups: number[]) => {
        const param: any = this.props.match.params;
        this.props.downloadSelectedDocuments(param.clientId, documentGroups);
    }

    saveDelegateeState = () => {
        this.setState({ isDelegateeAssigned: true });
    }

    handlePayPreviousBtn = () => {

        const {
            isDelegateeAssigned
        } = this.state;

        if (
            isDelegateeAssigned
        ) {
            return;
        }
        this.goToPreviousStep();
    }

    toolTipForPay = (relatedTaxReturnDelivered: boolean, voucherExists: boolean) => {
        if (!voucherExists) {
            return "No Payment Voucher Available";
        }
        else if (relatedTaxReturnDelivered) {
            return "Your payment step has been disabled per the delivery of your tax return.";
        }
        else {
            return "Pay"
        }
    }

    public render() {

        const {
            hideNavigationBar,
            hideFooter
        } = this.state;

        const {
            taxReturn,
            taxReturn: {
                documentSettings: { documentSignatureSetting: { signatureFormSelectionType }
                },
                relatedTaxReturnDelivered, taxYear
            },
            signingRequiredState: { signingRequiredForSigner, nextSignerExists },
            showPaymentAlert
        } = this.props;

        const paymentAlert = relatedTaxReturnDelivered ?
            <Alert
                dismissible={true}
                show={showPaymentAlert}
                className="payment-alert"
                onClose={() => {
                    this.props.togglePaymentAlert(false);
                }}>
                <span className="payment-alert-icon">
                    <SvgAlertIcon />
                </span>
                <span className="payment-alert-text">
                    A link to your completed {taxYear} tax return has been sent to you via email.
                    Payments can be made by accessing the link contained in the email you received.</span>
            </Alert> :
            null;

        const ssnValue: string = taxReturn &&
            (taxReturn.clientType == ClientType.Spouse && TaxDocument.isMutual(taxReturn)) ?
            taxReturn.taxpayer.ssn :
            taxReturn && (taxReturn.clientType == ClientType.Taxpayer && TaxDocument.isMutual(taxReturn)) ?
                taxReturn.spouse.ssn : "";

        const invoicePresent = false;

        const completedPercentage = PdfViewManager.getControlValueUpdatedPercentage(this.props.signatureControlsData);

        const param: any = this.props.match.params;

        const SignStepIcon = Common.Images.SignStepIcon;

        const voucherExists = this.props.summaryData.vouchers.length > 0;

        const paymentSectionDisabled = !voucherExists || relatedTaxReturnDelivered;

        const spouseOperationRequired = this.isSpouseOperationRequired();

        return (<Wizard
            hideTopNavigation={hideNavigationBar}
            hideFooter={hideFooter}
            nextButtonAutomationTestId={"FA6F3407-F80B-4FCD-87A5-C094ECF286EC"}
            previousButtonAutomationTestId={"D945DDEF-3701-4155-9529-1C7BDECA4A29"}
            submitLabel={"Done"}
            ref={(ref: any) => {
                this._wizardRef = ref;
                this._taxpayerSignFlowManager = TaxpayerSignFlowManager.create(ref, this.props);
            }}
            onStepChange={this.handleStepChange}
            onSubmit={this.handleSubmit}>

            {
                this.props.taxReturn.id > 0 &&
                <Step id={SignProcessSteps.Summary.toString()}
                    tooltip={"Summary"}
                    name={"Summary"}
                    disableNavigationClick={this.state.isDelegateeAssigned}
                    template={<SvgIconSummaryWizardActive className={"step-icon"} />}
                    hideNextButton={true} hidePreviousButton={true}
                    previousLabel={Common.Wizard.PreviousButtonLabel}
                    nextLabel={Common.Wizard.NextButtonLabel}>
                    {paymentAlert}
                    <SummaryWizard.WizardBody
                        commonData={this.props.commonData}
                        match={this.props.match}
                        history={this.props.history}
                        summaryData={this.props.summaryData}
                        requestSummaryDetails={this.props.requestSummaryDetails}
                        goToStep={this.goToStep}
                        showPaymentRefundPrint={false}
                        signProcessInfo={this.props.signProcessInfo}
                        goToStepById={this.thumbNailClick}
                        tutorialInfo={this.props.tutorialInfo}
                        hideSummaryTutorial={this.props.hideSummaryTutorial}
                        headerInfo={this.props.headInfoViewModel}
                        signingRequiredForSigner={signingRequiredForSigner}
                        signatureFormSelectionType={signatureFormSelectionType}
                        showPreparerMessage={this.props.documentSettings.deliverySettings.preparerMessage.body != ""}
                        relatedTaxReturnDelivered={relatedTaxReturnDelivered}
                    />
                </Step>
            }
            {
                this.props.taxReturn.id > 0 && this.props.documentSettings.deliverySettings.preparerMessage.body != "" &&
                <Step id={SignProcessSteps.PreparerMessage.toString()} disableNavigationClick={true}
                    previousLabel={Common.Wizard.PreviousButtonLabel}
                    nextLabel={Common.Wizard.NextButtonLabel}>
                    {paymentAlert}
                    <PreparerMessageWizard.PreparerMessageWizardBody
                        preparerMessage={this.props.documentSettings.deliverySettings.preparerMessage.body}
                        headerInfo={this.props.headInfoViewModel}
                        isDelegatee={false}
                    />
                </Step>
            }
            {
                this.props.taxReturn.id > 0 &&
                <Step id={SignProcessSteps.Invoice.toString()} name={"Review"} tooltip={"Review"}
                    template={<SvgIconReviewWizardActive className={"step-icon"} />}
                    preventNextButtonDefaultBehaviour={!invoicePresent}
                    preventPreviousButtonDefaultBehaviour={!invoicePresent}
                    onNext={this.onReviewWizardNextBtnClick}
                    hidden={hideReviewStep(this.props.documentReviewModel)}
                    onPrevious={this.onReviewWizardPreviousBtnClick}
                    disableNavigationClick={
                        this.disableNavigationLink(SignProcessSteps.Invoice) ||
                        this.state.isDelegateeAssigned
                    }
                    previousLabel={Common.Wizard.PreviousButtonLabel}
                    hideScrollbar={true}
                    nextLabel={(
                        !spouseOperationRequired &&
                        paymentSectionDisabled &&
                        !signingRequiredForSigner &&
                        signatureFormSelectionType != SignatureType.ManualSign
                    ) ?
                        Common.Wizard.Submit :
                        Common.Wizard.NextButtonLabel
                    }
                    showCustomButton={this.showCustomBtnInReviewStep()}
                    customButtonLabel={invoicePresent ?
                        !this.props.companyData.onlinePaymentUrl ? Common.Wizard.ForwardDocuments :
                            Common.Wizard.PayNow : Common.Wizard.ForwardDocuments}
                    onCustomButtonClick={invoicePresent ?
                        !this.props.companyData.onlinePaymentUrl ? this.showCCRecipientModal : this.openInvoiceUrl
                        : this.showCCRecipientModal}
                >
                    {paymentAlert}
                    <ReviewWizard.ReviewWizardBody
                        onHeaderAndFooterVisibility={this.handleHeaderAndFooterVisibility}
                        ref={(ref: any) => { this._reviewRef = ref }}
                        documentReviewModel={this.props.documentReviewModel}
                        downloadAttachment={this.props.downloadIndividualAttachment}
                        downloadAllAttachments={this.props.downloadAllAttachments}
                        match={this.props.match}
                        nextStep={this.goToNextStep}
                        prevStep={this.goToPreviousStep}
                        hideTitle={hideNavigationBar && hideFooter}
                        tutorialInfo={this.props.tutorialInfo}
                        hideReviewTutorial={this.props.hideReviewTutorial}
                        showModal={this.state.showCCModal}
                        closeCCRecipientModal={this.closeCCRecipientModal}
                        ccRecipientDownloadableDocuments={this.props.ccRecipientDownloadableDocuments}
                        taxdocumentId={this.props.taxReturn.id}
                        forwardDocumentsToRecipients={this.props.forwardDocumentsToRecipients}
                        downloadAllDocuments={this.downloadAllDocuments}
                        downloadSelectedDocuments={this.downloadSelectedDocuments}
                        showDownloadButton={true}
                        signingRequiredForSigner={signingRequiredForSigner}
                        signatureFormSelectionType={signatureFormSelectionType}
                        review={this.props.review}
                        goToStep={this.goToStep}
                        relatedTaxReturnDelivered={relatedTaxReturnDelivered}
                        voucherExists={voucherExists}
                        goToCompletionWizard={this.goToCompletionWizard}
                        fromPreviewComponent={false}
                        spouseOperationRequired={spouseOperationRequired}
                        history={this.props.history}
                        isClientView={false}
                        signatureStatus={this.props.taxReturn.signatureStatus}
                        documentStatus={this.props.taxReturn.documentStatus}
                        refreshTaxDocument = {this.props.requestTaxDocument}
                        taxyear={this.props.taxReturn.taxYear}
                    />
                </Step>
            }
            {
                this.props.taxReturn.id > 0 &&

                <Step id={SignProcessSteps.SignMethod.toString()}
                    name={"Sign"}
                    tooltip={"Sign"}
                    template={<SvgIconSignWizardActive className={"step-icon"} />}
                    hidden={
                        this._taxpayerSignFlowManager.isSigningProcessCompleted() ||
                        !(
                            signingRequiredForSigner &&
                            signatureFormSelectionType == SignatureType.ESignWhenAllIncludedReturnsAllowed
                        )
                    }
                    hideNextButton={true}
                    hidePreviousButton={false}
                    onPrevious={this.onConsentWizardPreviousBtnClick}
                    disableNavigationClick={this.disableNavigationLink(SignProcessSteps.SignMethod)}
                    previousLabel={Common.Wizard.PreviousButtonLabel}
                    nextLabel={Common.Wizard.NextButtonLabel}
                >
                    {paymentAlert}
                    <SelectSignMethod
                        onSetSignMethod={this.handleSetSignMethod}
                        clearParentState={this.clearState}
                        match={this.props.match}
                        history={this.props.history}
                        taxReturn={this.props.taxReturn}
                        saveDelegateeDetails={this.props.assignToDelegatee}
                        isDelegateeAssigned={this.state.isDelegateeAssigned}
                        goToStep={this.goToStepById}
                        saveDelegateeState={this.saveDelegateeState}
                        isPreviewMode={this.state.isPreviewMode}
                        voucherExists={voucherExists}
                        relatedTaxReturnDelivered={relatedTaxReturnDelivered}
                        goToCompletionWizard={this.goToCompletionWizard}
                        signerData={this.props.signerData}
                    />
                </Step>
            }

            {
                this.props.taxReturn.id > 0 &&
                <Step id={SignProcessSteps.EsignConsentEmail.toString()}
                    hidden={
                        this._taxpayerSignFlowManager.isSigningProcessCompleted() ||
                        !(
                            signingRequiredForSigner &&
                            (
                                signatureFormSelectionType == SignatureType.ESignWhenAllIncludedReturnsAllowed
                                && isIndividualEngagementType(this.props.taxReturn.engagementType)
                            )
                        )
                    }
                    disableNextButton={this.state.isNextButtonDisable}
                    onNext={this.onConsentWizardNextBtnClick}
                    onPrevious={this.onConsentWizardPreviousBtnClick}
                    preventNextButtonDefaultBehaviour={true}
                    preventPreviousButtonDefaultBehaviour={true}
                    disableNavigationClick={true}
                    previousLabel={Common.Wizard.PreviousButtonLabel}
                    nextLabel={Common.Wizard.NextButtonLabel}>
                    {paymentAlert}
                    <EsignConsentEmail
                        ref={(ref: any) => { this._consentRef = ref }}
                        history={this.props.history}
                        match={this.props.match}
                        isPreviewMode={this.state.isPreviewMode}
                        updateSpouseMailChange={this.props.updateSpouseMail}
                        updateSpouseMobileChange={this.props.updateMobileNumber}
                        updateDOBChange={this.props.updateDOB}
                        ssnValue={ssnValue}
                        individual={this.props.taxReturn as TaxDocument.IIndividualTaxReturn}
                        mutual={this.props.taxReturn as TaxDocument.IMarriedJointTaxReturn}
                        consent={this.state.isConsentAgreed}
                        onConsentAgreed={this.onConsentAgreed}
                        nextStep={this.goToNextStep}
                        prevStep={this.goToPreviousStep}
                        refreshTaxDocument={this.props.requestTaxDocument}
                        signerData={this.props.signerData}
                        disableNextButton={this.disableNextButton}
                        clearParentState={this.clearState}
                        nextSignerExists={nextSignerExists}
                    />
                </Step>
            }

            {
                this.props.taxReturn.id > 0 &&
                <Step id={SignProcessSteps.KBA.toString()}
                    hidden={
                        !(
                            signingRequiredForSigner &&
                            (
                                signatureFormSelectionType == SignatureType.ESignWhenAllIncludedReturnsAllowed
                                && this.props.taxReturn.engagementType == EngagementTypeS.E1040
                                && !this.props.isKBAPassed
                                && this.props.documentSettings.documentSignatureSetting.knowledgeBasedAuthentication
                            )
                        )
                    }
                    hideNextButton={true}
                    hidePreviousButton={
                        this.props.kbaData.transactionStatus.toString() !==
                        KBATransactionResultType[KBATransactionResultType.error]
                    }
                    disableNavigationClick={true}
                    previousLabel={Common.Wizard.PreviousButtonLabel}
                    nextLabel={Common.Wizard.NextButtonLabel}>
                    {paymentAlert}
                    <KBAComponent
                        match={this.props.match}
                        loadKBAQuestions={this.props.clientAuthentication}
                        validateKBAAnswers={this.props.clientValidation}
                        response={this.props.kbaData}
                        clearParentState={this.clearState}
                        refreshKBAStatus={this.props.requestKBAStatus}
                        onCompletion={this.goToNextStep}
                        updateDocumentSignatureSettingModel={this.props.updateDocumentSignatureSettingModel}
                        onKBAFailure={this.onKBAFailure}
                        refreshTaxDocument={this.props.requestTaxDocument}
                        updateLastVisitedStep={this.props.updateLastVisitedStep}
                    />
                </Step>
            }

            {
                this.props.taxReturn.id > 0 &&
                <Step id={SignProcessSteps.Esign.toString()}
                    hidden={
                        this._taxpayerSignFlowManager.isSigningProcessCompleted() ||
                        !(
                            signingRequiredForSigner &&
                            (
                                signatureFormSelectionType == SignatureType.ESignWhenAllIncludedReturnsAllowed
                            )
                        )
                    }
                    disableNextButton={completedPercentage < 100}
                    disableNavigationClick={true}
                    preventNextButtonDefaultBehaviour={true}
                    onNext={this.onEsignWizardNextBtnClick}
                    previousLabel={Common.Wizard.PreviousButtonLabel}
                    nextLabel={Common.Wizard.NextButtonLabel} hideScrollbar={true}>
                    {paymentAlert}
                    <Esign
                        ref={(ref: any) => { this._esignRef = ref }}
                        match={this.props.match}
                        history={this.props.history}
                        isPreviewMode={this.state.isPreviewMode}
                        onHeaderAndFooterVisibility={this.handleHeaderAndFooterVisibility}
                        requestSignatureControls={this.props.requestSignatureControls}
                        signatureControlsData={this.props.signatureControlsData}
                        onSigningComplete={this.props.updateSignatureControlsData}
                        disableNextButton={this.disableNextButton}
                        completeSigningStep={this.onEsignComplete}
                        isSpouseSignRequired={spouseOperationRequired}
                        sign={this.props.sign}
                        headerInfo={this.props.headInfoViewModel}
                        engagementType={this.props.taxReturn.engagementType}
                    />
                </Step>
            }

            {
                this.props.taxReturn.id > 0 &&
                <Step id={SignProcessSteps.SpouseSignature.toString()}
                    name={"Sign"} tooltip={"Sign"}
                    template={
                        this._taxpayerSignFlowManager.isSigningProcessCompleted()
                        && spouseOperationRequired
                        && <SignStepIcon className="step-icon" />
                    }
                    hidden={!(spouseOperationRequired)}
                    disableNavigationClick={true}
                    preventNextButtonDefaultBehaviour={true}
                    onNext={this.onSpouseSignRequiredNextBtnClick}
                    previousLabel={Common.Wizard.PreviousButtonLabel}
                    nextLabel={!paymentSectionDisabled ? Common.Wizard.NextButtonLabel : Common.Wizard.Submit}
                >
                    {paymentAlert}
                    <SpouseSignRequired
                        ref={(ref: any) => { this._spouseSignRequiredRef = ref }}
                        companyName={this.props.headInfoViewModel.companyName}
                        refreshTaxDocument={this.props.requestTaxDocument}
                        mutual={this.props.taxReturn as TaxDocument.IMarriedJointTaxReturn}
                        match={this.props.match}
                        isPreviewMode={this.state.isPreviewMode}
                        updateSpouseMailChange={this.props.updateSpouseMail}
                        updateSpouseMobileNumber={this.props.updateSpouseMobileNumber}
                        signatureControlsData={this.props.signatureControlsData}
                        sign={this.props.sign}
                        gotoNextStep={!paymentSectionDisabled ? this.goToNextStep : this.goToCompletionWizard}
                        signerData={this.props.signerData}
                        signingCompleted={() => this._taxpayerSignFlowManager.isSigningProcessCompleted()}
                        nextSignerExists={nextSignerExists}
                        signingRequiredForSigner={signingRequiredForSigner}
                        signatureFormSelectionType={signatureFormSelectionType}
                        review={this.props.review}
                        goToStep={this.goToStep}
                        relatedTaxReturnDelivered={relatedTaxReturnDelivered}
                        voucherExists={voucherExists}
                        goToCompletionWizard={this.goToCompletionWizard}
                        history={this.props.history}
                        isClientView={false}
                        signatureStatus={this.props.taxReturn.signatureStatus}
                    />
                </Step>
            }

            {
                this.props.taxReturn.id > 0 &&

                <Step id={SignProcessSteps.SignComplete.toString()}
                    name={"Sign"} tooltip={"Sign"}
                    template={this._taxpayerSignFlowManager.isSigningProcessCompleted()
                        && <SignStepIcon className="step-icon" />}
                    hidden={!(signingRequiredForSigner && !this.hideSignComplete())}
                    disableNavigationClick={true}
                    previousLabel={Common.Wizard.PreviousButtonLabel}
                    nextLabel={!paymentSectionDisabled ? Common.Wizard.NextButtonLabel : Common.Wizard.Submit}
                    preventNextButtonDefaultBehaviour={true}
                    onNext={!paymentSectionDisabled ? this.confirmRedirectToPay : () => this.goToCompletionWizard()}
                >
                    {paymentAlert}

                    <SignatureCompletedPage
                        companyName={this.props.headInfoViewModel.companyName} />

                </Step>
            }

            {
                this.props.taxReturn.id > 0 &&
                <Step id={SignProcessSteps.ManualSignDownload.toString()}
                    name={"Sign"} tooltip={"Sign"}
                    template={<SvgIconSignWizardActive className={"step-icon"} />}
                    disableNextButton={true}
                    previousLabel={Common.Wizard.PreviousButtonLabel}
                    nextLabel={Common.Wizard.NextButtonLabel}
                    hidden={
                        !(signatureFormSelectionType == SignatureType.ManualSign)
                        || this._taxpayerSignFlowManager.isLinkVisited(SignProcessSteps.ManualSign)
                    }>
                    {paymentAlert}

                    <ManualSignDownloadFiles
                        downloadEfileForms={this.props.downloadEfileForms}
                        clientId={param.clientId}
                        goToStepById={this.goToStepById}
                        additionalEsignDocuments={this.props.additionalEsignDocuments}
                        downloadAllAdditionEsignDocuments={this.props.downloadAllAdditionEsignDocuments}
                        clientName={this.props.headInfoViewModel.clientName}
                        taxReturn={this.props.taxReturn}
                        isPreviewMode={this.state.isPreviewMode}
                    />

                </Step>
            }

            {
                this.props.taxReturn.id > 0 &&
                <Step id={SignProcessSteps.ManualSign.toString()}
                    name={"Sign"} tooltip={"Sign"}
                    template={
                        this._taxpayerSignFlowManager.isLinkVisited(SignProcessSteps.ManualSign) &&
                        <SvgIconDownloadWizardActive className={"step-icon"} />
                    }
                    hidden={
                        !(
                            signatureFormSelectionType == SignatureType.ManualSign
                        )
                    }
                    preventNextButtonDefaultBehaviour={true}
                    previousLabel={Common.Wizard.PreviousButtonLabel}
                    nextLabel={!paymentSectionDisabled ? Common.Wizard.NextButtonLabel : Common.Wizard.Submit}
                    onNext={this.confirmRedirectToPay}
                    disableNextButton={this.state.isNextButtonDisable}
                    disableNavigationClick={true}>
                    {paymentAlert}

                    <StepLayout>

                        <Body title={ManualSignConstants.Title}
                            lg={10} md={12} sm={12} xs={12}
                            automationTestId={"11BB28B5-42F3-4571-B567-20700B1740B6"} >
                            <ManualSign match={this.props.match}
                                downloadEfileForms={this.props.downloadEfileForms}
                                addSignedDocument={this.props.addSignedDocument}
                                deleteSignedDocument={this.props.deleteSignedDocument}
                                requestSignedDocuments={this.props.requestSignedDocuments}
                                signedDocuments={this.props.manualSignData}
                                completeManualSign={this.props.completeManualSign}
                                onCompletion={!paymentSectionDisabled ? this.goToNextStep : this.goToCompletionWizard}
                                ref={(ref: any) => { this._manualSign = ref }}
                                additionalEsignDocuments={this.props.additionalEsignDocuments}
                                downloadAllAdditionEsignDocuments={this.props.downloadAllAdditionEsignDocuments}
                                requestAdditionalEsignDocument={this.props.requestAdditionalEsignDocument}
                                clientName={this.props.headInfoViewModel.clientName}
                                taxReturn={this.props.taxReturn}
                                isPreviewMode={this.state.isPreviewMode}
                                isDelegatee={false}
                                disableNextButton={this.disableNextButton}
                                companyInfo={this.props.companyData}
                            />
                        </Body>
                    </StepLayout>

                </Step>
            }

            {
                this.props.taxReturn.id > 0 &&
                <Step id={SignProcessSteps.Pay.toString()}
                    tooltip={this.toolTipForPay(relatedTaxReturnDelivered, voucherExists)}
                    disablePreviousButton={this.state.isDelegateeAssigned}
                    name={"Pay"}
                    template={!paymentSectionDisabled ?
                        <SvgIconMakePaymentsWizardActive className={"step-icon"} /> :
                        <SvgIconMakePaymentsWizardActive className={"step-icon cursor-disable"} fillColor={"#D3D3D3"} outerSecondaryColor={"#999999"} secondaryColor={"#0973BA"} primaryColor={"#90EE90"} />
                    }
                    disableNavigationClick={!paymentSectionDisabled ? this.disableNavigationLink(SignProcessSteps.Pay) : true}
                    previousLabel={Common.Wizard.PreviousButtonLabel}
                    nextLabel={Common.Wizard.NextButtonLabel}
                    preventPreviousButtonDefaultBehaviour={true}
                    onPrevious={this.handlePayPreviousBtn}>

                    <StepLayout>

                        <Body
                            lg={11} md={12} sm={12} xs={12}
                            noBackgroundColor={true}
                            bodyClassName={"no-padding no-margin pay-step row"}
                            automationTestId={"11BB28B5-42F3-4571-B567-20700B1740B6"}>

                            <Header lg={12} md={12} sm={12} xs={12} >
                                <PaymentInstruction clientInstructions={this.props.payScreenData.paymentInstructions} />
                            </Header>

                            <PayWizard.Pay
                                ref={(ref: any) => { this._payRef = ref }}
                                commonData={this.props.commonData} match={this.props.match}
                                history={this.props.history} payScreenData={this.props.payScreenData}
                                requestPayScreenDetails={this.props.requestPayScreenDetails}
                                documentSettings={this.props.documentSettings}
                                nextStep={this.goToCompletionWizard}
                                prevStep={this.goToPreviousStep}
                                taxReturn={this.props.taxReturn}
                                downloadAllVouchers={this.props.downloadAllVouchers}
                                downloadFilingInstructions={this.props.downloadFilingInstructions}
                                updateVoucherNotificationSettings={this.props.updateVoucherNotificationSettings}
                                downloadSingleVoucher={this.props.downloadSingleVoucher}
                                saveVoucherPayment={this.saveVoucherPayment}
                                isPreviewMode={this.state.isPreviewMode}
                            />

                        </Body>

                    </StepLayout>

                </Step>
            }

        </Wizard>);
    }

}
