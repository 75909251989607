import { History } from 'history';
import * as React from 'react';
import { match } from 'react-router';
import Tour, { ReactourStep } from 'reactour';
import { SignProcessSteps } from '../../Home/TaxpayerSignFlowManager';
import { ITutorialInfo } from '../../../core/domain/models/ITutorialInfo';
import { ICommonDataViewModel } from '../../../core/domain/viewModels/ICommonDataViewModel';
import { IHeaderInfoViewModel } from '../../../core/domain/viewModels/IHeaderInfoViewModel';
import { ISignProcessInfoViewModel } from '../../../core/domain/viewModels/ISignProcessInfoViewModel';
import { IDelegateeSignerDetails } from '../../../core/domain/models/DelegateeModels';
import { SummaryTurorialConstants } from '../../Common/Constants';
import { Main } from '../../Summary/Main/Main';
import { Welcome } from './Welcome';
import { logger } from '../../../routes';
import { LogEventConstants } from '../../Helper/Constants';

export interface WizardBodyProps {
    delegateeSignerInfo: IDelegateeSignerDetails,
    match: match;
    history: History;
    goToStep: (stepNumber: number) => void;
    goToStepById: (id: any) => void;
    signProcessInfo: ISignProcessInfoViewModel,
    tutorialInfo: ITutorialInfo,
    hideSummaryTutorial: (clientId: string, doNotShowNextTime: boolean) => any;
    headerInfo: IHeaderInfoViewModel;
}
export interface WizardBodyState {
    isTourOpen: boolean,
    isDoNotShowCkbChecked: boolean
}

const NO_INDEX = -1;

const pageTitle = "Delegatee Summary Wizard";
export class WizardBody extends React.Component<WizardBodyProps, WizardBodyState> {
    constructor(props: WizardBodyProps) {
        super(props);
        this.state = {
            isTourOpen: true,
            isDoNotShowCkbChecked: false
        }
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.Open}  ${pageTitle}`,
                { count: 1, page: pageTitle }
            )
        );
    }

    static getDerivedStateFromProps(nextProps: WizardBodyProps, prevState: WizardBodyState) {
        const sideMenu: any = document.getElementById("openSidebarMenu");
        if (sideMenu) {
            sideMenu.checked = true;
        }
        return { isTourOpen: false };
    }

    goToStep = (step: SignProcessSteps) => {
        let index = NO_INDEX;
        index = this.props.signProcessInfo.visitedSteps && this.props.signProcessInfo.visitedSteps.indexOf(step);

        if (index != null && index != NO_INDEX) {
            this.props.goToStepById(step);
        }
        else {
            this.props.goToStepById(SignProcessSteps.PreparerMessage.toString());
        }
        logger.buildEvent(
            `${LogEventConstants.Common.ClickNext}  ${pageTitle}`,
            { count: 1, page: pageTitle }
        );
    }

    onTutorialDone = () => {
        const param: any = this.props.match.params;
        if (param.clientId) {
            this.props.hideSummaryTutorial(param.clientId, this.state.isDoNotShowCkbChecked);
        }
    }

    onDoNotShowCkbChecked = (event: any) => {
        this.setState({ isDoNotShowCkbChecked: event.target.checked });
    }

    getTaxYearModel = (goTo: any) => {
        return (
            <div>
                <div className="tutorial-header"><span>{SummaryTurorialConstants.TaxYearModal.header}</span></div>
                <div className="tutorial-body"><p>{SummaryTurorialConstants.TaxYearModal.body}</p></div>
                <div className="tutorial-footer">
                    <div className="tutorial-checkbox">
                        <input type="checkbox" id="chkDoNotShowInst" name="chkDoNotShowInst" className='form-check-inline' onChange={this.onDoNotShowCkbChecked} checked={this.state.isDoNotShowCkbChecked} />
                        <span className="tutorial-checkmark"></span>
                        <label className="tutorial-checkbox-label" htmlFor="chkDoNotShowInst"> {SummaryTurorialConstants.TaxYearModal.doNotShowInstruction} </label>

                        <button className="btn btn-primary flrt" data-test-auto="8a19e5ba-5523-4391-857d-dea4f4fa262b"
                            onClick={() => { if (this.state.isDoNotShowCkbChecked) { this.onTutorialDone() } else { goTo(1) } }}>
                            {this.state.isDoNotShowCkbChecked ?
                                SummaryTurorialConstants.Buttons.done : SummaryTurorialConstants.Buttons.next}
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    public render() {
        const steps: ReactourStep[] = [];
        return (
            <div id="welcomeMessage" className="row width-100 summary-step-container center-align">

                <Main>
                    <Welcome
                        clientName={this.props.delegateeSignerInfo.firstName + " " + this.props.delegateeSignerInfo.lastName}
                        taxYear={this.props.headerInfo.taxYear}
                        goToStep={this.goToStep}
                        goToStepById={this.props.goToStepById}
                    />
                </Main>
                <Tour
                    steps={steps}
                    isOpen={this.state.isTourOpen}
                    onRequestClose={() => { }}
                    disableDotsNavigation={true}
                    showNavigation={false}
                    showNumber={false}
                    showCloseButton={false}
                    showButtons={false}
                    closeWithMask={false}

                />
            </div>
        );
    }
}
