import * as React from 'react';
import { ManualSignConstants } from '../../Common/Constants';

interface UploadHeaderProps {
    minimized?: boolean;
    isAllFileUploaded?: boolean;
}

export const UploadHeader: React.FunctionComponent<UploadHeaderProps> = ({
    minimized, isAllFileUploaded
}) => {

    let fileUploadArea = () => {

        return isAllFileUploaded && ((minimized ? (<div data-test-auto="609FAE9E-6540-469A-BA40-922DC11D1E68" className={"filepicker upload-area minimized"}>
            <div><h6>{ManualSignConstants.UploadAreaMinimizedText}</h6></div>
        </div>) : (<React.Fragment><div className={"filepicker upload-area d-none d-lg-block d-md-block"}
            data-test-auto="FB2FA195-8F1A-4422-964D-3921950C8F8D">
            <div><h5> {ManualSignConstants.UploadAreaText}</h5></div>
            <br />
            <div><strong> {ManualSignConstants.UploadAreaTextSeperation} </strong></div>
            <br />
            <div><button className={"btn-ssr"} data-test-auto={"C113515F-B901-4B2C-8DC2-E7F3F3ECCD80"}>
                {ManualSignConstants.UploadAreaButtonText}
            </button>
            </div>
        </div>

            <div className={"filepicker upload-area d-sm-block d-md-none"} data-test-auto="FB2FA195-8F1A-4422-964D-3921950C8F8D">
                <div><h6>{ManualSignConstants.UploadAreaMinimizedText}</h6></div>
            </div>
        </React.Fragment>)));
    }


    return (<div className={"dz-open-file-browse"}>
        {fileUploadArea()}
    </div>);
};