import { AxiosResponse } from 'axios';
import { Action, Reducer } from 'redux';
import { actionTypes } from './../../../ActionTypes';
import { NotificationAction, StatusType } from './../../../Common/NotificationStore';
import { AppThunkAction } from './../../../index';
import { DisplayError, SSNPageConstants } from "./../../../../components/Common/Constants";
import {
    initialSSNPageStoreState, LoginResponse,
    ReceiveSSNPageDetailsAction, SSNPageLoaderAction, SSNPageStoreState,
    ErrorMessageAction
} from '../../Models/StoreModels';
import { initializeAxios } from 'src/core/services/dataAccess/DataService.Axios';

type KnownAction =
    DispatchAction |
    NotificationAction |
    ErrorMessageAction;

type DispatchAction =
    ReceiveSSNPageDetailsAction
    | SSNPageLoaderAction;

export const actionCreators = {
    requestSSNData: (clientId: string, errorCallBack: (message: string) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.SSN_PAGE_LOADER, loading: true });
        return initializeAxios().get<any>("api/Coverpage/SSN/" + clientId)
            .then((response: AxiosResponse<LoginResponse>) => {
                const result = response.data;
                if (result.isSuccess) {
                    dispatch({
                        type: actionTypes.RECEIVE_SSN_PAGE_DETAILS,
                        data: { ...result.data, loading: false } as SSNPageStoreState
                    });
                } else {
                    const errorDesc = result.errorDescription;
                    const errorCode = result.errorCode;

                    if (errorCode && DisplayError.includes(errorCode)) {

                        dispatch({
                            type: actionTypes.SET_ERROR_MESSAGE,
                            message: errorDesc,
                        });
                        errorCallBack(errorDesc);
                    } else {
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: errorDesc,
                            statusType: StatusType.Error
                        });
                    }
                }
            })
            .catch((e: any) => {

                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: SSNPageConstants.ErrorMessage.serverError,
                    statusType: StatusType.Error
                });
            }
            );
    },
    verifySSN: (SSN: string, clientId: string, navigatePath: (target: string) => void, errorCallBack: (message: string) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const ssnStoreState = getState().ssnPageStore;

        return initializeAxios().postJson<any>({ ...ssnStoreState, ssn: SSN }, '/api/Coverpage/SSN/Validate/' + clientId)
            .then((response: any) => {

                const result = response.data;

                if (result.isSuccess) {

                    if (result.data === "OTP") {
                        navigatePath("OTP");
                    } else {
                        navigatePath("MobileOTP");
                    }
                } else {
                    const errorDesc = result.errorDescription;
                    const errorCode = result.errorCode;

                    if (errorCode && DisplayError.includes(errorCode)) {
                        dispatch({
                            type: actionTypes.SET_ERROR_MESSAGE,
                            message: errorDesc,
                        });
                        errorCallBack(errorDesc);
                    } else {

                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: errorDesc,
                            statusType: StatusType.Error,
                        });

                    }
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: SSNPageConstants.ErrorMessage.serverError,
                    statusType: StatusType.Error,
                });

            });
    }
};

export const reducer: Reducer<SSNPageStoreState> = (state: SSNPageStoreState = initialSSNPageStoreState, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    const currentState = Object.assign({}, state);
    switch (action.type) {

        case actionTypes.SSN_PAGE_LOADER:
            return { ...currentState, loading: action.loading };

        case actionTypes.RECEIVE_SSN_PAGE_DETAILS:
            return { ...currentState, ...action.data };

        default:
            return currentState || initialSSNPageStoreState;
    }
};
