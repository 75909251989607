import { ApplicationState } from "../../store/index";
import { actionCreators as SummaryStore } from '../../store/SummaryStore';
import { actionCreators as HelperStore } from '../../store/Common/HelperStore';
import { actionCreators as EsignStore } from '../../store/Common/EsignStore';
import { TaxpayerSignFlow } from "./TaxpayerSignFlow";
import { actionCreators as PreparerMessageStore } from '../../store/PreparerMessageStore';
import { connect } from 'react-redux';
import { createLoadingSelector } from '../../store/selectors';
import { actionCreators as DocumentReviewStore } from '../../store/ReviewStore';
import { actionCreators as TaxDocumentStore } from '../../store/Common/TaxDocumentStore';
import { actionCreators as ManualSignStore } from '../../store/Sign/ManualSignStore';
import { actionCreators as CompanyStore } from '../../store/Common/Company/CompanyStore';
import { IDocument } from "../../core/domain/models/esign/Document";
import { actionCreators as PayStore } from '../../store/PayStore';
import { IPaymentVoucherNotificationSettings } from "../../core/domain/models/IDocumentSettings";
import { ClientType, EngagementTypeS } from "src/core/common/Enums";
import { actionCreators as K1DistributeStore } from '../../store/K1/K1DistributeStore';
import { IK1ShareHolderDetails } from '../../core/domain/models/IK1Distribute';
import { actionCreators as SignProcessStore } from '../../store/SignProcess/SignProcessStore';
import { actionCreators as TutorialStore } from '../../store/Tutorial/TutorialStore';
import { actionCreators as CCRecipientStore } from '../../store/CCRecipientStore';
import { DocumentStatus } from '../../core/common/Enums';
import { ICCRecipientModel } from '../../core/domain/models/ICCRecipientModel';
import { IClientProcessViewModel } from '../../core/domain/viewModels/IClientProcessViewModel'
import { actionCreators as DownloadableDocumentsStore } from '../../store/DownloadableDocumentsStore';
import { IVoucher } from "src/core/domain/models/IGroup";
import { actionCreators as AccountProcessStore } from '../../store/Common/AccountStore';
import { actionCreators as SigningRequiredStore } from '../../store/SignProcess/SigningRequiredStore';
import { ClientTypesNumber, ISignerModel } from "../../core/domain/models/ISignerModel";
import { actionCreators as DelegateeSignerStore } from '../../store/Delegatee/DelegateeSignerStore';
import { IDelegateeSignerDetails } from '../../core/domain/models/DelegateeModels';

const loadingSelector = createLoadingSelector(['HEADERINFO', 'SUMMARY_DETAILS',
    'TAXING_AUTHORITY', 'DOCUMENT_REVIEW_MODEL', 'DOCUMENT_SETTINGS']);

function mapStateToProps(state: ApplicationState) {
    return {
        summaryData: state.summaryData,
        esignData: state.esignData.data,
        signerData: state.esignData.signerDetails,
        manualSignData: state.manualSignData.data,
        companyData: state.companyData.data,
        kbaData: state.kbaData,
        signatureControlsData: state.esignData.controlsData,
        loading: loadingSelector(state),
        commonData: state.commonData,
        signatureSettingModel: state.signatureSettingData,
        documentSettings: state.documentSettings,
        documentReviewModel: state.documentReviewModel,
        headInfoViewModel: state.headerInfo,
        taxReturn: state.taxReturn.taxDocument,
        isKBAPassed: state.taxReturn.isKBAPassed,
        k1Data: state.k1Data.data,
        signProcessData: state.signProcessData.data,
        payScreenData: state.payScreenData,
        signProcessInfo: state.signProcessData.clientprocessmodel.currentstep,
        loggedInUserInfo: state.headerInfo.loggedInUserInfo,
        tutorialInfo: state.tutorialInfo,
        additionalEsignDocuments: state.downloadableDocuments.additionalEsignDocuments,
        ccRecipientDownloadableDocuments: state.ccRecipientDownloadableDocuments,
        signingRequiredState: state.signingRequiredStore,
        showPaymentAlert: state.taxReturn.showPaymentAlert
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        requestSummaryDetails: (clientId: string) =>
            dispatch(SummaryStore.requestSummaryDetails(clientId)),

        requestAllTaxingAuthorities: (id: string) =>
            dispatch(HelperStore.requestAllTaxingAuthorities(id)),

        clientAuthentication: (clientGuid: string, failureCallback: () => void) =>
            dispatch(EsignStore.clientAuthentication(clientGuid, failureCallback)),

        clientValidation: (clientGuid: string, data: any, successCallback: () => void, failureCallback: () => void) =>
            dispatch(EsignStore.clientValidation(clientGuid, data, successCallback, failureCallback)),

        requestSignatureControls: (clientGuid: string) =>
            dispatch(EsignStore.requestSignatureControls(clientGuid)),

        updateDOB: (clientGuid: string, id: string, dob: string, step: boolean, callback: (step: boolean) => void) =>
            dispatch(EsignStore.updateDOB(clientGuid, id, dob, step, callback)),

        updateDocumentSignatureSettingModel: (clientId: string, signatureType: number, callback: any) =>
            dispatch(EsignStore.updateDocumentSignatureSettingModel(clientId, signatureType, callback)),

        updateSignatureControlsData: (data: any) =>
            dispatch(EsignStore.updateSignatureControlsData(data)),

        updateSpouseMail: (clientGuid: string, id: string, mail: string, type: ClientType, step?: boolean, callback?: (step: boolean) => void) =>
            dispatch(EsignStore.updateSpouseMail(clientGuid, id, mail, type, step, callback)),

        updateSpouseMobileNumber: (clientGuid: string, id: string, number: string, countryCodeValue: string, ssnValue: string, clientType: ClientType, callback: () => void) =>
            dispatch(EsignStore.updateSpouseMobileNumber(clientGuid, id, number, countryCodeValue, ssnValue, clientType, callback)),

        updateMobileNumber: (clientGuid: string, id: string, number: string, countryCodeValue: string, ssnValue: string, clientType: ClientType, callback: () => void) =>
            dispatch(AccountProcessStore.updateMobileNumber(clientGuid, id, number, countryCodeValue, ssnValue, clientType, callback)),

        downloadAllAttachments: (clientId: string, attachments: any) =>
            dispatch(DocumentReviewStore.downloadAllAttachments(clientId, attachments)),

        downloadIndividualAttachment: (clientId: string, attachment: any) =>
            dispatch(DocumentReviewStore.downloadIndividualAttachment(clientId, attachment)),

        requestDocumentReviewModel: (clientId: string) =>
            dispatch(DocumentReviewStore.requestDocumentReviewModel(clientId)),

        requestDocumentSettings: (clientId: string) =>
            dispatch(PreparerMessageStore.requestDocumentSettings(clientId)),

        requestKBAStatus: (clientGuid: string, successCallback?: () => void) =>
            dispatch(TaxDocumentStore.requestKBAStatus(clientGuid, successCallback)),

        requestTaxDocument: (clientGuid: string, callback?: (data?: any) => void) =>
            dispatch(TaxDocumentStore.requestTaxDocument(clientGuid, callback)),

        requestSigners: (clientGuid: string, callback?: (signers: ISignerModel[]) => void) =>
            dispatch(EsignStore.requestSigners(clientGuid, callback)),

        addSignedDocument: (clientGuid: string, signedDocument: any, successCallback?: () => void, failureCallback?: () => void) =>
            dispatch(ManualSignStore.addSignedDocument(clientGuid, signedDocument, successCallback, failureCallback)),

        completeManualSign: (clientGuid: string, successCallback?: () => void, failureCallback?: () => void) =>
            dispatch(ManualSignStore.completeManualSign(clientGuid, successCallback, failureCallback)),

        deleteSignedDocument: (clientGuid: string, id: number, successCallback?: () => void, failureCallback?: () => void) =>
            dispatch(ManualSignStore.deleteSignedDocument(clientGuid, id, successCallback, failureCallback)),

        requestSignedDocuments: (clientGuid: string, forceRefresh?: boolean) =>
            dispatch(ManualSignStore.requestSignedDocuments(clientGuid, forceRefresh)),

        requestCompanyDetails: (clientGuid: string, forceRefresh?: boolean) =>
            dispatch(CompanyStore.requestCompanyDetails(clientGuid, forceRefresh)),

        requestK1ShareHolderDetails: (clientGuid: string, pageNo: number, pageSize: number, sortBy: string, sortOrder: string, searchText: string) =>
            dispatch(K1DistributeStore.requestK1ShareHolderDetails(clientGuid, pageNo, pageSize, sortBy, sortOrder, searchText)),

        updateK1ShareHolderEmail: (clientGuid: string, k1Partner: IK1ShareHolderDetails, successCallback?: () => void) =>
            dispatch(K1DistributeStore.updateK1ShareHolderEmail(clientGuid, k1Partner, successCallback)),

        updateK1ShareHolderDetail: (clientGuid: string, k1Partner: IK1ShareHolderDetails, isEmailChange: boolean, callBack?: () => void) =>
            dispatch(K1DistributeStore.updateK1ShareHolderDetail(clientGuid, k1Partner, isEmailChange, callBack)),

        downloadK1DocumentAsync: (clientId: string, engagementTpe: EngagementTypeS) =>
            dispatch(K1DistributeStore.downloadK1DocumentAsync(clientId, engagementTpe)),

        downloadIndividualK1DocumentAsync: (clientId: string, shareHolder: IK1ShareHolderDetails, engagementTpe: EngagementTypeS) =>
            dispatch(K1DistributeStore.downloadIndividualK1DocumentAsync(clientId, shareHolder, engagementTpe)),

        sendMailToShareHolder: (clientId: string, partners: IK1ShareHolderDetails[], callback: () => void) =>
            dispatch(K1DistributeStore.sendMailToShareHolder(clientId, partners, callback)),

        downloadSelectedK1DocumentAsync: (
            clientId: string,
            shareHolders: IK1ShareHolderDetails[],
            engagementTpe: EngagementTypeS,
            callback?: () => void) =>
            dispatch(K1DistributeStore.downloadSelectedK1DocumentAsync(
                clientId,
                shareHolders,
                engagementTpe,
                callback)),

        sign: (clientId: string, documentData: IDocument[], callback: (status: boolean) => void) =>
            dispatch(EsignStore.sign(clientId, documentData, callback)),

        requestLastVisitedStep: (clientGuid: string, successCallback?: (lastVisitedStep: number) => void) =>
            dispatch(SignProcessStore.requestLastVisitedStep(clientGuid, successCallback)),

        updateLastVisitedStep: (clientGuid: string, step: number, successCallback?: () => void) =>
            dispatch(SignProcessStore.updateLastVisitedStep(clientGuid, step, successCallback)),

        requestPayScreenDetails: (clientId: string) =>
            dispatch(PayStore.requestPayScreenDetails(clientId)),

        downloadAllVouchers: (clientId: string) =>
            dispatch(PayStore.downloadAllVouchers(clientId)),

        downloadFilingInstructions: (clientId: string) =>
            dispatch(PayStore.downloadFilingInstructions(clientId)),

        updateVoucherNotificationSettings: (
            clientId: string,
            setting: IPaymentVoucherNotificationSettings, callback: () => void) =>
            dispatch(PayStore.updateVoucherNotificationSettings(clientId, setting, callback)),

        downloadSingleVoucher: (clientId: string, fileName: string, pageNo: string, bookMarks: string) =>
            dispatch(PayStore.downloadSingleVoucher(clientId, fileName, pageNo, bookMarks)),

        downloadEfileForms: (clientGuid: string, successCallback?: () => void) =>
            dispatch(ManualSignStore.downloadEfileForms(clientGuid, successCallback)),

        addVoucherPayemnt: (clientId: string, voucher: IVoucher) =>
            dispatch(PayStore.addVoucherPayemnt(clientId, voucher)),

        updateVoucherPayemnt: (clientId: string, voucher: IVoucher) =>
            dispatch(PayStore.updateVoucherPayemnt(clientId, voucher)),

        requestCurrentStepAndUserRole: (
            clientGuid: string,
            successCallback?: (clientProcessState: IClientProcessViewModel) => void) =>
            dispatch(SignProcessStore.requestCurrentStepAndUserRole(clientGuid, successCallback)),

        requestTutorialInfo: (clientId: string) =>
            dispatch(TutorialStore.requestTutorialInfo(clientId)),

        hideReviewTutorial: (clientId: string, doNotShowNextTime: boolean) =>
            dispatch(TutorialStore.hideReviewTutorial(clientId, doNotShowNextTime)),

        hideSummaryTutorial: (clientId: string, doNotShowNextTime: boolean) =>
            dispatch(TutorialStore.hideSummaryTutorial(clientId, doNotShowNextTime)),

        requestAdditionalEsignDocument: (clientId: string) =>
            dispatch(DownloadableDocumentsStore.requestAdditionalEsignDocument(clientId)),

        downloadAllAdditionEsignDocuments: (clientId: string, fileName: string) =>
            dispatch(DownloadableDocumentsStore.downloadAllAdditionEsignDocuments(clientId, fileName)),

        requestCCRecipientDownloadableDocuments: (clientGuid: string, documentStatus: DocumentStatus
        ) => dispatch(CCRecipientStore.requestCCRecipientDownloadableDocuments(clientGuid, documentStatus)),

        forwardDocumentsToRecipients: (clientId: string, recipientInfo: ICCRecipientModel, callback: () => void) =>
            dispatch(CCRecipientStore.forwardDocumentsToRecipients(clientId, recipientInfo, callback)),

        downloadAllDocuments: (clientId: string, fileName: string) =>
            dispatch(DocumentReviewStore.downloadAllDocuments(clientId, fileName)),

        downloadSelectedDocuments: (clientId: string, documentGroups: number[]) =>
            dispatch(DocumentReviewStore.downloadSelectedDocuments(clientId, documentGroups)),

        review: (clientGuid: string, callback?: (status: boolean) => void) =>
            dispatch(EsignStore.review(clientGuid, callback)),

        checkSignatureRequiredForDocument: (clientGuid: string, callback?: (status: boolean) => void) =>
            dispatch(SigningRequiredStore.checkSignatureRequiredForDocument(clientGuid, callback)),

        checkSignatureRequiredForSigner: (clientGuid: string, callback?: (status: boolean) => void) =>
            dispatch(SigningRequiredStore.checkSignatureRequiredForSigner(clientGuid, callback)),

        checkNextSignerExists: (
            clientGuid: string,
            clientType: ClientTypesNumber,
            callback?: (status: boolean) => void) =>
            dispatch(SigningRequiredStore.checkNextSignerExists(clientGuid, clientType, callback)),

        assignToDelegatee: (
            clientId: string,
            delegatee: IDelegateeSignerDetails,
            callback: () => void) =>
            dispatch(DelegateeSignerStore.assignToDelegatee(clientId, delegatee, callback)),

        relatedTaxReturnDelivered: (
            clientId: string) =>
            dispatch(TaxDocumentStore.relatedTaxReturnDelivered(clientId)),

        togglePaymentAlert: (
            toggle: boolean) =>
            dispatch(TaxDocumentStore.togglePaymentAlert(toggle))
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(TaxpayerSignFlow);

/*export default withRouter(connect(
    (state: ApplicationState) => ({
        summaryData: state.summaryData,
        esignData: state.esignData.data,
        signerData: state.esignData.signerDetails,
        manualSignData: state.manualSignData.data,
        companyData: state.companyData.data,
        kbaData: state.kbaData,
        signatureControlsData: state.esignData.controlsData,
        loading: loadingSelector(state),
        commonData: state.commonData,
        signatureSettingModel: state.signatureSettingData,
        documentSettings: state.documentSettings,
        documentReviewModel: state.documentReviewModel,
        headInfoViewModel: state.headerInfo,
        taxReturn: state.taxReturn.taxDocument,
        isKBAPassed: state.taxReturn.isKBAPassed,
        k1Data: state.k1Data.data
    }),
    {
        ...SummaryStore.actionCreators,
        ...HelperStore.actionCreators,
        ...PreparerMessageStore.actionCreators,
        ...DocumentReviewStore.actionCreators,
        ...HelperStore.actionCreators,
        ...EsignStore.actionCreators,
        ...CompanyStore.actionCreators,
        ...ManualSignStore.actionCreators,
        ...TaxDocumentStore.actionCreators
        ...K1DistributeStore.actionCreators
    }
)(TaxpayerSignFlow));*/
