import * as React from 'react';
import Moment from 'moment/moment';
import { Row, Col } from 'react-bootstrap';
import { IPayScreenViewModel } from 'src/core/domain/viewModels/IPayScreenViewModel';
import { ITaxingAuthority } from 'src/core/domain/models/ITaxingAuthority';
import { IDocumentNotificationSetting } from 'src/core/domain/models/IDocumentSettings';
import { Shimmer } from '../Common/Shimmer/Shimmer';
import { IUtilities } from '../../core/utilities/Utilities';
import { IVoucher, VoucherTypes, DocumentGroups, CustomType, VoucherMode } from '../../core/domain/models/IGroup';
import { container } from '../../startup/inversify.config';
import { TYPES } from '../../startup/types';
import { CustomButton, CustomButtonPlacement } from '../Common/CustomButton/CustomButton';
import { Common, DownloadDocumentsConstants } from '../Common/Constants';
import { DropdownComponent } from '../Common/DropdownComponent';
import { ISelectItem } from '../../core/domain/viewModels/ICommon';
import { ITaxReturn } from 'src/core/domain/models/ITaxReturn';
import { PaymentInformationPopup } from './PaymentInformationPopup';
import { AuthorityImage, StateUSA, StateImages } from '../Common/Icons/StateImages';
import { SvgIconTaxPaymentsSummary, SvgIconLeftPointer, SvgIconPayTaxPrep } from '../Common/Icons/SvgIcons';
import { getDueDateBasedVouchers } from './PayHelper';
import { VoucherPaymentInfoPopup } from './VoucherPaymentInfoPopup';
import { MarsNotifier } from '../Common/Notification/MarsNotifier';
import { logger } from '../../routes';
import { LogEventConstants } from '../Helper/Constants';

const utilities = container.get<IUtilities>(TYPES.IUtilities);


export interface VoucherDetailsProps {
    payScreenData: IPayScreenViewModel;
    documentNotificationSetting: IDocumentNotificationSetting;
    taxingAuthorities: ITaxingAuthority[];
    selectedDate: string;
    selectedPaymentType: string;
    taxReturn: ITaxReturn;
    clientId: string;
    downloadAllVouchers: (clientId: string) => void;
    downloadFilingInstructions: (clientId: string) => void;
    downloadSingleVoucher: (clientId: string, fileName: string, pageNo: string, bookMarks: string) => void;
    HandleVoucherReminderEnable: (value: boolean) => void;
    HandleVoucherReminderDays: (value: number) => void;
    saveVoucherPayment: (voucher: IVoucher) => void;
    isPreviewMode: boolean;
}

interface VoucherDetailsState {
    selectedVouchers: IVoucher[];
    showInitialMessage: boolean;
    IsVoucherReminderEnable: boolean;
    reminderDays: ISelectItem[];
    showPaymentInfoPopup: boolean;
    taxingAuthority: ITaxingAuthority;
    voucher: IVoucher;
    voucherReminderDay: ISelectItem;
    showVoucherDiv: boolean;
    showVoucherPaidInfo: boolean;
}
export const Constants = {
    ReminderDays: 30,
    VoucherRemTxt1: "Email me reminder",
    VoucherRemTxt2: "days before payment is due"
}

const pageTitle = "Voucher Details";
export class VoucherDetails extends React.Component<VoucherDetailsProps, VoucherDetailsState> {
    constructor(props: VoucherDetailsProps) {
        super(props);
        this.state = {
            selectedVouchers: [],
            showInitialMessage: true,
            IsVoucherReminderEnable: false,
            reminderDays: [],
            voucherReminderDay: { key: "", value: "" },
            showPaymentInfoPopup: false,
            voucher: {
                amount: 0,
                authorityID: 0,
                authorityImage: '',
                authorityName: '',
                bookmark: '',
                customText: '',
                customType: 0,
                dueDate: new Date(),
                formName: '',
                groupId: 0,
                isMasterPaymentUrl: false,
                onlinePaymentUri: '',
                pageNo: [],
                paymentType: 0,
                vocherMode: 0,
                voucherNo: 0,
                amountPaid: 0,
                checkNumber: '',
                paidDate: new Date(),
                isPaid: false,
                signatureControls: []
            },
            taxingAuthority: {
                Id: 0,
                AuthorityName: "",
                isEsignEnabled: false,
                Abbreviation: "",
                PaymentURL: "",
                PrefixStateCode: false,
                Active: false,
                CustomAuthority: false,
                StateImage: "",
                InUse: 0
            },
            showVoucherDiv: false,
            showVoucherPaidInfo: false,
        }
    }


    componentDidMount() {
        this.fillReminderDropDown();
    }

    fillReminderDropDown() {
        let days: ISelectItem[] = [];
        for (var i = 1; i <= Constants.ReminderDays; i++) {
            let strNum = i.toString();
            days.push({ key: strNum, value: strNum });
        }
        this.setState({ reminderDays: days });
    }

    componentWillReceiveProps(nextProps: VoucherDetailsProps) {
        if (nextProps.selectedPaymentType !== "None"
            && nextProps.selectedPaymentType !== "") {
            const groupedVouchers = getDueDateBasedVouchers(nextProps.payScreenData.vouchers);

            const selectedVouchers = groupedVouchers.find(m => m.groupName === nextProps.selectedDate)!
                .vouchers.filter(n => n.paymentType === VoucherTypes[nextProps.selectedPaymentType as keyof typeof VoucherTypes])
                .sort(function (a, b) { return a.authorityID - b.authorityID; });

            this.setState({
                showInitialMessage: false,
                selectedVouchers: selectedVouchers,
            });
        }
        else if (nextProps.selectedPaymentType === "None") {
            this.setState({
                showInitialMessage: false,
            });
        }
        if (nextProps.documentNotificationSetting && nextProps.documentNotificationSetting.paymentVoucherNotificationSettingsModel) {
            const { enablePaymentVoucherReminder, noOfDaysForVoucherReminder } = nextProps.documentNotificationSetting.paymentVoucherNotificationSettingsModel;
            this.setState({
                IsVoucherReminderEnable: enablePaymentVoucherReminder,
                voucherReminderDay: {
                    key: noOfDaysForVoucherReminder.toString(), value: noOfDaysForVoucherReminder.toString()
                },
                showVoucherDiv: enablePaymentVoucherReminder
            });
        }
    }

    private onReminderDaysChange = (value: string) => {
        let val: number = parseInt(value);
        if (val === undefined || val === null) {
            val = 0;
        }
        if (!isNaN(val)) {
            this.props.HandleVoucherReminderDays(val);

            this.setState({
                voucherReminderDay: {
                    key: val.toString(), value: val.toString()
                }
            });
            logger.trackEvent(
                logger.buildEvent(
                    `${LogEventConstants.Common.Pay.VoucherReminderChanged}  ${pageTitle}`,
                    { count: 1, page: pageTitle, value: this.state.voucherReminderDay, clientId: this.props.clientId }
                )
            );
        }
    }

    onDownloadAllVouchers = () => {
        if (!this.props.isPreviewMode) {
            this.props.downloadAllVouchers(this.props.clientId);
            logger.trackEvent(
                logger.buildEvent(
                    `${LogEventConstants.Common.Pay.DownloadAllVoucher}  ${pageTitle}`,
                    { count: 1, page: pageTitle, clientId: this.props.clientId }
                )
            );
        }
        else {
            MarsNotifier.Warning(DownloadDocumentsConstants.DownloadNotAllowed, null);
        }
    }

    onDownloadFilingInstructions = () => {
        if (!this.props.isPreviewMode) {
            this.props.downloadFilingInstructions(this.props.clientId);
            logger.trackEvent(
                logger.buildEvent(
                    `${LogEventConstants.Common.Pay.DownloadFilingInstruction}  ${pageTitle}`,
                    { count: 1, page: pageTitle, clientId: this.props.clientId }
                )
            );
        }
        else {
            MarsNotifier.Warning(DownloadDocumentsConstants.DownloadNotAllowed, null);
        }
    }

    onDownloadIndividualVoucher = (clientId: string, fileName: string, pageNo: string, bookMarks: string) => {
        if (!this.props.isPreviewMode) {
            this.props.downloadSingleVoucher(clientId, fileName, pageNo, bookMarks);
            logger.trackEvent(
                logger.buildEvent(
                    `${LogEventConstants.Common.Pay.DownloadSelectedVoucher}  ${pageTitle}`,
                    { count: 1, page: pageTitle, clientId: this.props.clientId, fileName: fileName }
                )
            );
        }
        else {
            MarsNotifier.Warning(DownloadDocumentsConstants.DownloadNotAllowed, null);
        }
    }

    private onReminderEnable = (e: any) => {
        const isChecked = e.target.checked;
        this.props.HandleVoucherReminderEnable(isChecked);
        this.setState({ IsVoucherReminderEnable: isChecked });
    }

    private onPayClick = (taxAuthority: ITaxingAuthority, vouch: IVoucher) => {
        this.setState({ showPaymentInfoPopup: true, voucher: vouch, taxingAuthority: taxAuthority });
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.Pay.ClickPay}  ${pageTitle}`,
                { count: 1, page: pageTitle, voucher: vouch, taxingAuthority: taxAuthority, clientId: this.props.clientId }
            )
        );
    }

    private onPaymentInfoCancel = () => {
        this.setState({
            showPaymentInfoPopup: false
        });
    }

    private addVoucherPayement = (taxAuthority: ITaxingAuthority, voucher: IVoucher) => {
        this.setState({ showVoucherPaidInfo: true, voucher: voucher, taxingAuthority: taxAuthority });
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.Pay.ClickAddVoucher}  ${pageTitle}`,
                { count: 1, page: pageTitle, voucher: voucher, taxingAuthority: taxAuthority, clientId: this.props.clientId }
            )
        );
    }

    addVoucherPayementCancel = () => {
        this.setState({
            showVoucherPaidInfo: false
        });
    }

    public render() {

        const { taxReturn: { formGroups, invoiceAmount } } = this.props;

        const filingInstructionExists = formGroups.some(x => x.group === DocumentGroups.Transmittals);

        return (<>
            <div className="row no-margin margin-top-20">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 no-padding">
                    {
                        this.state.showVoucherDiv &&
                        <div className="checkbox-inline voucher-dropdown">
                            <input
                                type="checkbox"
                                id="chkReminder"
                                checked={this.state.IsVoucherReminderEnable}
                                className="voucher-checkbox"
                                onChange={this.onReminderEnable}
                                data-test-auto="317C2922-D91A-41DA-9072-470F39E36D4E"
                            /><label htmlFor="chkReminder">
                                &nbsp;{Constants.VoucherRemTxt1} &nbsp;<DropdownComponent
                                    options={this.state.reminderDays}
                                    onChange={this.onReminderDaysChange}
                                    selectedValue={this.state.voucherReminderDay.key}
                                    customPlaceHolder="Days"
                                    disabled={!this.state.IsVoucherReminderEnable}
                                    size="sm"
                                />
                                &nbsp;{Constants.VoucherRemTxt2}
                            </label>
                        </div>
                    }
                </div>

                <div
                    className="col-xs-12 col-sm-12 col-md-12 col-lg-8 no-padding custombutton-left" >
                    {
                        filingInstructionExists &&
                        <>
                            <CustomButton placement={CustomButtonPlacement.Right}
                                icon={Common.Images.download}
                                text="Download Filing Instructions"
                                onClick={this.onDownloadFilingInstructions}
                                automationTestId="CB0CC5B1-16D4-48B4-BB13-95FE4B323DD4"
                            />
                            &nbsp;
                        </>
                    }
                    <CustomButton placement={CustomButtonPlacement.Right}
                        icon={Common.Images.download}
                        text="Download All Payment Vouchers"
                        onClick={this.onDownloadAllVouchers}
                        automationTestId="A7E39EDC-22DF-4392-B20D-A7D595A4AC50" />
                </div>

            </div>
            <hr />
            <div>
                {
                    formGroups.find(m => m.group == DocumentGroups.Vouchers)?.forms?.length === 0 &&
                        invoiceAmount === 0 ?
                        <React.Fragment>
                            <div style={{ marginTop: "1%" }}>
                                <h6 className="section-heading">
                                    No Payment Vouchers
                                </h6>
                            </div>
                        </React.Fragment>

                        :

                        this.state.showInitialMessage ?

                            <React.Fragment>
                                {this.props.payScreenData.documentId === 0 ? <Shimmer height={340} /> :
                                    <div>
                                        <Row>
                                            <Col lg={12} md={12} sm={12} xs={12}
                                                style={{ textAlign: 'center', padding: '3%' }}>
                                                <SvgIconTaxPaymentsSummary
                                                    width={100}
                                                    height={100}
                                                    data-test-auto="CAAB95C3-CC00-49F4-BCBB-76FBAA71F662"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="row width-100" >
                                            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-2">
                                                <SvgIconLeftPointer
                                                    width={110}
                                                    className="svg-shadow" />
                                            </div>
                                            <div className="col-xs-12 col-sm-12 col-md-8 col-lg-10">
                                                <h4 className="section-heading"> Select a Due Date from the left panel </h4>
                                                <h6 className="section-heading">(Once selected, you will be able to view tax vouchers and make payments)</h6>
                                            </div>
                                        </Row>
                                    </div>
                                }
                            </React.Fragment>
                            :
                            /* Voucher Info */
                            (this.state.selectedVouchers.map((item, index) => {
                                const stateCode = this.props.taxingAuthorities.find(m => m.Id == item.authorityID)!.AuthorityName;
                                const authorityName = StateImages.find(s => s.stateUSA === stateCode)?.imageName;
                                let taxingAuthority: ITaxingAuthority = this.props.taxingAuthorities.find(m => m.Id == item.authorityID) as ITaxingAuthority;
                                return (<>
                                    <div className="pay-display-box pay-location" key={index}>
                                        <div className="pay-date"> Due {Moment.utc(item.dueDate).format("MM/DD/YY")}</div>
                                        {item.isPaid ? <i className="fa fa-check-circle voucher-paid-icon"></i> : ""}
                                        {item.vocherMode === VoucherMode.Recognized
                                            && (item.customType === CustomType.DoNotPayScheduledAutomaticWithdrawal
                                                || item.customType == CustomType.AddCustom)
                                            &&
                                            <div className="download-singlevoucher">
                                                <a data-test-auto="615A30F8-442F-4468-BC49-E22C63D1788C">
                                                    <i className="fa fa-download fa-1x"
                                                        onClick={() => {
                                                            this.onDownloadIndividualVoucher(this.props.clientId,
                                                                'Voucher_' + taxingAuthority.AuthorityName + '_' + Moment(item.dueDate).format('MM-DD-YYYY').toString(),
                                                                item.pageNo.toString(),
                                                                item.bookmark)
                                                        }}>
                                                    </i>
                                                </a>
                                            </div>
                                        }
                                        <div className="pay-icon">
                                            <AuthorityImage
                                                authoriyName={authorityName || "state-federal"} />
                                        </div>

                                        <div className="pay-fee-name"> {this.props.taxingAuthorities.find(m => m.Id == item.authorityID)!.AuthorityName} </div>
                                        <div className="pay-fee-amount"> ${utilities.formatCurrencyText(item.amount)}</div>

                                        {item.customType === CustomType.DoNotPayScheduledAutomaticWithdrawal ?
                                            <div className="pay-button" style={{ paddingLeft: "4px" }}>
                                                <span style={{ fontSize: "13px" }} //reducing font-size by 1px so as to fit the text inside the box
                                                    title="DO NOT PAY : Scheduled for automatic withdrawal">
                                                    <b> DO NOT PAY :</b> Scheduled for automatic withdrawal
                                                </span>
                                            </div>
                                            :
                                            item.isPaid ?
                                                (<button className="btn btn-pay-online btn-paid pointer-Stylling"
                                                    data-test-auto="36BEFCEE-3FDA-4F4F-8F59-A98BCC39A438">
                                                    Paid
                                                </button>) :
                                                <div className={item.customType === CustomType.OnlinePaymentRequired ? 'margin-top-15' : ' pay-button'}>

                                                    {item.customType === CustomType.OnlinePaymentRequired ?
                                                        (<button className=" btn btn-pay-online btn-secondary "
                                                            data-test-auto="4C8EF285-E158-4430-99E7-EEFBB38E9396"
                                                            onClick={() => this.onPayClick(taxingAuthority, item)}>
                                                            Pay Online (Required)
                                                        </button>)
                                                        : item.customType === CustomType.AddCustom ?
                                                            <span title={item.customText}>
                                                                {item.customText}
                                                            </span>
                                                            : (item.customType === CustomType.None || item.customType === CustomType.Select) &&
                                                            (<button onClick={() => { this.onPayClick(taxingAuthority, item) }}
                                                                data-test-auto="75A98C85-7EAB-483B-B3AA-80816B17A8AA"
                                                                className="btn btn-lg btn-iconed btn-secondary">Pay
                                                            </button>)
                                                    }
                                                </div>
                                        }

                                        {item.customType !== CustomType.DoNotPayScheduledAutomaticWithdrawal ?
                                            <div className="voucherPayementLinkContainer">
                                                {!item.isPaid ?

                                                    <span>
                                                        <a
                                                            data-test-auto="810437EC-C36F-464D-A9BA-8565E8F365A4"
                                                            className="voucherPayementLink"
                                                            onClick={() => this.addVoucherPayement(taxingAuthority, item)}>Add payment details</a>
                                                    </span> :
                                                    <span>
                                                        <a data-test-auto="0FD0EEEE-3F9C-4943-A1B3-EFE05EC854E0"
                                                            className="voucherPayementLink"
                                                            onClick={() => this.addVoucherPayement(taxingAuthority, item)}
                                                        >Update payment details</a>
                                                    </span>}
                                            </div> : ''
                                        }



                                    </div>
                                </>)
                            })
                            )}
            </div>

            <PaymentInformationPopup
                show={this.state.showPaymentInfoPopup}
                downloadFilingInstructions={this.onDownloadFilingInstructions}
                taxingAuthority={this.state.taxingAuthority}
                voucher={this.state.voucher}
                onCancel={this.onPaymentInfoCancel}
                clientId={this.props.clientId}
                downloadSingleVoucher={this.onDownloadIndividualVoucher}
                filingInstructionExists={filingInstructionExists}
            />

            <VoucherPaymentInfoPopup
                show={this.state.showVoucherPaidInfo}
                onCancel={this.addVoucherPayementCancel}
                taxingAuthority={this.state.taxingAuthority}
                voucher={this.state.voucher}
                saveVoucherPayment={this.props.saveVoucherPayment}
            />


        </>);
    }
}