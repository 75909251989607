import * as React from 'react';
import { match } from "react-router";
import { Row, FormLabel } from 'react-bootstrap';
import YearMonthDay from '../../Common/YearMonthDayComponent';
import { DateType } from '../../../core/domain/viewModels/ICommon';
import { StepLayout } from '../../Common/StepLayout/StepLayout';
import { Body } from '../../Common/StepLayout/Body';
import {
    IDelegateeInfo, IDelegateeConsentInfo,
    initialDelegateeConsentInfo, DelegateeConsentInfo
} from '../../../core/domain/models/DelegateeModels';
import { SignProcessSteps } from '../../Home/TaxpayerSignFlowManager';
import { isValidDOB } from '../../Common/Validations';
import { MarsNotifier } from '../../Common/Notification/MarsNotifier';
import { SiginingConstants, DelegateeSignerConstants } from '../../Common/Constants';
import { logger } from '../../../routes';
import { LogEventConstants } from '../../Helper/Constants';

export type KbaConsentFormProps = {
    match: match;
    nextStep: (id: any) => void;
    delegateeInfo: IDelegateeInfo;
    consentChecked: boolean;
    onConsentAgreed(isChecked: boolean): void;
    saveDelegateeConsentDetails(clientId: string, consentDetails: IDelegateeConsentInfo, callback: () => void): void;
}

export interface KbaConsentFormState {
    delegateeDetails: IDelegateeConsentInfo;
    isDOBUpdated: boolean;
}

const pageTitle = "Delegatee KBA Consent page";
export class KbaConsentForm extends React.Component<KbaConsentFormProps, KbaConsentFormState> {

    constructor(props: any) {
        super(props);

        this.state = {
            delegateeDetails: initialDelegateeConsentInfo,
            isDOBUpdated: false
        }
        const param: any = this.props.match.params;
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.Open}  ${pageTitle}`,
                { count: 1, page: pageTitle, clientId: param.clientId }
            )
        );
    }

    static getDerivedStateFromProps = (nextProps: KbaConsentFormProps, nextState: KbaConsentFormState) => {
        if (nextProps.delegateeInfo != null && nextProps.delegateeInfo != undefined &&
            nextState.delegateeDetails.email == "")
            return {
                delegateeDetails: DelegateeConsentInfo.create(nextProps.delegateeInfo.firstName,
                    nextProps.delegateeInfo.lastName, nextProps.delegateeInfo.email, "", "", "", "", new Date(), "")
            }
    }

    onChangeFirstName = (event: any) => {
        let delegateeConsent = { ...this.state.delegateeDetails };
        delegateeConsent.firstName = event.target.value;
        this.setState({ delegateeDetails: delegateeConsent });
    }

    onChangeLastName = (event: any) => {
        let delegateeConsent = { ...this.state.delegateeDetails };
        delegateeConsent.lastName = event.target.value;
        this.setState({ delegateeDetails: delegateeConsent });
    }

    onChangeHomeAddress = (event: any) => {
        let delegateeConsent = { ...this.state.delegateeDetails };
        delegateeConsent.homeAddress = event.target.value;
        this.setState({ delegateeDetails: delegateeConsent });
    }

    onChangeCity = (event: any) => {
        let delegateeConsent = { ...this.state.delegateeDetails };
        delegateeConsent.city = event.target.value;
        this.setState({ delegateeDetails: delegateeConsent });
    }

    onChangeState = (event: any) => {
        let delegateeConsent = { ...this.state.delegateeDetails };
        delegateeConsent.state = event.target.value;
        this.setState({ delegateeDetails: delegateeConsent });
    }

    onChangeZipCode = (event: any) => {
        let delegateeConsent = { ...this.state.delegateeDetails };
        delegateeConsent.zipCode = event.target.value;
        this.setState({ delegateeDetails: delegateeConsent });
    }

    onChangeSSN = (event: any) => {
        let delegateeConsent = { ...this.state.delegateeDetails };
        delegateeConsent.ssn = event.target.value;
        this.setState({ delegateeDetails: delegateeConsent });
    }

    onConsent = (e: any) => {
        this.props.onConsentAgreed(e.target.checked);
        const param: any = this.props.match.params;
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.Delegatee.KbaConsentCheck}  ${pageTitle}`,
                { count: 1, page: pageTitle, isChecked: e.target.checked, clientId: param.clientId }
            )
        );
    }

    handleDOBChange = (dob: string, type: DateType) => {
        let delegateeConsent = { ...this.state.delegateeDetails };
        const birthDate: Date = delegateeConsent.dateOfBirth;
        switch (type) {
            case DateType.Date:
                birthDate.setDate(parseInt(dob));
                delegateeConsent.dateOfBirth = birthDate;
                break;
            case DateType.Month:
                birthDate.setMonth(parseInt(dob) - 1);
                delegateeConsent.dateOfBirth = birthDate;
                break;
            case DateType.Year:
                birthDate.setFullYear(parseInt(dob));
                delegateeConsent.dateOfBirth = birthDate;
                break;
        }
        this.setState({ delegateeDetails: delegateeConsent, isDOBUpdated: true });
    }

    onNext = () => {
        if (!isValidDOB(this.state.delegateeDetails.dateOfBirth)) {
            MarsNotifier.Warning(SiginingConstants.WarningMessage.InvalidDOB, null);
            return;
        }
        if (this.state.delegateeDetails.ssn.trim() == "" || this.state.delegateeDetails.ssn.trim().length < 4) {
            MarsNotifier.Warning(SiginingConstants.WarningMessage.InvalidSSN, null);
            return;
        }
        else if (this.state.delegateeDetails.firstName.trim() == '') {
            MarsNotifier.Warning(DelegateeSignerConstants.WarningMessage.EmptyFirstName, "");
            return
        }
        const param: any = this.props.match.params;
        this.props.saveDelegateeConsentDetails(param.clientId, this.state.delegateeDetails,
            () => { this.props.nextStep(SignProcessSteps.KBA) });
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.Delegatee.KbaNext}  ${pageTitle}`,
                { count: 1, page: pageTitle, clientId: param.clientId }
            )  
        );  
    }

    public render() {
        return (
            <StepLayout>
                <Body lg={8} md={8} sm={8} xs={8}
                    containerClassName={"delegatee-kba-consent-wrapper"}
                    title={"Sign Tax Documents Electronically"} bodyClassName={"esign-consent-container"}>
                    <div className="esign-consent" data-test-auto="4FFB32DC-4674-4682-A047-FF94DAA7E259">
                        <div className="content-paragraph">
                            Before signing, you will need to verify your identity by answering a few short questions about yourself.
                        </div>
                        <div className="content-paragraph">
                            Please verify/enter the information requested and click continue to begin
                        </div>
                        <div style={{ marginTop: "20px" }}>
                            <Row className="form-group">
                                <div className="col-sm-4 col-md-4 col-lg-4">
                                    <span className="font600">First Name</span>
                                    <div>
                                        <input
                                            type="text"
                                            value={this.state.delegateeDetails.firstName}
                                            onChange={(e) => { this.onChangeFirstName(e) }}
                                            placeholder="Enter First Name"
                                        />
                                    </div>

                                </div>
                                <div className="col-sm-4 col-md-4 col-lg-4">
                                    <span className="font600">Last Name</span>
                                    <div>
                                        <input
                                            type="text"
                                            value={this.state.delegateeDetails.lastName}
                                            onChange={(e) => { this.onChangeLastName(e) }}
                                            placeholder="Enter Last Name"
                                        />
                                    </div>
                                </div>
                            </Row>

                            <Row className="form-group">
                                <div className="col-sm-4 col-md-4 col-lg-4">
                                    <span className="font600">Home Address</span>
                                    <div>
                                        <input
                                            type="text"
                                            value={this.state.delegateeDetails.homeAddress}
                                            onChange={(e) => { this.onChangeHomeAddress(e) }}
                                            placeholder="Enter Home Address"
                                        />
                                    </div>
                                </div>
                            </Row>

                            <Row className="form-group">
                                <div className="col-sm-4 col-md-4 col-lg-4">
                                    <span className="font600">City</span>
                                    <div>
                                        <input
                                            type="text"
                                            value={this.state.delegateeDetails.city}
                                            onChange={(e) => { this.onChangeCity(e) }}
                                            placeholder="Enter City"
                                        />
                                    </div>
                                </div>

                                <div className="col-sm-2 col-md-2 col-lg-2">
                                    <span className="font600">State</span>
                                    <div>
                                        <input
                                            type="text"
                                            value={this.state.delegateeDetails.state}
                                            onChange={(e) => { this.onChangeState(e) }}
                                            placeholder="Enter State"
                                        />
                                    </div>
                                </div>

                                <div className="col-sm-2 col-md-2 col-lg-2">
                                    <span className="font600">Zip Code</span>
                                    <div>
                                        <input
                                            type="text"
                                            value={this.state.delegateeDetails.zipCode}
                                            onChange={(e) => { this.onChangeZipCode(e) }}
                                            placeholder="Enter Zip Code"
                                        />
                                    </div>
                                </div>
                            </Row>


                            <Row className="form-group">
                                <div className="col-sm-4 col-md-4 col-lg-4">
                                    <span className="font600">Date of Birth</span>
                                    <YearMonthDay id="delegatee-dob"
                                        controlValue={this.state.delegateeDetails.dateOfBirth}
                                        onControlValueChange={this.handleDOBChange}
                                        customMonthClassName="delegatee-dob-dropdownselector"
                                        customDayClassName="delegatee-dob-dropdownselector"
                                    />

                                </div>

                                <div className="col-sm-4 col-md-4 col-lg-4">
                                    <span className="font600">Last 4 Digits of Social Security</span>
                                    <div>
                                        <input
                                            type="text"
                                            value={this.state.delegateeDetails.ssn}
                                            onChange={(e) => { this.onChangeSSN(e) }}
                                            placeholder="Enter Last 4"
                                            maxLength={4}
                                        />
                                    </div>
                                </div>
                            </Row>
                        </div>
                        <div>
                            <div className="consent-checkbox">
                                <input data-test-auto="D82B0A18-EDD9-4F7D-A5AB-7151CCD54526" type="checkbox" id="chkConsent"
                                    className='form-check-inline' onChange={this.onConsent} checked={this.props.consentChecked} />
                                <span className="checkmark"></span>
                                <FormLabel> I agree to provide my personal information in order to validate my identity. </FormLabel>
                            </div>
                            <div style={{ paddingLeft: 30 }}><i>Identity verification powered by LexisNexis</i></div>
                        </div>
                    </div>
                </Body>
            </StepLayout>);
    }
}