import * as React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { IK1ShareHolderDetails, initialK1ShareHolderDetails } from '../../core/domain/models/IK1Distribute';
import { MarsNotifier } from '../Common/Notification/MarsNotifier';
import { isValidEmailAddress, NullandEmptyCheck, validateMobileLength, ssnDisplay, einDisplay } from '../Common/Validations';
import { match } from 'react-router';
import { K1ShareHolderConstants, ValidationContants } from '../Common/Constants';
import { PhoneNumberComponent } from '../../components/Common/PhoneNumberComponent';
import { SelectComponent } from '../Common/SelectComponent';
import { EntityType } from '../../core/common/Enums';
import { CountryCodeOptions, EntityTypeOptions, k1StatusOptions } from '../Helper/HelperFunction';

interface IEditK1PartnerProps {
    showState: boolean;
    onCancel(): void;
    k1Partner: IK1ShareHolderDetails;
    match: match;
    type: string;
    updateK1ShareHolderDetail?: (
        clientGuid: string,
        k1Partner: IK1ShareHolderDetails,
        isEmailChange: boolean,
        callback: () => void
    ) => void;
    loadK1ShareHolderDetails(): void;
}
interface IEditK1PartnerState {
    shareHolder: IK1ShareHolderDetails;
    existingEmail: string;
    isEmailChange: boolean;
    isMobileChange: boolean;
    existingMobileNumber: string;
    exitsingCountryCode: string;
}


export class EditK1PartnerDetails extends React.Component<IEditK1PartnerProps, IEditK1PartnerState>{
    constructor(props: any) {
        super(props);
        this.state = {
            shareHolder: initialK1ShareHolderDetails,
            existingEmail: '',
            isEmailChange: false,
            isMobileChange: false,
            existingMobileNumber: '',
            exitsingCountryCode: ''
        }
    }

    componentWillReceiveProps(nextProps: IEditK1PartnerProps) {
        if (nextProps.k1Partner) {
            this.setState({
                shareHolder: nextProps.k1Partner,
                existingEmail: nextProps.k1Partner.email,
                existingMobileNumber: nextProps.k1Partner.mobileNumber,
                exitsingCountryCode: nextProps.k1Partner.countryCode,
            })
        }
    }

    onChangeAddress = (event: any) => {
        let temp = { ...this.state.shareHolder };
        temp.address = event.target.value;
        this.setState({
            shareHolder: temp
        })
    }

    onChangeEmail = (event: any) => {
        let temp = { ...this.state.shareHolder };
        temp.email = event.target.value;
        let emailChange = this.state.existingEmail === event.target.value ? false : true;
        this.setState({
            shareHolder: temp,
            isEmailChange: emailChange
        })
    }

    onChangeMobile = (value: any) => {
        let temp = { ...this.state.shareHolder };
        temp.mobileNumber = value;
        let change = this.state.existingMobileNumber === temp.mobileNumber && this.state.exitsingCountryCode === temp.countryCode ? false : true;
        this.setState({
            shareHolder: temp,
            isMobileChange: change
        })
    }

    onChangeCountryCode = (value: any) => {
        let temp = { ...this.state.shareHolder };
        temp.countryCode = value;
        let change = this.state.existingMobileNumber === temp.mobileNumber && this.state.exitsingCountryCode === temp.countryCode ? false : true;
        this.setState({
            shareHolder: temp,
            isMobileChange: change
        })
    }

    onChangeEntityType = (value: any) => {
        let temp = { ...this.state.shareHolder };
        temp.entityType = value;
        this.setState({
            shareHolder: temp
        })
    }

    onChangeK1Status = (value: any) => {
        let temp = { ...this.state.shareHolder };
        temp.k1Status = value;
        this.setState({
            shareHolder: temp
        })
    }

    onUpdatePartner = () => {
        if (this.validatePartner()) {
            let temp = { ...this.state.shareHolder };
            if (temp.entityType === EntityType.None) {
                temp.entityType = EntityType.Individual;
            }
            if (temp.k1Status === 0) {
                temp.k1Status = 3;
            }
            if (this.state.isMobileChange) {
                temp.isMobileVerify = false;
            }
            let param: any = this.props.match.params;
            this.props.updateK1ShareHolderDetail && this.props.updateK1ShareHolderDetail(param.clientId, temp, this.state.isEmailChange, this.props.loadK1ShareHolderDetails);
            this.props.onCancel();
        }
    }

    validatePartner() {
        if (!NullandEmptyCheck(this.state.shareHolder.email)) {
            MarsNotifier.Warning(K1ShareHolderConstants.WarningMessage.EmailAdrressWarning, null);
            return false;
        }
        else if (!isValidEmailAddress(this.state.shareHolder.email.trim())) {
            MarsNotifier.Warning(K1ShareHolderConstants.WarningMessage.InvalidEmailAddress, null);
            return false;
        }
        else if (NullandEmptyCheck(this.state.shareHolder.mobileNumber) && !validateMobileLength(this.state.shareHolder.mobileNumber)) {
            MarsNotifier.Warning(ValidationContants.PhoneNumberLengthWarning, null);
            return false;
        }
        return true;
    }

    public render() {
        return (
            <Modal show={this.props.showState} onHide={this.props.onCancel} className="k1PartnerPopup">
                <Modal.Header closeButton data-test-auto="86A8C423-8EC0-4E9F-A544-3574C9CBFD33">
                    <Modal.Title>
                        Update K1 {this.props.type} Details
                </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className='row padT10'>
                        <div className='col-sm-3 col-md-3 col-xl-3 padT5'>
                            Name
                           </div>
                        <div className='col-sm-9 col-md-9 col-xl-9'>
                            <input type='text'
                                className='form-control'
                                value={this.state.shareHolder.name}
                                disabled={true}
                                data-test-auto="618040C6-427C-4EF2-8CE7-95A16A147243">
                            </input>
                        </div>

                    </div>
                    <div className='row padT10'>
                        <div className='col-sm-3 col-md-3 col-xl-3 padT5'>
                            SSN/EIN
                           </div>
                        <div className='col-sm-9 col-md-9 col-xl-9'>
                            <input type='text'
                                className='form-control'
                                value={this.state.shareHolder.entityType == EntityType.Individual ? ssnDisplay(this.state.shareHolder.PIN) : einDisplay(this.state.shareHolder.PIN)}
                                disabled={true}
                                data-test-auto="637B7D83-4783-4272-9B9F-9AD9AB499984">
                            </input>
                        </div>

                    </div>
                    <div className='row padT10'>
                        <div className='col-sm-3 col-md-3 col-xl-3 padT10'>
                            Address
                           </div>
                        <div className='col-sm-9 col-md-9 col-xl-9'>
                            <textarea
                                placeholder="Address"
                                className="form-control"
                                rows={2}
                                data-test-auto="66C358C9-7813-4365-A8F7-54190FDA0F14"
                                value={this.state.shareHolder.address}
                                onChange={this.onChangeAddress}
                            />
                        </div>
                    </div>
                    <div className='row padT10'>
                        <div className='col-sm-3 col-md-3 col-xl-3 padT5'>
                            Email
                           </div>
                        <div className='col-sm-9 col-md-9 col-xl-9'>
                            <input type='text'
                                placeholder="Email"
                                className='form-control'
                                value={this.state.shareHolder.email}
                                onChange={this.onChangeEmail}
                                data-test-auto="22B9547E-E5F6-48C3-A62D-91B410E7F39B">
                            </input>
                        </div>
                    </div>
                    <div className='row padT10'>
                        <div className='col-sm-3 col-md-3 col-xl-3 padT5'>
                            Mobile
                         </div>
                        <div className='col-sm-3 col-md-3 col-xl-3 white-btn' data-test-auto="CB3B99EB-DA52-4D7F-B247-B77C0C5870A8">
                            <SelectComponent
                                id="ddlCountryCode1"
                                options={CountryCodeOptions()}
                                onChange={this.onChangeCountryCode}
                                selectedValue={this.state.shareHolder.countryCode}
                                data-test-auto="271C2A00-2C22-4E8A-9EEF-966E58AAE121"
                            />
                        </div>
                        <div className='col-sm-6 col-md-6 col-xl-6'>
                            <PhoneNumberComponent
                                phoneNumber={this.state.shareHolder.mobileNumber}
                                handleChangePhoneNumber={this.onChangeMobile}
                                className={ " phoneNumberFullWidth " }
                                data-test-auto="D876D071-AC7F-43B5-BA18-6D5D75C41212">
                            </PhoneNumberComponent>
                        </div>
                    </div>
                    <div className='row padT10'>
                        <div className='col-sm-3 col-md-3 col-xl-3 padT5'>
                            Entity Type
                           </div>
                        <div className='col-sm-9 col-md-9 col-xl-9 white-btn' data-test-auto="A1FDF0F4-555F-4C34-BB08-D82EA97ACFC0">
                            <SelectComponent
                                id="ddlEntityType"
                                options={EntityTypeOptions()}
                                onChange={this.onChangeEntityType}
                                selectedValue={this.state.shareHolder.entityType !== 0 ? this.state.shareHolder.entityType : EntityType.Individual}
                                data-test-auto="333C1230-A8D9-4B09-BF42-A4C22ED8AE36"
                            />
                        </div>
                    </div>
                    <div className='row padT10'>
                        <div className='col-sm-3 col-md-3 col-xl-3 padT5'>
                            Status
                           </div>
                        <div className='col-sm-9 col-md-9 col-xl-9 white-btn' data-test-auto="7E695C76-33AD-4512-92C0-1EEC4AD6AFF2">
                            <SelectComponent
                                id="ddlStatusList"
                                options={k1StatusOptions()}
                                onChange={this.onChangeK1Status}
                                selectedValue={this.state.shareHolder.k1Status !== 0 ? this.state.shareHolder.k1Status.toString() : 3}
                                data-test-auto="6327FC4C-8C87-460D-BA2D-5C20EC2E55A2"
                            />
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.onCancel}
                        data-test-auto="35DF912C-6F07-4582-AB97-366243825226"
                        className="btn btn-white btn-default rounded"
                        variant={"light"}>
                        <i className="fa fa-times"></i> Cancel
                    </Button>
                    <Button
                        onClick={this.onUpdatePartner}
                        data-test-auto="CC3A404D-37B9-439A-B452-45254D3B5FC6"
                        variant={"primary"}
                        className="btn btn-primary rounded">
                        <i className="fa fa-save"></i> Save
                    </Button>
                </Modal.Footer>
            </Modal >)
    }
}