import { SignProcessConstants } from '../Common/Constants';
import { container } from '../../startup/inversify.config';
import { IDialogBox } from '../../core/utilities/ui/DialogBox';
import { TYPES } from '../../startup/types';
import { ISignProcessInfoViewModel } from '../../core/domain/viewModels/ISignProcessInfoViewModel';
import { MainComponentProps } from '../Home/DelegateeSignFlow';
import { DocumentStatus } from '../../core/common/Enums';


export enum SignProcessSteps {
    Summary = 1,
    PreparerMessage = 2,
    Invoice = 3,
    Review = 4,
    SignMethod = 5,
    EsignConsentEmail = 6,
    KBA = 7,
    Esign = 8,
    SpouseSignature = 9,
    SignComplete = 10,
    ManualSignDownload = 11,
    ManualSign = 12,
    Pay = 13,
    DistributeSelection = 14,
    DistributeManual = 15,
    DistributeElectronic = 16,
    SignerProcessComplete = 1000
}

const dialogBox = container.get<IDialogBox>(TYPES.IDialogBox);
const NO_INDEX = -1;

export class DelegateeSignManager {

    private _wizardRef: any;
    private _props?: MainComponentProps;


    constructor(wizardRef: any, mainComponentProps?: MainComponentProps) {
        this._wizardRef = wizardRef;
        this._props = mainComponentProps;
    }


    public static create(wizardRef: any, mainComponentProps?: MainComponentProps) {
        return new DelegateeSignManager(wizardRef, mainComponentProps);
    }


    public static createNullObject() {
        return new DelegateeSignManager(null);
    }


    public signProcessRecoverConfirmation(lastVisitedStep: SignProcessSteps, clientId?: string, requestTutorialInfo?: (clientGuid: string) => any) {
        const _self = this;

        if (lastVisitedStep) {
            dialogBox.confirmCustom(
                SignProcessConstants.ProcessRecoveryConfirmation,
                SignProcessConstants.ProcessRecoveryMessage,
                SignProcessConstants.ProcessRecoveryConfirmationDialogLeftButton,
                SignProcessConstants.ProcessRecoveryConfirmationDialogRightButton, function (result: boolean) {

                    if (result) {

                        /* 10 - retry count until the step is visible based on the server call */
                        _self._wizardRef && _self._wizardRef.goToStepById(lastVisitedStep, 10).then(function (success: boolean) {

                            if (success == false) {
                                dialogBox.alert(SignProcessConstants.ProcessRecoveryFailedMessage);
                                _self._wizardRef.goToStep(0);
                            }
                        });
                    }
                    else {
                        _self._wizardRef.goToStep(0);
                    }
                    if (requestTutorialInfo && clientId) {
                        requestTutorialInfo(clientId);
                    }
                });
        }
        else {
            if (requestTutorialInfo && clientId) {
                requestTutorialInfo(clientId);
            }
        }
    }

    isLinkVisited(step: SignProcessSteps): boolean | undefined {
        let index = NO_INDEX;
        index = this._props && this._props.signProcessInfo.visitedSteps ? this._props.signProcessInfo.visitedSteps.indexOf(step) : NO_INDEX;
        return (index !== null && index !== NO_INDEX)
    }
    isSigningProcessCompleted(): boolean | undefined {
        const index = this._props && this._props.signProcessInfo.visitedSteps ? this._props.signProcessInfo.visitedSteps.filter(
            x => x === SignProcessSteps.SignComplete) : null;
        return (index !== null && index.length > 0) || this._props?.taxReturn.documentStatus == DocumentStatus.USERSIGNED;
    }

}
