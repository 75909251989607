import { clear } from "console";

export const LogEventConstants = {
    Common: {
        UserLogout: "User Logout",
        Upload: "Upload",
        Open: "Open",
        Close:"Close",
        ClickPrevious: "Go to Previous step clicked",
        ClickNext: "Go to next step clicked",
        DocumentEventName: "Extension Document Event",
        Account: {
            StayAlive: "StayAlive",
            Logoff: "logged off"
        },
        CcRecipients:{
            AddNewRow:"Add new recipients row",
            DeleteRow: "Deleted the recipient at the index",
            SendMail:"Send mail to cc recipients",
            DocumentsToShow: "Saved documents to show",
            Clear: "Clear all",
            SelectiveDownload: "downloading selected document",
            DownloadAll: "Downloading all documents"
        },
        ScreenShare: {
            SetStream: "Setting up video stream",
            AttachMediaStream: "Attaching the media Stream",
            DetachMediaStream: "Removing the media stream",
            HangUp: "Closing all connections"
        },
        Esign:{
            ValidateKBAQuestion: "Validated the KBA Question",
            SpouseMailUpdated: "Updated spouse mail",
            SpouseNumberUpdated: "Updated spouse phone number",
            KbaSubmit: "User submitted KBA",
            SubmitSuccess: "KBA Submit Successful",
            SubmitFailed: "KBA Submit Failed",
            RedirectToManualSign: "Redeiecting to manual sign on KBA failure",
            SelectSignMethod: "Selected the sign method",
            PreviewMode:"Go to Preveiew mode",
            SignCompletedWithSteps:"Signing Completed since visited steps indicates signing completion",
            SignCompletedWithApiSatus: "Signing Completed since API response",
            SignprocessFailed: "Signing procsess failed",
            ViewModeChanged: "View Mode Changed",
            DocumentSignFinished: "Document Sign Finished",
            FileClicked: "clicked on file with page number",
            StartNavigation: "Sign navigation started",
            EndNavigation: "Sign navigation ended",
            AddSignControl: "Signature control added",
            AddInitialControl: "Initial control added",
            AddDateControl: "Date control added",
            AddCompanyControl: "Company Control added",
            AddCustomTextControl: "Custom Text Control added",
            AddPrintNameControl: "Print Name Control added",
            AddTitleControl: "Title Control added",
        },
        ManualDownload:{
            ManualSignDownload: "Document Downloaded for manula sign",
            AdditionalFileDownload:"Additional Document(s) Downloaded",
            DocumentDeleted: "Uploaded document is deleted",
            AddFile: "Adding signed file",
            FileUploaded: "Manully signed file uploaded"
        },
        Review: {
            DownloadAttachement: "Downloaded the attachement file",
            DownloadAttachementsZip: "Downloaded all attachement files as ZIP "
        },
        Delegatee:{
            Submit:"Assign to delegatee is submitted",
            SubmitCancel:"Assign to delegatee cancelled",
            DownloadSignedDocuments: "Delegatee download signed documents",
            DocumentSignFinished:"Delegatee document sign finished",
            DocumentSignCompleted:"Delegatee document sign completed",
            DocumentSignSubmit: "Delegatee document sign is submitted",
            KbaConsentCheck: "Delegatee KBA Constent check box clicked",
            KbaNext: "Delegatee KBA next clicked",
            SelectSignMethod: "Selected delegatee sign method",
            GoToCompleteWizard: "Redorecting to complete wizard",
            SignProcessCompleted: "Signing process completed",
            ViewModeChanged: "View Mode Changed",
            TaxDocumentDownload: "Download Review tax docuement clicked"
        },
        CompletedSummary:{
            DownloadSignedDocuments: "Delegatee download signed documents",
            GoToPayment: "Redirecting to payments"
        },
        Pay:{
            GoToCompleteWizard: "Redirecting to complete wizard",
            ProcessCompleted: "Redirect to process completed",
            VoucherReminderChanged: "Voucher Reminder value changed",
            DownloadAllVoucher: "Download all vouchers is clicked",
            DownloadFilingInstruction: "Download filing instruction clicked",
            DownloadSelectedVoucher: "Download selected vouchers is clicked",
            ClickPay: "clicked on Pay",
            ClickAddVoucher: "Clicked on add payment vocher",
            AmountChanged: "Voucher amount changed",
            DateChanged: "Voucher amount changed",
            NumberChanged: "Voucher check number changed",
            SaveDetails: "Clicked voucher details save"
        },
        Layout:{
            AccessCodeEntered: "Entered Access Code",
            CountryCodeChange: "Country code changed",
            NumberChanged: "Phone number changed",
            ClickedDownload: "clicked download files in my download",
            DeleteDownload: "clicked delete from my download list"
        }
    }
};