import * as React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { IK1ShareHolderDetails } from '../../core/domain/models/IK1Distribute';
import { match } from 'react-router';
import { EngagementTypeS } from '../../core/common/Enums';
import { ITaxReturn } from '../../core/domain/models/ITaxReturn';
import { GetK1EngagementType } from '../../core/domain/models/IK1Distribute';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';

interface DistributeManualTableProps {
    k1Data: IK1ShareHolderDetails[];
    onSortChange: any;
    onPageChange: any;
    pageNo: number;
    pageSize: number;
    onSearchChange: (searchString: string) => void;
    onRowSelect: (row: any, isSelected: any, e: any) => void;
    onSelectAll: (isSelected: any, rows: any, e: any) => void;
    selectedRows: number[];
    taxReturn: ITaxReturn;
    match: match;
    downloadIndividualK1DocumentAsync: (
        clientId: string,
        shareHolder: IK1ShareHolderDetails,
        engagementTpe: EngagementTypeS
    ) => void;
}


export class DistributeManualTable extends React.Component<DistributeManualTableProps, {}>{
    constructor(props: any) {
        super(props);
    }

    handleK1Download = (shareHolderId: number) => {
        let shareHolder = this.props.k1Data.filter(x => x.shareHolderId === shareHolderId);
        if (shareHolder) {
            let param: any = this.props.match.params;
            this.props.downloadIndividualK1DocumentAsync(param.clientId, shareHolder[0], this.props.taxReturn.engagementType);
        }
    }

    private k1StatusAction = (cell: any, row: any) => {
        return (
            <div>
                <button
                    title={"Download"}
                    className="btn-white"
                    type="button"
                    onClick={() => { this.handleK1Download(row.shareHolderId) }}
                    data-test-auto="74EFE1B0-B246-41B0-87B8-146EDE68A834">
                    <i className='fa fa-download'></i>
                </button>
            </div>
        );
    }

    private defaultType = (cell: any, row: any) => {
        return <span title={cell} className="ellipsis" data-test-auto="40AFA486-A699-4E58-9963-BE6A7778A603">{cell}</span>;
    }

    private customHeaderName = () => {
        return this.props.taxReturn && GetK1EngagementType(this.props.taxReturn.engagementType) + ' Name';
    }

    private renderShowsTotal = (start: number, to: number, total: number) => {
        return (
            <p>
                Showing {start} to {to} of {total} entries
            </p>
        );
    }

    private columns = [
        {
            header: '',
            key: 'rowIndex',
            isKey: true,
            dataFormat: this.defaultType,
            columnClassName: '',
            dataSort: false,
            toolTip: false,
            isHidden: true,
            width: 'auto',

        },
        {
            header: this.customHeaderName(),
            key: 'name',
            isKey: false,
            dataFormat: this.defaultType,
            columnClassName: '',
            dataSort: true,
            toolTip: false,
            isHidden: false,
            width: 'auto',
        },
        {
            header: 'Action',
            key: 'button',
            isKey: false,
            dataFormat: this.k1StatusAction,
            columnClassName: '',
            dataSort: false,
            toolTip: false,
            isHidden: false,
            width: '70px',
        },
    ];

    public render() {

        const options = {
            onSortChange: this.props.onSortChange,
            onSearchChange: this.props.onSearchChange,
            onPageChange: this.props.onPageChange,
            sizePerPage: this.props.pageSize,
            page: this.props.pageNo,
            paginationShowsTotal: this.renderShowsTotal,
            clearSearch: false,
            hideSizePerPage: true
        };

        const data = this.props.k1Data && this.props.k1Data.map((model, index) => {
            return {
                name: model.name,
                rowIndex: index,
                button: 'Actions',
                shareHolderId: model.shareHolderId
            }
        });

        let selected: number[] = [];
        if (this.props.selectedRows.length > 0) {
            for (var i in this.props.selectedRows) {
                let rowIndex = this.props.selectedRows[i];
                selected.push(rowIndex);
            }
        }

        let selectRowProp: any =
        {
            mode: 'checkbox',
            onSelect: this.props.onRowSelect,
            onSelectAll: this.props.onSelectAll,
            selected: selected,
            className: 'row-selected',
            columnWidth: '40px'
        };
        let totalCount = this.props && this.props.k1Data.length > 0 ? this.props.k1Data[0].count : 0 
        let watermarkName = this.props.taxReturn && 'Search ' + GetK1EngagementType(this.props.taxReturn.engagementType);
        return (
            <div className="distributeManualTbl" data-test-auto="00D28309-65F6-44DF-AF2D-4A2B7EF65C5C">
                <BootstrapTable
                    ref='distributeManual'
                    data={data}
                    remote={true}
                    striped
                    pagination={totalCount > this.props.pageSize}
                    hover={true}
                    search={true}
                    options={options}
                    selectRow={selectRowProp}
                    searchPlaceholder={watermarkName}
                    fetchInfo={{ dataTotalSize: this.props && this.props.k1Data.length > 0 ? this.props.k1Data[0].count : 0 }}
                >
                    {this.columns.map((value, index) => {
                        return <TableHeaderColumn
                            key={index}
                            ref={value.key}
                            isKey={value.isKey}
                            dataField={value.key}
                            hidden={value.isHidden}
                            width={value.width}
                            dataFormat={value.dataFormat}
                            columnClassName={value.columnClassName}
                            columnTitle={value.toolTip}
                            dataSort={value.dataSort}
                        >{value.header}
                        </TableHeaderColumn>;
                    })}
                </BootstrapTable>
            </div>

        )
    }
}