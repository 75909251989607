import * as React from 'react';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { DownloadDocumentsConstants } from '../Common/Constants';
import { SvgIconDownload, SvgIconInfoCircle } from '../Common/Icons/SvgIcons';
import { MarsNotifier } from '../Common/Notification/MarsNotifier';
import {formatFileName} from '../Helper/HelperFunction'
var htmlencode = require('htmlencode');

export interface DownloadListProps {
    title: string,
    items: DownloadListItem[]
}

export interface DownloadListItem {
    text: string;
    testAutoId: string;
    onClick: (...args: any[]) => any;
    instruction?: string;
    args: any[];
    isPreviewMode: boolean;
}

export const DownloadList: React.SFC<DownloadListProps> = (props) => {

    function getInstructionPopOver(key: string, instruction: string) {
        return <Popover id={key} placement="bottom">
            <h3 className="popover-header">Instructions</h3>
            <div className="popover-body">
                <div dangerouslySetInnerHTML={{ __html: htmlencode.htmlDecode(instruction) }}></div>
            </div>
        </Popover>;
    }

    function handleClick(args: any[], funRef: any, isPreview: boolean | undefined) {
        if (!isPreview) {
            if (args) {
                if (args.length === 1) {
                    funRef(args[0]);
                } else if (args.length === 2) {
                    funRef(args[0], args[1]);
                } else if (args.length === 3) {
                    funRef(args[0], args[1], args[2]);
                }
            }
        } else {
            MarsNotifier.Warning(DownloadDocumentsConstants.DownloadNotAllowed, null);
        }
    }
    let borderClass = "dowload-list-first";
    return (<div className="download-list">
        <span className="title">{props.title}</span>
        <ul>
            {
                props.items.map((value, index) => {
                    console.log(value.instruction || "No Instruction");
                    return (<li
                        key={value.testAutoId}
                        onClick={() => { handleClick(value.args, value.onClick, value.isPreviewMode) }}
                        className={borderClass}
                        data-test-auto={"HN797ZV301KMC9DA2W0C6NYR58-" + index}>
                        {formatFileName(value.text)}
                        <SvgIconDownload
                            className="download-list-icon"
                            height={16}
                        />
                        { borderClass = ""}
                        {
                            value.instruction
                            && value.instruction.length > 0
                            && (<OverlayTrigger
                                data-test-auto={"DQYT5ZTQAA8MQ29U2T1PRXC160-" + index}
                                rootClose trigger={["hover", "focus"]}
                                onEnter={() => document.body.click()}
                                overlay={getInstructionPopOver("Instruction_" + value.testAutoId, value.instruction)}
                                placement="bottom">
                                <i className="fa fa-info-circle" style={{ fontWeight: 900, fontSize: '16px', color: '#0973ba' }}></i>
                            </OverlayTrigger>)
                        }
                    </li>)
                })
            }
        </ul>
    </div>)
}