import * as React from 'react';

interface HeaderProps {
    className?: string;
    width?: number;
    height?: number;
    lg?: number;
    md?: number;
    sm?: number;
    xs?: number;
}

const DEFAULT_WIDTH_LG: number = 9;
const DEFAULT_WIDTH_MD: number = 11;
const DEFAULT_WIDTH_SM: number = 12;
const DEFAULT_WIDTH_XS: number = 12;

export const Header: React.FunctionComponent<HeaderProps> = ({
    className, width, height, lg,
    md,
    sm,
    xs, children
}) => {


    let getBodyWidthClass = () => {

        let bodyWidthClass: string = "";

        if (lg) {
            bodyWidthClass = bodyWidthClass.concat(' col-lg-', lg.toString());
        }
        else {
            bodyWidthClass = bodyWidthClass.concat(' col-lg-', DEFAULT_WIDTH_LG.toString());
        }

        if (md) {
            bodyWidthClass = bodyWidthClass.concat(' col-md-', md.toString());
        }
        else {
            bodyWidthClass = bodyWidthClass.concat(' col-md-', DEFAULT_WIDTH_MD.toString());
        }

        if (sm) {
            bodyWidthClass = bodyWidthClass.concat(' col-sm-', sm.toString());
        }
        else {
            bodyWidthClass = bodyWidthClass.concat(' col-sm-', DEFAULT_WIDTH_SM.toString());
        }

        if (xs) {
            bodyWidthClass = bodyWidthClass.concat(' col-xs-', xs.toString());
        }
        else {
            bodyWidthClass = bodyWidthClass.concat(' col-xs-', DEFAULT_WIDTH_XS.toString());
        }

        return bodyWidthClass;
    }



    return (<div className={"step-layout-top-bar no-padding " + getBodyWidthClass() + " " + className}
        style={{   
            height: (height == undefined ? "auto" : height + "%")
        }}
        data-test-auto="31BA12F5-4974-4306-B78F-7E02D945D04C">
        {children}
    </div>);
};
