import * as React from 'react';
import { Modal, Col, Row } from 'react-bootstrap';
import { AuthorityImage, StateImages } from '../Common/Icons/StateImages';
import { ITaxingAuthority } from 'src/core/domain/models/ITaxingAuthority';
import { IUtilities } from '../../core/utilities/Utilities';
import { container } from '../../startup/inversify.config';
import { TYPES } from '../../startup/types';
import { IVoucher } from 'src/core/domain/models/IGroup';
import Moment from 'moment/moment';
import { CurrencyTextBox } from '../Common/Controls/CurrencyTextBox';
import DatePicker from "react-datepicker";
import { MarsNotifier } from '../Common/Notification/MarsNotifier';
import * as Constants from '../Common/Constants';
import { logger } from '../../routes';
import { LogEventConstants } from '../Helper/Constants';

//const DatePicker = require("react-bootstrap-date-picker");

const utilities = container.get<IUtilities>(TYPES.IUtilities);

export interface IVoucherPaymentInfoPopupProps {
    show: boolean;
    onCancel(): void;
    taxingAuthority: ITaxingAuthority;
    voucher: IVoucher;
    saveVoucherPayment: (voucher: IVoucher) => void;
}

interface IVoucherPaymentInfoPopupState {
    amountPaid: number;
    checkNumber: string;
    paidDate: Date;
}

const DATE_FORMAT: string = "MM/DD/YYYY";
const defaultDate = new Date(1, 1, 1);

const pageTitle = "Voucher Payment Info Popup";
export class VoucherPaymentInfoPopup extends React.Component<IVoucherPaymentInfoPopupProps, IVoucherPaymentInfoPopupState> {

    constructor(props: IVoucherPaymentInfoPopupProps) {
        super(props);
        this.state = {
            amountPaid: props.voucher.amountPaid,
            paidDate: Moment(props.voucher.paidDate).format('MM/DD/YYYY') === "01/01/0001" ? defaultDate : new Date(props.voucher.paidDate),
            checkNumber: props.voucher.checkNumber,
        }
        this.updateValue = this.updateValue.bind(this);
        this.handleAmountChange = this.handleAmountChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleCheckNumberChange = this.handleCheckNumberChange.bind(this);
    }

    componentDidMount() {
        this.updateValue(this.props);
    }

    componentWillReceiveProps(nextProps: IVoucherPaymentInfoPopupProps) {
        this.updateValue(nextProps);
    }

    private updateValue(props: IVoucherPaymentInfoPopupProps) {
        const amountPaidTemp = props.voucher.amountPaid;
        const paidDateTemp = new Date(props.voucher.paidDate);
        const checkNumberTemp = props.voucher.checkNumber;

        this.setState({
            amountPaid: amountPaidTemp,
            paidDate: paidDateTemp,
            checkNumber: checkNumberTemp,
        });
    }

    handleAmountChange = (value: number) => {
        if (event !== null) {
            this.setState({ amountPaid: value });
            logger.trackEvent(
                logger.buildEvent(
                    `${LogEventConstants.Common.Pay.AmountChanged}  ${pageTitle}`,
                    { count: 1, page: pageTitle, amount: this.state.amountPaid  }
                )
            );
        }
    }

    handleDateChange = (event: any) => {
        this.setState({
            paidDate: event === null ? new Date('0001-01-01') : event
        });
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.Pay.DateChanged}  ${pageTitle}`,
                { count: 1, page: pageTitle, date: this.state.paidDate  }
            )
        );
    }


    handleCheckNumberChange = (event: any) => {
        this.setState({ checkNumber: event.target.value });
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.Pay.NumberChanged}  ${pageTitle}`,
                { count: 1, page: pageTitle, number: this.state.checkNumber  }
            )
        );
    }

    onCancel = () => {
        this.setState({ checkNumber: "", paidDate: defaultDate, amountPaid: 0 });
        this.props.onCancel();
    }

    validateVoucherPaymentDetails = () => {

    }

    saveVoucherPayementDetails = () => {
        if (this.props.voucher !== null) {
            if (this.state.amountPaid > this.props.voucher.amount) {
                MarsNotifier.Warning(Constants.PayConstants.InvalidVoucherAmount, null);
                return;
            }
            else {
                let tempVoucher = this.props.voucher;
                tempVoucher.amountPaid = this.state.amountPaid;
                tempVoucher.checkNumber = this.state.checkNumber;
                tempVoucher.paidDate = this.state.paidDate;
                this.setState({ checkNumber: "", paidDate: defaultDate, amountPaid: 0 });
                this.props.onCancel();
                this.props.saveVoucherPayment(tempVoucher);
                logger.trackEvent(
                    logger.buildEvent(
                        `${LogEventConstants.Common.Pay.SaveDetails}  ${pageTitle}`,
                        { count: 1, page: pageTitle, voucher: tempVoucher  }
                    )
                );
            }
        }
    }


    private renderBody = () => {
        const authorityName = StateImages.find(s => s.stateUSA === this.props.taxingAuthority.AuthorityName)?.imageName;
        return <>
            <div>
                <table className="voucher">
                    <tbody>
                        <tr>
                            <td
                                rowSpan={3}
                                className="pay-icon image-col">
                                <AuthorityImage
                                    data-test-auto="629EA225-8B0D-4419-913B-C7A9EBEC061A"
                                    authoriyName={authorityName}
                                    className="state-shadow"
                                    height={110}
                                    width={110}
                                />
                            </td>
                            <td colSpan={3}
                                data-test-auto="9BE1E99E-2850-4434-ABA0-7E86844331AB"
                                style={{ paddingTop: "10px" }}
                                className="pay-fee-name large-text">
                                {this.props.taxingAuthority.AuthorityName == "Federal" ? "Internal Revenue Service" : this.props.taxingAuthority.AuthorityName}
                            </td>
                        </tr>
                        <tr>
                            <td className="larger amount indent">
                                <span>Amount:</span>
                            </td>
                            <td
                                data-test-auto="7D0CFCE5-AE08-4FF0-8FDD-C9786EF6EF59"
                                className="larger amount">
                                <span style={{ fontWeight: "bold" }}>
                                    ${utilities.formatCurrencyText(this.props.voucher.amount)}
                                </span>
                            </td>
                            <td />
                        </tr>
                        <tr>
                            <td
                                data-test-auto="61016C69-4431-46EF-84EA-E78368E33799"
                                className="larger due lined indent">
                                <span>Due:</span>
                            </td>
                            <td
                                data-test-auto="7C5A9D2E-0388-42EC-AD04-85B9160ABAEB"
                                className="larger due lined">
                                <span style={{ fontWeight: "bold" }}>
                                    {Moment(this.props.voucher.dueDate).format('MM/DD/YYYY')}
                                </span>
                            </td>
                            <td />
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='row padTLR'>
                <div className='col-sm-5 col-md-5 col-xl-5 padT5'>
                    Amount Paid :<span className="fa fa-asterisk compulsory-star"></span>
                </div>
                <div className='col-sm-7 col-md-7 col-xl-7'>
                    <span className="input-dollar left">
                        <CurrencyTextBox
                            data-test-auto="0ACC92D1-F44E-4256-BBDC-2C1E21D16B36"
                            value={this.state.amountPaid}
                            onChange={this.handleAmountChange} />
                    </span>
                </div>

            </div>
            <div className='row padTLR'>
                <div className='col-sm-5 col-md-5 col-xl-5 padT5'>
                    Date Paid :<span className="fa fa-asterisk compulsory-star"></span>
                </div>
                <div className='col-sm-7 col-md-7 col-xl-7 voucher-pay-datepicker'>
                    <span className="input-dollar left">
                        <div className="input-group">
                            <DatePicker
                                data-test-auto="A3FA1EBE-72B5-4739-B616-9EE4B4D77484"
                                selected={this.state.paidDate && Moment(this.state.paidDate).format('MM/DD/YYYY') === "01/01/0001" ? null : this.state.paidDate}
                                onChange={this.handleDateChange}
                                maxDate={new Date()}
                                placeholderText="MM/DD/YYYY"
                                className="form-control input-fontSize"
                            />
                            <span className="input-group-addon calenderStyling"><i className="fa fa-calendar"></i></span>
                        </div>
                    </span>

                </div>

            </div>
            <div className='row padTLR'>
                <div className='col-sm-5 col-md-5 col-xl-5 padT5'>
                    Check Number :
                           </div>
                <div className='col-sm-7 col-md-7 col-xl-7'>
                    <input type='text'
                        className="form-control input-fontSize"
                        value={this.state.checkNumber}
                        onChange={this.handleCheckNumberChange}
                        placeholder="enter check number"
                        data-test-auto="54678A68-8B5C-4107-9D4D-913E52F498BB">
                    </input>
                </div>

            </div>
        </>;
    };

    public render() {
        const { amountPaid, paidDate } = this.state;
        const enabled =
            amountPaid > 0 &&
            paidDate !== null && Moment(this.state.paidDate).format('MM/DD/YYYY') !== "01/01/0001";
        return (<Modal
            style={{ borderRadius: '5px', marginTop: '90px' }}
            className="voucherPayementOverlayPopup"
            show={this.props.show}
            onHide={this.props.onCancel}>

            <Modal.Header closeButton>
                <Modal.Title>
                    <div className="payment-info-modal-title">
                        <span
                            data-test-auto="12336D54-417C-4371-947C-38CBA96E5E65"
                            className="popup-heading">
                            {this.props.voucher.isPaid ? 'Update Payment Details' : 'Add Payment Details'}
                        </span>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {this.renderBody()}
            </Modal.Body>
            <Modal.Footer>
                <button className=" btn btn-lg btn-iconed btn-rounded success"
                    data-test-auto="C2610598-AE33-4512-A6D6-B047FC96147F"
                    onClick={this.onCancel}
                    hidden={false}>
                    Cancel
                </button>
                <button className=" btn btn-lg btn-iconed btn-rounded success"
                    data-test-auto="546FCCB2-B309-40B2-BA98-20256EB85CC0"
                    onClick={this.saveVoucherPayementDetails}
                    disabled={!enabled}>
                    {this.props.voucher.isPaid ? 'Update' : 'Ok'}
                </button>
            </Modal.Footer>
        </Modal>);
    }
}