import { ApplicationState } from "../../store/index";
import { connect } from 'react-redux';
import { actionCreators as PayStore } from '../../store/PayStore';
import { IPaymentVoucherNotificationSettings } from "../../core/domain/models/IDocumentSettings";
import { PayCompleted } from "./PayCompleted";
import { createLoadingSelector } from '../../store/selectors';
import { actionCreators as HelperStore } from '../../store/Common/HelperStore';
import { actionCreators as PreparerMessageStore } from '../../store/PreparerMessageStore';
import { actionCreators as TaxDocumentStore } from '../../store/Common/TaxDocumentStore';
import { actionCreators as SignProcessStore } from '../../store/SignProcess/SignProcessStore';
import { IClientProcessViewModel } from '../../core/domain/viewModels/IClientProcessViewModel'
import { IVoucher } from '../../core/domain/models/IGroup';
import { actionCreators as CompanyStore } from '../../store/Common/Company/CompanyStore';


const loadingSelector = createLoadingSelector(['HEADERINFO', 'SUMMARY_DETAILS',
    'TAXING_AUTHORITY', 'DOCUMENT_REVIEW_MODEL', 'DOCUMENT_SETTINGS']);


function mapStateToProps(state: ApplicationState) {
    return {
        payScreenData: state.payScreenData,
        companyData: state.companyData.data,
        commonData: state.commonData,
        signatureSettingModel: state.signatureSettingData,
        documentSettings: state.documentSettings,
        headInfoViewModel: state.headerInfo,
        taxReturn: state.taxReturn.taxDocument,
    };
}
//temperroy fix for webpack failure need to invetigate further when we have time
const mapDispatchToProps = (dispatch: any) => {
    return {      
        requestCompanyDetails: (clientId: string) => dispatch(CompanyStore.requestCompanyDetails(clientId)),
        requestDocumentSettings: (clientId: string) => dispatch(PreparerMessageStore.requestDocumentSettings(clientId)),
        requestTaxDocument: (clientGuid: string) => dispatch(TaxDocumentStore.requestTaxDocument(clientGuid)),
        requestAllTaxingAuthorities: (id: string) => dispatch(HelperStore.requestAllTaxingAuthorities(id)),
        requestPayScreenDetails: (clientId: string) => dispatch(PayStore.requestPayScreenDetails(clientId)),
        downloadAllVouchers: (clientId: string) => dispatch(PayStore.downloadAllVouchers(clientId)),
        downloadFilingInstructions: (clientId: string) => dispatch(PayStore.downloadFilingInstructions(clientId)),
        updateVoucherNotificationSettings: (clientId: string, setting: IPaymentVoucherNotificationSettings, callback: () => void) => dispatch(PayStore.updateVoucherNotificationSettings(clientId, setting, callback)),
        downloadSingleVoucher: (clientId: string, fileName: string, pageNo: string, bookMarks: string) => dispatch(PayStore.downloadSingleVoucher(clientId, fileName, pageNo, bookMarks)),
        requestCurrentStepAndUserRole: (clientGuid: string, successCallback?: (clientProcessState: IClientProcessViewModel) => void) => dispatch(SignProcessStore.requestCurrentStepAndUserRole(clientGuid, successCallback)),
        addVoucherPayemnt: (clientId: string, voucher: IVoucher) => dispatch(PayStore.addVoucherPayemnt(clientId, voucher)),
        updateVoucherPayemnt: (clientId: string, voucher: IVoucher) => dispatch(PayStore.updateVoucherPayemnt(clientId, voucher)),
        dispatch
    }
}


const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(PayCompleted);



