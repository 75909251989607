import { injectable } from "inversify";

export interface IDateUtilities {
    getFormattedDate(date: Date): string;
}

@injectable()
export class DateUtilities implements IDateUtilities {

  getFormattedDate(date: Date): string {
       var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
   }    
}