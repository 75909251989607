import { connect } from 'react-redux';
import { actionCreators as HeaderActions } from "./../../../../store/Login/Common/Header/HeaderStore";
import { actionCreators as DelegateeActions } from "./../../../../store/Login/Delegatee/OTP/OTPStore";
import { ApplicationState } from '../../../../store';
import MobileOTP from '../../Common/OTP/MobileOTP';
import { OtpPageStoreState } from '../../../../store/Login/Models/StoreModels';

export default connect(
    (state: ApplicationState) => ({
        headerStoreState: state.headerPageStore,
        backToHomePath: "",
        successRedirectPath: "/Delegatee/Initialize/",
        mobileOtpPageStoreState: {
            countryCode: state.delegateeOTPPageStore?.delegatee?.countryCode ?? "",
            mobileNo: state.delegateeOTPPageStore?.delegatee?.mobileNumber ?? "",
            loading: state.delegateeOTPPageStore?.loading ?? false
        } as OtpPageStoreState,
        backToHome: false,
    }),
    {
        requestHeaderDetails: (clientId: string) =>
            HeaderActions.requestDelegateeHeaderDetails(clientId),

        requestClientInfo: (clientId: string) =>
            DelegateeActions.getClientInfo(clientId),

        generateOtp: (clientId: string) =>
            DelegateeActions.generateMobileOTP(clientId),

        verifyOtp: (otp: string, clientId: string, handelOtpVerification: (guid: string) => void, handleError: () => void) =>
            DelegateeActions.verifyMobileOTP(otp, clientId, handelOtpVerification, handleError)
    },
)(MobileOTP);