import * as React from 'react';
import { FormLabel } from 'react-bootstrap';
import { Shimmer } from '../Common/Shimmer/Shimmer';
import { SelectComponent } from '../Common/SelectComponent';
import { PhoneNumberComponent } from '../Common/PhoneNumberComponent';
import { CountryCodeOptions } from '../Helper/HelperFunction';

export interface SpouseEmailProps {
    spouseName: string;
    spouseEmail: string;
    editedCountryCode: string;
    editedMobileNumber: string;
    nextSignerExists: boolean;
    onChangeCountryCode(value: string): void;
    onMobileNumberChange(value: string): void;
    handleSpouseMailChange(mail: string): void;
}

const SpouseEmail: React.FunctionComponent<SpouseEmailProps> = ({
    spouseName,
    spouseEmail,
    handleSpouseMailChange,
    editedCountryCode,
    editedMobileNumber,
    onChangeCountryCode,
    onMobileNumberChange,
    nextSignerExists,
}) => {

    const onSpouseMailChange = (e: any) => {
        //if (!e.target.value || !isValidEmailAddress(e.target.value)) {
        //    return;
        //} else {
        handleSpouseMailChange(e.target.value);
        //}

    };

    const heading = nextSignerExists ? "Spouse's Signature Required" : "Spouse's Review Required";
    const action = nextSignerExists ? "Sign their forms electronically." : "Review their forms.";
    return (
        <div className="esign-spouse">
            <div className="spouse-header">
                {heading}
                    </div>
            <div className="content-paragraph">
                Please provide an email address for your spouse. It may be the same as your own if you share an email account.
                {`They will receive a similar request asking them to ${action}`}
                </div>
            <div className="padding-full-20" data-test-auto="00CBB4CE-B89F-4E91-B4F9-E20CE46C5BAA">

                {
                    spouseName ?

                        (<>
                            <span className="width-40 dib">
                                Spouse's Name: <FormLabel><b>{spouseName}</b></FormLabel>
                            </span>
                            <span className="width-60  dib">
                                Email Address:
                                <input className="height-44"
                                    type="text" data-test-auto="2C580DDD-1425-4242-A2B2-F371B968ACEF"
                                    value={spouseEmail}
                                    onChange={onSpouseMailChange} />
                            </span>
                            <span style={{ width: "100%" }} className="dib"></span>
                            <span className="width-40 dib"></span>
                            <span className="width-60  dib">
                                <div className="row" style={{ marginLeft: 0 }}>
                                    <div className="form-inline" >
                                        Phone number:
                                                <div className="phoneNumberSelect">
                                            <SelectComponent
                                                id="ddlCountryCode"
                                                options={CountryCodeOptions()}
                                                onChange={onChangeCountryCode}
                                                selectedValue={editedCountryCode}
                                                data-test-auto="E26B85C0-9D8E-4F77-B30D-C49AA30C3DF2" />
                                        </div>

                                        <PhoneNumberComponent
                                            phoneNumber={editedMobileNumber}
                                            handleChangePhoneNumber={onMobileNumberChange}
                                            data-test-auto="088E1A87-206B-4FFF-AF31-EA86901CCF58"
                                            className={"height-44"}
                                        >
                                        </PhoneNumberComponent>

                                    </div>
                                </div>
                            </span>
                        </>
                        )
                        : <Shimmer height={50} />}

            </div>
        </div>);
};

export { SpouseEmail };
