import { ClientType, DocumentStatus, SignatureStatus, SignatureType } from '../../core/common/Enums';
import { ISignerModel } from 'src/core/domain/models/ISignerModel';
import * as React from 'react';
import { match } from 'react-router';
import { IDocument } from '../../core/domain/models/esign/Document';
import { IMarriedJointTaxReturn } from '../../core/domain/models/ITaxReturn';
import { PathConstants, SiginingConstants } from '../Common/Constants';
import { MarsNotifier } from '../Common/Notification/MarsNotifier';
import { Shimmer } from '../Common/Shimmer/Shimmer';
import { Body } from '../Common/StepLayout/Body';
import { StepLayout } from '../Common/StepLayout/StepLayout';
import { isValidEmailAddress, validatePhone } from '../Common/Validations';
import TaxpayerHelper from '../Helper/TaxpayerHelper';
import { EditSpouseMail } from './EditSpouseMail';
import { ChangeNumber } from '../../components/Layout/ChangeNumber';
import { getDecodedClientId, mobileNumberMasking } from '../Helper/HelperFunction';
import { logger } from '../../routes';
import { LogEventConstants } from '../Helper/Constants';
import { History } from 'history';

export type ISpouseSignRequiredProps = {
    companyName: string;
    mutual: IMarriedJointTaxReturn;
    match: match;
    signerData: ISignerModel[];
    refreshTaxDocument: (clientGuid: string) => void;
    updateSpouseMailChange?(clientGuid: string, id: string, mail: string, type: ClientType, step: boolean, callback?: (step: boolean) => void): void;
    updateSpouseMobileNumber?(clientGuid: string, id: string, number: string, countryCodeValue: string,
        ssnValue: string, clientType: ClientType, callback: () => void): void;
    signatureControlsData: IDocument[];
    sign?: (clientId: string, documentData: IDocument[], callback: (status: boolean) => void) => void;
    gotoNextStep: () => void;
    signingCompleted?: () => boolean | undefined;
    isPreviewMode: boolean;
    nextSignerExists: boolean;
    review: (clientId: string, callback?: ()=>void) => void;
    signingRequiredForSigner: boolean;
    signatureFormSelectionType: SignatureType;
    voucherExists?: boolean;
    goToStep: (stepNumber: number) => void;
    relatedTaxReturnDelivered: boolean;
    goToCompletionWizard: (isDocSettingsUpdated: boolean) => void;
    isClientView: boolean;
    history: History;
    signatureStatus : SignatureStatus;
}

interface ISpouseSignRequiredState {
    emailAddress: string,
    mobileNumber: string,
    countryCode: string,
    name: string,
    showAlert: boolean,
    showEditSpouseEmailPopUp: boolean,
    showEditSpouseMobileNumberPopUp: boolean,
    showSubmitLoader: boolean,
}

const pageTitle = "Spouse Sign Required Step";

export class SpouseSignRequired extends React.Component<ISpouseSignRequiredProps, ISpouseSignRequiredState>
{
    constructor(props: any) {
        super(props);
        var param: any = this.props.match.params;
        let clientId = getDecodedClientId(param.clientId);
        this.state = {
            emailAddress: TaxpayerHelper.getSecondSignerEmail(props.mutual, props.signerData, clientId),
            mobileNumber: TaxpayerHelper.getSecondSignerMobileNumber(props.mutual, props.signerData, clientId),
            countryCode: TaxpayerHelper.getSecondSignerCountryCode(props.mutual, props.signerData, clientId),
            name: TaxpayerHelper.getSecondSignerName(props.mutual, props.signerData, clientId),
            showAlert: true,
            showEditSpouseEmailPopUp: false,
            showEditSpouseMobileNumberPopUp: false,
            showSubmitLoader: false,
        }
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.Open} ${pageTitle}`,
                { count: 1, page: pageTitle, clientId: clientId }
            )
        );
    }

    static getDerivedStateFromProps(props: ISpouseSignRequiredProps, state: ISpouseSignRequiredState) {
        if (!props.mutual || !props.mutual.spouse || !props.mutual.spouse.email || !props.mutual.spouse.name) {
            return null;
        }

        else {
            if ((props.mutual.spouse && props.mutual.spouse.email && props.mutual.spouse.email !== state.emailAddress) ||
                (props.mutual.spouse && props.mutual.spouse.mobileNumber && props.mutual.spouse.mobileNumber !== state.mobileNumber) ||
                (props.mutual.spouse && props.mutual.spouse.mobileNumber && props.mutual.spouse.countryCode !== state.countryCode) ||
                (props.mutual.spouse && props.mutual.spouse.name && props.mutual.spouse.name !== state.name)) {

                var param: any = props.match.params;
                let clientGuid = getDecodedClientId(param.clientId);

                var isTaxpayerLoggedIn = TaxpayerHelper.isTaxpayerLoggedIn(props.mutual, props.signerData, clientGuid);
                return {
                    emailAddress: isTaxpayerLoggedIn ? (props.mutual.spouse.email ? props.mutual.spouse.email : "") :
                        (props.mutual.taxpayer.email ? props.mutual.taxpayer.email : ""),
                    mobileNumber: isTaxpayerLoggedIn ? (props.mutual.spouse.mobileNumber ? props.mutual.spouse.mobileNumber : "") :
                        (props.mutual.taxpayer.mobileNumber ? props.mutual.taxpayer.mobileNumber : ""),
                    countryCode: isTaxpayerLoggedIn ? (props.mutual.spouse.countryCode ? props.mutual.spouse.countryCode : "") :
                        (props.mutual.taxpayer.countryCode ? props.mutual.taxpayer.countryCode : ""),
                    name: isTaxpayerLoggedIn ? (props.mutual.spouse.name ? props.mutual.spouse.name : "") :
                        props.mutual.taxpayer.name ? props.mutual.taxpayer.name : ""
                };
            }
        }
        return null;
    }


    onCancelEditSpouseEmailPopUp = () => {
        this.setState({
            showEditSpouseEmailPopUp: false,
        })
    }
    onHideChangeNumberPopUp = () => {
        this.setState({
            showEditSpouseMobileNumberPopUp: false,
        })
    }
    onChangeMobileNumberClick = () => {
        this.setState({
            showEditSpouseMobileNumberPopUp: true,
        })
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.Open} Change Number layout`,
                { count: 1, page: "Change Number layout" }
            )
        );
    }
    onSpouseMobileUpdateSuccess = (clientType: ClientType) => {
        MarsNotifier.Success(SiginingConstants.StatusMessage.EditMobileNumber, null);
    }
    onSpouseEmailUpdateSuccess = () => {
        MarsNotifier.Success(SiginingConstants.StatusMessage.EditSpouseEmailSuccess, null);
    }
    onSaveNumberClick = (changedMobileNumber: string, changedCountryCode: string) => {

        var param: any = this.props.match.params;
        let clientGuid = getDecodedClientId(param.clientId);
        var isTaxpayerLoggedIn = TaxpayerHelper.isTaxpayerLoggedIn(this.props.mutual, this.props.signerData, clientGuid);
        var mobileData = TaxpayerHelper.getSecondSignerMobileData(this.props.mutual, this.props.signerData, clientGuid);
        if (mobileData.countryCode !== changedCountryCode || mobileData.mobileNumber !== changedMobileNumber) {
            if (validatePhone(changedMobileNumber)) {
                if (changedCountryCode && changedCountryCode !== "") {
                    const taxDocument = this.props.mutual;
                    const taxDocumentClient: any = this.props.match.params;
                    const clientId: number = TaxpayerHelper.getSecondSignerId(this.props.mutual, this.props.signerData, clientGuid);
                    const ssnValue: string = isTaxpayerLoggedIn ? (this.props.mutual && this.props.mutual.spouse ? this.props.mutual.spouse.ssn : "") :
                        this.props.mutual.taxpayer.ssn;;
                    const spouseClientType: ClientType = TaxpayerHelper.getSecondSignerType(this.props.mutual, this.props.signerData, clientGuid)

                    this.setState({ mobileNumber: changedMobileNumber, countryCode: changedCountryCode },
                        () => {
                            this.props.updateSpouseMobileNumber &&
                                this.props.updateSpouseMobileNumber(taxDocumentClient.clientId, clientId.toString(), this.state.mobileNumber,
                                    this.state.countryCode, ssnValue, spouseClientType, () => { this.onSpouseMobileUpdateSuccess(spouseClientType) });
                        });

                    let param: any = this.props.match.params;
                    logger.trackEvent(
                        logger.buildEvent(
                            `${LogEventConstants.Common.Esign.SpouseNumberUpdated} ${pageTitle}`,
                            { count: 1, page: pageTitle, clientId: param.clientId, code: this.state.countryCode, number: this.state.mobileNumber }
                        )
                    );

                    if (isTaxpayerLoggedIn) {
                        taxDocument.spouse.mobileNumber = changedMobileNumber;
                        taxDocument.spouse.countryCode = changedCountryCode;
                    }
                    else {
                        taxDocument.taxpayer.mobileNumber = changedMobileNumber;
                        taxDocument.taxpayer.countryCode = changedCountryCode;
                    }
                    this.setState({ showEditSpouseMobileNumberPopUp: false });
                    this.props.refreshTaxDocument(param.clientId);
                } else {
                    MarsNotifier.Warning(SiginingConstants.WarningMessage.CountryCodeWarning, null);
                }
            }
        }
        else {
            this.setState({ showEditSpouseMobileNumberPopUp: false });
        }
    }

    updateSpouseEmail = (changedEmailAddress: string) => {
        if (changedEmailAddress.trim() === "" || !isValidEmailAddress(changedEmailAddress)) {
            MarsNotifier.Warning(SiginingConstants.WarningMessage.InvalidEmailAddress, null);
            return false;
        }
        else {
            let param: any = this.props.match.params;
            let clientGuid = getDecodedClientId(param.clientId);
            let clientId: number = TaxpayerHelper.getSecondSignerId(this.props.mutual, this.props.signerData, clientGuid);
            this.setState({ emailAddress: changedEmailAddress },
                () => {
                    this.props.updateSpouseMailChange &&
                        this.props.updateSpouseMailChange(
                            param.clientId,
                            clientId.toString(),
                            this.state.emailAddress,
                            TaxpayerHelper.getSecondSignerType(this.props.mutual, this.props.signerData, clientGuid),
                            false,
                            () => { this.onSpouseEmailUpdateSuccess(); this.props.refreshTaxDocument(param.clientId); });
                });
            var isTaxpayerLoggedIn = TaxpayerHelper.isTaxpayerLoggedIn(this.props.mutual, this.props.signerData, clientGuid);
            const taxDocument = this.props.mutual;
            if (isTaxpayerLoggedIn) {
                taxDocument.spouse.email = changedEmailAddress;
            }
            else {
                taxDocument.taxpayer.email = changedEmailAddress;
            }
            this.setState({ showEditSpouseEmailPopUp: false });
            logger.trackEvent(
                logger.buildEvent(
                    `${LogEventConstants.Common.Esign.SpouseMailUpdated}  ${pageTitle}`,
                    { count: 1, page: pageTitle, clientId: param.clientId, email: this.state.emailAddress }
                )
            );
        }
    }

    onChangeEmailClick = () => {
        this.setState({
            showEditSpouseEmailPopUp: true,
            showAlert: true
        })
    }

    review = (): boolean => {

        const {
            signingRequiredForSigner,
            signatureFormSelectionType,
            review,
            voucherExists,
            relatedTaxReturnDelivered,
            isPreviewMode,
            goToStep,
            goToCompletionWizard,
            match: { params },
            gotoNextStep,
            isClientView,
            history,
            signatureStatus,
            refreshTaxDocument
        } = this.props;

        if (!signingRequiredForSigner &&
            signatureFormSelectionType !== SignatureType.ManualSign) {

        if(signatureStatus.toString() !== SignatureStatus[SignatureStatus.PartiallyReviewed]){
            review((params as any).clientId, ()=> refreshTaxDocument((params as any).clientId));
        }

            if (!voucherExists || relatedTaxReturnDelivered) {
                if (isPreviewMode) {
                    if (isClientView) {
                        const param: any = this.props.match.params;
                        history && history.push(`${PathConstants.CompletionWizard}${param.clientId}`);
                    } else {
                        goToStep(0)
                    }
                } else {
                    goToCompletionWizard(true)
                }
            } else {
                gotoNextStep();
            }
            return true;
        }
        return false;
    }

    onNext(index: number) {

        if (!this.state.emailAddress || this.state.emailAddress.trim() === "" || !isValidEmailAddress(this.state.emailAddress)) {
            MarsNotifier.Warning(SiginingConstants.WarningMessage.InvalidEmailAddress, null);
            return;
        }

        if (this.review()) {
            return;
        }

        this.setState({ showSubmitLoader: true });
        let param: any = this.props.match.params;
        logger.trackTrace(`IsPreviewMode: ${this.props.isPreviewMode}`);
        if (this.props.isPreviewMode) {
            this.props.gotoNextStep();
            logger.trackEvent(
                logger.buildEvent(
                    `${LogEventConstants.Common.Esign.PreviewMode} ${pageTitle}`,
                    { count: 1, page: pageTitle }
                )
            );
        }
        else {
            if (this.props.signingCompleted && this.props.signingCompleted()) {
                logger.trackTrace(`gotoNextStep : Signing Completed since visited steps indicates signing completion`);
                logger.buildEvent(
                    `${LogEventConstants.Common.Esign.SignCompletedWithSteps} ${pageTitle}`,
                    { count: 1, page: pageTitle, clientId: param.clientId }
                );
                this.props.gotoNextStep();

            }
            else {
                this.props.sign && this.props.sign(param.clientId, this.props.signatureControlsData, (status: boolean) => {
                    if (status !== undefined && status === true) {
                        logger.trackTrace(`gotoNextStep : Signing Completed since API response ${status}`);
                        logger.trackEvent(
                            logger.buildEvent(
                                `${LogEventConstants.Common.Esign.SignCompletedWithApiSatus} ${status} ${pageTitle}`,
                                { count: 1, page: pageTitle, status: status, clientId: param.clientId }
                            )
                        );
                        this.props.gotoNextStep();
                    }
                    else {
                        this.setState({ showSubmitLoader: false });
                        MarsNotifier.Error("Signing process failed!", "");
                        logger.trackEvent(
                            logger.buildEvent(
                                `${LogEventConstants.Common.Esign.SignprocessFailed} ${pageTitle}`,
                                { count: 1, page: pageTitle, clientId: param.clientId }
                            )
                        );
                    }
                });
            }
        }
    }

    render() {

        const header = this.props.nextSignerExists ? "Electronic Signature" : "Review";
        const action = this.props.nextSignerExists ? "sign" : "review";
        const forwardAction = this.props.nextSignerExists ? "signing" : "review";

        return (<StepLayout>
            <Body lg={10} md={12} sm={12} xs={12}
                containerClassName={"content-wrapper-full"}
                title={`Spouse ${header} Required`}>
                {
                    <React.Fragment><br /><div className="content-paragraph" data-test-auto="8CEFEB12-51FB-45C0-BF1B-EF2FDD76C03D">
                        <span className="font-weight-normal-bold">{this.state.name}</span>
                        {`is still required to ${action} and will receive an email notification sent to:`}
                        <br />
                        <br />
                        <span className="font-weight-normal-bold">{this.state.emailAddress}</span>
                        <span className="tab" style={{ color: '#00cc00' }} data-test-auto="CCF78AE0-CFD1-49C7-AB9C-34BD35B4C6A9"
                            onClick={() => { this.onChangeEmailClick() }}>{this.state.emailAddress ? "Change Email Address" : "Enter Email Address"}</span>
                    </div>
                    </React.Fragment>

                }
                {
                    <div className="content-paragraph" data-test-auto="8CEFEB12-51FB-45C0-BF1B-EF2FDD76C03F"> <br />
                        <span className="font-weight-normal-bold">{this.state.name}</span> mobile device number :
                        <span className="font-weight-normal-bold">
                            {this.state.countryCode} {mobileNumberMasking(this.state.mobileNumber)}
                        </span>
                        <span className="tab" style={{ color: '#00cc00' }} data-test-auto="CCF78AE0-CFD1-49C7-AB9C-34BD35B4C6B9"
                            onClick={() => { this.onChangeMobileNumberClick() }}>
                            {this.state.mobileNumber ? "Change Mobile Number" : "Enter Mobile Number"}</span>
                    </div>
                }
                <br />
                <br />
                <div className="content-paragraph" data-test-auto="EDF130A0-C32F-433E-94B5-A5B65CA933B6">
                    {`Once they have completed the ${forwardAction} process, your documents will be forwarded to:`}
                    <br />
                    <br />
                    <span className="font-weight-normal-bold" data-test-auto="EDF130A0A5B65CA933B6">
                        {this.props.companyName}
                    </span>
                </div>

                {
                    this.state.showEditSpouseEmailPopUp &&
                    <EditSpouseMail
                        onHideEditSpouseEmail={this.onCancelEditSpouseEmailPopUp}
                        onSaveButtonClick={this.updateSpouseEmail}
                        showAlert={this.state.showAlert}
                        spouseEmail={this.state.emailAddress}
                        showState={this.state.showEditSpouseEmailPopUp} />
                }
                <ChangeNumber
                    onHideChangeNumberPopUp={this.onHideChangeNumberPopUp}
                    showState={this.state.showEditSpouseMobileNumberPopUp}
                    mobileNumber={this.state.mobileNumber}
                    countryCode={this.state.countryCode}
                    onSaveNumberClick={this.onSaveNumberClick}
                />

            </Body>
        </StepLayout>);



    }
}

export default SpouseSignRequired;