import * as React from 'react';


export enum CustomButtonPlacement {
    Left = 0,
    Center = 1,
    Right = 2
}

export enum CustomButtonSize {
    SM = 0,
    MD = 1,
    LG = 2,
    XL = 3
}


interface CustomButtonProps {
    text: string;
    icon?: any;
    tooltip?: string;
    onClick?: () => void;
    automationTestId?: string;
    placement?: CustomButtonPlacement;
    size?: CustomButtonSize;   
    customClass?: string;
}

export const CustomButton: React.FunctionComponent<CustomButtonProps> = ({
    text, icon, tooltip, onClick, placement, size, automationTestId, customClass
}) => {

    let getPlacementClass = () => {

        switch (placement) {
            case CustomButtonPlacement.Left:
                return " left ";
            case CustomButtonPlacement.Center:
                return " center ";
            case CustomButtonPlacement.Right:
                return " right ";
            default:
                return " ";
        }
    }
    
    let getCustomClass = () => {
        if (customClass != undefined)
            return customClass;
        else
            return "";
    }

    let getSizeClass = () => {

        switch (size) {
            case CustomButtonSize.XL:
                return " xl ";
            case CustomButtonSize.LG:
                return " lg ";
            case CustomButtonSize.MD:
                return " md ";
            case CustomButtonSize.SM:
                return " sm ";
            default:
                return " lg ";
        }
    }
    const IconSvg = icon;
    return (<div className={getCustomClass() + " " + "custom-button " + getPlacementClass() + " " + getSizeClass() } title={tooltip} data-test-auto={automationTestId} onClick={onClick}>
        
            <div className={"custom-button-icon-container"}>
                <IconSvg />
            </div>

        <div className={"custom-button-text-container"}>
            {text}
        </div>

    </div>);
};