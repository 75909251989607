import * as React from 'react';


export enum CustomButtonPlacement {
    Left = 0,
    Center = 1,
    Right = 2
}

interface CustomLargeButtonProps {
    text: string;
    icon: any;
    tooltip?: string;
    onClick?: () => void;
    automationTestId?: string;
    placement?: CustomButtonPlacement;
    className?: string;
}

export const CustomLargeButton: React.FunctionComponent<CustomLargeButtonProps> = ({
    text, icon, tooltip, onClick, placement, automationTestId, className
}) => {

    let getPlacementClass = () => {

        switch (placement) {
            case CustomButtonPlacement.Left:
                return " left ";
            case CustomButtonPlacement.Center:
                return " center ";
            case CustomButtonPlacement.Right:
                return " right ";
            default:
                return " ";
        }
    }

    const IconSvg = icon;
    
    return (<div
        className={"custom-large-button " + " " + className + getPlacementClass()}
        title={tooltip}
        data-test-auto={automationTestId}
        onClick={onClick}>        
        <div className={"custom-large-button-icon-container"}>
            <IconSvg  />
        </div>

        <div className={"custom-large-button-text-container"}>
            {text}
        </div>
    </div>);
};