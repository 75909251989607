import { IUserModel } from "./IUserModel";
import { SignatureType, KBAMode } from "src/core/common/Enums";
import { DocumentGroups } from "./IGroup";
import { ClientTypes } from "./ITaxClient";

export interface IDocumentSettings {
    documentId: number;
    deliverySettings: IDeliverySettings;
    documentDisplaySetting: IDocumentDisplaySetting;
    documentSignatureSetting: IDocumentSignatureSetting;
    documentNotificationSetting: IDocumentNotificationSetting;
    documentRetentionSetting: IDocumentRetentionSetting
    isModified: boolean;
}

export interface IDeliverySettings {
    deliverTo: ClientTypes,
    contactPerson: number,
    preparerMessage: MessageDetails,
    sender: DefaultSenderInfo,
    notifyUser: number;
    manualAddressId: number;
    isDirectDeliveryToTaxCaddy: boolean;
    deliveryMode: DeliveryMode
    paperReturnSettings: IPaperReturnSettings;
}

export interface IDocumentDisplaySetting {
    isSendWithoutInvoice: boolean;
    isEnableInvoice: boolean;
    customColumn: IColumnValues;
}

export interface IDocumentSignatureSetting {
    signatureFormSelectionType: SignatureType;
    knowledgeBasedAuthentication: boolean;
    signatureStampUser: IUserModel;
    kbaMode: KBAMode;
    enableSignerDelegation: boolean;
    enableDelegateeKbaAuthentication: boolean;
}

export interface IDocumentNotificationSetting {
    paymentVoucherNotificationSettingsModel: IPaymentVoucherNotificationSettings;
    signingReminderNotificationSettingsModel: ISigningReminderNotificationSettings;
    notifyUser: IUserModel;
}

export interface IDocumentRetentionSetting {
    retentionPeriod: number;
}

export interface MessageDetails {
    id: number;
    name: string;
    body: string;
    isAllowToEdit: boolean;
}

export const initialMessageDetails: MessageDetails = {
    id: -101,
    name: "Custom Message",
    body: "Custom Body",
    isAllowToEdit: false
}

export interface DefaultSenderInfo {
    senderType: DefaultSenderInfoType;
    senderId: number;
}

export enum DefaultSenderInfoType {
    None = "None",
    Company = "Company",
    User = "User"
}

export const initialDefaultSenderInfo: DefaultSenderInfo = {
    senderType: DefaultSenderInfoType.None,
    senderId: -1001
}

export enum PaperReturnDeliveryType {
    None = 0,
    MultipleFiles = 1,
    SingleFile = 2
}

export enum DeliveryMode {
    ElectronicFiled = 0,
    PaperFiled = 1
}

export interface IPaperReturnSettings {
    deliveryType: PaperReturnDeliveryType;
    documentOrder: DocumentGroups[];
    isNotifyUserEnabled: boolean;
}

export const initialPaperReturnSettings: IPaperReturnSettings = {
    deliveryType: PaperReturnDeliveryType.None,
    documentOrder: [],
    isNotifyUserEnabled: false
}

export interface IColumnValues {
    id: number;
    value: string;
    customColumn: number;
    inUseCount: number;
}

export interface IPaymentVoucherNotificationSettings {
    enablePaymentVoucherReminder: boolean,
    noOfDaysForVoucherReminder: number,
    disabledVoucherReminderFromTP: boolean
}

export interface ISigningReminderNotificationSettings {
    enableSigningReminder: boolean,
    noOfDaysForSigningReminder: number
}